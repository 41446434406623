import React from 'react';
import { Box, Button } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { useStoreon } from 'storeon/react';

import Api from '../../../api/Api';
import DecoratedTextField from '../../common/form/v2/DecoratedTextField';

function CommentForm({ callback, comment, type, typeid }) {
	const { dispatch } = useStoreon();
	const { control, handleSubmit, reset } = useForm({
		defaultValues: { comment: comment?.text || '' }
	});

	const onSubmit = data => {
		if (comment?.uuid) {
			Api.updateComment({ comment: data.comment }, comment.uuid)
				.then(() => callback && callback())
				.catch(() =>
					dispatch('notification/add', {
						message: 'There was a problem updating a comment',
						severity: 'error',
						variant: 'banner'
					})
				);
		} else {
			const formData = new FormData();
			formData.append('comment', data.comment);
			Api.createComment(formData, type, typeid)
				.then(() => {
					reset();
					if (callback) callback();
				})
				.catch(() => {
					dispatch('notification/add', {
						message: 'There was a problem creating a comment',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	};

	return (
		<Box alignItems='center' display='flex' pt={3}>
			<Controller
				control={control}
				name='comment'
				label='Comment'
				render={({ field: { onBlur, onChange, value } }) => (
					<DecoratedTextField
						aria-label='comment'
						editing
						label='Write a comment'
						multiline
						rows={4}
						onBlur={onBlur}
						onChange={onChange}
						placeholder='Write a comment'
						value={value}
					/>
				)}
			/>
			<Button
				color='primary'
				onClick={handleSubmit(onSubmit)}
				size='md'
				style={{ marginLeft: '16px' }}
				variant='contained'
			>
				Post
			</Button>
		</Box>
	);
}

export default CommentForm;
