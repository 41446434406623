import React, { Component } from 'react';

import './Loading.css';

export default class Loading extends Component {
	render() {
		return (
			<div className='loading'>
				<img
					alt="we're loading up the data you asked for"
					src='/img/loading.gif'
				/>
			</div>
		);
	}
}
