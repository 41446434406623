import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../style/colors';

export default makeStyles(theme => ({
	p: {
		paddingTop: '5px'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '20px',
		paddingRight: '12px',
		paddingLeft: '12px',
		paddingTop: '6px',
		paddingBottom: '6px',
		minWidth: 0,
		color: colors.black.lowOpacity,
		'&:focus': {
			outline: 'none'
		},
		'&:hover': {
			background: colors.purple.primary,
			color: colors.white.primary
		}
	},
	selected: {
		color: `${colors.white.primary}`,
		background: colors.elektra.primary,
		'&:disabled': {
			color: 'white'
		}
	},
	iconButton: {
		padding: 0,
		paddingRight: '8px'
	}
}));
