import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

interface TplProductRowProperties {
	name: string;
}

const GRID_SPACING_VALUE = 2;

export default function TplProductRow({ name }: TplProductRowProperties) {
	return (
		<Grid
			container
			columnSpacing={{
				xs: GRID_SPACING_VALUE
			}}
		>
			<Grid item xs={12} sm>
				<Typography
					variant='body2'
					sx={{
						color: 'rgba(0, 0, 0, 0.87)'
					}}
				>
					{name}
				</Typography>
			</Grid>
		</Grid>
	);
}
