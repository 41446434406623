import React from 'react';
import { ReviewText } from '../../Utils';
import SubsList from '../../Utils/SubsList';

function ReviewForm() {
	return (
		<>
			<ReviewText />
			<SubsList />
		</>
	);
}

export default ReviewForm;
