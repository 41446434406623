import React from 'react';
import EventBody from '../EventBody';
import { TypeForm } from '../../Forms';

function Define() {
	return (
		<EventBody
			title='Define Event'
			description='Specify the criteria for this event. An event will be triggered when a Conserv sensor detects these conditions.'
		>
			<TypeForm editable />
		</EventBody>
	);
}

export default Define;
