import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '24px',
		letterSpacing: '0em'
	},
	subscriptionsTitle: {
		fontWeight: 500,
		marginTop: '24px'
	},
	list: {
		paddingLeft: '1.5em'
	},
	marker: {
		'&::marker': {
			fontSize: '0.8em'
		}
	}
}));
