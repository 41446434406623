import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import MUIPlacesAutocomplete from 'mui-places-autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import Icon from '@mdi/react';

import DecoratedTextField from '../../common/form/v2/DecoratedTextField';
import { loadScript } from '../../../hooks/UseScript';
import AddressUtil from '../../../util/AddressUtil';
import icons from '../../../style/icons';

import useStyles from './styles';
import './index.css';

function EditAddress({ editing }) {
	const classes = useStyles();

	const [mapsLoaded, setMapsLoaded] = useState(false);
	const [showForm, setShowForm] = useState(false);

	const {
		control,
		formState: { errors },
		setValue,
		watch
	} = useFormContext();

	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places`,
			loaded => setMapsLoaded(loaded)
		);
	}, []);

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (
				(value.add_1 || value.city || value.state || value.zip) &&
				!showForm
			) {
				setShowForm(true);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		if (errors.add_1 || errors.city || errors.state || errors.zip) {
			setShowForm(true);
		}
	}, [errors]);

	const suggestionCallback = address => {
		setValue('add_1', address.add_1, {
			shouldValidate: true,
			shouldDirty: true
		});
		setValue('city', address.city, { shouldValidate: true, shouldDirty: true });
		setValue('country', address.country, {
			shouldValidate: true,
			shouldDirty: true
		});
		setValue('state', address.state, {
			shouldValidate: true,
			shouldDirty: true
		});
		setValue('zip', address.zip, { shouldValidate: true, shouldDirty: true });
		setValue('elevation', address.elevation, {
			shouldValidate: true,
			shouldDirty: true
		});
		setShowForm(true);
	};

	const onSuggestionSelected = selected =>
		AddressUtil.onSuggestionSelected(selected, suggestionCallback);

	const handleShowForm = () => setShowForm(true);

	const handleHideForm = () => setShowForm(false);

	const renderSuggestions = () => <div />;

	const address = watch('city');

	return (
		<Box>
			{!showForm && mapsLoaded && (
				<Box display='flex' alignItems='center'>
					<Box pr={1} pb={2}>
						<Icon path={icons.search} size={1} />
					</Box>
					<Box pb={1} width='100%'>
						<MUIPlacesAutocomplete
							disabled={!editing}
							onSuggestionSelected={onSuggestionSelected}
							textFieldProps={{
								InputProps: { style: { height: '56px' } },
								variant: 'outlined'
							}}
							renderTarget={renderSuggestions}
						/>
					</Box>
				</Box>
			)}
			{!showForm && (
				<Box pl={4}>
					<Typography className={classes.link} onClick={handleShowForm}>
						Add address manually
					</Typography>
				</Box>
			)}
			{showForm && (
				<>
					<Box display='flex' flexDirection='column' pt={address ? 0 : 2}>
						<Box width='100%'>
							<Controller
								control={control}
								name='add_1'
								render={({ field: { onChange, value, ref } }) => (
									<DecoratedTextField
										required
										id='add_1'
										label='Address'
										aria-label='address'
										error={errors.add_1}
										hasSpace
										helperText={errors.add_1?.message}
										icon={icons.address}
										inputProps={{ height: '56px' }}
										onChange={onChange}
										value={value}
										editing={editing}
										inputRef={ref}
									/>
								)}
							/>
						</Box>
						<Box display='flex' pt={2}>
							<Box pr={2}>
								<Controller
									control={control}
									name='city'
									render={({ field: { onChange, value, ref } }) => (
										<DecoratedTextField
											required
											id='city'
											label='City'
											aria-label='city'
											error={errors.city}
											hasSpace
											helperText={errors.city?.message}
											inputProps={{ height: '56px' }}
											onChange={onChange}
											value={value}
											editing={editing}
											inputRef={ref}
										/>
									)}
								/>
							</Box>
							<Box pr={2}>
								<Controller
									control={control}
									name='state'
									render={({ field: { onChange, value, ref } }) => (
										<DecoratedTextField
											required
											id='state'
											label='State'
											aria-label='state'
											error={errors.state}
											hasSpace
											helperText={errors.state?.message}
											inputProps={{ height: '56px' }}
											onChange={onChange}
											value={value}
											editing={editing}
											inputRef={ref}
										/>
									)}
								/>
							</Box>
							<Box>
								<Controller
									control={control}
									name='zip'
									render={({ field: { onChange, value, ref } }) => (
										<DecoratedTextField
											required
											id='zip'
											label='Zip Code'
											aria-label='postal code'
											error={errors.zip}
											hasSpace
											helperText={errors.zip?.message}
											inputProps={{ height: '56px' }}
											onChange={onChange}
											value={value}
											editing={editing}
											inputRef={ref}
										/>
									)}
								/>
							</Box>
						</Box>
					</Box>
					<Box pt={2} width='100%'>
						<Controller
							control={control}
							name='country'
							render={({ field: { onChange, value, ref } }) => (
								<DecoratedTextField
									required
									id='country'
									label='Country'
									aria-label='country'
									error={errors.country}
									hasSpace
									helperText={errors.country?.message}
									inputProps={{ height: '56px' }}
									onChange={onChange}
									value={value}
									editing={editing}
									inputRef={ref}
								/>
							)}
						/>
					</Box>
					<Box pt={2} pl={4}>
						<Typography className={classes.link} onClick={handleHideForm}>
							Search for a location
						</Typography>
					</Box>
				</>
			)}
		</Box>
	);
}

export default EditAddress;
