import React from 'react';
import { useStoreon } from 'storeon/react';
import Icon from '@mdi/react';
import { Tooltip, Box, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import icons from '../../../style/icons';
import { tooltipStyles } from '../../../style/styles';
import VideoModal from './VideoModal';

const useStyles = makeStyles(() => ({
	...tooltipStyles
}));

function InfoTooltip(props) {
	const classes = useStyles();
	const option = props.option || tooltipOptions[props.name];
	const { dispatch } = useStoreon();

	/**
	 * This function will insert video or outside help links where relevant.
	 */
	const formatTitle = () => (
		<Box>
			<Typography>{option.text}</Typography>
			<Box pb={0.5} />
			{option.moreInfoUrl || option.video ? (
				<Typography variant='caption'>Click icon to learn more</Typography>
			) : null}
		</Box>
	);

	const showVideo = () => {
		dispatch('navstate/update', {
			dialogContent: <VideoModal video={option.video} open />,
			dialogOpen: true
		});
	};

	const getIcon = () => {
		if (option.moreInfoUrl) {
			return (
				<Link href={option.moreInfoUrl} target='_new'>
					<Icon size={0.5} path={icons.info} />
				</Link>
			);
		}
		if (option.video) {
			return (
				<Link href='#' onClick={showVideo}>
					<Icon size={0.5} path={icons.info} />
				</Link>
			);
		}
		return <Icon size={0.5} path={icons.info} />;
	};

	if (option) {
		return (
			<Tooltip
				arrow
				title={formatTitle(option)}
				aria-label={option.text}
				placement={props.placement}
				classes={{
					tooltip: classes.infoTooltip,
					arrow: classes.infoTooltipArrow
				}}
			>
				{props.children ? (
					props.children
				) : (
					<Box display='inline' pl={0.5} pr={0.5}>
						{getIcon()}
					</Box>
				)}
			</Tooltip>
		);
	}
	return null;
}

export const tooltipOptions = {
	levelProfile: {
		text: 'Level profiles define the appropriate environmental levels for your collection',
		moreInfoUrl:
			'https://support.conserv.io/knowledge/how-to-assign-level-profiles'
	},
	tempLevel: {
		text: 'Incorrect temperature or extreme fluctuations can cause damage to objects. Set the target temperature range so you can compare your readings against the target.'
		// video: {
		//   url: 'https://www.loom.com/embed/24749031490e4ef682337e7ba00b409e',
		//   title: 'How to assign level profiles',
		//   moreInfoUrl:
		//     'https://support.conserv.io/knowledge/how-to-assign-level-profiles',
		// },
	},
	rhLevel: {
		text: 'Incorrect relative humidity or extreme fluctuations can cause damage to objects. Set the target relative humidity range so you can compare your readings against the target.'
	},
	lightHours: {
		text: 'Limiting the number of hours of light exposure reduces the risk of fading or other light related damage.'
	},
	illum: {
		text: 'High light intensity and/or long exposure can cause irreversible damage to objects. Lux hours are calculated by illuminance multiplied by hours of light per day.'
	},
	uv: {
		text: 'Ultraviolet light is particularly damaging to objects, causing rapid fading and degredation'
	},
	observation: {
		text: 'You can log any noteworthy observations such as a temperature spike, pest sighting, open window, HVAC outage, or any other relevant event'
	},
	relatedTo: {
		text: 'Observations can be made about locations, spaces, or sensor data'
	},
	startTime: {
		text: 'Observations can be made at a point in time, or cover a span of time'
	},
	endTime: {
		text: 'Observations can be made at a point in time, or cover a span of time'
	},
	importStatus: {
		text: 'Was this import completed successfully, or can it be re-run?'
	},
	importSummary: {
		text: 'How many rows were imported, and what date range does the imported data cover?'
	},
	notificationAlertName: {
		text: 'Each alert profile has a name to help you keep track of which alert was triggered'
	},
	notificationData: {
		text: 'When an alert is triggered, we capture all the available data around the reading that triggered the alert'
	},
	condition: {
		text: 'What condition triggered this alert?'
	},
	triggeredBy: {
		text: 'If this alert was triggered by a sensor reading, which sensor triggered the alert?'
	},
	alertName: {
		text: 'Alert profiles are named to help you keep track of which alerts are triggered'
	},
	alertSpace: {
		text: 'In which space does this alert apply?'
	},
	alertSensor: {
		text: 'Which sensor readings will be used to determine if an alert is necessary?'
	},
	alertDetail: {
		text: 'What conditions will trigger the alert?'
	},
	notifyUsers: {
		text: 'When an alert is triggered, who should we notifiy?'
	},
	hierarchyName: {
		text: 'The name of the sensor, space, or location'
	},
	currentReadings: {
		text: 'This is the last set of readings taken by this device.'
	},
	sensorsLevelProfile: {
		text: 'Level Profiles define your environmental condition targets.  A sensor inherits its level profile from the space it is in, or from the location.'
	},
	spaceName: {
		text: 'In Conserv, sensors are assigned to spaces (like a gallery, room, or cabinet).'
	},
	locationName: {
		text: 'In Conserv, spaces are assigned to locations'
	},
	lastUpdated: {
		text: 'When was the last reading taken by this device?'
	},
	database: {
		text: 'Conserv has compiled a list of environmental levels used by collections around the world, along with references to the origingal materials.'
	},
	productPortal: {
		text: 'Conserv plans our future product based on feedback.  Our product portal is where the next ideas live.'
	},
	knowledgebase: {
		text: 'Search the Conserv knowledgebase for answers to your questions about the product, or click to open in a new window.',
		moreInfoUrl: 'https://support.conserv.io/knowledge'
	},
	viewSingle: {
		text: 'View a single reading series taken by a single sensor'
	},
	compareReadings: {
		text: 'Compare multiple readings taken by the same sensor'
	},
	compareSensors: {
		text: 'Compare a single reading series across multiple sensors'
	},
	kpiScore: {
		text: 'Conserv scores spaces according to temp and RH, see the knowledgebase for more details',
		moreInfoUrl: 'https://support.conserv.io/knowledge/scoring-and-standards'
	},
	kpiInRange: {
		text: 'The percentage of time this reading was within the range defined in levels'
	},
	kpiVariation: {
		text: 'The percentage of time the fluctuation of this reading was within the range defined in levels'
	},
	kpiLightHours: {
		text: 'The total amount of cumulative light hours of exposure as measured at the sensor'
	},
	pdfDataSummary: {
		text: 'Summary statistics of your data (min, max, average) for the selected time period'
	},
	pdfGraph: {
		text: 'Create a PDF version of this graph and its supporting data.'
	},
	pdfObservations: {
		text: 'A list of observations related to the selected time period and spaces.'
	},
	pdfDataTable: {
		text: 'The same data on the graph, represented as a table.'
	},
	pdfKpi: {
		text: 'The KPI indicators, and their descriptions.'
	},
	analyticsWeatherDisabled: {
		text: 'Weather is only available for hourly and daily aggregations of temp and RH readings on single sensors.'
	},
	analyticsWeatherAddressDisabled: {
		text: 'Weather data is only available if you have set an address for this location.  Right click on the graph and select the location icon to set one.'
	},
	analyticsVariationDisabled: {
		text: '24h fluctuation graphs are only available for daily aggregations of single sensor readings.'
	},
	analyticsWeatherUsingLocationAddress: {
		text: 'Using the address of the location of the sensor'
	},
	analyticsWeatherUsingCustomerAddress: {
		text: 'The location of this sensor does not have an address set, using your account address instead.'
	},
	analyticsAlertsDisabled: {
		text: 'Alert lines are only available for single sensor reading graphs.'
	},
	analyticsLevelsDisabled: {
		text: 'Level bands are only available for single sensor reading graphs.'
	},
	eventsSubscribe: {
		text: 'If checked, you are subscribed to the event. This does not affect other users of your organization.'
	},
	eventsStatus: {
		text: 'When an event is paused, that means the event will not occur any longer until it is unpaused. This affects all subscribers of this event.'
	},
	importDelimiter: {
		text: 'This is the character used to separate fields in your import file.  We can automatically detect it, or you can pick from some common options.  Please do not use slash (/), hyphen (-), or colon (:) as a field delimeter as those characters are commonly used in date data and can make detection fail.',
		moreInfoUrl: 'https://support.conserv.io/knowledge/importing-data-how-to'
	}
};

export default InfoTooltip;
