import React, { Component } from 'react';
import labels from '../../../res/data';

export default class DataLabel extends Component {
	render() {
		const labelname = this.props.name.toLowerCase();
		return (
			<>{labels.getLabelText(labelname, this.props.format === 'minimal')}</>
		);
	}
}
