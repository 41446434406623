import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';

import useStyles from './styles';

function EmptyHistory({ description, showButton, onClickNewEvent }) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Typography className={classes.title}>No events</Typography>
			<div className={classes.divider} />
			<Typography className={classes.subtitle}>{description}</Typography>
			<div className={classes.divider} />
			{showButton ? (
				<Button onClick={onClickNewEvent} variant='outlined'>
					New Event
				</Button>
			) : null}
		</Box>
	);
}

export default EmptyHistory;
