/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import type { AxiosResponse } from 'axios';
import Api from '../../legacy/api/Api';
import { Box } from '@mui/material';
import Loading from 'legacy/components/common/elements/v2/Loading';
import type { DownloadResponse } from '../../types/DownloadResponse';

interface RouteParameters {
    filepath: string;
}

interface History {
    push(url: string): void;
}

function ExportHandler(): JSX.Element {
    const { filepath } = useParams<RouteParameters>();
    const history = useHistory() as History;

    useEffect(() => {
        const handleExport = async () => {
            try {
                if (!filepath) {
                    throw new Error('No filepath provided');
                }

                const response = await Api.generateExportDownloadUrl(filepath) as AxiosResponse<DownloadResponse>;
                
                if (response.data.downloadLink) {
                    // Crear elemento temporal para la descarga
                    const anchor = document.createElement('a');
                    anchor.href = response.data.downloadLink;
                    anchor.download = response.data.fileName;
                    anchor.style.display = 'none';
                    document.body.append(anchor);
                    anchor.click();
                    anchor.remove();
                    
                    history.push('/');
                } else {
                    console.error('Failed to generate download link');
                    history.push('/');
                }
            } catch (error) {
                console.error('Error processing download request:', error);
                history.push('/');
            }
        };

        void handleExport();
    }, [filepath, history]);

    return (
        <Box 
            display="flex" 
            justifyContent="center" 
            alignItems="center" 
            minHeight="100vh"
        >
            <Loading text="Preparing your download..." />
        </Box>
    );
}

export default ExportHandler; 