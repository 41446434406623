import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography
} from '@material-ui/core';
import DecoratedTextField from '../../../common/form/v2/DecoratedTextField';
import useStyles from './styles';

function TriggerForm({ editable }) {
	const classes = useStyles();

	const [typeOptions] = useState([
		{ label: 'Temperature', value: 'temp1' },
		{ label: 'Relative Humidity', value: 'rh' },
		{ label: 'Illuminance', value: 'vis' },
		{ label: 'Ultraviolet', value: 'uv1' },
		{ label: 'Movement', value: 'shock' },
		{ label: 'Leak', value: 'leak' },
		{ label: 'Sensor Offline', value: 'offline' }
	]);

	const { control, trigger, watch } = useFormContext();

	const watchType = watch('type');

	const getTypeLabel = type => {
		if (type === 'offline') {
			return 'the sensor';
		}

		return typeOptions.find(e => e.value === type)?.label || type;
	};

	return (
		<Grid>
			{watchType && watchType !== 'leak' && watchType !== 'shock' ? (
				<>
					<Grid item>
						<Typography className={classes.title}>Start</Typography>
						<Typography className={classes.subtitle} variant='h1'>
							How long should <b>{getTypeLabel(watchType)}</b>{' '}
							{watchType === 'uv1'
								? 'be detected for'
								: watchType === 'offline'
								? 'be offline'
								: 'be out of range'}{' '}
							before the event <b>starts</b>?
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<Controller
									name='lagStart'
									control={control}
									render={({
										field: { onChange, defaultValue, value },
										fieldState: { error }
									}) => (
										<DecoratedTextField
											aria-label='above or below threshold value'
											type='number'
											defaultValue={defaultValue}
											value={value}
											editing={editable}
											error={error}
											helperText={error?.message}
											id='lagStart'
											label='Duration'
											onChange={onChange}
										/>
									)}
								/>
							</Grid>
							<Grid item xs={6} className={classes.radioContainer}>
								<Controller
									name='lagStartUnit'
									control={control}
									render={({
										field: { onChange, value },
										fieldState: { error }
									}) => (
										<FormControl component='fieldset'>
											<RadioGroup
												row
												aria-label='position'
												name='position'
												value={value}
												defaultValue='minute'
												onChange={e => {
													onChange(e);
													trigger('lagStart');
												}}
											>
												{watchType !== 'offline' && (
													<FormControlLabel
														disabled={!editable}
														value='minute'
														control={<Radio color='primary' />}
														label='Minute'
													/>
												)}
												<FormControlLabel
													disabled={!editable}
													value='hour'
													control={<Radio color='primary' />}
													label='Hour'
												/>
												<FormControlLabel
													disabled={!editable}
													value='day'
													control={<Radio color='primary' />}
													label='Day'
												/>
											</RadioGroup>
										</FormControl>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
					{watchType !== 'offline' ? (
						<>
							<Divider className={classes.section} />
							<Grid item className={classes.section}>
								<Typography className={classes.title}>End</Typography>
								<Typography className={classes.subtitle} variant='h1'>
									How long should <b>{getTypeLabel(watchType)}</b>{' '}
									{watchType === 'uv1'
										? 'be no longer detected'
										: 'be back in range'}{' '}
									before the event <b>ends</b>?
								</Typography>
							</Grid>
							<Grid item className={classes.section}>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Controller
											name='lagEnd'
											control={control}
											render={({
												field: { onChange, defaultValue, value },
												fieldState: { error }
											}) => (
												<DecoratedTextField
													aria-label='above or below threshold value'
													type='number'
													defaultValue={defaultValue}
													value={value}
													editing={editable}
													error={error}
													helperText={error?.message}
													id='lagEnd'
													label='Duration'
													onChange={onChange}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6} className={classes.radioContainer}>
										<Controller
											name='lagEndUnit'
											control={control}
											render={({
												field: { onChange, value },
												fieldState: { error }
											}) => (
												<FormControl component='fieldset'>
													<RadioGroup
														row
														aria-label='position'
														name='position'
														value={value}
														defaultValue='minute'
														onChange={onChange}
													>
														<FormControlLabel
															disabled={!editable}
															value='minute'
															control={<Radio color='primary' />}
															label='Minute'
														/>
														<FormControlLabel
															disabled={!editable}
															value='hour'
															control={<Radio color='primary' />}
															label='Hour'
														/>
														<FormControlLabel
															disabled={!editable}
															value='day'
															control={<Radio color='primary' />}
															label='Day'
														/>
													</RadioGroup>
												</FormControl>
											)}
										/>
									</Grid>
								</Grid>
							</Grid>
						</>
					) : (
						<>
							<Divider className={classes.section} />
							<Grid item className={classes.section}>
								<Typography className={classes.title}>End</Typography>
								<Typography className={classes.subtitle} variant='h1'>
									The event will end when a reading is received from the sensor
									or the gateway is back online.
								</Typography>
							</Grid>
						</>
					)}
				</>
			) : (
				<>
					<Grid item>
						<Typography className={classes.subtitle} variant='h1'>
							<b>Start</b> event {watchType === 'shock' ? 'when' : 'whenever'}{' '}
							{watchType === 'shock' ? 'movement' : 'a leak'} is detected.
						</Typography>
					</Grid>
					{watchType === 'leak' ? (
						<Grid item className={classes.section}>
							<Typography className={classes.subtitle} variant='h1'>
								<b>End</b> event when a leak is no longer detected.
							</Typography>
						</Grid>
					) : null}
				</>
			)}
		</Grid>
	);
}

export default TriggerForm;
