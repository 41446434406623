const AddressUtil = {};

AddressUtil.parseAddressComponents = components => {
	let streetNum = '';
	let route = '';
	let city = '';
	let state = '';
	let zip = '';
	let country = '';

	components.forEach(component => {
		const { types } = component;
		types.forEach(type => {
			switch (type) {
				case 'street_number':
					streetNum = component.long_name;
					break;
				case 'route':
					route = component.long_name;
					break;
				case 'locality':
					city = component.long_name;
					break;
				case 'administrative_area_level_1':
					state = component.short_name;
					break;
				case 'country':
					country = component.short_name;
					break;
				case 'postal_code':
					zip = component.long_name;
					break;
				default:
					break;
			}
		});
	});
	const addressObj = {
		add_1: `${streetNum} ${route}`,
		add_2: '',
		city,
		state,
		zip,
		country
	};
	return addressObj;
};

AddressUtil.onSuggestionSelected = (suggestion, callback) => {
	const request = {
		placeId: suggestion.place_id
	};
	const { google } = window;
	const placeService = new google.maps.places.PlacesService(
		document.createElement('div')
	);
	const elevationService = new google.maps.ElevationService();
	placeService.getDetails(request, (place, status) => {
		if (status == google.maps.places.PlacesServiceStatus.OK) {
			const latLng = {
				lat: place.geometry.location.lat(),
				lng: place.geometry.location.lng()
			};
			elevationService.getElevationForLocations(
				{ locations: [latLng] },
				(results, status) => {
					const addressObj = AddressUtil.parseAddressComponents(
						place.address_components
					);
					if (status === 'OK' && results[0].elevation) {
						addressObj.elevation = Math.round(parseFloat(results[0].elevation));
					}
					addressObj.google_place_id = suggestion.place_id;
					callback(addressObj);
				}
			);
		}
	});
};

export default AddressUtil;
