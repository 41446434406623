import { makeStyles } from '@material-ui/core';
import { colors } from '../../../style/colors';

export default makeStyles(theme => ({
	footer: {
		background: '#FFF',
		bottom: 0,
		borderTop: '.5px solid #BDBDBD',
		padding: '10px 40px',
		position: 'sticky',
		width: '100%',
		zIndex: 10
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '24px'
	},
	topSticky: {
		top: 0,
		position: 'sticky',
		zIndex: 10
	},
	tabs: {
		background: colors.white.primary
	},
	commentsContainer: {
		paddingTop: theme.spacing(2),
		paddingRight: theme.spacing(2),
		paddingLeft: theme.spacing(2)
	}
}));
