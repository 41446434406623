import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogActions
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GradientDialogHeader from '../common/dialog/GradientDialogHeader';
import DecoratedTextField from '../common/form/DecoratedTextField';
import AdminApi from '../../api/AdminApi';
import { buttonStyles } from '../../style/styles';

const useStyles = makeStyles(() => ({
	...buttonStyles
}));

function CreateAccountDialog(props) {
	const [name, setName] = useState(props.customer.name);
	const [formValidation, setFormValidation] = useState({});
	const { dispatch } = useStoreon();
	const classes = useStyles();

	const handleClose = () => {
		dispatch('navstate/update', { dialogOpen: false, dialogContent: null });
	};

	useEffect(() => {
		validateForm();
	}, [name]);

	const addMonitoring = () => {
		if (name.trim() !== 'My Organization') {
			AdminApi.addMonitoringToCustomer(props.customer.id, name)
				.then(() => {
					handleClose();
					dispatch('notification/add', {
						message: `Added monitoring to customer: ${name}`,
						severity: 'success',
						variant: 'snackbar'
					});
				})
				.catch(() => {
					dispatch('notification/add', {
						message: `Failed to add monitoring to customer: ${name}`,
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			dispatch('notification/add', {
				message:
					'Cannot add monitoring to default account name "My Organization, please change"',
				severity: 'error',
				variant: 'banner'
			});
		}
	};

	const validateForm = () => {
		if (name === 'My Organization' || !name) {
			setFormValidation({
				formValid: false,
				formValidation: {
					nameError: 'Name must not be "My Organization" or blank'
				}
			});
		} else {
			setFormValidation({ formValid: true, formValidation: {} });
		}
	};

	return (
		<Dialog
			open
			onClose={handleClose}
			aria-labelledby='form-dialog-title'
			classes={{ paper: classes.paper }}
		>
			<GradientDialogHeader
				title='Add Monitoring to Customer'
				subtitle='Please confirm the customer name before adding monitoring'
				onClose={handleClose}
			/>
			<DialogContent style={{ width: 'auto' }}>
				<DecoratedTextField
					required
					conservField='name'
					id='customer name'
					label='Customer Name'
					error={Boolean(formValidation.nameError)}
					helperText={formValidation.nameError}
					aria-label='customer name'
					onChange={ev => setName(ev.target.value)}
					value={name}
					editing
				/>
			</DialogContent>
			<DialogActions>
				<Button
					variant='contained'
					disabled={!formValidation.formValid}
					color='primary'
					disableRipple
					onClick={addMonitoring}
				>
					Add Monitoring
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default CreateAccountDialog;
