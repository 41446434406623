import React, { useEffect, useState } from 'react';
import { useStoreon } from 'storeon/react';
import useSubscribers from '../../../../hooks/useSubscribers';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

function SubsList() {
	const classes = useStyles();
	const [subscriptors, setSubscriptors] = useState([]);
	const { getSubscribersList } = useSubscribers();
	const { eventProfiles } = useStoreon('eventProfiles');
	const { editing: eventProfile } = eventProfiles;

	useEffect(() => {
		if (eventProfile) {
			setSubscriptors(
				getSubscribersList({
					eventsubs: eventProfile.eventsubs,
					subscribersList: eventProfile.subscribersList
				})
			);
		}
	}, [eventProfile]);

	return (
		<>
			<Typography className={(classes.text, classes.subscriptionsTitle)}>
				Subscriptions:
			</Typography>
			<Typography className={classes.text}>
				<ul className={classes.list}>
					{subscriptors.map(sub => (
						<li className={classes.marker}>{sub}</li>
					))}
				</ul>
			</Typography>
		</>
	);
}

export default SubsList;
