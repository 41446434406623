/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable react/sort-default-props */

import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import type { ReactComponentChildren } from '../../types/ReactComponentChildren';
import ConservDialogTitle from './ConservDialogTitle';
import printChildren from '../../utils/general/printChildren';

interface ConservDialogProperties {
	children?: ReactComponentChildren;
	title?: string;
	isOpen?: boolean;
	showCloseButton?: boolean;
	onCloseHandler?: () => void;
	fullWidth?: boolean;
}

export default function ConservDialog({
	children,
	onCloseHandler,
	title = '',
	isOpen = false,
	showCloseButton = false,
	fullWidth
}: ConservDialogProperties) {
	const [open, setOpen] = useState(isOpen);

	useEffect(() => {
		setOpen(isOpen);
	}, [isOpen]);

	const onClose = () => {
		setOpen(false);

		onCloseHandler?.();
	};

	const titleComponent = (
		<ConservDialogTitle>
			<>
				<Typography
					variant='h6'
					component='div'
					sx={{
						display: 'inline-block',
						textTransform: 'normal',
						textAlign: 'left'
					}}
				>
					{title}
				</Typography>{' '}
				{showCloseButton ? (
					<IconButton
						aria-label='close'
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: '38px',
							top: '30px',
							color: theme => theme.palette.grey[500]
						}}
					>
						<CloseIcon />
					</IconButton>
				) : undefined}
			</>
		</ConservDialogTitle>
	);

	return (
		<div>
			<Dialog
				fullWidth={fullWidth}
				onClose={onClose}
				aria-labelledby='customized-dialog-title'
				open={open}
			>
				{titleComponent}
				<DialogContent>{printChildren(children)}</DialogContent>
			</Dialog>
		</div>
	);
}

ConservDialog.defaultProps = {
	isOpen: false,
	showCloseButton: false,
	title: ''
};
