import React, { Component } from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default class CommonEditAlert extends Component {
	render() {
		return (
			<Alert severity='warning' onClose={this.props.handleDismissAlert}>
				<Typography variant='h3'>You sure about that?</Typography>
				<Typography variant='body1'>
					You have changed some of your information, but haven't saved it yet.{' '}
					<strong>You sure you want to do that?</strong>
				</Typography>
				<Box
					pt={1}
					display='flex'
					alignItems='center'
					width='340px'
					justifyContent='space-between'
				>
					<Button
						variant='contained'
						color='primary'
						onClick={this.props.handleSave}
					>
						Yep, save it
					</Button>
					<Typography variant='body1'>or</Typography>
					<Button
						variant='contained'
						color='primary'
						onClick={this.props.handleCloseWithoutSave}
					>
						Nope, don't save
					</Button>
				</Box>
			</Alert>
		);
	}
}
