/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable unicorn/no-useless-undefined */

import * as Rx from 'rxjs';
import { DeveloperModeService } from './DeveloperModeService';
import * as mnmxIoCommon from '@conserv/mnmx-io-common';

const LOG = mnmxIoCommon.getConsoleLogger('developer/DeveloperModeServiceImpl');

export class DeveloperModeServiceImpl implements DeveloperModeService {
	private devModeEnabledFlag: boolean = false;
	private readonly onChangeSubject = new Rx.Subject<undefined>();

	public isEnabled(): boolean {
		return this.devModeEnabledFlag;
	}

	public enable(): void {
		LOG.info(`enable(): Enabling developer mode`);
		this.devModeEnabledFlag = true;
		this.onChangeSubject.next(undefined);
	}

	public getChangeObservable(): Rx.Observable<undefined> {
		return this.onChangeSubject.asObservable();
	}
}
