import React, { useState } from 'react';
import { Box, Avatar, Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvatarGroup } from '@material-ui/lab';
import { avatarStyles } from '../../../style/styles';

const useStyles = makeStyles(() => ({
	...avatarStyles,
	dialogImage: {
		objectFit: 'scale-down',
		height: '100%',
		width: '100%'
	}
}));

function ImageList(props) {
	const classes = useStyles();
	const [dialogOpen, setDialogOpen] = useState(false);

	return (
		<Box>
			<AvatarGroup max={4}>
				{props.observation.images?.length > 0 ? (
					<Avatar
						// variant="square"
						className={classes.avatar}
						alt={props.observation?.observation?.description}
						src={props.observation.images[0].imageurl}
						onClick={ev => {
							setDialogOpen(true);
							ev.stopPropagation();
						}}
					/>
				) : null}
			</AvatarGroup>
			<Dialog
				onClick={ev => {
					ev.stopPropagation();
					ev.preventDefault();
				}}
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
			>
				{props.observation.images?.length > 0 ? (
					<img
						className={classes.dialogImage}
						style={{ borderRadius: '6px' }}
						alt={props.observation?.observation?.description}
						src={props.observation.images[0].imageurl}
					/>
				) : null}
			</Dialog>
		</Box>
	);
}

export default ImageList;
