import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	subscriber: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '16px'
	},
	subscriberInfo: {
		alignItems: 'center',
		display: 'flex',
		gap: 16
	},
	me: {
		color: '#000',
		fontWeight: 400,
		fontSize: '16px',
		letterSpacing: '0.15px'
	}
}));
