import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		height: '100%'
	},
	divider: {
		margin: '12px 0'
	},
	subtitle: {
		color: '#757575',
		fontSize: '16px',
		lineHeight: '19px'
	},
	title: {
		color: '#616161',
		fontSize: '24px',
		fontWeight: '700'
	}
}));
