import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../style/colors';

export default makeStyles(theme => ({
	content: {
		width: '625px',
		paddingTop: 0,
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(7),
		paddingRight: theme.spacing(7)
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '31px',
		letterSpacing: '0em'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '76px',
		paddingLeft: '76px',
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		background: colors.elektra.primary
	},
	input: {
		padding: '17px'
	}
}));
