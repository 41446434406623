import React from 'react';
import { Avatar, Box, Tooltip } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import useStyles from './styles';

function SubscribersCell({ eventsubs }) {
	const classes = useStyles();

	const getSubAvatar = ({ name, avatar, key }) => (
		<Tooltip key={key} arrow title={name ? name : 'Unknown'} placement='top'>
			{name.startsWith('+') ? (
				<Avatar>{avatar ? '' : name}</Avatar>
			) : (
				<Avatar alt={name} src={avatar} />
			)}
		</Tooltip>
	);

	const getSubscribers = eventsubs =>
		eventsubs
			.filter(sub => sub.status === 'SUBSCRIBED')
			.map(({ user }) =>
				getSubAvatar({
					name: `${user.first_name} ${user.last_name}`,
					avatar: user.avatar,
					key: user.uuid
				})
			);

	return (
		<Box display='flex'>
			<AvatarGroup max={4} spacing={1} classes={{ avatar: classes.avatar }}>
				{getSubscribers(eventsubs)}
			</AvatarGroup>
		</Box>
	);
}

export default SubscribersCell;
