/* eslint-disable no-console */
import axios from 'axios';
import type OrderListItem from 'types/OrderListItem';

const getOrderList = async () => {
	try {
		const { data } = await axios.get<OrderListItem[]>(`/api/v2/order`);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return data;
	} catch (error) {
		console.error('Error getting the product list:', error);
	}
	return [];
};

export default getOrderList;
