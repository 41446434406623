import {
	FunctionComponent,
	SyntheticEvent,
	useState,
	useCallback
} from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

/**
 * The return type of the useSnackbar hook.
 */
interface UseSnackbarReturn {
	/**
	 * Shows a success message in a Snackbar.
	 *
	 * @param {string} message - The success message to display.
	 */
	showSuccess: (message: string) => void;

	/**
	 * Shows an error message in a Snackbar.
	 *
	 * @param {string} message - The error message to display.
	 */
	showError: (message: string) => void;

	/**
	 * Shows a warning message in a Snackbar.
	 *
	 * @param {string} message - The warning message to display.
	 */
	showWarning: (message: string) => void;

	/**
	 * The Snackbar component to be rendered.
	 *
	 * @returns {JSX.Element} The Snackbar component.
	 */
	SnackbarComponent: FunctionComponent;
}

/**
 * Custom hook to handle Snackbar notifications.
 *
 * @returns {UseSnackbarReturn} An object containing functions to show success, error, and warning messages, and a Snackbar component.
 */
export default function useSnackbar(): UseSnackbarReturn {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [severity, setSeverity] = useState<AlertColor>('success');

	/**
	 * Handles the close event for the Snackbar.
	 *
	 * @param {Event | SyntheticEvent} [event] - The event that triggered the close.
	 * @param {string} [reason] - The reason for closing.
	 */
	const onCloseHandler = (event?: Event | SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	/**
	 * Displays a Snackbar with the specified message and severity.
	 *
	 * @param {string} message_ - The message to display.
	 * @param {AlertColor} severity_ - The severity of the alert ('success', 'error', 'warning', etc.).
	 */
	const showAlert = useCallback((message_: string, severity_: AlertColor) => {
		setMessage(message_);
		setSeverity(severity_);
		setOpen(true);
	}, []);

	/**
	 * Displays a success Snackbar with the specified message.
	 *
	 * @param {string} message_ - The success message to display.
	 */
	const showSuccess = useCallback(
		(message_: string) => showAlert(message_, 'success'),
		[showAlert]
	);

	/**
	 * Displays an error Snackbar with the specified message.
	 *
	 * @param {string} message_ - The error message to display.
	 */
	const showError = useCallback(
		(message_: string) => showAlert(message_, 'error'),
		[showAlert]
	);

	/**
	 * Displays a warning Snackbar with the specified message.
	 *
	 * @param {string} message_ - The warning message to display.
	 */
	const showWarning = useCallback(
		(message_: string) => showAlert(message_, 'warning'),
		[showAlert]
	);

	/**
	 * The Snackbar component to be rendered.
	 *
	 * @returns {JSX.Element} The Snackbar component.
	 */
	function SnackbarComponent() {
		return (
			<Snackbar open={open} autoHideDuration={6000} onClose={onCloseHandler}>
				<Alert
					onClose={onCloseHandler}
					severity={severity}
					variant='filled'
					sx={{ width: '100%' }}
				>
					{message}
				</Alert>
			</Snackbar>
		);
	}

	return { showSuccess, showError, showWarning, SnackbarComponent };
}
