import React from 'react';
import { useStoreon } from 'storeon/react';
import EventBody from '../EventBody';
import { SubscribersForm } from '../../Forms';

function Subscribe() {
	const { user, users } = useStoreon('user', 'users');
	return (
		<EventBody
			title='Subscribe'
			description='Users subscribed to this event will receive email or SMS alerts about this event (according to their notification channel preference)'
		>
			<SubscribersForm me={user} users={users} />
		</EventBody>
	);
}

export default Subscribe;
