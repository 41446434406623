import React from 'react';
import { Box, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import useStyles from './styles';

function ErrorBanner({ message }) {
	const classes = useStyles();

	return (
		<Box
			display='flex'
			alignItems='center'
			mb={3}
			classes={{ root: classes.errorContainer }}
		>
			<InfoOutlinedIcon className={(classes.error, classes.errorIcon)} />
			<Typography className={classes.error}>{message}</Typography>
		</Box>
	);
}

export default ErrorBanner;
