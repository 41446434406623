import React, { Component } from 'react';
import {
	Box,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	IconButton,
	Menu,
	MenuItem,
	Typography
} from '@material-ui/core';
import dayjs from 'dayjs';
import { connectStoreon } from 'storeon/react';
import { Icon } from '@mdi/react';
import { withStyles } from '@material-ui/core/styles';
import { showAlert } from '../error/ConservAlertPopup';
import Api from '../../api/Api';
import icons from '../../style/icons';
import { collapsibleTableStyles, buttonStyles } from '../../style/styles';
import InfoTooltip from '../common/help/InfoTooltip';

const useStyles = () => ({
	...collapsibleTableStyles,
	...buttonStyles
});

class ImportMenuClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false
		};
	}

	openMenu(ev) {
		this.setState({ anchorEl: ev.currentTarget, open: true });
	}

	closeMenu(ev) {
		this.setState({ anchorEl: undefined, open: false });
	}

	render() {
		const { classes } = this.props;

		const menuItems = [];
		if (!this.props.importfile.completed) {
			menuItems.push(
				<MenuItem
					key={`deletemenu-${this.props.importfile.uuid}`}
					onClick={() => {
						this.closeMenu();
						this.props.onDelete(this.props.importfile.uuid);
					}}
				>
					Delete import
				</MenuItem>
			);
		} else {
			menuItems.push(
				<MenuItem
					key={`undomenu-${this.props.importfile.uuid}`}
					onClick={() => {
						this.closeMenu();
						this.props.onUndo(this.props.importfile.uuid);
					}}
				>
					Undo import
				</MenuItem>
			);
		}

		return (
			<Box key={this.props.importfile.uuid}>
				<IconButton
					size='small'
					disableRipple
					classes={{ root: classes.iconLabeledLinkButton }}
					style={{ justifyContent: 'flex-end' }}
					onClick={this.openMenu.bind(this)}
					key={`button=${this.props.importfile.uuid}`}
				>
					<Icon path={icons.more} size={1} aria-label='more-options' />
				</IconButton>
				<Menu
					key={`importlistmenu-${this.props.importfile.uuid}`}
					id={`importlist-${this.props.importfile.uuid}`}
					anchorEl={this.state.anchorEl}
					open={this.state.open}
					onClose={this.closeMenu.bind(this)}
					elevation={1}
				>
					<MenuItem
						onClick={() => this.props.onDownload(this.props.importfile.uuid)}
					>
						Download original file
					</MenuItem>
					{menuItems}
				</Menu>
			</Box>
		);
	}
}

class ImportListClass extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	loadImportList = () => {
		Api.listImports()
			.then(importfiles => {
				this.setState({ importfiles: importfiles.data });
			})
			.catch(() => {
				this.props.dispatch('notification/add', {
					message: 'There was an error loading the list of imports',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	renderSummary = summary => {
		if (!summary) {
			return 'Not Available';
		}
		return (
			<>
				<Typography>Read: {summary.read}</Typography>
				<Typography>Errors: {summary.errors}</Typography>
				{summary.firstentry && summary.lastentry ? (
					<>
						<Typography>
							Import Date Range: {dayjs(summary.firstentry).format('L LT')}
						</Typography>
						<Typography>
							To: {dayjs(summary.lastentry).format('L LT')}
						</Typography>
					</>
				) : null}
			</>
		);
	};

	downloadOriginal = uuid => {
		window.location.href = `/data/api/import/sourcefile/${uuid}`;
	};

	undoImport = uuid => {
		this.setState({
			alert: {
				iserror: false,
				title: 'Confirm Import Undo',
				message:
					'Are you sure you want to undo this import?  The original import file will remain available, but all associated data will be purged from Conserv',
				onConfirm: () => {
					this.completeImportUndo(uuid);
				}
			}
		});
	};

	cancelImport = uuid => {
		this.setState({
			alert: {
				iserror: false,
				title: 'Confirm Import Cancel',
				message:
					'Are you sure you want to cancel this import?  This import will no longer show up in the list, and cannot be resumed or downloaded',
				onConfirm: () => {
					this.completeImportCancel(uuid);
				}
			}
		});
	};

	completeImportCancel = uuid => {
		Api.cancelImport(uuid)
			.then(() => {
				this.loadImportList();
				this.props.dispatch('importFiles/refresh');
			})
			.catch(() => {
				this.props.dispatch('notification/add', {
					message: 'Threre was an error canceling this import',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	completeImportUndo = uuid => {
		Api.undoImport(uuid)
			.then(() => {
				this.loadImportList();
				this.props.dispatch('importFiles/refresh');
			})
			.catch(() => {
				this.props.dispatch('notification/add', {
					message:
						'Threre was an error undoing this import, please contact support',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	createImportRows = () => {
		const { classes } = this.props;
		const imports = [];
		this.props.importfiles.forEach(importfile => {
			imports.push(
				<TableRow classes={{ root: classes.level3Rows }} key={imports.length}>
					<TableCell>{importfile.filename}</TableCell>
					<TableCell>{dayjs(importfile.uploaddate).format('L LT')}</TableCell>
					<TableCell>
						{`${importfile.mnmxuser.first_name} ${importfile.mnmxuser.last_name}`}
					</TableCell>
					{importfile.completed ? (
						<>
							<TableCell>
								<Icon
									size={1}
									path={icons.complete}
									style={{ color: 'green' }}
								/>
							</TableCell>
							<TableCell>{this.renderSummary(importfile.summary)}</TableCell>
						</>
					) : (
						<>
							<TableCell>
								<Icon size={1} path={icons.remove} style={{ color: 'red' }} />
							</TableCell>
							<TableCell>Not Run</TableCell>
						</>
					)}
					<TableCell className='text-right'>
						<ImportMenu
							onDownload={this.downloadOriginal}
							onDelete={this.cancelImport}
							onUndo={this.undoImport}
							importfile={importfile}
						/>
					</TableCell>
				</TableRow>
			);
		});
		return imports;
	};

	render() {
		const { classes } = this.props;
		return (
			<>
				{showAlert(this.state.alert, () => this.setState({ alert: null }))}
				{this.props.importfiles ? (
					<div className='table-container'>
						<Table classes={{ root: classes.tableRoot }}>
							<TableHead>
								<TableRow>
									<TableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>
										File name
									</TableCell>
									<TableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>
										Uploaded
									</TableCell>
									<TableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>
										Uploaded by
									</TableCell>
									<TableCell style={{ width: '10%', whiteSpace: 'nowrap' }}>
										Status
										<InfoTooltip name='importStatus' />
									</TableCell>
									<TableCell style={{ width: '20%', whiteSpace: 'nowrap' }}>
										Summary
										<InfoTooltip name='importSummary' />
									</TableCell>
									<TableCell style={{ width: '10%', whiteSpace: 'nowrap' }} />
								</TableRow>
							</TableHead>
							<TableBody>{this.createImportRows()}</TableBody>
						</Table>
					</div>
				) : null}
			</>
		);
	}
}

const ImportList = connectStoreon(
	withStyles(useStyles, { withTheme: true })(ImportListClass)
);
const ImportMenu = withStyles(useStyles, { withTheme: true })(ImportMenuClass);

export { ImportList, ImportMenu };
