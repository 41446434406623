/* eslint-disable @typescript-eslint/no-magic-numbers */
import type React from 'react';
import { useRef } from 'react';
import Chip from '@mui/material/Chip';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Input } from '@mui/material';
import type TreeNode from '../../types/TreeNode';

interface TextFieldWithChipsProperties {
	selectedNodes: TreeNode[];
	placeholder: string;
	onDeleteChip: (node: TreeNode) => void;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onFocus: (reference: HTMLDivElement | null) => void;
	onClear: () => void;
	// eslint-disable-next-line react/require-default-props
	maxAmountChips?: number;
}

const iconStyle = { color: '#757575', cursor: 'pointer' };

const getChipStyle = (amountChips: number) => {
	if (amountChips === 0) {
		return {};
	}

	// The 80% is because I'm leaving some space for the (+1) and for the input
	const chipMaxWidth = 65 / amountChips;
	return { maxWidth: `${chipMaxWidth}%` };
};

const getLabel = (node: TreeNode): string =>
	/* eslint-disable	@typescript-eslint/no-unsafe-return */
	/* eslint-disable	@typescript-eslint/no-unsafe-call */
	node.labelFormater ? node.labelFormater(node.label) : node.label;

export default function TextFieldWithChips({
	selectedNodes,
	placeholder,
	onDeleteChip,
	onChange,
	onFocus,
	onClear,
	maxAmountChips = 5
}: TextFieldWithChipsProperties) {
	const inputReference = useRef<HTMLInputElement>();
	const componentReference = useRef<HTMLDivElement>();
	const { length } = selectedNodes;
	const shouldLimit = length > maxAmountChips;
	const toRender = shouldLimit
		? selectedNodes.slice(0, maxAmountChips)
		: selectedNodes;

	const renderChips = () => {
		const chipStyle = getChipStyle(toRender.length);

		const chips = toRender.map(node => (
			<Chip
				color='primary'
				variant='outlined'
				key={node.id}
				tabIndex={-1}
				label={getLabel(node)}
				onDelete={() => onDeleteChip(node)}
				sx={chipStyle}
				data-testid={`tree-multiselect-node-chip-${node.id}`}
			/>
		));
		if (shouldLimit) {
			chips.push(
				<Chip
					color='primary'
					variant='outlined'
					key='plus'
					tabIndex={-1}
					label={`+${length - maxAmountChips}`}
				/>
			);
		}
		return chips;
	};

	const onOpenList = () => {
		// eslint-disable-next-line unicorn/no-null
		onFocus(componentReference.current ?? null);
	};
	const getClearBoxDisplay = (amountChips: number): string => {
		const inputLength = inputReference.current?.value.length ?? 0;
		return amountChips + inputLength === 0 ? 'none' : 'inline';
	};

	const onClearInput = (): void => {
		if (inputReference.current) {
			inputReference.current.value = '';
		}
		onChange({ target: { value: '' } } as React.ChangeEvent<HTMLInputElement>);
	};

	const onClickClear = (): void => {
		onClear();
		onClearInput();
	};

	return (
		<Box
			ref={componentReference}
			sx={{
				borderBottom: 1,
				borderColor: 'primary.main',
				display: 'flex',
				flexWrap: 'no-wrap',
				paddingTop: 1,
				paddingBottom: 1,
				bgcolor: 'background.paper',
				gap: 1
			}}
			onClick={onOpenList}
		>
			{renderChips()}
			<Input
				disableUnderline
				placeholder={length === 0 ? placeholder : ''}
				onChange={onChange}
				fullWidth
				inputProps={{
					'data-testid': 'tree-multiselect-input',
					ref: inputReference
				}}
			/>
			<Box display={getClearBoxDisplay(toRender.length)}>
				<ClearIcon sx={iconStyle} onClick={onClickClear} />
			</Box>
			<Box>
				<SearchIcon sx={iconStyle} />
			</Box>
		</Box>
	);
}
