import React, { useMemo } from 'react';
import {
	Avatar,
	Button,
	Chip,
	Grid,
	TextField,
	Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { useStoreon } from 'storeon/react';
import useSubscribers from '../../../../hooks/useSubscribers';
import Subscriber from './Subscriber';
import isValidEmail from '../../../../util/isValidEmail';
import useRoles from 'legacy/hooks/useRoles';
import useStyles from './styles';
import Api from 'legacy/api/Api';

function SubscribersForm({
	eventProfileUuid,
	isFromModal = false,
	me,
	subscribers = [],
	users
}) {
	const classes = useStyles();
	const { isUser } = useRoles();
	const { dispatch } = useStoreon();
	const {
		control,
		formState: { errors },
		getValues,
		setValue,
		watch
	} = useFormContext();

	const { getLabel } = useSubscribers();

	const imNotSubscribed = !subscribers.some(sub => sub.user.uuid === me.uuid);

	const shouldShowMyself = !eventProfileUuid || (isUser && imNotSubscribed);

	const mappedUsers = useMemo(
		() =>
			users
				.filter(({ uuid }) => !subscribers.some(sub => sub.user.uuid === uuid))
				.map(({ email }) => email),
		[users]
	);

	const renderInput =
		(errors = []) =>
		// eslint-disable-next-line react/function-component-definition
		props => {
			return (
				<TextField
					{...props}
					onChange={() => {}}
					error={errors.filter(Boolean).length > 0}
					helperText={
						errors.filter(Boolean).length > 0 &&
						'Please select an active user or provide a properly formatted email address.'
					}
					label='Subscribe others to this event'
					variant='outlined'
				/>
			);
		};

	const renderOption = currentEmail => {
		const user = users.find(({ email }) => email === currentEmail);
		const username = getLabel(currentEmail);

		return (
			<div className={classes.subscriberOption}>
				<Avatar size={40} src={user.avatar} />
				<div className={classes.subscriberUsername}>{username}</div>
			</div>
		);
	};

	const renderTags = subscribedUsers => (value, getTagProps) => {
		const getColor = currentValue => {
			if (!isValidEmail(currentValue)) return 'error';
			return mappedUsers.includes(currentValue) ? 'primary' : 'default';
		};

		return value.map(
			(option, index) =>
				!subscribedUsers.some(({ user }) => user.email === option) && (
					<Chip
						classes={classes.errorChip}
						color={getColor(option)}
						label={getLabel(option)}
						{...getTagProps({ index })}
						style={{
							...(getColor(option) === 'error'
								? {
										backgroundColor: '#f44336',
										color: '#FFF'
								  }
								: {})
						}}
					/>
				)
		);
	};

	const handleChange = (onChange, item, subscribedUsers) => {
		if (subscribedUsers.some(({ user }) => item.includes(user.email))) return;
		onChange(item);
	};

	const handleMySubscription = () => {
		if (isUser && imNotSubscribed) {
			Api.createEventSubscription(eventProfileUuid)
				.then(() => {
					dispatch('eventprofiles/refresh');
					dispatch('notification/add', {
						message: 'Subscription updated',
						severity: 'success',
						variant: 'snackbar'
					});
				})
				.catch(() => {
					dispatch('notification/add', {
						message: 'There was an error subscribing to this event.',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
		const currentValue = getValues('mySubscription');
		setValue('mySubscription', !currentValue, { shouldDirty: true });
	};

	const currentSubscribers = watch('subscribers');
	const valueToRender = currentSubscribers.filter(
		currentSubscriber => currentSubscriber !== me.email
	);

	const isActive = watch('mySubscription');

	return (
		<div className={classes.container}>
			{shouldShowMyself && (
				<Grid item>
					<div className={classes.subscriber}>
						<div className={classes.subscriberInfo}>
							<Avatar size={40} src={me.avatar} />
							<Typography className={clsx(classes.me, classes.meStyled)}>
								{`${me.first_name} ${me.last_name} (you)`}
							</Typography>
						</div>
						<Button
							className={isActive && classes.subscribed}
							color={isActive ? 'success' : ''}
							onClick={handleMySubscription}
							variant='outlined'
						>
							{isActive ? 'Subscribed' : 'Unsubscribed'}
						</Button>
					</div>
				</Grid>
			)}
			{subscribers?.length > 0 ? (
				<div className={classes.subscribersContainer}>
					{subscribers.map(({ status, user }) => (
						<Subscriber
							key={user.uuid}
							eventProfileUuid={eventProfileUuid}
							isFromModal={isFromModal}
							status={status}
							user={user}
						/>
					))}
				</div>
			) : null}
			{!isUser ? (
				<Grid item>
					<Controller
						control={control}
						name='subscribers'
						render={props => {
							return (
								<Autocomplete
									{...props}
									freeSolo
									limitTags={5}
									multiple
									onChange={(event, item) =>
										handleChange(props.field.onChange, item, subscribers)
									}
									options={mappedUsers}
									renderInput={renderInput(props.fieldState.error)}
									renderOption={renderOption}
									renderTags={renderTags(subscribers)}
									value={valueToRender}
								/>
							);
						}}
					/>
					{!errors?.subscribers && (
						<Typography className={classes.textDescription}>
							If they don&apos;t have a Conserv account, we&apos;ll send them an
							email to join.
						</Typography>
					)}
				</Grid>
			) : null}
		</div>
	);
}

export default SubscribersForm;
