import React from 'react';
import { Button } from '@material-ui/core';

export default function UnsubscribedButton({ onClick, disabled = false }) {
	return (
		<Button onClick={onClick} variant='outlined' disabled={disabled}>
			Unsubscribed
		</Button>
	);
}
