import { useContext } from 'react';
import { PreferencesContext } from '../../../../providers/PreferencesProvider';
import * as yup from 'yup';
import {
	getNameSchema,
	getScopeSchema,
	getTypeSchema,
	getTimeTriggerSchema,
	getSubscribersSchema
} from './schemas';

const useSchema = ({ restrictedNames }) => {
	const context = useContext(PreferencesContext);

	const schema = yup.object({
		...getNameSchema(restrictedNames),
		...getScopeSchema(),
		...getTypeSchema(context),
		...getTimeTriggerSchema(),
		...getSubscribersSchema()
	});

	return schema;
};

export default useSchema;
