/* eslint-disable react/require-default-props */
import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import Import from '../../legacy/containers/Import';
import ExportTab from './ExportTab';

interface TabPanelProperties {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface RouterLocation {
  pathname: string;
  search: string;
  hash: string;
  state: unknown;
}

interface RouterHistory {
  push(path: string): void;
}

interface DataManagementContainerProperties {
  setFab?: (actions: unknown[] | null) => void;
}

function TabPanel(properties: TabPanelProperties) {
  const { children, value, index, ...other } = properties;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`data-management-tabpanel-${index}`}
      aria-labelledby={`data-management-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function getA11yProperties(index: number) {
  return {
    id: `data-management-tab-${index}`,
    'aria-controls': `data-management-tabpanel-${index}`,
  };
}

export default function DataManagementContainer({ setFab }: DataManagementContainerProperties): JSX.Element {
  const history = useHistory() as RouterHistory;
  const location = useLocation() as RouterLocation;
  
  const getInitialTab = React.useCallback(() => {
    const currentPath = location.pathname;
    if (currentPath === '/export') return 1;
    return 0;
  }, [location.pathname]);

  const [currentTab, setCurrentTab] = React.useState<number>(getInitialTab());

  const onTabChange = (_event: React.SyntheticEvent, selectedValue: number) => {
    setCurrentTab(selectedValue);
    const targetPath = selectedValue === 0 ? '/import' : '/export';
    history.push(targetPath);
  };

  React.useEffect(() => {
    setCurrentTab(getInitialTab());
  }, [getInitialTab]);

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs 
          value={currentTab} 
          onChange={onTabChange} 
          aria-label="data management tabs"
          textColor="primary"
          indicatorColor="primary"
        >
          <Tab 
            label="Import" 
            {...getA11yProperties(0)} 
            sx={{ width: 240 }}
          />
          <Tab 
            label="Export" 
            {...getA11yProperties(1)} 
            sx={{ width: 240 }}
          />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        <Import setFab={setFab} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <ExportTab />
      </TabPanel>
    </Box>
  );
} 