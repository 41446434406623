import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	paper: {
		width: '611px',
		maxWidth: '611px'
	},
	header: {
		height: theme.spacing(2),
		background: theme.palette.primary.main,
		color: '#FFF',
		padding: 0
	},
	icon: {
		color: theme.palette.primary.main,
		fontSize: '46px'
	},
	content: {
		paddingTop: 0,
		paddingBottom: '18px',
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4)
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '40px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '18.75px',
		letterSpacing: '0em'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px'
	},
	saveButton: {
		paddingRight: '13px',
		paddingLeft: '13px',
		paddingTop: '8px',
		paddingBottom: '8px',
		background: theme.palette.primary.main,
		height: '42px'
	},
	continueButton: {
		paddingRight: '11.5px',
		paddingLeft: '11.5px',
		paddingTop: '8px',
		paddingBottom: '8px',
		height: '42px'
	},
	discardButton: {
		paddingRight: '11.5px',
		paddingLeft: '11.5px',
		paddingTop: '8px',
		paddingBottom: '8px',
		color: '#2CA9DA',
		height: '42px'
	},
	greyBox: {
		backgroundColor: '#F5F5F5',
		borderRadius: '8px',
		padding: '16px'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(2)
	}
}));
