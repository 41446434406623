import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	footer: {
		background: '#FFF',
		bottom: 0,
		borderTop: '.5px solid #BDBDBD',
		padding: '10px 40px',
		position: 'sticky',
		width: '100%',
		zIndex: 10
	},
	topSticky: {
		top: 0,
		position: 'sticky',
		zIndex: 10
	}
}));
