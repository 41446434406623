import { createTheme } from '@material-ui/core';

const commonFont = {
	color: '#FFF',
	fontFamily: 'Roboto',
	fontSize: '14px',
	fontWeight: 400,
	letterSpacing: '0.15px'
};

// eslint-disable-next-line import/prefer-default-export
export const theme = createTheme({
	overrides: {
		MuiStepper: {
			root: {
				background: 'transparent',
				padding: 0
			}
		},
		MuiStepConnector: {
			lineHorizontal: {
				width: '25.5px'
			}
		},
		MuiStepIcon: {
			root: {
				'& $text': {
					fill: '#FAFAFA'
				},
				'&$active': {
					fill: '#FFF',
					'& $text': {
						fill: 'rgba(0, 0, 0, 0.87)'
					}
				},
				'&$completed': {
					cursor: 'pointer',
					fill: '#FFF',
					'& $text': {
						fill: '#6600FD'
					}
				}
			},
			text: {
				fill: 'yellow'
			}
		},
		MuiStepLabel: {
			label: {
				...commonFont,
				'&$completed': {
					...commonFont,
					fontWeight: 500
				},
				'&$active': {
					...commonFont,
					fontWeight: 500
				}
			}
		}
	}
});
