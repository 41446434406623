import React, { useState, useEffect } from 'react';
import {
	DialogContent,
	Button,
	Box,
	Typography,
	TextField
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import ErrorBanner from '../../../common/banners/ErrorBanner';
import useStyles from './styles';

function TeamInviteDialog({
	onBack,
	onNext,
	onMaybeLater,
	editCallback,
	error,
	validateEmails
}) {
	const classes = useStyles();
	const [emails, setEmails] = useState(['', '']);
	const [formValidation, setFormValidation] = useState({});
	const [loaded, setLoaded] = useState(false);

	const update = () => {
		const filteredEmails = emails.filter(email => email.length > 0);
		editCallback(filteredEmails);
	};

	useEffect(() => {
		if (loaded) {
			update();
		} else {
			setLoaded(true);
		}
	}, [emails]);

	const validateForm = async () => {
		const { valid, validationForm } = await validateEmails(emails);
		setFormValidation(validationForm);
		return valid;
	};

	const addAnotherHandler = () => {
		setEmails([...emails, '']);
	};

	const updateEmail = (value, index) => {
		const newEmails = [...emails];
		newEmails[index] = value;
		setEmails(newEmails);
	};

	const renderEmailField = (email, index) => {
		const helperText = formValidation?.emailErrors
			? formValidation?.emailErrors[index]
			: '';

		return (
			<Box mb={2} display='flex' alignItems='center' key={index}>
				<Box display='flex' alignItems='center' mr='9px' ml='-3px'>
					<PersonIcon />
				</Box>
				<TextField
					label='Email'
					id='email'
					variant='outlined'
					placeholder='Email'
					data-testid={`email${index}`}
					value={email}
					onChange={ev => updateEmail(ev.target.value, index)}
					error={helperText?.length > 0}
					helperText={helperText}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						classes: {
							input: classes.input
						}
					}}
				/>
			</Box>
		);
	};

	const handleNext = async ev => {
		const valid = await validateForm();
		if (valid) {
			onNext();
		}
	};

	return (
		<DialogContent classes={{ root: classes.content }}>
			<Box classes={{ root: classes.subContent }}>
				<Box mt={3} mb='5px'>
					<Typography className={classes.title} variant='h1'>
						Preservation is a team sport
					</Typography>
				</Box>
				<Box mt='10px'>
					<Typography className={classes.text}>
						Invite team members to supercharge monitoring efforts
					</Typography>
				</Box>
				{/* <Box mt={3}>
                    <Typography className={classes.text} >Invite with a link</Typography>
                </Box>
                <Box mt={1} mb={3} display='flex' alignItems='center'>
                    <Box>
                        <LinkOutlinedIcon/>
                    </Box>
                    <Box ml='13px' mr='9px' classes={{root: classes.linkContainer}}>
                        <TextField
                            disabled
                            value='https://invite.conserv.io/hq?3e87'
                            variant='outlined'
                        />
                    </Box>
                    <Box>
                        <Button
                        classes={{root: classes.copyButton}}
                        >COPY</Button>
                    </Box>
                </Box> */}
			</Box>
			{/* <Box mb={3}>
                <Divider/>
            </Box> */}
			<Box mt={5} className={classes.subContent}>
				{emails.map((email, index) => renderEmailField(email, index))}
				<Box display='flex' justifyContent='flex-end' mb={4}>
					<Button
						variant='text'
						className={classes.addAnotherButton}
						onClick={addAnotherHandler}
					>
						<AddCircleOutlineOutlinedIcon className={classes.addAnotherIcon} />{' '}
						Add Another
					</Button>
				</Box>
				{error && <ErrorBanner message={error} />}
				<Box display='flex' justifyContent='space-between'>
					<Box>
						<Button
							className={classes.maybeLaterButton}
							variant='text'
							onClick={onMaybeLater}
						>
							Maybe later
						</Button>
					</Box>
					<Box display='flex' justifyContent='right'>
						<Button variant='text' onClick={onBack}>
							Back
						</Button>
						<Button
							className={classes.button}
							id='complete_profile'
							onClick={handleNext}
							variant='contained'
							color='primary'
						>
							next
						</Button>
					</Box>
				</Box>
			</Box>
		</DialogContent>
	);
}

export default TeamInviteDialog;
