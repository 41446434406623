import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	body: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: '150%',
		letterSpacing: '0.15px',
		margin: '8px 0'
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '24px'
	},
	wideCard: {
		minWidth: '300px'
	},
	endAdornment: {
		fontFamily: 'Roboto'
	},
	divider: {
		marginTop: '16px',
		marginBottom: '16px'
	},
	link: {
		color: '#2898C4 !important',
		fontWeight: 'bold',
		cursor: 'pointer'
	},
	noPaddingTop: {
		paddingTop: '0 !important'
	},
	topSubTitle: {
		marginTop: '8px'
	},
	content: {
		paddingLeft: 0,
		paddingRight: 0
	},
	section: {
		marginLeft: '20px',
		marginRight: '20px'
	},
	firstSection: {
		marginTop: '18px'
	}
}));
