import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	leftNavBarContainer: {
		width: '128px',
		height: '100%'
	},
	leftNavBar: {
		display: 'flex',
		alignItems: 'center',
		paddingTop: '13px',
		paddingBottom: '13px',
		paddingLeft: '15px',
		'&:hover': {
			color: props => (props?.drawerOpen ? '#FFF' : '#E5E5E5')
		}
	},
	icon: {
		marginRight: '8px'
	},
	text: {
		color: '#FFFFFF',
		'&:hover': {
			fontWeight: 'bold'
		}
	},
	labelText: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '14px',
		letterSpacing: '0em'
	}
}));
