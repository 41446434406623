import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { PreferencesContext } from '../../providers/PreferencesProvider';
import DataLabel from '../common/data/DataLabel';
import labels from '../../res/data';

function EventParameters(props) {
	const context = useContext(PreferencesContext);
	const getReadingConditionDescription = () => {
		switch (props.eventProfile.params.threshold) {
			case 'in':
				return 'inside range';
			case 'out':
				return 'outside range';
			case 'gt':
				return 'greater than';
			case 'lt':
				return 'less than';
			default:
				return '';
		}
	};

	// how to display the reading params to the user in the events table
	const formatParameterValues = () => {
		const unit = `${
			props.eventProfile.params?.reading === 'vis' ? ' ' : ''
		}${labels.getLabelUnits(
			context.getDisplayUnit(props.eventProfile.params?.reading),
			true
		)}`;
		if (
			props.eventProfile.params.threshold === 'gt' ||
			props.eventProfile.params.threshold === 'lt'
		) {
			return `${context.convertUnitToDisplay(
				props.eventProfile.params?.reading.replace('1', ''),
				props.eventProfile.params.thresholdValue,
				false,
				true
			)}${unit}`;
		}
		if (
			props.eventProfile.params.rangeStart &&
			props.eventProfile.params.rangeEnd
		) {
			return `${context.convertUnitToDisplay(
				props.eventProfile.params?.reading.replace('1', ''),
				props.eventProfile.params.rangeStart,
				false,
				true
			)}${unit} - ${context.convertUnitToDisplay(
				props.eventProfile.params?.reading.replace('1', ''),
				props.eventProfile.params.rangeEnd,
				false,
				true
			)}${unit}`;
		}
		return (
			`${context.convertUnitToDisplay(
				props.eventProfile.params.reading,
				props.eventProfile.params.rangeStart,
				false,
				true
			)}${unit} - ${context.convertUnitToDisplay(
				props.eventProfile.params.reading,
				props.eventProfile.params.rangeEnd,
				false,
				true
			)}${unit}` || 'N/A'
		);
	};

	const getSystemConditionDescription = () => {
		switch (props.eventProfile.params.system) {
			case 'bat':
				return 'Battery Low';
			case 'notSeen':
				return `Sensor not seen in ${props.eventProfile.params.systemThreshold} hours `;
			default:
				return '';
		}
	};

	const getUvDescription = () => {
		const startDurationUnit =
			props.eventProfile.params?.lagStartUnit || 'minute';
		const startDuration =
			props.eventProfile?.params?.lagStartUnit === 'day'
				? Number(props.eventProfile?.params?.lagStart) / 1440
				: props.eventProfile?.params?.lagStartUnit === 'hour'
				? Number(props.eventProfile?.params?.lagStart) / 60
				: Number(props.eventProfile?.params?.lagStart);

		return (
			<Box>
				UV detected for {startDuration} {startDurationUnit}
				{startDuration > 1 ? 's' : ''}
			</Box>
		);
	};

	return (
		<Box>
			{props?.eventProfile?.type === 'reading' ? (
				<>
					{props.eventProfile.params?.reading.replace('1', '') === 'uv' ? (
						getUvDescription()
					) : (
						<>
							<DataLabel name={props.eventProfile.params.reading} />
							{props.eventProfile.params?.reading.replace('1', '') !== 'offline'
								? ` ${getReadingConditionDescription()} ${formatParameterValues()}`
								: ''}
						</>
					)}
				</>
			) : null}
			{props?.eventProfile?.type === 'system'
				? getSystemConditionDescription()
				: null}
			{props?.eventProfile?.type === 'leak' ? <Box>Leak detected</Box> : null}
			{props?.eventProfile?.type === 'shock' ? (
				<Box>Movement detected</Box>
			) : null}
		</Box>
	);
}

export default EventParameters;
