import React from 'react';
import EventBody from '../EventBody';
import { TriggerForm } from '../../Forms';

function Trigger() {
	return (
		<EventBody title='Event Trigger'>
			<TriggerForm editable />
		</EventBody>
	);
}

export default Trigger;
