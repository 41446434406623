export default [
	{
		id: '0',
		label: 'Parent 1',
		children: [
			{
				id: '1',
				label: 'Child - 1'
			},
			{
				id: '3',
				label: 'Child - 3',
				children: [
					{
						id: '4',
						label: 'Child - 4',
						children: [
							{
								id: '7',
								label: 'Child - 7'
							},
							{
								id: '8',
								label: 'Child - 8'
							}
						]
					}
				]
			},
			{
				id: '5',
				label: 'Child - 5',
				children: [
					{
						id: '6',
						label: 'Child - 6'
					}
				]
			}
		]
	},
	{
		id: '10',
		label: 'Parent 2',
		children: [
			{
				id: '11',
				label: 'Child - 11'
			},
			{
				id: '12',
				label: 'Child - 12'
			},
			{
				id: '13',
				label: 'Child - 13',
				children: [
					{
						id: '14',
						label: 'Child - 14',
						children: [
							{
								id: '17',
								label: 'Child - 17'
							},
							{
								id: '18',
								label: 'Child -18'
							}
						]
					}
				]
			},
			{
				id: '15',
				label: 'Child - 15',
				children: [
					{
						id: '16',
						label: 'Child - 16'
					}
				]
			}
		]
	}
];
