import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Box,
	Typography
} from '@material-ui/core';
import DataLabel from '../../../../common/data/DataLabel';
import DataUnit from '../../../../common/data/DataUnit';
import DataValue from '../../../../common/data/DataValue';

import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles';

function EventDetails({ open, event, onClose }) {
	const classes = useStyles();

	const getConditionDescription = params => {
		let singleReading = 0;
		if (Array.isArray(params.value)) {
			singleReading = params.value[0];
		} else {
			singleReading = params.value;
		}
		switch (params.type) {
			case 'in':
				return (
					<>
						in the specified alert range of{' '}
						<DataValue
							name={params.reading}
							value={params.value[0]}
							format='minimal'
						/>
						<DataUnit name={params.reading} format='minimal' /> to{' '}
						<DataValue
							name={params.reading}
							value={params.value[1]}
							format='minimal'
						/>
						<DataUnit name={params.reading} format='minimal' />
					</>
				);
			case 'out':
				return (
					<>
						outside the specified alert range of{' '}
						<DataValue name={params.reading} value={params.value[0]} /> to
						<DataUnit name={params.reading} format='minimal' /> to{' '}
						<DataValue name={params.reading} value={params.value[1]} />
						<DataUnit name={params.reading} format='minimal' /> to{' '}
					</>
				);
			case 'gt':
				return (
					<>
						above the specified value of{' '}
						<DataValue name={params.reading} value={singleReading} />
						<DataUnit name={params.reading} format='minimal' />
					</>
				);
			case 'lt':
				return (
					<>
						below the specified value of{' '}
						<DataValue name={params.reading} value={singleReading} />
						<DataUnit name={params.reading} format='minimal' />
					</>
				);
			default:
				return 'unknown condition';
		}
	};

	const getCondition = ({ data, params }) => {
		const alertval = data[params.reading];
		return (
			<Box>
				<Typography className={classes.text}>
					<DataLabel name={params.reading} format='minimal' />{' '}
					<DataValue name={params.reading} value={alertval} />
					<DataUnit name={params.reading} format='minimal' /> is{' '}
					{getConditionDescription(params)}
				</Typography>
			</Box>
		);
	};
	return (
		<Dialog open={open} classes={{ paper: classes.paper }} onClose={onClose}>
			<DialogTitle
				disableTypography
				className={classes.header}
				id='alert-dialog-title'
			/>

			<DialogContent classes={{ root: classes.content }}>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onClose}
				>
					<CloseIcon />
				</IconButton>

				<Box mt={'43.5px'} ml={'5px'} display='flex'>
					<Typography className={classes.title} variant='h1'>
						Details
					</Typography>
				</Box>
				<Box mt={3} display='flex'>
					{getCondition(event)}
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default EventDetails;
