import React from 'react';
import { Button } from '@material-ui/core';

export default function PendingSubscriptionButton({ onClick }) {
	return (
		<Button disabled onClick={onClick} variant='outlined'>
			Pending
		</Button>
	);
}
