import React, { useState, useEffect } from 'react';
import { Grid, InputAdornment, MenuItem } from '@material-ui/core';
import { Controller, useFormContext } from 'react-hook-form';
import DecoratedSelect from '../../../common/form/v2/DecoratedSelect';
import DecoratedTextField from '../../../common/form/v2/DecoratedTextField';
import DataUnit from '../../../common/data/DataUnit';
import useStyles from './styles';

function TypeForm({ editable }) {
	const classes = useStyles();
	const { control, clearErrors, setValue, watch, trigger } = useFormContext();

	const [typeOptions] = useState([
		{ label: 'Temperature', value: 'temp1' },
		{ label: 'Relative Humidity', value: 'rh' },
		{ label: 'Illuminance', value: 'vis' },
		// { label: 'Ultraviolet', value: 'uv1' },
		{ label: 'Movement', value: 'shock' },
		{ label: 'Leak', value: 'leak' },
		{ label: 'Sensor Offline', value: 'offline' }
	]);
	const [conditionOptions] = useState([
		{ label: 'Above a value', value: 'gt' },
		{ label: 'Below a value', value: 'lt' },
		{ label: 'Outside a range', value: 'out' }
	]);

	const getTypeLabel = type =>
		typeOptions.find(e => e.value === type)?.label || type;

	const watchType = watch('type');
	const watchCondition = watch('threshold');

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (name === 'type' && type === 'change' && value.type === 'offline') {
				setValue('lagStartUnit', 'hour');
			}
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		clearErrors(['rangeStart', 'rangeEnd']);
	}, [watchType]);

	return (
		<>
			<Controller
				name='type'
				control={control}
				render={({
					field: { onChange, value, ref, defaultValue },
					fieldState: { error }
				}) => (
					<DecoratedSelect
						required
						aria-label='type'
						defaultValue={defaultValue}
						value={value}
						editing={editable}
						error={error}
						helperText={error?.message}
						id='type'
						onChange={onChange}
						placeholder='Select event type'
						inputProps={{ ref }}
					>
						{typeOptions.map(({ label, value: levelValue }) => (
							<MenuItem key={levelValue} value={levelValue}>
								{label}
							</MenuItem>
						))}
					</DecoratedSelect>
				)}
			/>
			{watchType &&
				watchType !== 'leak' &&
				watchType !== 'shock' &&
				watchType !== 'uv1' &&
				watchType !== 'offline' && (
					<>
						<Grid item className={classes.section}>
							<Controller
								name='threshold'
								control={control}
								render={({
									field: { onChange, value, ref, defaultValue },
									fieldState: { error }
								}) => (
									<DecoratedSelect
										required
										aria-label='threshold'
										defaultValue={defaultValue}
										value={value}
										editing={editable}
										error={error}
										helperText={error?.message}
										id='threshold'
										onChange={e => {
											onChange(e);
											trigger(['thresholdValue', 'rangeEnd', 'rangeStart']);
										}}
										placeholder='Select condition'
										inputProps={{ ref }}
									>
										{conditionOptions.map(({ label, value: levelValue }) => (
											<MenuItem key={levelValue} value={levelValue}>
												{label}
											</MenuItem>
										))}
									</DecoratedSelect>
								)}
							/>
						</Grid>
						{watchCondition !== 'out' ? (
							<Grid item className={classes.section}>
								<Controller
									name='thresholdValue'
									control={control}
									render={({
										field: { onChange, defaultValue, value },
										fieldState: { error }
									}) => (
										<DecoratedTextField
											aria-label='define thresholdValue'
											type='number'
											defaultValue={defaultValue}
											value={value}
											editing={editable}
											error={error}
											helperText={error?.message}
											id='thresholdValue'
											label='Define value'
											onChange={onChange}
											endAdornment={
												<InputAdornment position='end'>
													<DataUnit name={watchType} format='minimal' />
												</InputAdornment>
											}
										/>
									)}
								/>
							</Grid>
						) : (
							<Grid item className={classes.section}>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<Controller
											name='rangeStart'
											control={control}
											render={({
												field: { onChange, defaultValue, value },
												fieldState: { error }
											}) => (
												<DecoratedTextField
													aria-label='above or below threshold value'
													type='number'
													defaultValue={defaultValue}
													value={value}
													editing={editable}
													error={error}
													helperText={error?.message}
													id='rangeStart'
													label={`Minimum ${getTypeLabel(watchType)}`}
													onChange={e => {
														onChange(e);
														trigger(['rangeEnd']);
													}}
													endAdornment={
														<InputAdornment position='end'>
															<DataUnit name={watchType} format='minimal' />
														</InputAdornment>
													}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={6}>
										<Controller
											name='rangeEnd'
											control={control}
											render={({
												field: { onChange, defaultValue, value },
												fieldState: { error }
											}) => (
												<DecoratedTextField
													aria-label='above threshold value'
													type='number'
													defaultValue={defaultValue}
													value={value}
													editing={editable}
													error={error}
													helperText={error?.message}
													id='rangeEnd'
													label={`Maximum ${getTypeLabel(watchType)}`}
													onChange={e => {
														onChange(e);
														trigger(['rangeStart']);
													}}
													endAdornment={
														<InputAdornment position='end'>
															<DataUnit name={watchType} format='minimal' />
														</InputAdornment>
													}
												/>
											)}
										/>
									</Grid>
								</Grid>
							</Grid>
						)}
					</>
				)}
		</>
	);
}

export default TypeForm;
