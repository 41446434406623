import * as yup from 'yup';

const getScopeSchema = () => ({
	scope: yup.string().trim().required('A scope is required'),
	eventprofilescopes: yup
		.array()
		.test(
			'is-eventprofilescopes-required',
			'A selected scope is required',
			function (eventprofilescopes) {
				const isValid =
					this.parent.scope === 'global' || eventprofilescopes?.length > 0;
				return isValid;
			}
		)
});

export default getScopeSchema;
