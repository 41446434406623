import React from 'react';
import { DialogContent, Box, Typography } from '@material-ui/core';
import useStyles from './styles';

function EventBody({ title, description, children }) {
	const classes = useStyles();

	return (
		<DialogContent classes={{ root: classes.content }}>
			<Box mb='5px'>
				<Typography className={classes.title} variant='h3'>
					{title}
				</Typography>
			</Box>
			{description ? (
				<Box mb={3}>
					<Typography className={classes.text}>{description}</Typography>
				</Box>
			) : null}
			<Box>{children}</Box>
		</DialogContent>
	);
}

export default EventBody;
