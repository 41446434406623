import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import clsx from 'clsx';
import {
	Box,
	Chip,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	TableSortLabel,
	Tooltip,
	Typography
} from '@material-ui/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useHistory } from 'react-router-dom';

import Loading from '../../../common/elements/Loading';
import theme from '../../../../style/themev2';
import EventDetails from './EventDetails';
import useStyles from './styles';
import SegmentUtil from '../../../../util/SegmentUtil';
import { formatTimeFromMinutes } from '../../../../util/formatTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

function EventList({ events, onSort, sort: defaultSort, isFetchingData }) {
	const classes = useStyles();
	const history = useHistory();

	const [sort, setSort] = useState(defaultSort);
	const [selectedEvent, setSelectedEvent] = useState();
	const [open, setOpen] = useState(false);

	const onRequestSort = property => {
		const newSort =
			property !== sort?.orderBy
				? { orderBy: property, direction: 'asc' }
				: sort.direction === 'desc'
				? property === 'date'
					? { orderBy: 'date', direction: 'asc' }
					: { orderBy: 'date', direction: 'desc' }
				: { ...sort, direction: 'desc' };

		setSort(newSort);
		onSort(newSort);
	};

	const createSortHandler = property => event => {
		onRequestSort(property);
	};

	const handleClickRow = event => history.push(`/event/details/${event.uuid}`);

	const handleCloseDetails = () => {
		setOpen(false);
		setSelectedEvent(null);
	};

	const headerConfig = [
		{
			id: 1,
			name: 'Date and time',
			sortKey: 'date',
			width: '14%',
			onClick: createSortHandler('date')
		},
		{
			id: 2,
			name: 'Duration',
			sortKey: 'duration',
			width: '7%',
			onClick: createSortHandler('duration')
		},
		{
			id: 3,
			name: 'Location',
			sortKey: 'location',
			width: '12%',
			onClick: createSortHandler('location')
		},
		{
			id: 4,
			name: 'Space',
			sortKey: 'space',
			width: '12%',
			onClick: createSortHandler('space')
		},
		{
			id: 5,
			name: 'Sensor',
			sortKey: 'sensor',
			width: '12%',
			onClick: createSortHandler('sensor')
		},
		{
			id: 6,
			name: 'Name',
			sortKey: 'name',
			width: '12%',
			onClick: createSortHandler('name')
		},
		{
			id: 7,
			name: 'Type',
			sortKey: 'type',
			width: '12%',
			onClick: createSortHandler('type')
		},
		{
			id: 8,
			name: 'Last Comment',
			sortKey: 'last_comment_at',
			width: '12%',
			onClick: createSortHandler('last_comment_at')
		},
		{
			id: 9,
			name: '',
			width: '8%'
		}
	];

	const handleClick = (onClickCallback, sortKey) => {
		SegmentUtil.track(SegmentUtil.actions.sort, { by: sortKey });
		onClickCallback();
	};

	const getLabel = event => {
		if (event.eventType !== 'offline') {
			return eventDictionary[event.eventType];
		}

		return event.triggerByScope === 'sensor'
			? 'Sensor Offline'
			: 'Gateway Offline';
	};

	const getGatewayLabel = gateway => {
		let label = '';
		if (gateway?.name) {
			label = `${gateway.name} `;
		}
		return `${label}Gateway Offline`;
	}

	const eventDictionary = {
		rh: 'Relative Humidity',
		temp1: 'Temperature',
		vis: 'Illuminance',
		uv1: 'Ultraviolet',
		movement: 'Movement',
		leak: 'Leak',
		offline: 'Offline'
	};

	return (
		<ThemeProvider theme={theme}>
			<Table
				classes={{ root: classes.tableRoot }}
				aria-label='alert table'
				stickyHeader
			>
				<TableHead className={classes.tableHead}>
					<TableRow>
						{headerConfig.map(({ id, name, sortKey, width, onClick }) => (
							<TableCell
								className={classes.tableTitle}
								key={id}
								style={{ width }}
							>
								{name ? (
									<TableSortLabel
										active={sortKey === sort?.orderBy}
										direction={sort?.direction}
										onClick={() => handleClick(onClick, sortKey)}
									>
										{name}
									</TableSortLabel>
								) : null}
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				{isFetchingData ? (
					<Box className={classes.loadingContainer}>
						<Loading />
					</Box>
				) : (
					<TableBody>
						{events.length === 0 ? (
							<TableRow key={0}>
								<TableCell colSpan={7}>
									<Typography variant='h4'>No events found</Typography>
								</TableCell>
							</TableRow>
						) : (
							events.map(event => (
								<TableRow
									key={event.uuid || event.id}
									className={classes.tableRow}
									onClick={() => handleClickRow(event)}
								>
									<TableCell sortDirection='asc' className={classes.tableCell}>
										<Typography
											variant='body2'
											noWrap
											className={event.unread ? classes.tableCellTextBold : ''}
										>
											{dayjs(event.startDate).format('MM/DD/YYYY hh:mm A')}
										</Typography>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Typography
											variant='body2'
											noWrap
											className={event.unread ? classes.tableCellTextBold : ''}
										>
											{formatTimeFromMinutes(event.duration)}
										</Typography>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Typography
											variant='body2'
											noWrap
											className={event.unread ? classes.tableCellTextBold : ''}
										>
											{event.facility?.name}
										</Typography>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Typography
											variant='body2'
											noWrap
											className={event.unread ? classes.tableCellTextBold : ''}
										>
											{event.zone?.name}
										</Typography>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Typography
											variant='body2'
											noWrap
											className={event.unread ? classes.tableCellTextBold : ''}
										>
											{event.triggerByScope === 'sensor'
												? event.sensor?.name
												: null}
										</Typography>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Tooltip title={event.name} arrow>
											<Typography
												variant='body2'
												noWrap
												className={
													event.unread ? classes.tableCellTextBold : ''
												}
											>
												{event.triggerByScope === 'gateway'
													? getGatewayLabel(event.gateway)
													: event.name}
											</Typography>
										</Tooltip>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Typography
											variant='body2'
											noWrap
											className={clsx(
												classes.eventType,
												event.unread ? classes.tableCellTextBold : ''
											)}
										>
											{getLabel(event)}
										</Typography>
									</TableCell>
									<TableCell className={classes.tableCell}>
										<Tooltip
											title={
												event.lastCommentAt
													? dayjs(event.lastCommentAt).format(
															'MM/DD/YYYY hh:mm A'
													  )
													: ''
											}
										>
											<Typography variant='body2'>
												{event.lastCommentAt
													? dayjs(event.lastCommentAt).fromNow()
													: ''}
											</Typography>
										</Tooltip>
									</TableCell>
									<TableCell
										className={(classes.tableCell, classes.statusCell)}
									>
										{event.type === 'event' && !event.stopDate && (
											<Chip label='Active' className={classes.activeChip} />
										)}
									</TableCell>
								</TableRow>
							))
						)}
					</TableBody>
				)}
			</Table>
			{open && (
				<EventDetails
					open={open}
					event={selectedEvent}
					onClose={handleCloseDetails}
				/>
			)}
		</ThemeProvider>
	);
}

export default EventList;
