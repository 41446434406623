import React from 'react';
import { FormControl, Box } from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
	KeyboardDateTimePicker
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';
import icons from '../../../../../style/icons';
import dateTimePickerTheme from './styles';

export default function DecoratedDatePicker(props) {
	let iconPath = null;
	// allow override of variant icon by setting icon path directly.
	if (props.iconPath) {
		iconPath = props.iconPath;
	} else {
		iconPath = icons[props.conservField];
	}
	const passthroughProps = { ...props };
	delete passthroughProps.conservField;
	delete passthroughProps.includeTime;
	delete passthroughProps.editing;

	return (
		<Box
			display='flex'
			flexWrap='nowrap'
			alignItems='flex-end'
			flexDirection='row'
			style={{ width: '100%' }}
		>
			<Box flexGrow={1}>
				<FormControl style={{ width: '100%' }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						{props.includeTime ? (
							<ThemeProvider theme={dateTimePickerTheme}>
								<KeyboardDateTimePicker
									{...passthroughProps}
									inputVariant='outlined'
									disabled={!props.editing}
									variant='inline'
								/>
							</ThemeProvider>
						) : (
							<KeyboardDatePicker
								{...passthroughProps}
								inputVariant='outlined'
								disabled={!props.editing}
								variant='inline'
								InputProps={{
									style: props.inputStyle
								}}
							/>
						)}
					</MuiPickersUtilsProvider>
				</FormControl>
			</Box>
		</Box>
	);
}
