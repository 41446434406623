import React, { useState, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';

import {
	Box,
	ListItem,
	ListItemAvatar,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	TextField
} from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from './styles';

const DraggableListItem = ({
	item: { id, primary, secondary, editing = false },
	index,
	isValid,
	onEdit,
	onDelete,
	label
}) => {
	const [display, setDisplay] = useState(false);
	const [showEditing, setShowEditing] = useState(editing);
	const [error, setError] = useState('');
	const [editedPrimary, setEditedPrimary] = useState(primary);
	const classes = useStyles({ editing: showEditing });

	const handleDone = async () => {
		setError('');
		if (editedPrimary?.length > 0) {
			const { valid, errorMessage } = isValid(editedPrimary, index);
			if (valid) {
				await onEdit(editedPrimary, index);
				setShowEditing(false);
			} else {
				setError(errorMessage);
			}
		} else {
			setError('This field is required');
		}
	};

	const handleCancel = () => {
		setShowEditing(false);
		if (!primary) {
			onEdit(primary, index);
		}
	};

	return (
		<Box
			className={classes.listItemDiv}
			onMouseEnter={e => setDisplay(true)}
			onMouseLeave={e => setDisplay(false)}
		>
			<Draggable draggableId={`dragable-${id}`} index={index}>
				{(provided, snapshot) => (
					<ListItem
						ContainerComponent='div'
						ref={provided.innerRef}
						{...provided.draggableProps}
						{...provided.dragHandleProps}
						className={
							(snapshot.isDragging ? classes.draggingListItem : '',
							classes.listItem)
						}
					>
						{showEditing ? (
							<React.Fragment>
								<TextField
									className={classes.input}
									error={Boolean(error)}
									helperText={error}
									label={`${label} Name`}
									onChange={e => setEditedPrimary(e.target.value)}
									required
									defaultValue={primary}
									variant='outlined'
									autoFocus
									onKeyPress={ev => {
										if (ev.key === 'Enter') {
											handleDone();
											ev.preventDefault();
										}
									}}
									onKeyDown={ev => {
										if (ev.key === 'Escape') {
											handleCancel();
											ev.preventDefault();
											ev.stopPropagation();
										}
									}}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge='end'
										aria-label='edit'
										className={classes.iconButton}
										onClick={handleCancel}
									>
										<CloseIcon className={classes.close} />
									</IconButton>
									<IconButton
										edge='end'
										aria-label='delete'
										className={classes.iconButton}
										onClick={handleDone}
									>
										<DoneIcon className={classes.done} />
									</IconButton>
								</ListItemSecondaryAction>
							</React.Fragment>
						) : (
							<React.Fragment>
								<DragIndicatorIcon className={classes.dragIcon} />
								<ListItemText
									primary={primary}
									secondary={secondary}
									className={classes.itemText}
								/>
								{display ? (
									<ListItemSecondaryAction>
										<IconButton
											edge='end'
											aria-label='edit'
											className={classes.iconButton}
											onClick={e => setShowEditing(value => !value)}
										>
											<EditIcon />
										</IconButton>
										<IconButton
											edge='end'
											aria-label='delete'
											className={(classes.iconButton, classes.close)}
											onClick={e => onDelete(index)}
										>
											<CloseIcon />
										</IconButton>
									</ListItemSecondaryAction>
								) : null}
							</React.Fragment>
						)}
					</ListItem>
				)}
			</Draggable>
		</Box>
	);
};

export default DraggableListItem;
