import React, { useState } from 'react';
import { Avatar, Grid, Typography } from '@material-ui/core';

import Api from '../../../../../api/Api';
import useStyles from './styles';
import PendingSubscriptionButton from '../../../../common/buttons/PendingSubscriptionButton';
import SubscribedButton from '../../../../common/buttons/SubscribedButton';
import UnsubscribedButton from '../../../../common/buttons/UnsubscribedButton';
import { useStoreon } from 'storeon/react';
import SegmentUtil from '../../../../../util/SegmentUtil';
import useRoles from 'legacy/hooks/useRoles';

const BUTTONS_MAP = {
	PENDING: PendingSubscriptionButton,
	SUBSCRIBED: SubscribedButton,
	UNSUBSCRIBED: UnsubscribedButton
};

function Subscriber({ eventProfileUuid, isFromModal, status, user }) {
	const classes = useStyles();
	const { dispatch, user: me } = useStoreon('user');
	const { isUser } = useRoles();

	const [currentStatus, setCurrentStatus] = useState(status);

	const isSubscribed = currentStatus === 'SUBSCRIBED';

	const handleSubscription = async () => {
		if (!eventProfileUuid) return;

		const response = await Api.updateSubscribers({
			uuid: eventProfileUuid,
			subscribersToUpdate: [
				{ subscriberUuid: user.uuid, status: !isSubscribed }
			]
		});

		if (response.data && response.data.subscribersUpdated.length > 0) {
			dispatch('eventprofiles/refresh');
			const newStatus = isSubscribed ? 'UNSUBSCRIBED' : 'SUBSCRIBED';
			const action = isSubscribed
				? SegmentUtil.actions.removesubscriber
				: SegmentUtil.actions.addsubscriber;

			SegmentUtil.track(action, {
				category: 'app',
				where: isFromModal ? 'Standalone Modal' : 'Drawer'
			});

			setCurrentStatus(newStatus);
			dispatch('notification/add', {
				message: `${username} was ${newStatus.toLocaleLowerCase()} successfully!`,
				severity: 'success',
				variant: 'snackbar'
			});
		}
	};

	const username = user.first_name
		? `${user.first_name} ${user.last_name}`
		: user.email;

	const StatusButton = BUTTONS_MAP[currentStatus];

	return (
		<Grid item>
			<div className={classes.subscriber}>
				<div className={classes.subscriberInfo}>
					<Avatar size={40} src={user.avatar} />
					<Typography className={classes.me}>{username}</Typography>
				</div>
				<StatusButton
					onClick={handleSubscription}
					disabled={isUser && user.uuid !== me.uuid}
				/>
			</div>
		</Grid>
	);
}

export default Subscriber;
