import { createTheme } from '@material-ui/core';
import { colors } from './colors';

const theme = createTheme({
	disabled: {
		color: colors.ice.light,
		backgroundColor: colors.nitro.primary
	},
	palette: {
		common: {
			white: '#ffffff',
			black: colors.text.primary
		},
		primary: {
			main: colors.ice.primary,
			background: colors.laser.primary
		},
		secondary: {
			main: colors.ice.primary
		},
		grey: {
			50: '#fff'
		},
		disabled: {
			color: colors.ice.light,
			backgroundColor: colors.nitro.primary
		}
	},
	typography: {
		fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif'],
		fontSize: 14,
		h1: {
			fontSize: '32px',
			fontWeight: 700,
			fontFamily: ['Sansita', 'Helvetica', 'Arial', 'sans-serif']
		},
		h2: {
			fontSize: '24px',
			fontWeight: 500,
			fontFamily: ['Sansita', 'Helvetica', 'Arial', 'sans-serif']
		},
		h3: {
			fontSize: '22px',
			fontWeight: 500,
			fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
		},
		h4: {
			fontSize: '20px',
			fontWeight: 700,
			fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
		},
		h5: {
			fontSize: '16px',
			fontWeight: 700,
			fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
		},
		h6: {
			fontSize: '14px',
			fontWeight: 400,
			fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
		},
		body1: {
			fontSize: 14,
			fontWeight: 400
		},
		body2: {
			fontSize: 12,
			fontWeight: 300
		},
		body3: {
			fontSize: 16,
			fontWeight: 400,
			fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
		},
		caption: {
			fontSize: 12,
			fontWeight: 300
		}
	},
	overrides: {
		Mui: {
			'&$focused': {
				borderColor: colors.nitro.primary
			}
		},
		MuiAlert: {
			icon: {
				marginLeft: 12
			}
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: '#fff',
				borderBottom: `1px solid ${colors.laser.primary}`
			}
		},
		MuiButton: {
			root: {
				fontWeight: 400,
				minWidth: 100
			},
			text: {
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: 'transparent',
					color: colors.ice.primary
				}
			},
			containedPrimary: {
				backgroundColor: colors.ice.primary,
				'&:hover': {
					backgroundColor: colors.ice.medium,
					outline: 0,
					border: 'none'
				},
				'&:active': {
					outline: 0,
					border: 'none',
					backgroundColor: colors.ice.shade // #E43C3D
				},
				'&:focus': {
					outline: 0,
					border: 'none',
					backgroundColor: colors.ice.shade
				}
			},
			textPrimary: {
				color: colors.ice.primary,
				'&:hover': {
					backgroundColor: colors.ice.lightOpacity
				}
			},
			outlinedPrimary: {
				color: colors.ice.primary,
				borderColor: colors.ice.primary,
				backgroundColor: '#fff',
				'&:hover': {
					borderColor: colors.ice.primary,
					backgroundColor: colors.ice.lightOpacity
				}
			},
			containedSecondary: {
				backgroundColor: colors.gemini.primary,
				color: '#FFF',
				'&:hover': {
					backgroundColor: colors.gemini.medium,
					outline: 0,
					border: 'none'
				},
				'&:active': {
					outline: 0,
					border: 'none',
					backgroundColor: colors.gemini.shade
				},
				'&:focus': {
					outline: 0,
					border: 'none',
					backgroundColor: colors.gemini.shade
				}
			}
		},
		MuiToggleButtonGroup: {
			root: {
				height: '34px'
			},
			groupedHorizontal: {
				'&:not(:first-child)': {
					marginLeft: -1,
					borderLeft: '1px solid transparent',
					borderTopLeftRadius: 0,
					borderBottomLeftRadius: 0
				},
				'&:not(:last-child)': {
					borderTopRightRadius: 0,
					borderBottomRightRadius: 0
				}
			}
		},
		MuiToggleButton: {
			root: {
				textTransform: 'initial',
				border: '1px solid black',
				borderRadius: 8,
				color: 'black',
				backgroundColor: colors.laser.light,
				'&:hover': {
					backgroundColor: colors.laser.light
				},
				'&:active': {
					outline: 0,
					border: 'none',
					color: 'white',
					backgroundColor: colors.laser.primary
				},
				'&:focus': {
					outline: 0,
					color: 'white',
					backgroundColor: colors.laser.primary
				},
				'&$selected': {
					color: 'white',
					backgroundColor: colors.laser.primary,
					'&:hover': {
						backgroundColor: colors.laser.primary
					},
					'& + &': {
						borderLeft: 0,
						marginLeft: 0
					}
				}
			}
		},
		MuiPaper: {
			outlined: {
				borderColor: '#fff',
				'&:hover': {
					borderColor: colors.laser.medium,
					border: 1,
					borderStyle: 'solid',
					backgroundColor: '#fafafa'
				}
			},
			rounded: {
				borderRadius: 6
			}
		},
		MuiSvgIcon: {
			colorPrimary: {
				color: colors.nitro.primary
			},
			colorError: {
				color: colors.gemini.primary
			}
		},
		MuiTextField: {
			root: {
				color: colors.ice.primary,
				marginBottom: 5,
				border: 0
			}
		},
		MuiFormLabel: {
			root: {
				focused: {
					color: colors.ice.primary
				},
				'&$focused': {
					color: colors.ice.primary
				}
			},
			asterisk: {
				color: colors.gemini.shade
			}
		},
		MuiSelect: {
			nativeInput: {
				'&$focused': {
					color: colors.ice.primary
				}
			}
		},
		MuiInput: {
			underline: {
				'&::after': {
					borderColor: colors.ice.primary
				}
			}
		},
		MuiOutlinedInput: {
			root: {
				fontSize: '16px',
				focused: {
					borderColor: colors.ice.primary
				},
				'&$focused $notchedOutline': {
					borderColor: colors.ice.primary,
					borderWidth: 2
				},
				width: '100%'
			},
			input: {
				padding: 9,
				fontSize: '16px',
				focused: {
					borderColor: colors.ice.primary
				},
				'&$focused': {
					borderColor: colors.ice.primary
				}
			},
			colorSecondary: {
				backgroundColor: '#fff',
				border: 0,
				'&$focused': {
					border: '2 !important'
				}
			},
			notchedOutline: {
				'&$focused': {
					borderColor: colors.ice.primary,
					color: 'tomato',
					fontWeight: 'bold',
					Mui: {
						focused: {
							borderColor: colors.ice.primary
						}
					}
				}
			}
		},
		MuiSpeedDial: {
			fab: {
				color: '#000',
				backgroundColor: colors.nitro.primary,
				'&:hover': {
					backgroundColor: colors.nitro.medium
				},
				'&:focus': {
					outline: 0
				}
			}
		},
		MuiSlider: {
			root: {
				color: colors.nitro.primary
			}
		},
		MuiDialog: {
			paper: {
				// cannot set a fixed height, we have some dialogs (like PDF export) that need
				// more vertical space.  In general, let's aim for rules, not fixed values.
				// height: 269
				minWidth: 400
			}
		},
		MuiBackdrop: {
			root: {
				backgroundColor: 'rgba(240,240,240,0.85)'
			}
		},
		MuiDialogContent: {
			root: {
				padding: '48px 40px',
				'&&:first-child': {
					paddingTop: 50
				}
			}
		},
		MuiDialogTitle: {
			root: {
				padding: '8px 8px 24px 40px'
			}
		},
		MuiDialogActions: {
			root: {
				justifyContent: 'flex-start',
				paddingRight: '40px',
				paddingLeft: '40px',
				paddingTop: '16px',
				paddingBottom: '16px'
			}
		},
		MuiLink: {
			root: {
				fontSize: 14,
				fontWeight: 500,
				textTransform: 'none'
			},
			underlineHover: {
				textDecoration: 'underline',
				'&:hover': {
					color: colors.diamond.primary,
					fontWeight: '700 !important',
					cursor: 'pointer'
				}
			}
		},
		MuiListItemText: {
			primary: {
				fontSize: 16
			}
		},
		MuiListItem: {
			button: {
				border: '0 !important'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontFamily: 'Roboto',
				fontSize: '14px',
				fontWeight: 400
			}
		},
		MuiChip: {
			root: {
				borderRadius: '8px',
				backgroundColor: '#FFF',
				borderColor: colors.ice.primary,
				border: '2px solid',
				color: colors.ice.primary
			},
			deleteIcon: {
				color: colors.ice.primary,
				'&:hover': {
					color: colors.ice.shade
				}
			}
		},
		MuiSnackbar: {
			root: {
				backgroundColor: colors.ice.primary.shade,
				color: '#FFF'
			},
			anchorOriginBottomLeft: {
				bottom: '28px !important',
				left: '160px !important'
			}
		},
		MuiTableRow: {
			root: {
				'& th:first-child': {
					paddingLeft: '16px'
				},
				'& td:first-child': {
					paddingLeft: '16px'
				},
				'& td:last-child': {
					paddingRight: '16px'
				}
			}
		},
		MuiTabs: {
			root: {
				'&$selected': {
					outline: 0,
					border: 'none'
				},
				'&:active': {
					outline: 0,
					border: 'none'
				},
				'&:focus': {
					outline: 0,
					border: 'none'
				}
			}
		},
		MuiTab: {
			root: {
				fontSize: '16px',
				fontWeight: '700',
				'&$selected': {
					outline: 0,
					border: 'none',
					color: colors.ice.primary
				}
			},
			textColorInherit: {
				color: colors.text.primary,
				opacity: 1
			}
		},
		MuiTableCell: {
			root: {
				padding: '8px'
			},
			head: {
				color: colors.text.primary,
				fontSize: '14px',
				fontWeight: 500,
				fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif'],
				textTransform: 'uppercase',
				borderBottom: 0,
				backgroundColor: 'white'
			},
			stickyHeader: {
				color: colors.text.primary,
				backgroundColor: 'white'
			},
			body: {
				color: colors.text.primary,
				fontSize: '14px',
				fontWeight: 400,
				fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
			}
		},
		MuiPaginationItem: {
			root: {
				fontSize: 14,
				fontWeight: 400,
				fontFamily: ['Merriweather-Sans', 'Helvetica', 'Arial', 'sans-serif']
			}
		},
		MuiStepper: {
			root: {
				padding: 0
			}
		},
		MuiStepIcon: {
			root: {
				'&$active': {
					color: colors.ice.primary
				},
				'&$completed': {
					color: colors.ice.medium
				}
			}
		},
		MuiMenuItem: {
			root: {
				'&:hover': {
					backgroundColor: colors.ice.lightOpacity
				}
			}
		}
	},
	props: {
		MuiButtonBase: {
			disableTouchRipple: true // No more ripple, on the whole application 💣!
			// disableFocusRipple: true,
		},
		MuiTextField: {
			color: 'secondary',
			fullWidth: true
		},
		MuiRadio: {
			color: 'secondary'
		},
		MuiCheckbox: {
			color: 'secondary'
		},
		MuiGrid: {}
	}
});

export default theme;
