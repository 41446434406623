import { useState, useEffect } from 'react';
import axios from 'axios';
import SensorTypeResponse from 'types/SensorTypeResponse';

function useSensorTypes() {
	const [sensorTypes, setSensorTypes] = useState<SensorTypeResponse[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<Error | null>(null);

	useEffect(() => {
		async function fetchSensorTypes() {
			try {
				const response = await axios.get<SensorTypeResponse[]>(
					'/api/sensor/types'
				);
				setSensorTypes(response.data);
				setLoading(false);
				setError(null);
			} catch (error_: unknown) {
				setLoading(false);
				setError(error_ as Error);
			}
		}

		void fetchSensorTypes();
	}, []);

	return { sensorTypes, loading, error };
}

export default useSensorTypes;
