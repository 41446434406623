import React from 'react';
import Avatar from 'react-avatar';
import { connectStoreon } from 'storeon/react';

class UserAvatar extends React.Component {
	constructor(props) {
		super(props);
		this.handleMouseHover = this.handleMouseHover.bind(this);
		this.state = {
			isHovering: false
		};
	}

	componentDidMount() {
		this.determineSize(this.props.size);
	}

	determineSize(size) {
		if (!size) {
		} else if (size === 'small') {
			this.setState({ size: '25' });
		} else if (size === 'medium') {
			this.setState({ size: '55' });
		} else if (size === 'large') {
			this.setState({ size: '100' });
		}
	}

	handleMouseHover() {
		this.setState(this.toggleHoverState);
	}

	toggleHoverState(state) {
		return {
			isHovering: !state.isHovering
		};
	}

	render() {
		if (this.props.user) {
			return (
				<Avatar
					name={this.props.user.name}
					src={this.props.user.avatar}
					size={this.state.size}
					round='50%'
				/>
			);
		}
		return null;
	}
}

export default connectStoreon(UserAvatar);
