/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { useEffect, useState, useMemo, useCallback } from 'react';
import type TreeNode from '../../types/TreeNode';
import type TreeNodeWithType from '../../types/TreeNodeWithType';
import type FacilityZone from '../../types/FacilityZone';
import type Sensor from '../../types/Sensor';

import type FacilityZoneResponse from '../../types/FacilityZoneResponse';
import Api from '../../legacy/api/Api';
import TreeMultiselect from '../TreeMultiselect/TreeMultiselect';
import useSensorTypes from 'services/useSensorType/useSensorType';
import SensorTypeResponse from 'types/SensorTypeResponse';

export enum SensorType {
	leak = 'leak'
}
interface MapSensorParameters {
	sensor: Sensor;
}

interface MapZoneParameters {
	zone: FacilityZone;
	children?: TreeNode[];
}

const mapSensor = ({ sensor }: MapSensorParameters): TreeNode => {
	const mappedZone: TreeNodeWithType = {
		id: sensor.uuid,
		label: sensor.name,
		type: 'sensor'
	};

	return mappedZone;
};
const mapZone = ({ zone, children = [] }: MapZoneParameters, hideSpaceCheckbox?: boolean) => {
	const mappedZone: TreeNodeWithType = {
		id: zone.uuid,
		label: zone.name,
		type: 'zone',
		children,
		hideCheckbox: hideSpaceCheckbox
	};

	return mappedZone;
};

const mapFacility = (facility: FacilityZone, children: TreeNode[], hideFacilityCheckbox?: boolean) => {
	const mappedFacility: TreeNodeWithType = {
		id: facility.uuid,
		label: facility.name,
		type: 'facility',
		children,
		hideCheckbox: hideFacilityCheckbox
	};

	return mappedFacility;
};

const mapPromiseAll = async <T, U>(
	array: T[],
	callback: (argument: T) => Promise<U>
): Promise<U[]> => {
	const promises = array.map(async element => callback(element));
	return Promise.all(promises);
};

const mapZoneSensors = (
	zone: FacilityZone,
	filteredSensorTypeIds: number[]
) => {
	const activeSensors = zone.sensors
		? zone.sensors.filter(
				s => s.active && !filteredSensorTypeIds.includes(s.sensortypeId)
		  )
		: [];
	return activeSensors.map(sensor => mapSensor({ sensor }));
};

const buildLocationFilter = async (
	filteredSensorTypeIds: number[],
	hideCheckboxes?: {
		locations?: boolean;
		spaces?: boolean;
		sensors?: boolean;
	}
) => {
	const facilities = (await Api.getFacilities()) as FacilityZoneResponse;
	const activeFacilities = facilities.data.filter(f => f.active);
	const zonesPromises = activeFacilities.map(
		async facility =>
			Api.getFacilityZones(facility.id) as Promise<FacilityZoneResponse>
	);
	const zones = await mapPromiseAll(zonesPromises, async promise => {
		const zonesResponse = await promise;
		const activeZones = zonesResponse.data.filter(
			z => z.active && z.sensors && z.sensors.some(s => s.active)
		);

		return activeZones.map(zone =>
			mapZone(
				{ 
					zone, 
					children: mapZoneSensors(zone, filteredSensorTypeIds).map(sensor => ({
						...sensor,
						hideCheckbox: hideCheckboxes?.sensors
					}))
				}, 
				hideCheckboxes?.spaces
			)
		);
	});

	const filterLocation: TreeNodeWithType[] = activeFacilities.map(
		(facility, index) => {
			const mappedFacility = mapFacility(
				facility, 
				zones[index], 
				hideCheckboxes?.locations
			);
			return mappedFacility;
		}
	);

	return filterLocation;
};

function filterSensorTypesBySensorType(
	filters: SensorType[],
	sensorTypes: SensorTypeResponse[]
): number[] {
	let filtered: number[] = [];

	if (filters.includes(SensorType.leak)) {
		filtered = [
			...filtered,
			...sensorTypes
				.filter(type =>
					['Tektelic-Leak-Detection', 'Dragino-LWL02', 'Leak-Sensor'].includes(
						type.name
					)
				)
				.map(st => st.id)
		];
	}
	return filtered;
}

interface LocationSpaceSensorFilterProperties {
	onChangeLocationFilter: (updatedSelection: TreeNodeWithType[]) => void;
	hideSensorsType?: SensorType[];
	initialSelectedSensorIds?: string[];
	maxAmountChips?: number;
	maxSelection?: number;
	hideCheckboxes?: {
		locations?: boolean;
		spaces?: boolean;
		sensors?: boolean;
	};
}

export default function LocationSpaceSensorFilter({
	onChangeLocationFilter,
	hideSensorsType,
	initialSelectedSensorIds,
	maxAmountChips,
	maxSelection,
	hideCheckboxes
}: LocationSpaceSensorFilterProperties): JSX.Element {
	const [filterLocationContent, setFilterLocationContent] = useState<
		TreeNodeWithType[] | []
	>([]);
	const [filteredSensorTypeIds, setFilteredSensorTypeIds] = useState<number[]>(
		[]
	);
	const { sensorTypes, loading, error } = useSensorTypes();

	const [selectedSensors, setSelectedSensors] = useState<TreeNodeWithType[]>(
		[]
	);

	useEffect(() => {
		if (sensorTypes.length > 0 && hideSensorsType) {
			const filteredIds = filterSensorTypesBySensorType(
				hideSensorsType,
				sensorTypes
			);
			setFilteredSensorTypeIds(filteredIds);
		}
	}, [sensorTypes, loading, hideSensorsType]);

	useEffect(() => {
		const getFacilitiesFilterData = async () => {
			const filterData = await buildLocationFilter(filteredSensorTypeIds, hideCheckboxes);
			setFilterLocationContent(filterData);
		};

		void getFacilitiesFilterData();
	}, [filteredSensorTypeIds, hideCheckboxes]);

	const findSelectedSensors = useCallback(
		(nodes: TreeNodeWithType[], ids: string[]): TreeNodeWithType[] =>
			nodes.flatMap(node => {
				if (node.type === 'sensor' && ids.includes(node.id)) {
					return [node];
				}
				if (node.children) {
					return findSelectedSensors(node.children, ids);
				}
				return [];
			}),
		[]
	);

	const initiallySelectedSensors = useMemo(() => {
		if (
			initialSelectedSensorIds &&
			initialSelectedSensorIds.length > 0 &&
			filterLocationContent.length > 0
		) {
			return findSelectedSensors(
				filterLocationContent,
				initialSelectedSensorIds
			);
		}
		return [];
	}, [initialSelectedSensorIds, filterLocationContent, findSelectedSensors]);


	useEffect(() => {
		setSelectedSensors(initiallySelectedSensors);
	}, [initiallySelectedSensors]);

	return (
		<TreeMultiselect
			options={filterLocationContent}
			onChange={onChangeLocationFilter}
			placeholder='Location / Space / Sensor'
			selected={selectedSensors}
			maxAmountChips={maxAmountChips}
			maxSelection={maxSelection}
		/>
	);
}

LocationSpaceSensorFilter.defaultProps = {
	hideCheckboxes: {
		locations: false,
		spaces: false,
		sensors: false
	},
	hideSensorsType: [],
	initialSelectedSensorIds: [],
	maxAmountChips: undefined,
	maxSelection: undefined
};
