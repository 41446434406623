import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import SegmentUtil from '../../../util/SegmentUtil';
import './Actions.css';
import Icon from '@mdi/react';
import icons from '../../../style/icons';

export default class GetStarted extends Component {
	componentDidMount() {
		SegmentUtil.track(SegmentUtil.actions.signedup, {
			category: 'app',
			email: this.props.user.email,
			full_name: `${this.props.user.first_name} ${this.props.user.last_name}`,
			createdAt: new Date()
		});
	}

	render() {
		return (
			<div>
				<div className='action-title'>
					<h2>Welcome to Conserv Cloud</h2>
					<h5>We have a few easy steps to get you up and running.</h5>
				</div>
				<div className='row'>
					<div className='column'>
						<div>
							<Icon size={2} path={icons.upload} />
						</div>
						<div className='step-description'>Import some data</div>
					</div>
					<div className='column'>
						<div>
							<Icon size={2} path={icons.gauge} />
						</div>
						<div className='step-description'>Get a score</div>
					</div>
					<div className='column'>
						<div>
							<Icon size={2} path={icons.analytics} />
						</div>
						<div className='step-description'>Analyze your data</div>
					</div>
				</div>
				<div>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.props.callback('importdata')}
					>
						Let's do it!
					</Button>
				</div>
			</div>
		);
	}
}
