import React, { Component } from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default class CommonErrorAlert extends Component {
	render() {
		return (
			<Alert severity='error'>
				<Typography variant='h3'>Oops...</Typography>
				<Typography variant='body1'>{this.props.message}</Typography>
				<Box pt={1}>
					<Button
						variant='contained'
						color='primary'
						onClick={this.props.handleCloseWithoutSave}
					>
						Ok
					</Button>
				</Box>
			</Alert>
		);
	}
}
