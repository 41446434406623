import React from 'react';
import { useStoreon } from 'storeon/react';
import { Dialog, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	videoDialog: {
		width: '80%',
		height: '80%',
		maxWidth: '100%'
	},
	videoDialogContent: {
		width: '100%',
		height: '100%'
	}
}));

function VideoDialog(props) {
	const { dispatch } = useStoreon();
	const classes = useStyles();
	return (
		<Dialog
			open
			onClose={() =>
				dispatch('navstate/update', { dialogOpen: false, dialogContent: null })
			}
			classes={{ paper: classes.videoDialog }}
		>
			<DialogContent classes={{ root: classes.videoDialogContent }}>
				<iframe
					src={props.video.url}
					style={{ width: '100%', height: '98%' }}
				/>
			</DialogContent>
		</Dialog>
	);
}

export default VideoDialog;
