import { FormControl, TextField, Box } from '@material-ui/core';
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-material-ui/lib/style.css';
import { Icon } from '@mdi/react';
import icons from '../../../style/icons';

export default function DecoratedTextField(props) {
	let iconPath = null;
	// allow override of variant icon by setting icon path directly.
	if (props.iconPath) {
		iconPath = props.iconPath;
	} else {
		iconPath = icons[props.conservField];
	}

	const passthroughProps = { ...props };
	delete passthroughProps.conservField;
	delete passthroughProps.editing;
	delete passthroughProps.displayOnly;
	const { onChange, value, ...mobilePassthroughProps } = passthroughProps;

	return (
		<Box
			display='flex'
			flexWrap='nowrap'
			alignItems='flex-end'
			flexDirection='row'
			style={{ width: '100%' }}
		>
			<Box pb={props.editing ? 1 : 1.5} style={{ minWidth: '24px' }}>
				{iconPath ? <Icon path={iconPath} size={1} /> : null}
			</Box>
			<Box pl={2} flexGrow={1}>
				<FormControl style={{ width: '100%' }}>
					{props.conservField === 'mobile' ? (
						<ReactPhoneInput
							country={'us'}
							enableAreaCodes
							{...passthroughProps}
							inputProps={{
								InputLabelProps: { shrink: true },
								...mobilePassthroughProps
							}}
							disabled={!props.editing}
							component={TextField}
							buttonStyle={{ marginTop: '10px' }}
						/>
					) : (
						<TextField
							{...passthroughProps}
							disabled={!props.editing}
							InputProps={{ disableUnderline: props.displayOnly }}
						/>
					)}
				</FormControl>
			</Box>
		</Box>
	);
}
