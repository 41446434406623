import makeStyles from '@material-ui/core/styles/makeStyles';

export default makeStyles(theme => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap'
	},
	container: {
		flex: 1,
		minWidth: '100%'
	},
	addLabel: {
		color: theme.palette.primary.main,
		cursor: 'pointer'
	},
	addContainer: {
		marginTop: '11px',
		paddingTop: '6px',
		paddingLeft: '12px'
	},
	addIcon: {
		width: '14px'
	}
}));
