import { useState } from 'react';
import {
	useForm,
	type SubmitHandler,
	FormProvider,
	type DefaultValues
} from 'react-hook-form';
import Button from '@mui/material/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import VerifyEmailDialog from '../../legacy/components/account/verify/VerifyEmailDialog';
import FormInputText from 'components/forms/FormInputText';
import validateEmailFromApi from '../../services/validateEmailFromApi';
import registerUser from '../../services/registerUser';
import Box from '@mui/material/Box';

const schema = yup.object({
	email: yup.string().email().trim().required()
});

interface FormInput {
	email: string;
}

export default function RegisterForm(): JSX.Element {
	const defaultValues: DefaultValues<FormInput> = {
		email: ''
	};

	const [userEmail, setUserEmail] = useState('');
	const [showVerifyEmailDialog, setShowVerifyEmailDialog] = useState(false);

	const methods = useForm<FormInput>({
		resolver: yupResolver(schema),
		defaultValues
	});

	const onSubmitHandler: SubmitHandler<FormInput> = async ({
		email
	}: FormInput) => {
		const { valid, emailexists } = await validateEmailFromApi(email);
		if (!valid || emailexists) {
			methods.setError('email', {
				message: 'There is already an account associated with this address'
			});

			return;
		}

		const user = {
			email,
			password: 'default',
			first_name: '',
			last_name: '',
			orgname: ''
		};

		const isRegistered = await registerUser(user);
		if (isRegistered) {
			setUserEmail(email);
			setShowVerifyEmailDialog(true);
			methods.reset();
		}
	};

	const onCloseVerifyEmailDialog = () => {
		setShowVerifyEmailDialog(false);
	};

	return (
		<div>
			<VerifyEmailDialog
				open={showVerifyEmailDialog}
				showResend={false}
				onClose={onCloseVerifyEmailDialog}
				email={userEmail}
			/>
			<FormProvider {...methods}>
				{/* eslint-disable react/jsx-handler-names */}
				<form onSubmit={methods.handleSubmit(onSubmitHandler)}>
					<FormInputText
						name='email'
						label='Email'
						type='email'
						aria-label='Eamil'
						variant='outlined'
					/>
					<Box display='flex' justifyContent='center' mt='32px'>
						<Button type='submit' color='primary' variant='contained'>
							Create an account
						</Button>
					</Box>
				</form>
			</FormProvider>
		</div>
	);
}
