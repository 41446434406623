import * as yup from 'yup';
import global from '../../../../../res/global';

const getTypeSchema = context => {
	const convertTemp = temp => {
		return Number(context.convertUnitToDisplay('temp', temp));
	};
	const convertIlluminance = vis => {
		return Number(context.convertUnitToDisplay('vis', vis));
	};

	// minTemp and maxTemp are unused?
	const minTemp = convertTemp(global.levelRanges.temp.range[0]);
	const maxTemp = convertTemp(global.levelRanges.temp.range[1]);

	return {
		type: yup.string().trim().required('A type is required'),
		rangeStart: yup
			.number()
			.transform((v, o) => (o === '' ? null : v))
			.nullable(true)
			.test(
				'is-right-rangeStart',
				'Should be less or equal than maximum',
				function (temp) {
					const convert =
						this.parent.type === 'temp1'
							? convertTemp
							: this.parent.type === 'vis'
							? convertIlluminance
							: a => a;
					const isValid = !(
						this.parent.threshold === 'out' &&
						this.parent.rangeEnd &&
						convert(this.parent.rangeEnd) < convert(temp)
					);
					return isValid;
				}
			)
			.test(
				'is-right-rangeStart-for-rh',
				'Should be more or equal than 0',
				function (min) {
					const isValid = !(this.parent.type === 'rh' && min < 0);
					return isValid;
				}
			)
			.test(
				'is-valid-required-rangeStart',
				'This field is required',
				function check(value) {
					const isValid = !(
						this.parent.type !== 'leak' &&
						this.parent.type !== 'shock' &&
						this.parent.type !== 'uv1' &&
						this.parent.type !== 'offline' &&
						this.parent.threshold === 'out' &&
						typeof value !== 'number'
					);
					return isValid;
				}
			),
		rangeEnd: yup
			.number()
			.transform((v, o) => (o === '' ? null : v))
			.nullable(true)
			.test(
				'is-right-rangeEnd',
				'Should be more or equal than minimum',
				function (temp) {
					const convert =
						this.parent.type === 'temp1'
							? convertTemp
							: this.parent.type === 'vis'
							? convertIlluminance
							: a => a;
					const isValid = !(
						this.parent.threshold === 'out' &&
						this.parent.rangeStart &&
						convert(this.parent.rangeStart) > convert(temp)
					);
					return isValid;
				}
			)
			.test(
				'is-right-rangeEnd-for-rh',
				'Should be less or equal than 100',
				function (min) {
					const isValid = !(this.parent.type === 'rh' && min > 100);
					return isValid;
				}
			)
			.test(
				'is-valid-required-rangeEnd',
				'This field is required',
				function check(value) {
					const isValid = !(
						this.parent.type !== 'leak' &&
						this.parent.type !== 'shock' &&
						this.parent.type !== 'uv1' &&
						this.parent.type !== 'offline' &&
						this.parent.threshold === 'out' &&
						typeof value !== 'number'
					);
					return isValid;
				}
			),
		threshold: yup
			.string()
			.nullable(true)
			.test(
				'is-valid-required-theshold',
				'This field is required',
				function check(value) {
					const isValid = !(
						this.parent.type !== 'leak' &&
						this.parent.type !== 'shock' &&
						this.parent.type !== 'uv1' &&
						this.parent.type !== 'offline' &&
						value === undefined
					);
					return isValid;
				}
			),
		thresholdValue: yup
			.number()
			.transform((v, o) => (o === '' || o === undefined ? null : v))
			.nullable(true)
			.test(
				'is-valid-required-thesholdValue',
				'This field is required',
				function check(value) {
					const isValid = !(
						this.parent.type !== 'leak' &&
						this.parent.type !== 'shock' &&
						this.parent.type !== 'uv1' &&
						this.parent.type !== 'offline' &&
						this.parent.threshold !== 'out' &&
						typeof value !== 'number'
					);
					return isValid;
				}
			)
	};
};

export default getTypeSchema;
