import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

interface ConservDialogSubscriptionSuccessProperties {
	onCloseDialog: () => void;
	tplOrder: unknown;
}

export default function ThirdPartySendProductsModalSuccess({
	onCloseDialog,
	tplOrder
}: ConservDialogSubscriptionSuccessProperties) {
	console.log(
		'🚀 ~ file: ThirdPartySendProductsModalSuccess.tsx:15 ~ tplOrder:',
		tplOrder
	);
	const onCloseHandler = () => {
		onCloseDialog();
	};

	return (
		<>
			<Box
				sx={{
					backgroundColor: 'rgba(46, 125, 50, 0.12)',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					paddingTop: '35px',
					paddingBottom: '20px',
					paddingLeft: '20px',
					paddingRight: '20px',
					marginTop: '20px'
				}}
			>
				<CheckCircleIcon
					fontSize='large'
					color='success'
					sx={{
						position: 'relative',
						marginTop: '-50px',
						color: 'rgba(46, 125, 50, 0.5)'
					}}
				/>
				<Typography variant='h6' sx={{ marginBottom: '16px' }}>
					You have send the order to our 3pl!
				</Typography>
				<Typography variant='subtitle1'>
					Please check that everything is ok in the 3pl website.
				</Typography>
			</Box>
			<Button
				variant='outlined'
				color='primary'
				onClick={onCloseHandler}
				sx={{ marginTop: '27px', width: '100%' }}
			>
				Continue
			</Button>
		</>
	);
}
