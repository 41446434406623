/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable unicorn/no-null */
/* eslint-disable react/no-object-type-as-default-prop */

import type React from 'react';
import { useState, useCallback, useEffect } from 'react';
import {
	Box,
	ClickAwayListener,
	Paper,
	Popper,
	Typography
} from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import type TreeNode from '../../types/TreeNode';
import TreeMultiselectNode from './TreeMultiselectNode';
import TextFieldWithChips from './TextFieldWithChips';
import { getTreeElements, getParentNodes } from './utils';

interface RecursiveTreeViewProperties {
	options: TreeNode[];
	placeholder: string;
	selected: TreeNode[] | [];
	onChange: (updatedSelection: TreeNode[]) => void;
	width?: number;
	maxAmountChips?: number;
	maxSelection?: number;
}

const emptyArray = [] as TreeNode[];

export default function RecursiveTreeView({
	options,
	placeholder = 'Search',
	selected = emptyArray,
	onChange,
	width,
	maxAmountChips,
	maxSelection
}: RecursiveTreeViewProperties) {
	const [selectedNodes, setSelectedNodes] = useState<TreeNode[]>(selected);
	const [filter, setFilter] = useState<string>('');
	const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
	const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
		null
	);
	const open = Boolean(anchorElement);
	const idPopper = open ? 'simple-popper' : undefined;

	const removeUndefined = (nodes: (TreeNode | undefined)[]): TreeNode[] =>
		nodes.filter((element): element is TreeNode => element !== undefined);

	const memoedCollapseNodes = useCallback(() => {
		const collapseNodes = (nodes: TreeNode[]): TreeNode[] => {
			let toRemove: TreeNode[] = [];
			for (const node of nodes) {
				const treeNodes: TreeNode[] = getTreeElements(node);
				const areAllSelected = treeNodes.every(currentNode =>
					selectedNodes.includes(currentNode)
				);
				const childrensToRemove = treeNodes.filter(element => element !== node);
				if (areAllSelected) {
					toRemove = [...toRemove, ...childrensToRemove];
				}
			}
			return nodes.filter(node => !toRemove.includes(node));
		};
		return collapseNodes(selectedNodes);
	}, [selectedNodes]);

	const onClearSelectedNodes = (): void => {
		setSelectedNodes([]);
	};

	const selectedParents = (
		node: TreeNode,
		currentSelection: TreeNode[]
	): TreeNode[] => {
		const result: TreeNode[] = [];
		const parentNodes = getParentNodes(options, node)?.reverse();
		const partialSelection = currentSelection;
		if (parentNodes) {
			for (const parent of parentNodes) {
				const childNodes = getTreeElements(parent);

				const areAllChildSeleted = childNodes.every(
					currentNode =>
						partialSelection.includes(currentNode) || currentNode === parent
				);
				if (areAllChildSeleted) {
					partialSelection.push(parent);
					result.push(parent);
				}
			}
		}
		return result;
	};

	const addNodeToSelected = (node: TreeNode) => {
		// Adding all the childs elements
		const elementsToAdd = getTreeElements(node);
		let nextSelection = [
			...selectedNodes,
			...elementsToAdd.filter(element => !selectedNodes.includes(element))
		];

		// Adding parents if needed
		const parentsToAdd = selectedParents(node, nextSelection);
		nextSelection = [
			...nextSelection,
			...parentsToAdd.filter(element => !nextSelection.includes(element))
		];

		setSelectedNodes(nextSelection);
	};

	const onDeleteHandler = (node: TreeNode) => {
		const elementsToDelete = getTreeElements(node);
		const parentNodes = getParentNodes(options, node);
		const nextSelection = selectedNodes.filter(
			element =>
				!elementsToDelete.includes(element) && !parentNodes?.includes(element)
		);
		setSelectedNodes(nextSelection);
	};

	const isMaxSelectionReached = useCallback(() => {
		if (!maxSelection) return false;
		return selectedNodes.length >= maxSelection;
	}, [selectedNodes.length, maxSelection]);

	const onChangeCheckbox = (node: TreeNode, checked: boolean) => {
		if (checked && maxSelection && selectedNodes.length >= maxSelection) {
			return;
		}

		if (checked) {
			addNodeToSelected(node);
		} else {
			onDeleteHandler(node);
		}
	};

	const isSelected = useCallback(
		(node: TreeNode) => selectedNodes.includes(node),
		[selectedNodes]
	);

	const isIndeterminated = useCallback(
		(node: TreeNode) => {
			const allTreeNodes = getTreeElements(node);
			return (
				allTreeNodes.some(currentNode => selectedNodes.includes(currentNode)) &&
				allTreeNodes.some(currentNode => !selectedNodes.includes(currentNode))
			);
		},
		[selectedNodes]
	);

	const onChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const { value } = event.target;
		setFilter(value);
	};

	const shouldShowNode = useCallback(
		(node: TreeNode): boolean => {
			if (!filter) return true;
			// get the parent nodes of those nodes
			const nodesToShowParents = getParentNodes(options, node);

			const allTreeNodes = [
				...getTreeElements(node),
				...(nodesToShowParents ?? [])
			];
			return allTreeNodes.some(
				currentNode =>
					currentNode.label.toLowerCase().includes(filter.toLowerCase()) ||
					currentNode.secondaryLabel
						?.toLowerCase()
						.includes(filter.toLowerCase())
			);
		},
		[filter, options]
	);

	const onFocusHandler = (reference: HTMLDivElement | null) => {
		setAnchorElement(reference);
	};

	useEffect(() => {
		const nextSelection = memoedCollapseNodes();
		onChange(nextSelection);
	}, [selectedNodes, memoedCollapseNodes, onChange]);

	useEffect(() => {
		if (filter) {
			// get all nodes that contains the filter in their label
			const nodesToShow = options.filter(element => shouldShowNode(element));
			// get all children of those nodes
			const nodesToShowChildren = nodesToShow.flatMap(element =>
				getTreeElements(element)
			);
			// get the parent nodes of those nodes
			const nodesToShowParents = nodesToShow.flatMap(element =>
				getParentNodes(options, element)
			);
			// get the unique nodes
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const uniqueNodes: (TreeNode | undefined)[] = [
				...new Set([
					...nodesToShow,
					...nodesToShowChildren,
					...nodesToShowParents
				])
			];
			const nodesToExpand = removeUndefined(uniqueNodes).map(node => node.id);
			setExpandedNodes(nodesToExpand);
		} else {
			setExpandedNodes(options.map(node => node.id));
		}
	}, [filter, options, shouldShowNode]);

	const onClickAwayHandler = () => {
		setAnchorElement(null);
	};
	const onNodeToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
		setExpandedNodes(nodeIds);
	};

	useEffect(() => {
		setSelectedNodes(selected);
	}, [selected]);

	return (
		<ClickAwayListener onClickAway={onClickAwayHandler}>
			<Box sx={{ width }}>
				<TextFieldWithChips
					selectedNodes={memoedCollapseNodes()}
					placeholder={placeholder}
					onDeleteChip={onDeleteHandler}
					onChange={onChangeTextField}
					onFocus={onFocusHandler}
					onClear={onClearSelectedNodes}
					maxAmountChips={maxAmountChips}
				/>
				<Popper
					id={idPopper}
					open={open}
					placement='bottom-start'
					anchorEl={anchorElement}
					style={{ width }}
				>
					{filter && expandedNodes.length === 0 ? (
						<Paper variant='outlined' data-testid='no-result-message'>
							<Box sx={{ p: 2 }}>
								<Typography variant='body2'>
									<i>
										No results found for <b>{filter}</b>
									</i>
								</Typography>
							</Box>
						</Paper>
					) : (
						<Paper variant='outlined'>
							<TreeView
								sx={{
									maxHeight: 264,
									flexGrow: 1,
									maxWidth: 400,
									overflowY: 'auto'
								}}
								defaultCollapseIcon={
									<ExpandLessIcon aria-label='expand icon' />
								}
								defaultExpandIcon={
									<ExpandMoreIcon aria-label='collapse icon' />
								}
								expanded={expandedNodes}
								onNodeToggle={onNodeToggle}
							>
								{options.map(node => (
									<TreeMultiselectNode
										key={node.id}
										node={node}
										isSelected={isSelected}
										isIndeterminated={isIndeterminated}
										onChangeCheckbox={onChangeCheckbox}
										shouldShow={shouldShowNode}
										highlightedText={filter}
										disabled={!isSelected(node) && isMaxSelectionReached()}
									/>
								))}
							</TreeView>
						</Paper>
					)}
				</Popper>
			</Box>
		</ClickAwayListener>
	);
}
