import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../style/colors';

export default makeStyles(theme => ({
	paper: {
		minWidth: '576px'
	},
	header: {
		height: theme.spacing(2),
		background: theme.palette.primary.main,
		color: '#FFF',
		padding: 0
	},
	content: {
		paddingTop: 0,
		paddingBottom: '60px',
		paddingLeft: theme.spacing(4),
		paddingRight: '60px'
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '29px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0em'
	},
	closeButton: {
		position: 'absolute',
		padding: 0,
		right: '15px',
		top: '31px'
	}
}));
