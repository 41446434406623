/* eslint-disable react/require-default-props */
import DialogTitle from '@mui/material/DialogTitle';
import type { ReactComponentChildren } from '../../types/ReactComponentChildren';

interface DialogTitleProperties {
	children?: ReactComponentChildren;
}

export default function ConservDialogTitle({
	children
}: DialogTitleProperties) {
	return (
		<DialogTitle
			sx={{
				textTransform: 'none',
				textAlign: 'left'
			}}
		>
			{children}
		</DialogTitle>
	);
}
