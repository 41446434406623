import React, { Component } from 'react';
import labels from '../../../res/data';
import {
	PreferencesConsumer,
	PreferencesContext
} from '../../../providers/PreferencesProvider';

export default class DataUnit extends Component {
	static contextType = PreferencesContext;

	render() {
		const labelname = this.props.name.toLowerCase();
		const units = labels.getLabelUnits(
			labelname,
			this.props.format === 'minimal'
		);
		if (units) {
			return (
				<PreferencesConsumer>
					{({}) =>
						labels.getLabelUnits(
							this.context.getDisplayUnit(labelname),
							this.props.format === 'minimal'
						)
					}
				</PreferencesConsumer>
			);
		}
		return '';
	}
}
