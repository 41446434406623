import React, { useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import { loadStripe } from '@stripe/stripe-js';
import { useHistory } from 'react-router-dom';
import { useStoreon } from 'storeon/react';
import Loading from '../common/elements/Loading';
import Api from '../../api/Api';

function StripeButton(props) {
	const products = [
		{ name: 'monthly', stripeid: 'price_1JEkuULBBgLUQatBmwgCZkv4' },
		{ name: 'annual', stripeid: 'price_1JEkuULBBgLUQatBONkYXrP5' }
	];

	const { dispatch } = useStoreon('user');
	const [stripe, setStripe] = useState();
	const history = useHistory();

	useEffect(() => {
		loadStripe(process.env.REACT_APP_STRIPE_KEY)
			.then(stripe => {
				setStripe(stripe);
			})
			.catch(() => {
				dispatch('notification/add', {
					message:
						'There was a problem loading our payment system, you may not be able to subscribe',
					severity: 'error',
					variant: 'banner'
				});
			});
	}, []);

	const startTrial = () => {
		const plan = products.find(product => product.name === props.term).stripeid;
		if (plan) {
			Api.createCheckoutSession({
				plan,
				successUrl: `${window.location.protocol}//${process.env.REACT_APP_CONSERV_HOST}:${window.location.port}/landing/subscription${window.location.pathname}?activetab=subscription&session_id={CHECKOUT_SESSION_ID}`,
				cancelUrl: `${window.location.protocol}//${process.env.REACT_APP_CONSERV_HOST}:${window.location.port}/landing/subscription${window.location.pathname}?abandoncheckout=true&activetab=subscription`
			})
				.then(res => {
					stripe
						.redirectToCheckout({
							sessionId: res.data.id
						})
						.then(result => {
							if (result.error) {
								dispatch('notification/add', {
									message:
										'There was a problem redirecting you to our checkout, please contact Conserv',
									severity: 'error',
									variant: 'banner'
								});
							}
						});
				})
				.catch(() => {
					dispatch('notification/add', {
						message:
							'There was a problem redirecting you to our checkout, please contact Conserv support',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			dispatch('notification/add', {
				message:
					'There was a problem finding the right plan, please contact Conserv',
				severity: 'error',
				variant: 'banner'
			});
		}
	};

	if (!stripe) {
		return <Loading />;
	}
	if (props.renderAs === 'button') {
		return (
			<Button variant='contained' color='primary' onClick={startTrial}>
				Start Free Trial
			</Button>
		);
	}
	return (
		<a href='/#' onClick={startTrial}>
			Start Free Trial
		</a>
	);
}

export default StripeButton;
