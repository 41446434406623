import React from 'react';
import { useStoreon } from 'storeon/react';

const useSubscribers = () => {
	const { user, users } = useStoreon('user', 'users');

	const getLabel = currentEmail => {
		const currentUser = users.find(({ email }) => email === currentEmail);

		if (currentUser && currentUser.first_name)
			return `${currentUser.first_name} ${currentUser.last_name}${
				user.uuid === currentUser.uuid ? ' (you)' : ''
			}`;

		return currentEmail;
	};

	const getNewSubLabel = newSub => {
		const currentUser = users.find(
			({ uuid }) => uuid === newSub.subscriberUuid
		);
		if (currentUser && currentUser.first_name) {
			return `${currentUser.first_name} ${currentUser.last_name}${
				user.uuid === currentUser.uuid ? ' (you)' : ''
			}`;
		}
		return `${newSub.email} (pending)`;
	};

	const getSubscribersList = ({ eventsubs, subscribersList }) => {
		const existingSubs = eventsubs
			? [
					...eventsubs
						.filter(e => e.status !== 'UNSUBSCRIBED')
						.map(e => getLabel(e.user.email))
			  ]
			: [];

		const newSubs = subscribersList
			? subscribersList.map(s => getNewSubLabel(s))
			: [];

		return [...existingSubs, ...newSubs];
	};

	return { getLabel, getNewSubLabel, getSubscribersList };
};

export default useSubscribers;
