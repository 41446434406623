import React from 'react';
import { FormControl, TextField, Box } from '@material-ui/core';
import Icon from '@mdi/react';

export default function DecoratedTextField(props) {
	const passthroughProps = { ...props };
	const { endAdornment } = props;
	delete passthroughProps.conservField;
	delete passthroughProps.editing;
	delete passthroughProps.displayOnly;
	delete passthroughProps.endAdornment;

	return (
		<Box style={{ width: '100%' }}>
			<Box flexGrow={1}>
				<FormControl style={{ width: '100%' }}>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{props.hasSpace ? (
							<Box pr={1} style={{ paddingBottom: props.error ? '30px' : 0 }}>
								<Icon path={props.icon} size={1} />
							</Box>
						) : null}
						<TextField
							{...passthroughProps}
							disabled={!props.editing}
							InputProps={{
								disableunderline: props.displayOnly,
								style: props.inputProps,
								endAdornment
							}}
							variant='outlined'
						/>
					</Box>
				</FormControl>
			</Box>
		</Box>
	);
}
