import { makeStyles } from '@material-ui/core';
import { colors } from '../../../style/colors';

export default makeStyles(() => ({
	body: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: '150%',
		letterSpacing: '0.15px',
		margin: '8px 0'
	},
	fieldBody: {
		color: '#000000',
		fontFamily: 'Roboto',
		fontSize: '16px',
		lineHeight: '24px'
	},
	fieldTitle: {
		color: '#AAAAAA',
		fontFamily: 'Roboto',
		fontSize: '12px',
		lineHeight: '24px',
		letterSpacing: '0.15px',
		marginTop: '4px'
	},
	footer: {
		background: '#FFF',
		bottom: 0,
		borderTop: '.5px solid #BDBDBD',
		padding: '10px 40px',
		position: 'sticky',
		width: '100%',
		zIndex: 10
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '24px'
	},
	topSticky: {
		top: 0,
		position: 'sticky',
		zIndex: 10
	},
	tabs: {
		background: colors.white.primary
	},
	link: {
		color: '#2CA9DA !important',
		fontWeight: 'bold',
		cursor: 'pointer'
	},
	bold: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 700,
		letterSpacing: '0.15px',
		marginRight: '4px'
	},
	fileName: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 700,
		letterSpacing: '0.15px'
	}
}));
