import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';

function Loading({ text }) {
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<img
				alt="we're loading up the data you asked for"
				src='/img/loading.gif'
			/>
			<Typography variant='body1' className={classes.text}>
				{text}
			</Typography>
		</Box>
	);
}

export default Loading;
