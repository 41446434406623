import axios from 'axios';
import type CustomerSubscription from '../../types/CustomerSubscription';

const isFreeCustomer = async () => {
	try {
		const { data } = await axios.get<CustomerSubscription[]>(
			`/api/subscription/all`
		);

		const activeProSubscriptions = data.some(
			subscription => subscription.active && subscription.type === 'pro'
		);

		return !activeProSubscriptions;
	} catch (error) {
		console.error('Error getting the customer subscriptions:', error);
	}

	return true;
};

export default isFreeCustomer;
