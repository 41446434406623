import React, { useState } from 'react';
import { FormControlLabel, Switch, Typography } from '@material-ui/core';
import { useStoreon } from 'storeon/react';

import useStyles from './styles';

import Api from '../../../../../api/Api';
import SegmentUtil from '../../../../../util/SegmentUtil';

function TrackingSwitch({ eventProfile, disabled }) {
	const classes = useStyles();

	const { dispatch } = useStoreon();

	const [active, setActive] = useState(eventProfile?.active);

	const handleTrackingSwitch = event => {
		const action = eventProfile.active
			? SegmentUtil.actions.deactivateevent
			: SegmentUtil.actions.activateevent;

		SegmentUtil.track(action);

		Api.toggleEvent(eventProfile.uuid, !eventProfile.active);
		dispatch('eventprofiles/refresh');
		setActive(!active);
	};

	return (
		<FormControlLabel
			control={
				<Switch
					checked={active}
					onChange={handleTrackingSwitch}
					color='primary'
					disabled={disabled}
				/>
			}
			label={
				<Typography className={classes.trackingLabel}>
					{active ? 'Tracked' : 'Not tracked'}
				</Typography>
			}
		/>
	);
}

export default TrackingSwitch;
