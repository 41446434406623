import React from 'react';
import { Box, Button } from '@material-ui/core';

import useStyles from './styles';
import clsx from 'clsx';

function Footer({
	backVisible,
	isFinalStep = false,
	isNextDisable = false,
	onBack,
	onCancel,
	onFinalStep,
	onNext
}) {
	const classes = useStyles();

	const label = isFinalStep ? 'Create Event' : 'Next';
	const callback = isFinalStep ? onFinalStep : onNext;
	const disabled = !isFinalStep && isNextDisable;

	return (
		<Box className={classes.container}>
			<Box className={classes.actionGroup}>
				<Button color='secondary' onClick={onCancel} variant='text'>
					Cancel
				</Button>
				<Box className={classes.backAndNext}>
					{backVisible && (
						<Button
							className={clsx(classes.customButton, classes.secondary)}
							onClick={onBack}
							variant='contained'
						>
							Back
						</Button>
					)}
					<Button
						className={classes.customButton}
						color='primary'
						disabled={disabled}
						onClick={callback}
						variant='contained'
					>
						{label}
					</Button>
				</Box>
			</Box>
		</Box>
	);
}

export default Footer;
