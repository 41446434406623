import React, { useCallback, useState } from 'react';
import {
	Checkbox,
	Collapse,
	List,
	ListItem,
	ListItemText,
	FormControlLabel,
	FormGroup
} from '@material-ui/core';
import { ExpandLess } from '@material-ui/icons';
import { Controller, useFormContext } from 'react-hook-form';

import useStyles from './styles';
import SegmentUtil from '../../../util/SegmentUtil';

function Option({
	locations = [],
	name,
	onOptionClick,
	options,
	sensors = [],
	spaces = []
}) {
	const classes = useStyles();
	const { control, setValue, watch } = useFormContext();

	const [open, setOpen] = useState(false);

	const handleClick = () => setOpen(!open);

	const handleCheckbox = (field, event, checked) => {
		const objectType =
			// eslint-disable-next-line no-nested-ternary
			field.name === 'Location'
				? locations
				: field.name === 'Sensor'
				? sensors
				: spaces;

		if (event.target.value === 'all' && checked) {
			const uuids = objectType.map(({ uuid }) => uuid);
			return field.onChange(['all', ...uuids]);
		}

		if (event.target.value === 'all' && !checked) {
			return field.onChange([]);
		}

		// Si estoy checkeando un sensor y mi array contiene la key "all"
		if (event.target.value !== 'all' && field.value.includes('all')) {
			// Si estoy chequeando el valor
			if (checked) {
				return field.onChange([...field.value, event.target.value]);
				// eslint-disable-next-line no-else-return
			} else {
				const removeKeys = field.value
					.filter(x => x !== 'all')
					.filter(x => x !== event.target.value);

				return field.onChange(removeKeys);
			}
		}
		if (checked) {
			return field.onChange([...field.value, event.target.value]);
		}
		return field.onChange(
			field.value.filter(currentValue => currentValue !== event.target.value)
		);
	};

	const handleChange = (field, event, checked) => {
		if (checked) {
			SegmentUtil.track(SegmentUtil.actions.filter, {
				by: field.name,
				param: event.target.value
			});
		}

		if (field.name === 'Status' || field.name === 'Comments') {
			if (checked) {
				return field.onChange([event.target.value]);
			}
		}

		if (
			field.name === 'Location' ||
			field.name === 'Sensor' ||
			field.name === 'Space'
		) {
			return handleCheckbox(field, event, checked);
		}

		if (checked) {
			field.onChange([...field.value, event.target.value]);
		} else {
			field.onChange(
				field.value.filter(currentValue => currentValue !== event.target.value)
			);
		}
	};

	const renderOption = useCallback((currentOptions, field, type) => {
		const tags = ['locations', 'sensors', 'spaces'];
		const label = tags.includes(type) ? 'name' : 'label';
		const value = tags.includes(type) ? 'uuid' : 'value';

		return currentOptions.map(option => {
			return (
				<List key={option.value} component='div' disablePadding>
					<ListItem button className={classes.nested}>
						<FormControlLabel
							className={classes.text}
							key={option[value]}
							label={option[label]}
							control={
								<Checkbox
									className={classes.checkbox}
									value={option[value]}
									checked={field.value.some(
										existingValue => existingValue === option[value]
									)}
									onChange={(event, checked) =>
										handleChange(field, event, checked)
									}
								/>
							}
						/>
					</ListItem>
				</List>
			);
		});
	}, []);

	return (
		<>
			<ListItem button onClick={handleClick}>
				<ListItemText primary={name} />
				{open && <ExpandLess />}
			</ListItem>
			<Collapse in={open} timeout='auto'>
				<FormGroup>
					<Controller
						name={name}
						control={control}
						render={({ field }) => renderOption(options, field, null)}
					/>
					<Controller
						name={name}
						control={control}
						render={({ field }) => renderOption(locations, field, 'locations')}
					/>
					<Controller
						name={name}
						control={control}
						render={({ field }) => renderOption(sensors, field, 'sensors')}
					/>
					<Controller
						name={name}
						control={control}
						render={({ field }) => renderOption(spaces, field, 'spaces')}
					/>
				</FormGroup>
			</Collapse>
		</>
	);
}

export default Option;
