import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	avatar: {
		border: 0,
		width: '32px',
		height: '32px',
		marginRight: '4px',
		zIndex: '1 !important',
		fontFamily: 'Montserrat',
		fontWeight: 700,
		fontSize: '12px',
		lineHeight: '15px',
		color: '#757575',
		backgroundColor: '#F5F5F5;'
	}
}));
