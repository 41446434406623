import React, { useState } from 'react';
import { Icon } from '@mdi/react';
import icons from '../../../style/icons';
import { colors } from '../../../style/colors';

function CancelCheck(props) {
	const [hovering, setHovering] = useState(false);

	return (
		<Icon
			style={{ cursor: 'pointer' }}
			size={1}
			onMouseEnter={props.checked ? () => setHovering(true) : null}
			onMouseLeave={props.checked ? () => setHovering(false) : null}
			path={hovering && props.checked ? icons.close : icons.complete}
			color={
				props.checked
					? hovering
						? colors.gemini.primary
						: colors.blaze.primary
					: null
			}
			onClick={
				props.checked
					? () => {
							setHovering(false);
							props.onClick();
					  }
					: null
			}
		/>
	);
}

export default CancelCheck;
