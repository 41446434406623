/* eslint-disable @typescript-eslint/consistent-type-imports */
/* eslint-disable import/prefer-default-export */

import { DeveloperModeService } from 'developer/DeveloperModeService';
import { DeveloperModeServiceImpl } from 'developer/DeveloperModeServiceImpl';
import { Container } from 'inversify';
import { DI_TYPES } from './diTypes';
import { DamageRiskProviderService } from 'services/damage/DamageRiskProviderService';
import { DamageRiskProviderServiceImpl } from 'services/damage/DamageRiskProviderServiceImpl';
import { SensorReadingProviderService } from 'sensorreading/SensorReadingProviderService';
import { SensorReadingProviderServiceFakeImpl } from 'sensorreading/SensorReadingProviderServiceFakeImpl';

const diContainer = new Container();

const developerModeService: DeveloperModeService =
	new DeveloperModeServiceImpl();
const damageRiskProviderService: DamageRiskProviderService =
	new DamageRiskProviderServiceImpl();

diContainer
	.bind<DeveloperModeService>(DI_TYPES.DeveloperModeService)
	.toConstantValue(developerModeService);

const sensorReadingProviderService: SensorReadingProviderService =
	new SensorReadingProviderServiceFakeImpl();
diContainer
	.bind<SensorReadingProviderService>(DI_TYPES.SensorReadingProviderService)
	.toConstantValue(sensorReadingProviderService);

diContainer
	.bind<DamageRiskProviderService>(DI_TYPES.DamageRiskProviderService)
	.toConstantValue(damageRiskProviderService);

export { diContainer };
