/* eslint-disable no-console */
import axios from 'axios';
import type OrderListItem from 'types/OrderListItem';
import ProductAddedToSubscription from 'types/ProductAddedToSubscription';

const createOrder = async (order: {
	customerId: number;
	products: ProductAddedToSubscription[];
}) => {
	try {
		const { data } = await axios.post<OrderListItem[]>(
			`/api/v2/admin/order`,
			order
		);

		return data;
	} catch (error) {
		console.error('Error creating order:', error);
	}
	return [];
};

export default createOrder;
