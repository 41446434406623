import React, { Component } from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export default class FallbackComponent extends Component {
	render() {
		return (
			<Alert severity='error'>
				<Typography variant='h5'>Something went wrong...</Typography>
			</Alert>
		);
	}
}
