import React, { useState, useEffect } from 'react';
import {
	DialogContent,
	Button,
	Box,
	MenuItem,
	Typography,
	TextField
} from '@material-ui/core';
import CustomTextField from '../../../common/form/CustomTextField/CustomTextField';
import ErrorBanner from '../../../common/banners/ErrorBanner';
import useStyles from './styles';

function AboutYourselfDialog({
	onNext,
	editCallback,
	userProfile,
	user,
	valid,
	error,
	roles
}) {
	const classes = useStyles();

	const [firstName, setFirstName] = useState(userProfile?.first_name || null);
	const [lastName, setLastName] = useState(userProfile?.last_name || null);
	const [jobtitle, setJobtitle] = useState(userProfile?.jobtitle_uuid || null);
	const [orgName, setOrgName] = useState(userProfile?.customer?.name || null);
	const [formValidation, setFormValidation] = useState({});
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (loaded) {
			update();
		} else {
			setLoaded(true);
		}
	}, [firstName, lastName, jobtitle, orgName]);

	const update = () => {
		const updatedProfile = {
			first_name: firstName,
			last_name: lastName,
			jobtitle_uuid: jobtitle,
			customer: {
				name: orgName
			},
			valid: validateForm()
		};
		editCallback(updatedProfile);
	};

	const validateForm = () => {
		const newFormValidation = {};
		let isValid = true;
		if (!firstName || !firstName.trim()) {
			if (firstName !== null) {
				newFormValidation.firstNameError = 'First name is required';
			}
			isValid = false;
		}
		if (!lastName || !lastName.trim()) {
			if (lastName !== null) {
				newFormValidation.lastNameError = 'Last name is required';
			}
			isValid = false;
		}

		if (user.role === 'admin') {
			if (!orgName || !orgName.trim()) {
				if (orgName !== null) {
					newFormValidation.orgNameError = 'Organization name is required';
				}
				isValid = false;
			}
		}

		if (!jobtitle || !jobtitle.trim()) {
			if (jobtitle !== null) {
				newFormValidation.jobtitleError = 'Role is required';
			}
			isValid = false;
		}

		setFormValidation(newFormValidation);
		return isValid;
	};

	return (
		<DialogContent className={classes.content}>
			<Box mt={3} mb={3}>
				<Typography className={classes.title} variant='h1'>
					Please tell us a bit about yourself
				</Typography>
			</Box>
			<Box mb={2}>
				<TextField
					required
					label='First Name'
					id='firstName'
					data-testid='firstName'
					variant='outlined'
					placeholder='First Name'
					value={firstName ?? ''}
					onChange={ev => setFirstName(ev.target.value)}
					error={Boolean(formValidation.firstNameError)}
					helperText={formValidation.firstNameError}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						classes: {
							input: classes.input
						}
					}}
				/>
			</Box>
			<Box mb={2}>
				<TextField
					required
					label='Last Name'
					id='lastName'
					data-testid='lastName'
					variant='outlined'
					placeholder='Last Name'
					error={Boolean(formValidation.lastNameError)}
					helperText={formValidation.lastNameError}
					aria-label='last name'
					onChange={ev => setLastName(ev.target.value)}
					value={lastName ?? ''}
					InputLabelProps={{ shrink: true }}
					InputProps={{
						classes: {
							input: classes.input
						}
					}}
				/>
			</Box>
			<Box mb={2}>
				<CustomTextField
					required={user.role === 'admin'}
					disabled={user.role !== 'admin'}
					label='Organization Name'
					id='orgname'
					data-testid='orgname'
					variant='outlined'
					placeholder='Organization Name'
					error={Boolean(formValidation.orgNameError)}
					helperText={formValidation.orgNameError}
					aria-label='organization name'
					onChange={ev => setOrgName(ev.target.value)}
					value={orgName ?? ''}
					InputLabelProps={{ shrink: true }}
				/>
			</Box>
			<Box mb={4}>
				<TextField
					select
					required
					label='Role'
					id='jobTitle'
					data-testid='jobTitle'
					variant='outlined'
					error={Boolean(formValidation.jobtitleError)}
					helperText={formValidation.jobtitleError}
					aria-label='role'
					placeholder='Role'
					onChange={ev => setJobtitle(ev.target.value)}
					value={jobtitle ?? ''}
					InputLabelProps={{ shrink: true }}
					SelectProps={{
						value: jobtitle
					}}
					InputProps={{
						classes: {
							input: classes.input
						}
					}}
				>
					{roles.map(role => (
						<MenuItem key={role.uuid} value={role.uuid}>
							{role.title}
						</MenuItem>
					))}
				</TextField>
			</Box>
			{error && <ErrorBanner message={error} />}
			<Box display='flex' justifyContent='space-between'>
				<Button
					className={classes.button}
					id='complete_profile'
					onClick={onNext}
					variant='contained'
					color='primary'
					disabled={!valid}
				>
					next
				</Button>
			</Box>
		</DialogContent>
	);
}

export default AboutYourselfDialog;
