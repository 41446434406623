import React, { Component } from 'react';
import { Box, Dialog, Radio, Typography } from '@material-ui/core';
import { Icon } from '@mdi/react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import StripeButton from '../../payment/StripeButton';
import { colors } from '../../../style/colors';
import icons from '../../../style/icons';

dayjs.extend(localizedFormat);

export default class SubscribeModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			term: 'annual'
		};
	}

	changeTerm = event => {
		this.setState({ term: event.target.value });
	};

	render() {
		const {
			props: { handleClose, show },
			state: { term }
		} = this;

		return (
			<Dialog open={show} onClose={handleClose}>
				<Box display='flex' flexDirection='column' alignItems='center' p={2}>
					<Box pb={2}>
						<Typography variant='h2'>
							Upgrade to Conserv Essentials and get 30 days free!
						</Typography>
					</Box>
					<Box display='flex' flexDirection='column' width='100%'>
						<Box p={1} display='flex' flexDirection='row'>
							<Icon
								size={1}
								path={icons.complete}
								color={colors.blaze.primary}
							/>
							<Box pl={1}>Your first 30 days are free, cancel any time</Box>
						</Box>
						<Box p={1} display='flex' flexDirection='row'>
							<Icon
								size={1}
								path={icons.complete}
								color={colors.blaze.primary}
							/>
							<Box pl={1}>
								Your first bill will be scheduled for{' '}
								{dayjs().add(30, 'day').format('LL')}
							</Box>
						</Box>
						<Box p={1} display='flex' flexDirection='row'>
							<Icon
								size={1}
								path={icons.complete}
								color={colors.blaze.primary}
							/>
							<Box pl={1}>We will remind you 3 days before your trial ends</Box>
						</Box>
					</Box>
					<Box display='flex' flexDirection='column' width='100%'>
						<Box
							pt={2}
							pb={2}
							display='flex'
							flexDirection='row'
							alignItems='baseline'
							bgcolor={term === 'monthly' ? colors.ice.lightOpacity : null}
						>
							<Box>
								<Radio
									checked={term === 'monthly'}
									onChange={this.changeTerm}
									value='monthly'
									name='pay-monthly'
									inputProps={{ 'aria-label': 'pay monthly' }}
								/>
							</Box>
							<Box pb={2}>
								<b>Monthly</b> - 50 USD / Month
							</Box>
						</Box>
						<Box
							pt={1}
							display='flex'
							flexDirection='row'
							alignItems='baseline'
							bgcolor={term === 'annual' ? colors.ice.lightOpacity : null}
						>
							<Box>
								<Radio
									checked={term === 'annual'}
									onChange={this.changeTerm}
									value='annual'
									name='pay-annually'
									inputProps={{ 'aria-label': 'pay annually' }}
								/>
							</Box>
							<Box pb={2} display='flex' flexDirection='column'>
								<Box>
									<b>Yearly</b> - 43 USD / Month
								</Box>
								<Box>
									<Typography variant='caption'>
										$516.00 billed yearly
									</Typography>
								</Box>
							</Box>
							<Box flexGrow={1} textAlign='right' pr={1}>
								<Typography variant='caption'>Save 14%</Typography>
							</Box>
						</Box>
					</Box>
					<Box pt={2}>
						<StripeButton renderAs='button' term={term} />
					</Box>
				</Box>
			</Dialog>
		);
	}
}
