import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	subscriberOption: {
		alignItems: 'center',
		display: 'flex',
		padding: '0 16px'
	},
	subscriberUsername: {
		padding: '0 16px'
	},
	errorChip: {
		'& .MuiChip-deleteIcon': {
			color: 'yellow'
		}
	},
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 8
	},
	section: {
		marginLeft: '20px',
		marginRight: '20px'
	},
	subscriber: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '16px'
	},
	subscriberInfo: {
		alignItems: 'center',
		display: 'flex',
		gap: 16
	},
	me: {
		color: '#000',
		fontWeight: 400,
		fontSize: '16px',
		letterSpacing: '0.15px'
	},
	meStyled: {
		fontWeight: 'bold'
	},
	subscribed: {
		border: '1px solid #4CAF50',
		borderRadius: '4px',
		color: '#4CAF50'
	},
	subscribersContainer: {
		maxHeight: '260px',
		overflow: 'auto'
	},
	textDescription: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: '12px',
		letterSpacing: '0.4px',
		marginLeft: '14px',
		marginTop: '3px'
	}
}));
