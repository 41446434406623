import React from 'react';
import { Box } from '@material-ui/core';
import EventBody from '../EventBody';
import { NameForm, ScopeForm } from '../../Forms';
import useStyles from './styles';

function Setup() {
	const classes = useStyles();

	return (
		<EventBody
			title='Setup Event'
			description='First, let’s name your event and select the sensor, space, or location of where you’d like to track the event.'
		>
			<>
				<Box>
					<NameForm editable />
				</Box>
				<Box className={classes.scopeForm}>
					<ScopeForm editable />
				</Box>
			</>
		</EventBody>
	);
}

export default Setup;
