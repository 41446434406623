import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import {
	Box,
	Typography,
	Table,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
	Backdrop,
	Link,
	MenuItem
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import Dropzone from 'react-dropzone';
import uuid4 from 'uuid4';
import parse from 'csv-parse';
import * as csvstring from 'csv-string';
import Bugsnag from '@bugsnag/js';
import { importStyles, formValidationStyles } from '../../../style/styles';
import ImportParser from '../ImportParser';
import CircularProgressWithLabel from '../../common/elements/CircularProgressWithLabel';
import icons from '../../../style/icons';
import Api from '../../../api/Api';
import SubscribeModal from '../../account/subscription/SubscribeModal';
import DecoratedSelect from '../../common/form/DecoratedSelect';
import InfoTooltip from '../../common/help/InfoTooltip';

const useStyles = makeStyles({
	...importStyles,
	...formValidationStyles
});

const LimitedBackdrop = withStyles({
	root: {
		position: 'absolute',
		height: '100%',
		zIndex: 1
	}
})(Backdrop);

function UploadFileStep(props) {
	const classes = useStyles();

	const { dispatch, subscription } = useStoreon('subscription');
	const [, setReadReady] = useState(false);
	const [uploadFiles, setUploadFiles] = useState(
		props.importConfig.uploadFiles || []
	);
	const [uploading, setUploading] = useState(false);
	const [uploadPercent, setUploadPercent] = useState(0);
	const [files, setFiles] = useState([]);
	const [fileValidation, setFileValidation] = useState(
		props.importConfig.fileValidation || { valid: true }
	);
	const [groupUuid] = useState(uuid4());
	const [delimiter, setDelimiter] = useState(
		props.importConfig.delimiter || 'auto'
	);
	const [uploadComplete, setUploadComplete] = useState(false);

	useEffect(() => {
		processFiles();
	}, [files]);

	useEffect(() => {
		if (!fileValidation.valid) {
			Bugsnag.notify(new Error(JSON.stringify(fileValidation.message)));
		}
	}, [fileValidation]);

	const rejectUpload = () => {};

	const processFiles = () => {
		files.forEach(file => {
			const reader = new FileReader();
			reader.addEventListener('load', ev => handleUploadEvent(ev, file));
			reader.readAsText(file);
		});
	};

	const handleUploadFiles = files => {
		if (files.length <= 10) {
			setUploading(true);
			setFiles(files);
		} else {
			const fv = {
				valid: false,
				message: 'A maximum of 10 files are supported in a single import'
			};
			setFileValidation(fv);
		}
	};

	const addUploadFile = uploadFile => {
		uploadFiles.push(uploadFile);
		setUploadFiles(uploadFiles);
		const validation = validateUploadFiles();
		setFileValidation(validation);
		const newConfig = { ...props.importConfig };
		newConfig.uploadFiles = uploadFiles;
		newConfig.fileValidation = validation;
		newConfig.groupUuid = groupUuid;
		newConfig.delimiter = delimiter;
		if (uploadFiles.length === files.length) {
			uploadAllFiles(newConfig);
		}
	};

	const uploadAllFiles = newImportConfig => {
		let uploaded = 0;
		const uploadPromises = [];
		uploadFiles.forEach(uploadFile => {
			// iterate over uploadFiles array, upload each one and update the percentage
			const uploadPromise = Api.uploadImportFile(
				uploadFile.file,
				{ groupUuid },
				updateUploadProgress
			);
			uploadPromises.push(uploadPromise);
		});
		Promise.all(uploadPromises)
			.then(allResponses => {
				allResponses.forEach((res, index) => {
					uploadFiles[index].uuid = res.data.uploaduuid;
					const pct = Math.floor((uploaded / uploadFiles.length) * 100);
					setUploadPercent(pct > 100 ? 100 : pct);
					uploaded++;
				});
				newImportConfig.uploadFiles = uploadFiles;
				props.onUpdateImportConfig(newImportConfig);
				setUploading(false);
				setUploadComplete(true);
			})
			.catch(() => {
				props.onError({
					fatal: false,
					message: 'There was an error uploading one or more of your files.'
				});
				setUploading(false);
				setUploadComplete(true);
			});
	};

	/**
	 * Handles pre-upload and pre-parsing validation
	 */
	const prevalidateSingleFile = uploadFile => {
		let valid = false;
		// Could be a text type (typically text/csv or text/plain, unless it's windows, then it's an excel type becuase Microsoft sucks.)
		if (uploadFile.type.toLowerCase().includes('text/')) {
			valid = true;
		}

		// could be a PEM2 file?
		if (uploadFile.name.toLowerCase().includes('.pm2')) {
			valid = true;
		}

		// PEM2 files might also start with p2_ by default
		if (uploadFile.name.toLowerCase().includes('p2_')) {
			valid = true;
		}

		// PEM2 files can also use a numeric extension, and they get detected by the browser as a RAR file??
		if (uploadFile.type.toLowerCase().includes('rar')) {
			valid = true;
		}

		// could also have a .csv or .txt extension
		if (
			uploadFile.name.toLowerCase().includes('.csv') ||
			uploadFile.name.toLowerCase().includes('.txt')
		) {
			valid = true;
		}

		return valid;
	};

	const validateSingleFile = uploadFile => {
		let foundValidDate = false;
		let foundNumericColumn = false;
		let columnsFound = true;
		const fileValidation = { valid: true, message: '' };

		if (uploadFile.firstRows.length === 0) {
			columnsFound = false;
		} else {
			uploadFile.firstRows.forEach(row => {
				const keys = Object.keys(row);
				keys.forEach(key => {
					// try to parse a date.  If we get something vaguely parseable as a date, that check passes
					const timestamp = Date.parse(row[key]);
					if (isNaN(timestamp) === false) {
						foundValidDate = true;
					}

					// validate that we have at least one column that contains parseable numeric values
					// we have to convert any decimal separators with commas to periods (123,4 becomes 123.4) or javascript can't parse it properly
					const fixedFloat = row[key];
					if (fixedFloat) fixedFloat.replace(',', '.');
					if (parseFloat(fixedFloat.match(/^-?\d*(\.\d+)?$/)) > 0) {
						foundNumericColumn = true;
					}
				});
			});
		}

		fileValidation.valid = foundValidDate && foundNumericColumn && columnsFound;

		if (!columnsFound) {
			fileValidation.message =
				'No columns found, do your header columns match your data columns?';
		} else {
			if (!foundValidDate) {
				fileValidation.message +=
					'The uploaded file does not appear to have any valid time data.  Date and time should be in the ISO 8601 format YYYY-MM-DDTHH:MM:SS (for example, 2021-06-10T12:30:00).  ';
			}
			if (!foundNumericColumn) {
				fileValidation.message +=
					'  The uploaded file does not appear to have any valid numeric reading data';
			}
		}

		setFileValidation(fileValidation);
		return fileValidation.valid;
	};

	const detectDelimiter = fileReaderResult => {
		// first, lets just get the first 1k of data from this file
		const partial = fileReaderResult.substring(0, 1024);
		// now split this into an array by newline (allow LF, CR, or CRLF)
		const lines = partial.split(/\r?\n/);
		if (lines.length === 1) {
			return null;
		}
		const delimiters = [];
		let detected = '';
		// check all of the available lines, we'll pick the most frequently detected delimiter.
		lines.forEach(line => {
			detected = csvstring.detect(line);
			delimiters.push(detected);
		});
		detected = mode(delimiters);
		if (delimiter === 'auto') {
			setDelimiter(detected);
		} else {
			detected = delimiter;
		}
		return detected;
	};

	const mode = arr =>
		arr
			.sort(
				(a, b) =>
					arr.filter(v => v === a).length - arr.filter(v => v === b).length
			)
			.pop();

	const handleUploadEvent = (event, uploadFile) => {
		const firstRows = [];
		const fileReader = event.currentTarget;
		const fileValidation = { valid: true };
		const valid = prevalidateSingleFile(uploadFile);
		if (valid) {
			if (event.type === 'load') {
				const detectedDelimiter = detectDelimiter(fileReader.result);
				const importparser = new ImportParser();
				try {
					const parserConfig = importparser.selectParserConfig(
						uploadFile,
						fileReader.result,
						props.importConfig.fileType,
						detectedDelimiter
					);
					const tempParserConfig = { ...parserConfig };
					// for the in-browser parsing, limit to the first 100 lines to avoid performance problems.
					tempParserConfig.to_line = 100;
					const parser = parse(fileReader.result, tempParserConfig);
					let record;
					parser.on('readable', () => {
						while ((record = parser.read()) && firstRows.length < 10) {
							firstRows.push(record);
						}
					});
					parser.on('finish', () => {
						const newFile = {
							file: uploadFile,
							parserConfig,
							firstRows,
							groupUuid
						};
						if (validateSingleFile(newFile)) {
							addUploadFile(newFile);
						} else {
							// display the error that happened in this file validation
						}
					});
					parser.on('error', err => {
						// error rows are likely to be things that aren't parseable as delimited data, like PEM2 headers
						fileValidation.valid = false;
						fileValidation.message = (
							<>
								There was an error reading the selected file, is it a supported
								type (csv, pm2, etc)?
								<br />
								Detail: {err.message}
							</>
						);
						setFileValidation(fileValidation);
						setUploading(false);
					});
				} catch (err) {
					fileValidation.valid = false;
					fileValidation.message = (
						<>
							There was an error reading the selected file, is it a supported
							type (csv, pm2, etc)?
							<br />
							Detail: {err.message}
						</>
					);
					setFileValidation(fileValidation);
					setUploading(false);
				}
			}
		} else {
			fileValidation.valid = false;
			fileValidation.message = (
				<>
					This file is not a supported file type. Uploaded data should be in a
					text file of some kind, such as a CSV.
				</>
			);
			setFileValidation(fileValidation);
			setUploading(false);
		}
	};

	const updateUploadProgress = progress => {
		const uploadPercent = Math.round(
			(progress.loaded / (progress.total + progress.total * 0.1)) * 100
		);
		setUploadPercent(uploadPercent);
	};

	const removeUploadFile = removeFile => {
		const newConfig = { ...props.importConfig };
		newConfig.importUuid = null;
		newConfig.uploadFiles = uploadFiles.filter(uploadFile => {
			if (uploadFile.file.name !== removeFile.file.name) {
				return uploadFile;
			}
		});
		setUploadFiles(newConfig.uploadFiles);
		if (newConfig.uploadFiles.length === 0) setUploadComplete(false);
		const validation = validateUploadFiles();
		setFileValidation(validation);
		if (newConfig.uploadFiles.length === 0) setReadReady(false);
		newConfig.firstRows = null;
		newConfig.fileValidation = validation;
		props.onUpdateImportConfig(newConfig);
		Api.cancelImport(removeFile.uuid)
			.then(() => {})
			.catch(() => {});
	};

	/**
	 * Multi-file uploads can only proceed under a few conditions for now:
	 *
	 * 1.  Less than the maximum count (10, and this is already checked elsewhere)
	 * 2.  The files have the same columns, headers, etc.  In other words, the same format
	 * 3.  The files are of the same detected type.
	 */
	const validateUploadFiles = () => {
		const fileValidation = { valid: true };

		// check the length
		if (uploadFiles.length > 10) {
			fileValidation.valid = false;
			fileValidation.message =
				'When uploading multiple files, a maximum of 10 files is supported';
		}

		// check the number of types, all need to be same type.
		const typeSet = new Set();
		uploadFiles.forEach(uploadFile => {
			typeSet.add(uploadFile.file.type);
		});

		if (typeSet.size > 1) {
			fileValidation.valid = false;
			fileValidation.message =
				'When uploading multiple files, the files must all be of the same type';
		}

		// check the columns sets, these should all be equal.
		const firstColumns = uploadFiles[0].parserConfig.metadata.columns;

		// we need at least two columns for this to work (time and a reading);
		if (firstColumns.length < 2) {
			fileValidation.valid = false;
			fileValidation.message =
				'At least two columns (timestamp and a reading) are required';
		}

		if (uploadFiles.length > 1) {
			uploadFiles.forEach(uploadFile => {
				if (fileValidation.valid) {
					if (firstColumns && Array.isArray(firstColumns)) {
						fileValidation.valid =
							firstColumns.length ===
								uploadFile.parserConfig.metadata.columns.length &&
							firstColumns.every(
								(value, index) =>
									value === uploadFile.parserConfig.metadata.columns[index]
							);
						if (!fileValidation.valid) {
							fileValidation.message =
								'When uploading multiple files, the format of each file must be the same';
						}
					} else {
						fileValidation.valid = false;
						fileValidation.message =
							'We could not find any columns in one or more files, are they of supported types?';
					}
				}
			});
		}
		return fileValidation;
	};

	const humanFileSize = (bytes, dp = 1) => {
		const thresh = 1024;
		if (Math.abs(bytes) < thresh) {
			return `${bytes} B`;
		}
		const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		let u = -1;
		const r = 10 ** dp;
		do {
			bytes /= thresh;
			++u;
		} while (
			Math.round(Math.abs(bytes) * r) / r >= thresh &&
			u < units.length - 1
		);
		return `${bytes?.toFixed(dp)} ${units[u]}`;
	};

	return (
		<Box pt={4}>
			<Box display='flex' flexDirection='row' justifyContent='space-between'>
				<Box>
					<Typography variant='h3'>Upload your file(s)</Typography>
					<Typography variant='body1'>Drag and drop your files here</Typography>
				</Box>
				<Box display='flex' minWidth='200px'>
					<DecoratedSelect
						conservField='delimiter'
						label='Field Separator'
						aria-label='separator character for import file'
						onChange={ev => setDelimiter(ev.target.value)}
						value={delimiter}
						editing={!uploadComplete && uploadFiles.length === 0}
					>
						<MenuItem value='auto'>Automatically detect</MenuItem>
						<MenuItem value=','>Comma ( , )</MenuItem>
						<MenuItem value=';'>Semicolon ( ; )</MenuItem>
						<MenuItem value='|'>Pipe ( | )</MenuItem>
						<MenuItem value='\t'>Tab</MenuItem>
					</DecoratedSelect>
					<Box pt={1}>
						<InfoTooltip name='importDelimiter' />
					</Box>
				</Box>
			</Box>
			{uploadFiles.length > 0 ? (
				<Box
					mt={2}
					style={{ position: 'relative', padding: '0px' }}
					minHeight='120px'
				>
					<LimitedBackdrop
						open={uploading}
						style={{
							position: 'absolute',
							display: uploading ? 'flex' : 'none'
						}}
					>
						<Box display='flex' flexDirection='column'>
							<CircularProgressWithLabel
								size={80}
								variant='determinate'
								value={uploadPercent}
							/>
						</Box>
					</LimitedBackdrop>
					<Box>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell />
									<TableCell>File</TableCell>
									<TableCell>Size</TableCell>
									<TableCell>Type</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{uploadFiles.map(uploadFile => (
									<TableRow key={uploadFile.file.name}>
										<TableCell>
											<Icon
												size={1}
												path={icons.close}
												onClick={() => removeUploadFile(uploadFile)}
											/>
										</TableCell>
										<TableCell>{uploadFile.file.name}</TableCell>
										<TableCell>{humanFileSize(uploadFile.file.size)}</TableCell>
										<TableCell>{uploadFile.file.type}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Box>
				</Box>
			) : (
				<Box mt={2} classes={{ root: classes.dropBox }}>
					<Dropzone
						onDrop={handleUploadFiles}
						onDropRejected={rejectUpload}
						multiple
						maxSize={100 * 1024 * 1024}
					>
						{({ getRootProps, getInputProps }) => (
							<Box
								{...getRootProps()}
								display='flex'
								flexDirection='column'
								alignItems='center'
								justifyContent='center'
								height='200px'
							>
								<input {...getInputProps()} />
								<Box>
									<Icon size={3} path={icons.upload} />
								</Box>
								<Box>
									<Typography variant='body1'>
										You can also upload your files by clicking here
									</Typography>
								</Box>
							</Box>
						)}
					</Dropzone>
				</Box>
			)}
			{subscription.type === 'free' ? (
				<Box pt={2} display='flex' flexDirection='row' justifyContent='center'>
					Need to upload multiple data files at once? &nbsp;
					<Link
						href='#'
						onClick={() =>
							dispatch('navstate/update', {
								dialogOpen: true,
								dialogContent: (
									<SubscribeModal
										show
										handleClose={() =>
											dispatch('navstate/update', {
												dialogOpen: true,
												dialogContent: null
											})
										}
									/>
								)
							})
						}
					>
						Upgrade to Conserv Essentials
					</Link>
				</Box>
			) : null}
			{!fileValidation.valid ? (
				<Box
					mt={2}
					classes={{ root: classes.errorBox }}
					display='flex'
					flexDirection='row'
					justifyContent='center'
				>
					<Typography variant='body1'>{fileValidation.message}</Typography>
				</Box>
			) : null}
		</Box>
	);
}

export default UploadFileStep;
