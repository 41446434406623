import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.background.paper,
		minWidth: '300px',
		width: '100%'
	},
	chipsContainer: {
		marginTop: '13px',
		marginBottom: '13px',
		maxHeight: '160px',
		overflow: 'hidden',
		overflowY: 'auto'
	},
	chip: {
		marginTop: '8px',
		marginBottom: '8px',
		marginRight: '8px',
		textTransform: 'capitalize'
	},
	chipText: {
		fontFamily: 'Roboto',
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: '18px',
		letterSpacing: '0.1599999964237213px'
	}
}));
