import React from 'react';
import {
	DialogContent,
	Button,
	Box,
	Typography,
	Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../style/colors';

const useStyles = makeStyles(theme => ({
	content: {
		width: '600px',
		paddingTop: 0,
		paddingBottom: theme.spacing(6),
		paddingLeft: 0,
		paddingRight: 0
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '31px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0em',
		maxWidth: '369px'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: theme.spacing(9),
		paddingLeft: theme.spacing(9),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		background: colors.elektra.primary
	},
	emailLink: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 700,
		color: colors.purpleHearth.primary,
		textDecoration: 'none',
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	}
}));

function AccountCompletedDialog({ onFinish }) {
	const classes = useStyles();

	return (
		<DialogContent className={classes.content}>
			<Box display='flex' justifyContent='center' paddingTop={3}>
				<img
					alt='conserv logo'
					style={{ width: '48px%' }}
					src='/img/account_completed.svg'
				/>
			</Box>
			<Box mt={4} display='flex' justifyContent='center'>
				<Typography className={classes.title} variant='h1'>
					Account Completed!
				</Typography>
			</Box>
			<Box mt={4} mb={3} display='flex' justifyContent='center'>
				<Typography className={classes.text} align='center'>
					You are set to start exploring the platform!
				</Typography>
			</Box>
			<Box display='flex' justifyContent='center' mb={4}>
				<Typography className={classes.text} align='center'>
					If you get stuck, visit the{' '}
					<Link
						className={classes.emailLink}
						style={{ textDecoration: 'underline' }}
						href='https://support.conserv.io/knowledge/kb-tickets/new'
						target='new'
					>
						knowledge base
					</Link>{' '}
					or feel free to contact{' '}
					<Link className={classes.emailLink} href='mailto:support@conserv.io'>
						support@conserv.io
					</Link>{' '}
					for help.
				</Typography>
			</Box>
			<Box display='flex' justifyContent='center'>
				<Button
					className={classes.button}
					id='complete_profile'
					onClick={onFinish}
					variant='contained'
					color='primary'
				>
					Finish
				</Button>
			</Box>
		</DialogContent>
	);
}

export default AccountCompletedDialog;
