import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useStoreon } from 'storeon/react';
import { Icon } from '@mdi/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import LocationDrawer from '../../location/LocationDrawer';
import ImageForm from '../../common/ImageForm';
import FormSaveError from '../../error/FormSaveError';
import Api from '../../../api/Api';
import DrawerHeader from '../../common/drawer/v2/DrawerHeader';
import EditSpace from '../../editors/EditSpace/EditSpace';
import icons from '../../../style/icons';
import Loading from '../../common/elements/Loading';
import PreferencesProvider, {
	PreferencesContext
} from '../../../providers/PreferencesProvider';
import SegmentUtil from '../../../util/SegmentUtil';
import ConfirmationDialog from '../../common/drawer/v2/ConfirmationDialog';
import theme from '../../../style/themev2';

import useStyles from './styles';

const schema = yup.object({
	name: yup.string().trim().required('A space name is required'),
	facilityId: yup
		.string()
		.required('A space location is required')
		.test('facilityId-is-set', 'A space location is required', facilityId => {
			return !!facilityId && facilityId !== '0';
		}),
	size: yup.number().min(0, `should be more or equal than 0`)
});

function SpaceDrawer({ create, space }) {
	const classes = useStyles();
	const context = useContext(PreferencesContext);

	const { dispatch, spaces } = useStoreon('spaces');

	const methods = useForm({
		shouldFocusError: true,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const [currentSpace, setCurrentSpace] = useState(space);
	const [showSaveError, setShowSaveError] = useState(false);
	const [errorResolved, setErrorResolved] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isDirtyImg, setIsDirtyImg] = useState(false);
	const [open, setOpen] = useState(false);

	const loadSpace = () => {
		if (space.id) {
			setIsLoading(true);
			Api.getSpaceDetails(space.id)
				.then(({ data }) => {
					const getPropUuid = (s, key) => {
						return (
							s.floor_hvac?.find(e => {
								return e.scope_scope_properties.property.property === key;
							})?.scope_property_uuid || 'none'
						);
					};

					const getFloorValue = s => {
						return getPropUuid(s, 'floor');
					};

					const getHvacValue = s => {
						return getPropUuid(s, 'hvacZone');
					};
					setCurrentSpace({
						...data,
						floor_uuid: getFloorValue(data),
						hvac_uuid: getHvacValue(data)
					});
				})
				.catch(error => {
					dispatch('notification/add', {
						message: 'There was a problem loading this space',
						severity: 'error',
						variant: 'banner'
					});
				})
				.finally(() => {
					setIsLoading(false);
				});
		} else {
			setCurrentSpace(space);
		}
	};

	const onSubmit = newSpace => {
		if (
			currentSpace.images &&
			(currentSpace.images || [])[0]?.uuid &&
			(!currentSpace.currentImages || currentSpace.currentImages?.length === 0)
		) {
			Api.removeImage(currentSpace.images[0].uuid);
		}

		// space size is input in display units, convert to API units if needed:
		const size = context.convertUnitToApi(
			'spacesize',
			newSpace.size || 0,
			false
		);

		const { floor_uuid, hvac_uuid, ...restNewSpace } = newSpace;

		const editedSpace = {
			data: {
				...restNewSpace,
				size,
				zoneId: space.id,
				id: space.id,
				uuid: space.uuid,
				floor_uuid: floor_uuid === 'none' ? '' : floor_uuid,
				hvac_uuid: hvac_uuid === 'none' ? '' : hvac_uuid
			},
			files:
				newSpace.currentImages.length > 0 && newSpace.currentImages[0].uuid
					? []
					: newSpace.currentImages
		};

		if (Boolean(methods.formState.errors)) {
			dispatch('spaces/edit', editedSpace);
			dispatch('spaces/save');
			SegmentUtil.track(SegmentUtil.actions.addspace, {
				context: context || 'env',
				spaceCount: spaces.activeList.length
			});
		} else {
			if (methods.formState.isDirty) {
				setShowSaveError(true);
				return false;
			}

			dispatch('navstate/update', {
				drawerOpen: false,
				drawerContent: null
			});
		}
	};

	const saveConfirmation = ev => {
		methods.handleSubmit(onSubmit)();
		setOpen(false);
	};

	const onDiscard = () => {
		dispatch('navstate/update', {
			drawerOpen: false,
			drawerContent: null,
			onDrawerClose: undefined
		});
	};

	const handleCloseDrawer = ev => {
		if (!methods.formState.isDirty && !isDirtyImg) {
			onDiscard();
		} else {
			setOpen(true);
		}
	};

	const getImages = useCallback(
		({ images = [] }) => (images.length > 0 && images) || [],
		[]
	);

	const getImagesCallback = images => {
		const newImages = images || [];
		const oldImages = currentSpace.images || [];
		if (newImages.length !== oldImages.length) {
			setIsDirtyImg(true);
		}
		methods.setValue('currentImages', images);
	};

	const handleVisitLocation = location => {
		dispatch('navstate/update', {
			onAfterDrawerClose: () =>
				dispatch('navstate/update', {
					drawerOpen: true,
					drawerContent: (
						<LocationDrawer key={location.uuid} location={location} />
					)
				})
		});
		handleCloseDrawer();
	};

	const clearVisit = () =>
		dispatch('navstate/update', {
			onAfterDrawerClose: null
		});

	useEffect(() => {
		loadSpace();
	}, []);

	useEffect(() => {
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, [isDirtyImg]);

	if (isLoading) return <Loading />;

	return (
		<ThemeProvider theme={theme}>
			<PreferencesProvider>
				<FormProvider {...methods}>
					<Grid container direction='column'>
						<Grid item className={classes.topSticky}>
							<DrawerHeader
								closeCallback={handleCloseDrawer}
								editing
								title={currentSpace.uuid ? 'Edit Space' : 'New Space'}
							/>
						</Grid>
						{currentSpace?.dirty && currentSpace?.valid && !showSaveError ? (
							<Grid item>
								<Box
									pl={5}
									pr={5}
									pt={2}
									display='flex'
									flexDirection='row'
									justifyContent='center'
								>
									<Icon path={icons.save} size={0.75} />
									<Typography variant='caption'>
										Your changes will be saved when the drawer is closed
									</Typography>
								</Box>
							</Grid>
						) : null}
						{showSaveError ? (
							<Grid item>
								<Box pl={5} pr={5} pt={2}>
									<FormSaveError
										onDiscardChanges={() =>
											dispatch('navstate/update', {
												drawerOpen: false,
												drawerContent: null
											})
										}
										resolved={errorResolved}
									/>
								</Box>
							</Grid>
						) : null}
						<Grid item>
							<Box>
								<EditSpace
									autosave
									editing
									create={create}
									space={currentSpace}
									visitLocation={handleVisitLocation}
								/>
							</Box>
						</Grid>
						<Grid item className={classes.imgContainer}>
							<Box pl={5} pr={5} pb={9} display='flex' justifyContent='center'>
								<ImageForm
									getImages={getImagesCallback}
									images={getImages(currentSpace)}
								/>
							</Box>
						</Grid>
						<Grid className={classes.footer} item>
							<Button
								color='primary'
								onClick={methods.handleSubmit(onSubmit)}
								variant='contained'
								disabled={!methods.formState.isDirty && !isDirtyImg}
							>
								SAVE ALL CHANGES
							</Button>
						</Grid>
					</Grid>
				</FormProvider>
			</PreferencesProvider>
			<ConfirmationDialog
				canSave={Object.keys(methods.formState.errors).length === 0}
				onGoBack={() => {
					clearVisit();
					setOpen(false);
				}}
				open={open}
				onSave={saveConfirmation}
				onDiscard={onDiscard}
			/>
		</ThemeProvider>
	);
}

export default SpaceDrawer;
