import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	date: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Montserrat',
		fontSize: '20px',
		fontWeight: 700,
		padding: '24px 24px 0 24px'
	},
	dateTitle: {
		marginBottom: '12px'
	}
}));
