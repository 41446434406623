import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import useStyles from './styles';

export default function SuccessMessage({ onBack, onCreate }) {
	const classes = useStyles();
	const { pathname } = useLocation();

	const getBackLabel = () => {
		let label = 'Event Settings';
		if (pathname.includes('sensor')) {
			label = 'Sensors';
		}
		if (pathname.includes('history')) {
			label = 'Events';
		}
		return label;
	};

	return (
		<Box className={classes.container}>
			<img alt='success' src='/img/success.svg' />
			<Box className={classes.text}>
				<Typography className={classes.title}>Event created!</Typography>
				<Typography className={classes.description}>
					You can view and edit your events in your{' '}
					<span className={classes.bold}>Event Settings.</span>
				</Typography>
			</Box>
			<Box className={classes.actions}>
				<Button
					className={classes.button}
					color='primary'
					onClick={onBack}
					variant='contained'
				>
					back to {getBackLabel()}
				</Button>
				<Button
					className={clsx(classes.button, classes.secondary)}
					onClick={onCreate}
					variant='contained'
				>
					create another event
				</Button>
			</Box>
		</Box>
	);
}
