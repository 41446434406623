import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	autocomplete: {
		marginTop: '24px'
	},
	chip: {
		marginTop: '8px',
		marginBottom: '8px',
		marginRight: '8px',
		textTransform: 'capitalize'
	},
	chipText: {
		fontFamily: 'Roboto',
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: '18px',
		maxWidth: '116px'
	},
	checkboxText: {
		fontFamily: 'Roboto',
		fontSize: '16px'
	},
	checkbox: {
		marginRight: '23px'
	}
}));
