import React, { Component } from 'react';
import {
	Button,
	Box,
	TextField,
	InputAdornment,
	Link,
	Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import { withCookies, Cookies } from 'react-cookie';
import { connectStoreon } from 'storeon/react';
import { instanceOf } from 'prop-types';
import QueryString from 'query-string';
import { passwordStrength } from 'check-password-strength';
import SegmentUtil from '../../util/SegmentUtil';
import Api from '../../api/Api';
import { PreferencesContext } from '../../providers/PreferencesProvider';
import icons from '../../style/icons';
import { buttonStyles, loginStyles } from '../../style/styles';
import { colors } from '../../style/colors';
import DividerWithText from '../common/elements/DividerWithText';
import GoogleButton from '../../../components/GoogleButton';
import Loading from '../common/elements/v2/Loading';

const useStyles = () => ({
	...buttonStyles,
	...loginStyles
});

class AcceptInvite extends Component {
	static contextType = PreferencesContext;

	static propTypes = {
		cookies: instanceOf(Cookies).isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			formValid: false,
			invitevalid: false,
			redirectToApp: false,
			ready: false,
			showExpired: false,
			user: {
				first_name: '',
				last_name: '',
				password: '',
				confirmPassword: '',
				date_of_birth: '1980-11-21',
				email: ''
			},
			formValidation: {},
			passwordStrength: -1
		};
	}

	componentDidMount() {
		if (!this.props.referralcode) {
			if (this.props.token) {
				Api.getInvite(this.props.token)
					.then(result => {
						// if this invite came back with an existing user attached,
						// then automatically accept
						if (result.data.existinguser) {
							Api.acceptInvite(result.data.existinguser, this.props.token)
								.then(() => {
									// now that we have a response, including the customer user link,
									// show the user how to join the new account.
									this.setState({
										redirectToApp: true,
										redirectUrl: '/'
									});
								})
								.catch(() => {
									if (this.props.onError) {
										this.props.onError({
											message:
												'There was a problem accepting this invitation, please contact Conserv support for assistance'
										});
									} else {
										this.props.dispatch('notification/add', {
											message:
												'There was a problem accepting this invitation, please contact Conserv support for assistance',
											severity: 'error',
											variant: 'banner'
										});
									}
								});
						} else {
							const { email, expired } = result.data;
							if (!expired) {
								this.setState({
									invitevalid: true,
									invite: result.data,
									ready: true,
									token: this.props.token,
									user: { email, first_name: '', last_name: '' },
									emailvalid: true,
									emailexists: false
								});
							} else {
								this.setState({
									ready: true,
									showExpired: true
								});
							}
						}
					})
					.catch(() => {
						if (this.props.onError) {
							this.props.onError({
								message:
									'There was a problem accepting this invitation, please contact Conserv support for assistance'
							});
						} else {
							this.props.dispatch('notification/add', {
								message:
									'There was a problem accepting this invitation, please contact Conserv support for assistance',
								severity: 'error',
								variant: 'banner'
							});
						}
					});
			} else {
				this.setState({
					redirectToApp: true,
					redirectUrl: '/'
				});
			}
		} else {
			const values = QueryString.parse(this.props.location.search);
			const { user } = this.state;
			user.email = values.email;
			this.setState({ ready: true, user });
			this.handleEmailChange({ target: { value: values.email } });
		}
	}

	togglePasswordVisibility = () => {
		this.setState({ showpassword: !this.state.showpassword });
	};

	handlePasswordChange = event => {
		const { user } = this.state;
		user.password = event.target.value;
		let strength;
		if (user.password) {
			strength = passwordStrength(user.password);
		}
		this.setState(
			{ user, passwordStrength: strength?.id || 0 },
			this.validateForm
		);
	};

	handleConfirmPasswordChange = event => {
		const { user } = this.state;
		user.confirmPassword = event.target.value;
		let strength;
		if (user.password) {
			strength = passwordStrength(user.confirmPassword);
		}
		this.setState(
			{ user, passwordStrength: strength?.id || 0 },
			this.validateForm
		);
	};

	registerReferred = () => {
		const reg = {
			password: this.state.user.password,
			email: this.state.user.email,
			orgname: '',
			referralcode: this.props.referralcode
		};
		Api.createRegistration(reg)
			.then(regresult => {
				// SEGMENT - track sign up
				Api.convertRegistration(reg, regresult.data.uuid)
					.then(() => {
						// SEGMENT - track account creation (convert registration to account)
						SegmentUtil.track(SegmentUtil.actions.accountcreate);
						this.loginNewUser();
					})
					.catch(err => {
						if (this.props.onError) {
							this.props.onError({
								message:
									'There was a problem accepting this invitation, please contact Conserv support for assistance'
							});
						} else {
							this.props.dispatch('notification/add', {
								message:
									'There was a problem accepting this invitation, please contact Conserv support for assistance',
								severity: 'error',
								variant: 'banner'
							});
						}
					});
			})
			.catch(() => {
				if (this.props.onError) {
					this.props.onError({
						message:
							'There was a problem accepting this invitation, please contact Conserv support for assistance'
					});
				} else {
					this.props.dispatch('notification/add', {
						message:
							'There was a problem accepting this invitation, please contact Conserv support for assistance',
						severity: 'error',
						variant: 'banner'
					});
				}
			});
	};

	loginNewUser = () => {
		const credentials = {
			email: this.state.user.email.toLowerCase(),
			pass: this.state.user.password
		};
		Api.login(credentials)
			.then(res => {
				const { cookies } = this.props;
				if (res.data.token) {
					this.setState(
						{
							redirectToApp: true,
							redirectUrl: '/'
						},
						() => {
							const expiry = new Date();
							expiry.setDate(new Date().getDate() + 30);
							cookies.set('jwt', res.data.token, {
								domain: '.conserv.io',
								path: '/',
								secure: false,
								expires: expiry
							});
							cookies.set('cuser', {
								email: res.data.email,
								role: res.data.role,
								customerId: res.data.customerId,
								uuid: res.data.uuid
							});
							Api.getPreferences()
								.then(prefres => {
									const preferences = prefres.data;
									this.context.updatePreferences(preferences);
								})
								.catch(() => {
									// swallow this error, as a 401 is expected here.
								})
								.finally(() => {
									SegmentUtil.identifyAndGroup(res.data);
								});
						}
					);
				} else {
					// handle a no token case?
					if (this.props.onError) {
						this.props.onError({
							message:
								'There was a problem accepting this invitation, please contact Conserv support for assistance'
						});
					} else {
						this.props.dispatch('notification/add', {
							message:
								'There was a problem accepting this invitation, please contact Conserv support for assistance',
							severity: 'error',
							variant: 'banner'
						});
					}
				}
			})
			.catch(() => {
				if (this.props.onError) {
					this.props.onError({
						message:
							'There was a problem accepting this invitation, please contact Conserv support for assistance'
					});
				} else {
					this.props.dispatch('notification/add', {
						message:
							'There was a problem accepting this invitation, please contact Conserv support for assistance',
						severity: 'error',
						variant: 'banner'
					});
				}
			});
	};

	acceptInvitation = () => {
		// SEGMENT - Accept invitation
		SegmentUtil.track(SegmentUtil.actions.inviteaccepted, {
			category: 'app',
			email: this.state.user.email
		});
		if (this.state.token) {
			Api.acceptInvite(this.state.user, this.state.token)
				.then(() => {
					this.loginNewUser();
				})
				.catch(err => {
					if (this.props.onError) {
						this.props.onError({
							message:
								'There was a problem accepting this invitation, please contact Conserv support for assistance'
						});
					} else {
						this.props.dispatch('notification/add', {
							message:
								'There was a problem accepting this invitation, please contact Conserv support for assistance',
							severity: 'error',
							variant: 'banner'
						});
					}
				});
		} else {
			// if we don't have a token, then we should have a referral code
			this.registerReferred();
		}
	};

	validateForm = () => {
		let valid = true;
		const formValidation = {};
		if (this.state.passwordStrength < 1) {
			valid = false;
			if (this.state.passwordStrength != -1) {
				formValidation.passwordError =
					'Password must be at least six characters, upper and lower case';
			}
		}
		if (this.state.user.password !== this.state.user.confirmPassword) {
			formValidation.passwordError = 'Passwords must match';
		}
		this.setState({ formValidation, formValid: valid });
	};

	handleEmailChange = event => {
		this.state.user.email = event.target.value;
		this.setState({ user: this.state.user }, () => {
			if (this.state.user.email) {
				Api.validateEmail(this.state.user.email).then(result => {
					this.setState({
						emailvalid: result.data.valid,
						emailexists: result.data.emailexists
					});
				});
			}
		});
	};

	loginWithGoogle = (user, token) => {
		const { cookies } = this.props;
		Api.loginoauth(user, token)
			.then(res => {
				if (res.data.token) {
					const { firstlogin } = res.data;
					const expiry = new Date();
					expiry.setDate(new Date().getDate() + 30);
					cookies.set('jwt', res.data.token, {
						domain: '.conserv.io',
						path: '/',
						secure: false,
						expires: expiry
					});

					cookies.set('cuser', {
						email: res.data.email,
						first_name: res.data.first_name,
						last_name: res.data.last_name,
						role: res.data.role,
						customerId: res.data.customerId,
						uuid: res.data.uuid
					});
					SegmentUtil.identifyAndGroup(res.data);
					Api.getPreferences()
						.then(res => {
							const preferences = res.data;
							this.context.updatePreferences(preferences);
						})
						.catch(() => {
							// A 401 here is expected
						});
					this.setState({
						error: false,
						redirectToApp: true,
						redirectUrl: '/'
					});
				} else {
					// handle a no token case?
					this.setState({
						error: {
							message: 'There has been an error authenticating with Google'
						}
					});
				}
			})
			.catch(() => {
				this.setState({
					error: {
						message: 'There has been an error authenticating with Google'
					}
				});
			});
	};

	googleLoginError = () => {
		this.props.onError({
			message: (
				<>
					<div>
						There has been an error logging in with your Google account, please
					</div>
					<Link
						classes={{ root: this.classes?.errorLink }}
						href='https://support.conserv.io/knowledge/kb-tickets/new'
						target='new'
					>
						contact Conserv support
					</Link>
				</>
			)
		});
	};

	render() {
		const { classes } = this.props;
		if (this.state.redirectToApp) {
			window.location.href = this.state.redirectUrl;
			return null;
		}
		return (
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='space-around'
				alignItems='center'
				width='100%'
				height='100%'
				bgcolor={this.state.reset ? colors.ice.medium : ''}
			>
				<Box
					classes={{ root: classes.ctaPanel }}
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='center'
				>
					<Box
						display='flex'
						flexDirection='row'
						p={2}
						width='100%'
						justifyContent='center'
					>
						<img
							alt='conserv logo'
							style={{ width: '36%' }}
							src='/img/conserv_io_new_logo_white.png'
						/>
					</Box>
				</Box>
				<Box
					height='100%'
					width='50%'
					display='flex'
					flexDirection='column'
					alignItems='center'
					justifyContent='center'
				>
					{!this.state.ready ? (
						<Loading text='validating invitation...' />
					) : (
						<Box
							classes={{ root: classes.loginPanel }}
							display='flex'
							flexDirection='column'
							justifyContent='center'
						>
							{this.state.error ? (
								<Box classes={{ root: classes.errorBox }} pb={2} mb={2}>
									{this.state.error.message}
								</Box>
							) : null}
							<Box pb={2}>
								<Typography variant='h1' classes={{ root: classes.loginTitle }}>
									{!this.state.showExpired
										? 'Create Your Account'
										: 'Whoops, this link has expired'}
								</Typography>
							</Box>
							<Box mb={4}>
								{!this.state.showExpired ? (
									<Typography classes={{ root: classes.termsAndConditionText }}>
										You should have recieved an invite in your email. Please
										contact Conserv at{' '}
										<Link
											classes={{ root: classes.emailLink }}
											href='mailto:support@conserv.io'
										>
											support@conserv.io
										</Link>{' '}
										if you are having trouble.{' '}
									</Typography>
								) : (
									<Typography classes={{ root: classes.termsAndConditionText }}>
										Please contact your administrator to request a new
										invitation
									</Typography>
								)}
							</Box>
							{!this.state.showExpired ? (
								<Box>
									<Box mb={3}>
										<TextField
											id='email'
											error={Boolean(this.state.formValidation.emailError)}
											helperText={this.state.formValidation.emailError}
											label='Email'
											variant='outlined'
											placeholder='Email'
											type='email'
											value={this.state.user.email}
											onChange={this.handleEmailChange}
											autoComplete='email'
											InputLabelProps={{ shrink: true }}
											InputProps={{
												classes: {
													input: classes.input
												}
											}}
											disabled
										/>
									</Box>
									<Box mb={3}>
										<TextField
											id='password'
											variant='outlined'
											error={Boolean(this.state.formValidation.passwordError)}
											helperText={this.state.formValidation.passwordError}
											placeholder='Password'
											label='New Password'
											type={this.state.showPass ? 'text' : 'password'}
											value={this.state.user.password}
											onChange={this.handlePasswordChange}
											InputLabelProps={{ shrink: true }}
											autoFocus
											InputProps={{
												classes: {
													input: classes.input
												},
												endAdornment: (
													<InputAdornment postiion='end'>
														<Icon
															color={`${colors.black.mediumOpacity}`}
															size={1}
															path={
																this.state.showPass ? icons.hide : icons.show
															}
															onClick={() =>
																this.setState({
																	showPass: !this.state.showPass
																})
															}
														/>
													</InputAdornment>
												)
											}}
										/>
									</Box>
									<Box mb={4}>
										<TextField
											id='password'
											variant='outlined'
											error={Boolean(this.state.formValidation.passwordError)}
											helperText={this.state.formValidation.passwordError}
											placeholder='Password'
											label='Confirm New Password'
											type={this.state.showPass ? 'text' : 'password'}
											value={this.state.user.confirmPassword}
											onChange={this.handleConfirmPasswordChange}
											InputLabelProps={{ shrink: true }}
											InputProps={{
												classes: {
													input: classes.input
												},
												endAdornment: (
													<InputAdornment postiion='end'>
														<Icon
															color={`${colors.black.mediumOpacity}`}
															size={1}
															path={
																this.state.showPass ? icons.hide : icons.show
															}
															onClick={() =>
																this.setState({
																	showPass: !this.state.showPass
																})
															}
														/>
													</InputAdornment>
												)
											}}
										/>
									</Box>
									<Box display='flex' justifyContent='center' mb={3}>
										<Button
											color='primary'
											variant='contained'
											disabled={!this.state.formValid}
											onClick={this.acceptInvitation}
											classes={{ root: classes.singInButton }}
										>
											Continue
										</Button>
									</Box>

									<Box mb={3}>
										<DividerWithText>OR</DividerWithText>
									</Box>
									<Box mb={4} display='flex' justifyContent='center'>
										<GoogleButton
											onSuccess={this.loginWithGoogle}
											onFailure={this.googleLoginError}
										/>
									</Box>
									<Box>
										<Typography
											classes={{ root: classes.termsAndConditionText }}
										>
											By creating your account, you agree to our{' '}
											<Link
												style={{ textDecoration: 'none', fontWeight: 700 }}
												href='https://docs.google.com/document/d/1X76q31gWKK_YuokYNffqmovf0rzCTE7GqtCx3juDg40/edit#heading=h.fpva18fi05fu'
												target='_new'
											>
												Terms and Conditions
											</Link>{' '}
											and{' '}
											<Link
												style={{ textDecoration: 'none', fontWeight: 700 }}
												href='https://docs.google.com/document/d/1X76q31gWKK_YuokYNffqmovf0rzCTE7GqtCx3juDg40/edit#heading=h.4xzpd2cjrm41'
												target='_new'
											>
												Privacy Policy
											</Link>
											.{' '}
										</Typography>
									</Box>
								</Box>
							) : null}
						</Box>
					)}
				</Box>
			</Box>
		);
	}
}

export default withCookies(
	connectStoreon(withStyles(useStyles, { withTheme: true })(AcceptInvite))
);
