import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useStoreon } from 'storeon/react';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import Icon from '@mdi/react';

import CommentForm from '../CommentForm';
import Api from '../../../api/Api';
import icons from '../../../style/icons';
import useStyles from './styles';

function Comment({ callback, comment, type, typeid, user }) {
	const classes = useStyles();
	const { dispatch } = useStoreon();

	const [anchorEl, setAnchorEl] = useState(null);
	const [editing, setEditing] = useState(false);

	const handleClick = event => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const editComment = () => {
		setEditing(true);
		handleClose();
	};

	const deleteComment = () =>
		Api.deleteComment(comment.uuid)
			.then(() => callback && callback())
			.catch(() =>
				dispatch('notification/add', {
					message: 'There was a problem deleting a comment',
					severity: 'error',
					variant: 'banner'
				})
			);

	const callbackForm = () => {
		if (callback) callback();
		setEditing(false);
	};

	const fullName = useMemo(
		() =>
			`${comment.mnmxuser?.first_name || 'Unknown'} ${
				comment.mnmxuser?.last_name || ''
			}`,
		[]
	);

	const open = Boolean(anchorEl);

	return (
		<Box className={classes.container} px={3} py={3}>
			<Box alignItems='center' display='flex' justifyContent='space-between'>
				<Box alignItems='baseline' display='flex'>
					<Typography className={classes.fullName} variant='body1'>
						{fullName}
					</Typography>
					<Typography className={classes.date} variant='body1'>
						{dayjs(comment.createddate).format('d MMM YYYY, H:mm a')}
					</Typography>
				</Box>
				{comment.createdby === user.uuid && (
					<div>
						<Icon
							aria-label='more'
							id='long-button'
							aria-controls={open ? 'long-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-haspopup='true'
							className={classes.link}
							onClick={handleClick}
							size={1}
							path={icons.moreVertical}
						/>
						<Menu
							id='action-menu'
							MenuListProps={{
								'aria-labelledby': 'action menu'
							}}
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
						>
							<MenuItem onClick={editComment}>Edit Comment</MenuItem>
							<MenuItem onClick={deleteComment}>Delete Comment</MenuItem>
						</Menu>
					</div>
				)}
			</Box>
			{editing ? (
				<CommentForm
					callback={callbackForm}
					comment={comment}
					type={type}
					typeid={typeid}
				/>
			) : (
				<Box pt={3}>
					<Typography className={classes.comment} variant='body1'>
						Comment:
					</Typography>
					<Typography className={classes.description} variant='body1'>
						{comment.text}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

export default Comment;
