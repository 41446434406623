import React, { Component } from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import { connectStoreon } from 'storeon/react';
import { withCookies } from 'react-cookie';
import QueryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import GetStarted from './actions/GetStarted';
import DefaultAction from './actions/DefaultAction';
import InviteTeam from './actions/InviteTeam';
import ViewScore from './actions/ViewScore';
import InviteWelcome from './actions/InviteWelcome';
import SwitchAccount from './actions/SwitchAccount';

const useStyles = () => ({
	paper: {
		minWidth: '500px',
		maxWidth: '640px'
	}
});

class ActionModal extends Component {
	components = {
		getstarted: GetStarted,
		inviteteam: InviteTeam,
		viewscore: ViewScore,
		invitewelcome: InviteWelcome,
		switchaccount: SwitchAccount,
		default: DefaultAction
	};

	constructor(props) {
		super(props);
		this.state = { show: true };
		this.state.action = QueryString.parse(window.location.search).action;
		if (props.modal) {
			this.state.action = props.modal;
		}
	}

	onClose = () => {
		if (this.props.callBack) {
			this.props.callBack();
		}
		this.setState({ show: false });
	};

	nextAction = action => {
		if (action === 'complete') {
			this.setState({ show: false });
		} else {
			this.setState({ action });
		}
	};

	render() {
		const { classes } = this.props;
		if (!this.state.action || !this.props.user) {
			return null;
		}
		const ActionTag =
			this.components[this.state.action] || this.components.default;
		return (
			<Dialog
				classes={{ paper: classes.paper }}
				open={this.state.show}
				onClose={this.onClose}
			>
				<DialogContent>
					<ActionTag
						detailText={this.props.detailText}
						user={this.props.user}
						callback={this.nextAction}
					/>
				</DialogContent>
			</Dialog>
		);
	}
}

export default connectStoreon(
	'user',
	withCookies(withStyles(useStyles)(ActionModal))
);
