/* eslint-disable @typescript-eslint/no-magic-numbers */
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles/createPalette' {
	interface Palette {
		tertiary?: Palette['primary'];
	}
	interface PaletteOptions {
		tertiary?: PaletteOptions['primary'];
	}
}

declare module '@mui/material/styles' {
	interface TypographyVariants {
		body0: React.CSSProperties;
		body3: React.CSSProperties;
	}

	// allow configuration using `createTheme`
	interface TypographyVariantsOptions {
		body0?: React.CSSProperties;
		body3?: React.CSSProperties;
	}
}
declare module '@mui/material/Typography' {
	// eslint-disable-next-line unicorn/prevent-abbreviations
	interface TypographyPropsVariantOverrides {
		body0: true;
		body3: true;
	}
}

declare module '@mui/material/Button/Button' {
	// eslint-disable-next-line unicorn/prevent-abbreviations
	interface ButtonPropsColorOverrides {
		tertiary: true;
	}
}

const theme = createTheme({
	palette: {
		primary: {
			light: '#914BFA',
			main: '#781FFF',
			dark: '#5200CC',
			contrastText: '#FFFFFF'
		},
		secondary: {
			main: '#01A9FC',
			contrastText: '#FFFFFF'
		},
		tertiary: {
			main: '#42A5F5'
		},
		text: {
			primary: '#212121',
			secondary: '#666666',
			disabled: '#9E9E9E'
		}
	},
	typography: {
		fontFamily: 'Roboto, sans-serif',
		fontSize: 14,
		h1: {
			fontSize: '96px',
			fontWeight: 400,
			fontFamily: 'Sansita, Helvetica, Arial, sans-serif'
		},
		h2: {
			fontSize: '60px',
			fontWeight: 400,
			fontFamily: 'Sansita, Helvetica, Arial, sans-serif'
		},
		h3: {
			fontSize: '48px',
			fontWeight: 400,
			fontFamily: 'Sansita, Helvetica, Arial, sans-serif'
		},
		h4: {
			fontSize: '34px',
			fontWeight: 400,
			fontFamily: 'Montserrat, sans-serif'
		},
		h5: {
			fontSize: '24px',
			fontWeight: 400,
			fontFamily: 'Montserrat, sans-serif'
		},
		h6: {
			fontSize: '20px',
			fontWeight: 500,
			fontFamily: 'Montserrat, sans-serif'
		},
		subtitle1: {
			fontSize: '16px',
			fontWeight: 500,
			fontFamily: 'Roboto, sans-serif'
		},
		subtitle2: {
			fontSize: '14px',
			fontWeight: 500,
			fontFamily: 'Roboto, sans-serif'
		},
		body0: {
			fontSize: '20px',
			fontWeight: 400,
			fontFamily: 'Roboto, sans-serif'
		},
		body1: {
			fontSize: '16px',
			fontWeight: 400,
			fontFamily: 'Roboto, sans-serif'
		},
		body2: {
			fontSize: '14px',
			fontWeight: 400,
			fontFamily: 'Roboto, sans-serif'
		},
		body3: {
			fontSize: '12px',
			fontWeight: 400,
			fontFamily: 'Roboto, sans-serif',
			lineHeight: '16px'
		},
		button: {
			fontSize: '14px',
			fontWeight: 500,
			fontFamily: 'Roboto, sans-serif'
		},
		caption: {
			fontSize: '12px',
			fontWeight: 400,
			fontFamily: 'Roboto, sans-serif'
		},
		overline: {
			fontSize: '12px',
			fontWeight: 400,
			fontFamily: 'Roboto, sans-serif',
			textTransform: 'uppercase'
		}
	}
});

export default createTheme(theme, {
	components: {
		MuiButtonBase: {
			styleOverrides: {
				root: {
					'&:focus': {
						outline: 'none'
					},
					padding: '6px 16px' // set your desired padding value
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					'&:focus': {
						outline: 'none'
					},
					padding: '6px 16px' // set your desired padding value
				}
			}
		},
		MuiFab: {
			styleOverrides: {
				root: {
					height: '34px'
				}
			}
		},
		MuiCard: {
			styleOverrides: {
				root: {
					border: '1px solid #E0E0E0',
					borderColor: theme.palette.primary.main,
					margin: '20px 0 0 0 !important'
				}
			}
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					borderTop: '16px solid #E0E0E0',
					borderColor: theme.palette.primary.main,
					fontSize: '12px',
					fontWeight: 400,
					fontFamily: 'Roboto, sans-serif',
					textTransform: 'uppercase',
					textAlign: 'center'
				}
			}
		}
	}
});
