import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		alignItems: 'center'
	},
	border: {
		borderBottom: '1px solid #9E9E9E',
		width: '100%'
	},
	content: {
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
		paddingRight: 10,
		paddingLeft: 10,
		fontWeight: 400,
		fontSize: 24,
		color: '#9E9E9E'
	}
}));

function DividerWithText({ children }) {
	const classes = useStyles();
	return (
		<div className={classes.container}>
			<div className={classes.border} />
			<span className={classes.content}>{children}</span>
			<div className={classes.border} />
		</div>
	);
}
export default DividerWithText;
