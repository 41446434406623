import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontWeight: 700,
		lineHeight: '32px',
		marginBottom: '19px'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '24px',
		letterSpacing: '0.46000000834465027px'
	},
	saveButton: {
		paddingRight: '16px',
		paddingLeft: '16px',
		paddingTop: '6px',
		paddingBottom: '6px',
		background: '#6600FD'
	}
}));
