import React from 'react';
import EventBody from '../EventBody';
import { ReviewForm } from '../../Forms';

function Review() {
	return (
		<EventBody
			title='Review'
			description='Click on the steps above to edit the event.'
		>
			<ReviewForm />
		</EventBody>
	);
}

export default Review;
