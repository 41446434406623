import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import './Actions.css';

export default class InviteTeam extends Component {
	render() {
		return (
			<div>
				<div className='action-title'>
					<h2>Invite Your Team</h2>
					<h5>Let's get a few more of your colleagues involved!</h5>
				</div>
				<div className='row' />
				<div>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.props.callback('complete')}
					>
						Skip
					</Button>
				</div>
			</div>
		);
	}
}
