import { makeStyles } from '@material-ui/core';
import { colors } from '../../../style/colors';
export default makeStyles(theme => ({
	body: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: '150%',
		letterSpacing: '0.15px',
		margin: '8px 0'
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '24px'
	},
	wideCard: {
		minWidth: '300px'
	},
	required: {
		color: colors.gemini.shade,
		marginLeft: '4px'
	},
	listBullet: {
		'&::before': {
			fontSize: '4px'
		}
	},
	noPaddingTop: {
		paddingTop: '0 !important'
	}
}));
