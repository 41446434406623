/* eslint-disable no-use-before-define */
import React from 'react';
import {
	Checkbox,
	Chip,
	FormControlLabel,
	TextField,
	Typography
} from '@material-ui/core';
import Autocomplete, {
	createFilterOptions
} from '@material-ui/lab/Autocomplete';

import useStyles from './styles';

const MultiSelect = ({
	label,
	onChange,
	options,
	getOptionLabel,
	value,
	error,
	...autocompleteProps
}) => {
	const classes = useStyles();
	const SELECT_ALL = 'select-all';
	const allSelected = options.length === value.length;
	const handleToggleSelectAll = () => {
		if (allSelected) {
			onChange(options);
		} else {
			onChange([]);
		}
	};

	const handleChange = (event, selectedOptions, reason) => {
		if (selectedOptions.some(option => option === SELECT_ALL)) {
			if (selectedOptions.length <= options.length) {
				onChange(options);
			} else {
				onChange([]);
			}
		} else {
			onChange && onChange(selectedOptions.filter(e => e !== SELECT_ALL));
		}
	};

	const optionRenderer = (option, { selected }) => {
		const isSelected = option === SELECT_ALL ? allSelected : selected;
		return (
			<FormControlLabel
				className={classes.checkboxText}
				key={option}
				label={handleGetOptionLabel(option)}
				control={
					<Checkbox
						className={classes.checkbox}
						checked={isSelected}
						color='primary'
					/>
				}
			/>
		);
	};
	const inputRenderer = params =>
		renderInput ? (
			renderInput(params)
		) : (
			<TextField {...params} label={label} placeholder={placeholder} />
		);
	const getOptionSelected = (option, anotherOption) =>
		option.value === anotherOption.value;

	const filter = createFilterOptions();

	const handleGetOptionLabel = (option, isChip) => {
		const isSelectAll = option === SELECT_ALL;
		if (isSelectAll) {
			return isChip ? `All ${label}s` : 'Select all';
		} else {
			return getOptionLabel(option);
		}
	};

	const renderChip = (val, index, getTagProps) => (
		<Chip
			{...getTagProps({ index })}
			key={`chip-${val}`}
			color='primary'
			label={
				<Typography
					noWrap
					className={classes.chipText}
					title={handleGetOptionLabel(val, true)}
				>
					{handleGetOptionLabel(val, true)}
				</Typography>
			}
			className={classes.chip}
		/>
	);
	return (
		<Autocomplete
			className={classes.autocomplete}
			{...autocompleteProps}
			onChange={handleChange}
			options={options}
			getOptionLabel={handleGetOptionLabel}
			disableCloseOnSelect
			value={value}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);
				const list = [SELECT_ALL, ...filtered];
				return list;
			}}
			renderTags={(values, getTagProps) => {
				if (values.includes(SELECT_ALL) || allSelected) {
					const val = SELECT_ALL;
					const index = values.indexOf(val);
					return renderChip(val, index, getTagProps);
				}
				return values.map((val, index) => renderChip(val, index, getTagProps));
			}}
			renderInput={params => (
				<TextField
					required
					{...params}
					label={`Select ${label}`}
					variant='outlined'
					error={error}
					helperText={error?.message}
				/>
			)}
			renderOption={optionRenderer}
		/>
	);
};

MultiSelect.defaultProps = {
	limitTags: 5,
	options: [],
	value: [],
	getOptionLabel: value => value
};

export default MultiSelect;
