import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	backdrop: {
		zIndex: 15
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 8,
		justifyContent: 'center'
	},
	dropzone: {
		alignItems: 'center',
		background: '#F5F5F5',
		border: '2px dashed #AAAAAA',
		boxSizing: 'border-box',
		borderRadius: '4px',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		margin: ({ multiple }) => (multiple ? '.5rem' : null),
		padding: ({ multiple }) => `${multiple ? '21px' : '32px'} 16px`,
		width: ({ multiple }) => (multiple ? null : '100%')
	},
	dropzoneText: {
		color: '#767676',
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '19px'
	},
	image: {
		cursor: 'pointer',
		maxWidth: '100%'
	},
	imageContainer: {
		margin: '.5rem',
		position: 'relative'
	},
	link: {
		cursor: 'pointer'
	},
	remove: {
		background: '#E63946',
		borderRadius: '50%',
		cursor: 'pointer',
		padding: '4px',
		position: 'absolute',
		top: '-16px',
		right: '-16px',
		zIndex: 5
	},
	textButton: {
		color: '#2CA9DA',
		'&:hover': {
			color: '#2CA9DA'
		}
	},
	toolbar: {
		background: 'rgba(255, 255, 255, 0.5)',
		borderRadius: '0px 0px 4px 4px',
		bottom: 0,
		padding: '16px 24px',
		position: 'absolute'
	}
}));
