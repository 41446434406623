import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DecoratedTextField from '../../../common/form/v2/DecoratedTextField';

function NameForm({ editable }) {
	const { control } = useFormContext();
	return (
		<Controller
			name='name'
			control={control}
			render={({
				field: { onChange, value, defaultValue, ref },
				fieldState: { error }
			}) => (
				<DecoratedTextField
					aria-label='name of this event profile'
					defaultValue={defaultValue}
					value={value}
					editing={editable}
					error={error}
					helperText={error?.message}
					id='name'
					inputProps={{ height: '56px' }}
					label='Name your event'
					onChange={onChange}
					required
					inputRef={ref}
				/>
			)}
		/>
	);
}

export default NameForm;
