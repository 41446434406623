/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useEffect, useReducer, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Button from '@mui/material/Button';
import type Product from '../../types/Product';
import ProductRow from '../ProductRow/ProductRow';
import getProductList from '../../services/product/getProductList';
import Loading from 'legacy/components/common/elements/v2/Loading';
import ConservDialog from 'components/ConservDialog/ConservDialog';
import NumberInput from 'components/NumberInput/NumberInput';
import reducerProductsToUpdate from 'components/ConservDialog/ConservDialogSubscription/reducerProductsToUpdate';
import {
	Autocomplete,
	AutocompleteChangeReason,
	TextField
} from '@mui/material';
import createOrder from 'services/admin/order/admin.createOrder';
import Customer from 'types/Customer';

interface CreateOrderModalParameters {
	isOpen: boolean;
	onCloseDialog: () => void;
	customers: unknown[];
}
export default function CreateOrderModal({
	isOpen,
	onCloseDialog,
	customers
}: CreateOrderModalParameters) {
	const [products, setProducts] = useState<Product[]>([]);
	const [gateways, setGateways] = useState<Product[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [productsToAdd, dispatch] = useReducer(reducerProductsToUpdate, []);
	const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

	useEffect(() => {
		const getTheProductList = async () => {
			const productList = (await getProductList({
				isStarterKit: false
			})) as Product[];
			setProducts(productList.filter(product => product.type === 'sensor'));
			setGateways(productList.filter(product => product.type === 'gateway'));
			setLoading(false);
		};
		void getTheProductList();
	}, []);

	const onCustomerSelectChange = (
		_event: React.SyntheticEvent,
		customer: unknown,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		_reason: AutocompleteChangeReason
	) => {
		setSelectedCustomer(customer as Customer);
	};

	const onCreateOrderHandler = async () => {
		if (selectedCustomer) {
			const order = await createOrder({
				customerId: selectedCustomer.id,
				products: productsToAdd
			});
			console.log(order);
			onCloseDialog();
		}
	};

	const getProductAddedByUuid = (uuid: string) =>
		productsToAdd.find(productToAdd => productToAdd.uuid === uuid);

	const onIncreaseProductQuantity = (
		uuid: number | string,
		quantity: number
	) => {
		if (getProductAddedByUuid(uuid as string)) {
			dispatch({ type: 'INCREASE_AMOUNT', payload: uuid as string });
		} else {
			dispatch({
				type: 'ADD_ITEM',
				payload: { uuid: uuid as string, amount: quantity }
			});
		}
	};

	const onDecreaseProductQuantity = (uuid: number | string) => {
		const productAdded = getProductAddedByUuid(uuid as string);
		if (productAdded) {
			const action =
				productAdded.amount === 1 ? 'REMOVE_ITEM' : 'DECREASE_AMOUNT';
			dispatch({ type: action, payload: uuid as string });
		}
	};

	const onChangeProductQuantity = (uuid: number | string, quantity: number) => {
		if (getProductAddedByUuid(uuid as string)) {
			dispatch({
				type: 'SET_AMOUNT',
				payload: { uuid: uuid as string, amount: quantity }
			});
		} else {
			dispatch({
				type: 'ADD_ITEM',
				payload: { uuid: uuid as string, amount: quantity }
			});
		}
	};

	return (
		<ConservDialog
			fullWidth
			title='Create Order'
			isOpen={isOpen}
			onCloseHandler={onCloseDialog}
			showCloseButton
		>
			<Box>
				<Box mt={5} mb={5}>
					<Autocomplete
						disablePortal
						id='combo-box-demo'
						options={customers}
						sx={{ width: 300 }}
						getOptionLabel={(option: any) => `${option.id} - ${option.name}`}
						onChange={onCustomerSelectChange}
						renderInput={parameters => (
							<TextField {...parameters} label='Customer' />
						)}
					/>
				</Box>
				<Grid
					container
					spacing={4}
					rowSpacing={2}
					sx={{ marginBottom: '20px' }}
				>
					{loading ? (
						<Loading text='Loading products information...' />
					) : (
						products.map(
							({ id, uuid, imagePath, name, description, price }) => {
								const productAdded = getProductAddedByUuid(uuid);
								return (
									<Grid key={id} container sx={{ marginBottom: '20px' }}>
										<Grid xs={10}>
											<ProductRow
												imagePath={imagePath}
												title={name}
												description={description}
												price={price}
											/>
										</Grid>
										<Grid
											xs={2}
											display='flex'
											justifyContent='center'
											alignItems='center'
										>
											<NumberInput
												triggerId={uuid}
												onIncrease={onIncreaseProductQuantity}
												onDecrease={onDecreaseProductQuantity}
												onChange={onChangeProductQuantity}
												value={productAdded?.amount ?? 0}
											/>
										</Grid>
									</Grid>
								);
							}
						)
					)}
				</Grid>

				<Typography
					variant='body0'
					component='div'
					sx={{ marginBottom: '20px' }}
				>
					Gateways
				</Typography>

				<Grid
					container
					spacing={4}
					rowSpacing={2}
					sx={{ marginBottom: '50px' }}
				>
					{loading ? (
						<Loading text='Loading products information...' />
					) : (
						gateways.map(
							({ id, uuid, imagePath, name, description, price }) => {
								const productAdded = getProductAddedByUuid(uuid);
								return (
									<Grid key={id} container sx={{ marginBottom: '20px' }}>
										<Grid xs={10}>
											<ProductRow
												imagePath={imagePath}
												title={name}
												description={description}
												price={price}
											/>
										</Grid>
										<Grid
											xs={2}
											display='flex'
											justifyContent='center'
											alignItems='center'
										>
											<NumberInput
												triggerId={uuid}
												onIncrease={onIncreaseProductQuantity}
												onDecrease={onDecreaseProductQuantity}
												onChange={onChangeProductQuantity}
												value={productAdded?.amount ?? 0}
											/>
										</Grid>
									</Grid>
								);
							}
						)
					)}
				</Grid>

				<Button
					variant='outlined'
					color='primary'
					onClick={onCreateOrderHandler}
					data-testid='manage-subscription-button'
					sx={{ marginBottom: '40px' }}
					disabled={productsToAdd.length === 0 || !selectedCustomer}
				>
					Create Order
				</Button>
			</Box>
		</ConservDialog>
	);
}
