import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	customButton: {
		boxShadow: 'none',
		width: '141px',
		height: '42px'
	},
	container: {
		borderTop: '1px solid #E0E0E0',
		padding: '24px 48px'
	},
	actionGroup: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	backAndNext: {
		display: 'flex',
		gap: '16px'
	},
	secondary: {
		backgroundColor: '#FFF',
		border: '1px solid rgba(103, 58, 183, 0.5)',
		color: '#6600FD'
	}
}));
