/* eslint-disable react/jsx-handler-names */
/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import type {
	GridColDef,
	GridRenderCellParams,
	GridValueGetterParams
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { useStoreon } from 'storeon/react';
import Loading from 'legacy/components/common/elements/v2/Loading';
import getUserList from 'services/admin/user/admin.getUserList';
import UserListItem from 'types/UserListItem';
import CustomerUser from 'types/CustomerUser';
import { Box, Button, Checkbox, TextField } from '@mui/material';
import UpdatePassword from 'components/UpdatePassword/UpdatePassword';

const updateUserActiveStatus = ({
	userId,
	active
}: {
	userId: number;
	active: boolean;
}) => {
	console.log('updating status', userId, active);
};

const columns: GridColDef[] = [
	{
		field: 'id',
		headerName: 'ID'
		// flex: 1
	},
	{
		field: 'email',
		headerName: 'Email',
		flex: 1
	},
	{
		field: 'customerName',
		headerName: 'Customers',
		flex: 1,
		valueGetter: (parameters: GridValueGetterParams<UserListItem>) =>
			parameters.row.customerusers
				.map((cu: CustomerUser) => cu.customer.name)
				.join('\n')
	},
	{
		field: 'active',
		headerName: 'Active',
		// flex: 1,
		renderCell: (parameters: GridRenderCellParams<UserListItem>) => {
			const isActive = parameters.row.active;
			return (
				<Checkbox
					checked={isActive}
					onChange={() =>
						updateUserActiveStatus({
							userId: parameters.row.id,
							active: !isActive
						})
					}
					inputProps={{ 'aria-label': 'primary checkbox' }}
				/>
			);
		}
	},
	{
		field: 'password',
		headerName: 'Set Password',
		flex: 1,
		renderCell: (parameters: GridRenderCellParams<UserListItem>) => (
			<UpdatePassword user={parameters.row} />
		)
	}

	// {
	// 	field: 'outdorSensor',
	// 	headerName: '# Outside Sensor',
	// 	flex: 1,
	// 	valueGetter: (parameters: GridValueGetterParams) =>
	// 		getProductQuantity({
	// 			products: parameters.row.products,
	// 			type: 'sensor',
	// 			subtype: 'outdoor'
	// 		})
	// },
	// {
	// 	field: 'action',
	// 	headerName: 'Action',
	// 	flex: 1,
	// 	renderCell: (parameters: GridRenderCellParams<Date>) => {
	// 		if (parameters.row.status === 'created') {
	// 			return <SendOrdersButton row={parameters.row} />;
	// 		}
	// 		return null;
	// 	}
	// }
];

const initialUserList: UserListItem[] = [];
export default function AdminUsers() {
	const [users, setUsers] = useState<UserListItem[]>(initialUserList);
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useStoreon();

	const retrieveUsers = useCallback(async () => {
		try {
			setIsLoading(true);
			const userList = await getUserList();
			setUsers(userList);
		} catch (error) {
			dispatch('notification/add', {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				message: `There was a problem fetching the users: ${error}`,
				severity: 'error',
				variant: 'banner'
			});
		} finally {
			setIsLoading(false);
		}
	}, [dispatch]);

	useEffect(() => {
		void retrieveUsers();
	}, [retrieveUsers]);

	return (
		<Box>
			<Box sx={{ height: '80vh', width: '100%' }}>
				{isLoading ? (
					<Loading text='fetching users...' />
				) : (
					<DataGrid
						rows={users}
						columns={columns}
						getRowId={row => row.uuid}
						autoPageSize
					/>
				)}
			</Box>
		</Box>
	);
}
