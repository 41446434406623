/* eslint-disable unicorn/no-null */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable unicorn/no-array-reduce */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useState, useEffect, useCallback } from 'react';
import dayjs from 'dayjs';
import Box from '@material-ui/core/Box';
import type {
	GridColDef,
	GridRenderCellParams,
	GridValueGetterParams
} from '@mui/x-data-grid';
import { DataGrid } from '@mui/x-data-grid';
import { useStoreon } from 'storeon/react';
import getOrderList from '../../services/order/getOrderList';
import type OrderListItem from 'types/OrderListItem';
import type OrderListItemProduct from 'types/OrderListItemProduct';
import type Product from 'types/Product';
import SendOrdersButton from 'components/SendOrdersButton/SendOrdersButton';
import CreateOrdersButton from 'components/CreateOrdersButton/CreateOrdersButton';
import Loading from 'legacy/components/common/elements/v2/Loading';
import { Button } from '@mui/material';

const getProductQuantity = ({
	products,
	type,
	subtype
}: {
	products: OrderListItemProduct[];
	type: Product['type'];
	subtype: Product['subtype'];
}) => {
	const foundProducts = products.filter(
		({ product }: OrderListItemProduct) =>
			product.type === type && product.subtype === subtype
	);

	const quantity =
		foundProducts.length > 0
			? foundProducts.reduce(
					(accumulator: number, productItem: OrderListItemProduct) =>
						accumulator + productItem.quantity,
					0
			  )
			: 0;

	return quantity;
};

const columns: GridColDef[] = [
	{
		field: 'date',
		headerName: 'Created Date',
		flex: 1,
		valueFormatter: ({ value }) => dayjs(value).format('MM/DD/YYYY HH:mm')
	},
	{
		field: 'customerName',
		headerName: 'Customer'
	},
	{
		field: 'smartSensor',
		headerName: '# Smart Sensor',
		flex: 1,
		valueGetter: (parameters: GridValueGetterParams) =>
			getProductQuantity({
				products: parameters.row.products,
				type: 'sensor',
				subtype: 'smart'
			})
	},
	{
		field: 'leakSensor',
		headerName: '# Leak Sensor',
		flex: 1,
		valueGetter: (parameters: GridValueGetterParams) =>
			getProductQuantity({
				products: parameters.row.products,
				type: 'sensor',
				subtype: 'leak'
			})
	},
	{
		field: 'coldSensor',
		headerName: '# Cold Storage Sensor',
		flex: 1,
		valueGetter: (parameters: GridValueGetterParams) =>
			getProductQuantity({
				products: parameters.row.products,
				type: 'sensor',
				subtype: 'coldstorage'
			})
	},
	{
		field: 'outdorSensor',
		headerName: '# Outside Sensor',
		flex: 1,
		valueGetter: (parameters: GridValueGetterParams) =>
			getProductQuantity({
				products: parameters.row.products,
				type: 'sensor',
				subtype: 'outdoor'
			})
	},
	{
		field: 'gateway',
		headerName: '# Gateway',
		flex: 1,
		valueGetter: (parameters: GridValueGetterParams) =>
			getProductQuantity({
				products: parameters.row.products,
				type: 'gateway',
				subtype: null
			})
	},
	{
		field: 'status',
		headerName: 'Status',
		flex: 1
	},
	{
		field: 'action',
		headerName: 'Action',
		flex: 1,
		renderCell: (parameters: GridRenderCellParams<Date>) => {
			if (parameters.row.status === 'created') {
				return <SendOrdersButton row={parameters.row} />;
			}
			return null;
		}
	}
];
interface AdminOrdersParameters {
	customers: unknown[];
}
export default function AdminOrders({ customers }: AdminOrdersParameters) {
	const [orders, setOrders] = useState<OrderListItem[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const { dispatch } = useStoreon();

	const retrieveOrders = useCallback(async () => {
		try {
			setIsLoading(true);
			const orderList = await getOrderList();
			setOrders(orderList);
		} catch (error) {
			dispatch('notification/add', {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				message: `There was a problem fetching the orders: ${error}`,
				severity: 'error',
				variant: 'banner'
			});
		} finally {
			setIsLoading(false);
		}
	}, [dispatch]);

	useEffect(() => {
		void retrieveOrders();
	}, [retrieveOrders]);

	const onRefreshHandler = () => {
		void retrieveOrders();
	};
	return (
		<Box>
			<Box display='flex' justifyContent='space-between'>
				<CreateOrdersButton customers={customers} />
				<Button variant='outlined' onClick={onRefreshHandler}>
					Refresh
				</Button>
			</Box>
			<Box sx={{ height: '80vh', width: '100%' }}>
				{isLoading ? (
					<Loading text='fetching orders...' />
				) : (
					<DataGrid
						rows={orders}
						columns={columns}
						getRowId={row => row.uuid}
						autoPageSize
					/>
				)}
			</Box>
		</Box>
	);
}
