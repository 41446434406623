import React from 'react';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';

function Event({ event }) {
	const classes = useStyles();

	return (
		<Box className={classes.container} px={3} py={3}>
			<Box alignItems='center' display='flex' justifyContent='space-between'>
				<Box
					alignItems='baseline'
					display='flex'
					className={classes.titleContainer}
				>
					<Typography className={classes.title} variant='body1'>
						{event.title}
					</Typography>
				</Box>
			</Box>
			<Box>
				<Typography className={classes.time} variant='body1'>
					{event.time}
				</Typography>
			</Box>
			{event.description && (
				<Box pt={2}>
					<Typography className={classes.comment} variant='body1'>
						Notes:
					</Typography>
					<Typography className={classes.description} variant='body1'>
						{event.description}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

export default Event;
