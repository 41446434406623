import React, { Component } from 'react';
import { connectStoreon } from 'storeon/react';
import Loading from '../components/common/elements/Loading';

export const PermissionContext = React.createContext();
export const PermissionConsumer = PermissionContext.Consumer;

class PermissionProvider extends Component {
	componentDidMount() {
		if (!this.props.user) {
			this.props.dispatch('user/refresh');
		}
	}

	render() {
		// if we do not have a user yet, display loading
		if (!this.props.user) {
			return <Loading />;
		}

		if (
			!this.props.requiredRole ||
			this.props.requiredRole === this.props.user?.role ||
			(this.props.requiredRole === 'admin' &&
				this.props.user?.role === 'superadmin')
		) {
			return (
				<PermissionContext.Provider role={this.props.user?.role} value={{}}>
					{this.props.children}
				</PermissionContext.Provider>
			);
		}
		return (
			<PermissionContext.Provider role={this.props.user?.role}>
				<></>
			</PermissionContext.Provider>
		);
	}
}

export default connectStoreon('user', PermissionProvider);
