/* eslint-disable @typescript-eslint/no-magic-numbers */
import axios from 'axios';
import type Product from '../../types/Product';

interface CreateOrderResponse {
	data: Product[];
}

const createOrder = async ({
	order,
	uuid
}: {
	order: unknown;
	uuid: string;
}) => {
	try {
		const { data } = await axios.post<CreateOrderResponse>(
			`/api/v2/3pl/order/${uuid}`,
			order
		);

		return data;
	} catch (error) {
		console.error('Error creating order in 3pl:', error);
	}

	return [];
};

export default createOrder;
