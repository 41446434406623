import React from 'react';
import { DialogContent, Button, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../style/colors';

const useStyles = makeStyles(theme => ({
	content: {
		paddingTop: 0,
		paddingBottom: theme.spacing(6),
		paddingLeft: theme.spacing(8),
		paddingRight: theme.spacing(8)
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '29px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0em',
		maxWidth: '338px'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '22px',
		paddingLeft: '22px',
		paddingTop: '8px',
		paddingBottom: '8px',
		background: colors.elektra.primary
	}
}));

function WelcomeDialog({ onNext }) {
	const classes = useStyles();

	return (
		<DialogContent classes={{ root: classes.content }}>
			<Box display='flex' justifyContent='center' paddingTop={3}>
				<img
					alt='conserv logo'
					style={{ width: '48px%' }}
					src='/img/conserv_circle.svg'
				/>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Typography className={classes.title} variant='h1'>
					Welcome to Conserv!
				</Typography>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Typography className={classes.text}>
					We’re so excited you’re here.
				</Typography>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Typography className={classes.text}>
					In a few short steps, we’ll get your profile set up so you can start
					monitoring your environment.
				</Typography>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Button
					className={classes.button}
					id='complete_profile'
					onClick={e => onNext()}
					variant='contained'
					color='primary'
				>
					get started
				</Button>
			</Box>
		</DialogContent>
	);
}

export default WelcomeDialog;
