/**
 * When a user lands here, strip out the "subscription" part of the URL and redirect them
 * to the remaining URL, after updating their subscription as indicated by the query params
 */

import React, { useEffect, useState } from 'react';
import { useStoreon } from 'storeon/react';
import QueryString from 'query-string';
import { Redirect } from 'react-router';
import SegmentUtil from '../../../util/SegmentUtil';
import Api from '../../../api/Api';

function SubscriptionLanding(props) {
	const { dispatch } = useStoreon();
	const [redirect, setRedirect] = useState();

	useEffect(() => {
		const values = QueryString.parse(props.location.search);
		let originalPath = window.location.pathname;
		originalPath = originalPath.replace('/landing/subscription', '');
		if (values.session_id) {
			SegmentUtil.track(SegmentUtil.actions.subscribepro);
			Api.updateCheckoutSession(values.session_id)
				.then(() => {
					dispatch('subscription/refresh');
					// now parse out the original destination and redirect to it.
				})
				.catch(() => {
					dispatch('notification/add', {
						message:
							'There was a problem redirecting you to our checkout, please contact Conserv',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			if (values.abandoncheckout) {
				SegmentUtil.track(SegmentUtil.actions.abandonsubscribepro);
			}
			dispatch('subscription/refresh');
		}
		setRedirect(originalPath);
	}, []);

	if (redirect) {
		return <Redirect to={redirect} />;
	}
	return <></>;
}

export default SubscriptionLanding;
