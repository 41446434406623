import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	container: {
		width: '100%'
	},
	ul: {
		listStyle: 'none',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		margin: 0,
		padding: 0
	},
	li: {
		marginRight: '6px'
	},
	resume: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '20px',
		color: '#00000080'
	}
}));
