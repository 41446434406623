import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import useStyles from './styles';

function ConservNavLink({ children, ...rest }) {
	const active = useRouteMatch(rest.to);
	const classes = useStyles({ active });

	return (
		<NavLink className={classes.navLink} {...rest}>
			{children}
		</NavLink>
	);
}

export default ConservNavLink;
