import { makeStyles } from '@material-ui/core';

import { colors } from '../../../../style/colors';

export default makeStyles(() => ({
	link: {
		cursor: 'pointer'
	},
	mediumButton: {
		backgroundColor: '#FFF',
		'&:hover': {
			backgroundColor: colors.nitro.light
		}
	},
	headerBox: {
		alignItems: 'center',
		backgroundColor: colors.v2.primary,
		color: '#FFF',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%'
	},
	link: {
		cursor: 'pointer'
	}
}));
