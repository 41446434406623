import Api from '../legacy/api/Api';

interface ExistEmailData {
	data: {
		valid: boolean;
		emailexists: boolean;
	};
}

const validateEmailFromApi = async (email: string) => {
	try {
		const existEmail = (await Api.validateEmail(email)) as ExistEmailData;

		const { valid, emailexists } = existEmail.data;

		return { valid, emailexists };
	} catch (error) {
		console.error(error);
	}

	return { valid: false, emailexists: false };
};

export default validateEmailFromApi;
