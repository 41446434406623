import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import global from '../../res/global';
import icons from '../../style/icons';
import SensorDrawer from '../sensor/SensorDrawer';
import SpaceDrawer from '../spaces/SpaceDrawer';
import LocationDrawer from '../location/LocationDrawer';
import { buttonStyles } from '../../style/styles';

const useStyles = makeStyles(() => ({
	...buttonStyles
}));

function ScopeLabel(props) {
	const classes = useStyles();
	const { dispatch } = useStoreon();
	const scope = global.scopeOptions.find(opt => opt.value === props.scope);
	const [entityDrawer, setEntityDrawer] = useState();

	useEffect(() => {
		if (props.scopedEntity) {
			if (props.scope === 'sensor') {
				setEntityDrawer(
					<SensorDrawer
						key={props.scopedEntity.uuid}
						sensor={{ ...props.scopedEntity }}
					/>
				);
			} else if (props.scope === 'space') {
				setEntityDrawer(
					<SpaceDrawer
						key={props.scopedEntity.uuid}
						space={{ ...props.scopedEntity }}
					/>
				);
			} else if (props.scope === 'location') {
				setEntityDrawer(
					<LocationDrawer
						key={props.scopedEntity.uuid}
						location={{ ...props.scopedEntity }}
					/>
				);
			}
		}
	}, []);

	const openEntityDrawer = () => {
		if (props.scopedEntity) {
			dispatch('navstate/update', {
				drawerOpen: true,
				drawerContent: entityDrawer
			});
		}
	};

	return (
		<Box display='inline'>
			<Button
				classes={{ root: classes.iconLabeledLinkButton }}
				disableRipple
				onClick={ev => {
					ev.stopPropagation();
					openEntityDrawer();
				}}
				startIcon={
					<Icon
						path={icons[props.scope]}
						size={1}
						aria-label='observed entity details'
					/>
				}
			>
				<Typography variant='body1' noWrap>
					{props.scopedEntity?.name || props.scopedName || 'Unknown'}
				</Typography>
			</Button>
		</Box>
	);
}

export default ScopeLabel;
