import { useEffect } from 'react';
import { useStoreon } from 'storeon/react'; // Use 'storeon/preact' for Preact

import Api from '../api/Api';

const useRoles = () => {
	const { dispatch, roles, user } = useStoreon('roles', 'user');

	const userRole = user.role;

	useEffect(() => {
		if (roles.length) return;

		Api.loadRoles().then(newRoles => {
			dispatch('roles/set', newRoles.data);
		});
	}, [dispatch]);

	return {
		roles,
		userRole,
		isUser: userRole === 'user',
		isSuperAdmin: userRole === 'superadmin',
		isAdmin: userRole === 'admin'
	};
};

export default useRoles;
