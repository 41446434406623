import axios from 'axios';

const AdminApi = {
	listCustomers() {
		try {
			return axios('/admin/api/customer/list', {
				method: 'get',
				withCredentials: true
			});
		} catch (err) {
			return err;
		}
	},
	validateAdmin() {
		try {
			return axios('/admin/api/user/validate', {
				method: 'get',
				withCredentials: true
			});
		} catch (err) {
			return err;
		}
	},
	joinCustomer(customer) {
		try {
			return axios('/admin/api/user/joincustomer', {
				method: 'post',
				withCredentials: true,
				data: { customerId: customer }
			});
		} catch (err) {
			return err;
		}
	},
	addMonitoringToCustomer(customerId, name) {
		try {
			return axios(`/admin/api/customer/${customerId}/addmonitoring`, {
				method: 'post',
				withCredentials: true,
				data: { name }
			});
		} catch (err) {
			return err;
		}
	},
	listModules() {
		try {
			return axios('/admin/api/module', {
				method: 'get',
				withCredentials: true
			});
		} catch (err) {
			return err;
		}
	},
	addModuleToCustomer(customerUuid, moduleUuid) {
		try {
			return axios(
				`/admin/api/customer/assign/module?customer_uuid=${customerUuid}&module_uuid=${moduleUuid}`,
				{
					method: 'post',
					withCredentials: true
				}
			);
		} catch (err) {
			return err;
		}
	}
};

export default AdminApi;
