import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DraggableList from '../DraggableList';
import useStyles from './styles';

const SortableList = ({ onChange, list, label, canDelete, canRename }) => {
	const classes = useStyles();
	const [items, setItems] = useState(list);

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = ({ destination, source }) => {
		// dropped outside the list
		if (!destination) return;

		const newItems = reorder(items, source.index, destination.index);

		setItems(newItems);
		onChange && onChange(newItems);
	};

	const onEdit = async (primary, index) => {
		const newItems = items.slice();
		//if primary comes empty means that is a new element and it was canceled with empty string, we remove this empty item
		if (!primary) {
			newItems.splice(index, 1);
			setItems(newItems);
			onChange && onChange(newItems);
		} else {
			if (items[index]?.uuid) {
				const shouldRename = await canRename(items[index]);
				if (shouldRename) {
					newItems[index] = { ...newItems[index], primary, editing: false };
					setItems(newItems);
					onChange && onChange(newItems);
				}
			} else {
				newItems[index] = { ...newItems[index], primary, editing: false };
				setItems(newItems);
				onChange && onChange(newItems);
			}
		}
	};

	const handleAdd = () => {
		const maxId = items.reduce((acc, elem) => Math.max(acc, elem.id), 0);
		const newItems = [...items, { editing: true, id: maxId + 1, uuid: null }];

		setItems(newItems);
	};

	const handleOnDelete = async index => {
		if (items[index]?.uuid) {
			const shouldDelete = await canDelete(items[index]);
			if (shouldDelete) {
				const newItems = items.slice();
				newItems.splice(index, 1);
				setItems(newItems);
				onChange && onChange(newItems);
			}
		} else {
			const newItems = items.slice();
			newItems.splice(index, 1);
			setItems(newItems);
			onChange && onChange(newItems);
		}
	};

	useEffect(() => {
		if (!items && !!list) {
			setItems(list);
		}
	}, [list]);

	return (
		<Box className={classes.root}>
			<Box className={classes.container}>
				<DraggableList
					items={items}
					onDragEnd={onDragEnd}
					onEdit={onEdit}
					onDelete={handleOnDelete}
					label={label}
				/>
			</Box>
			<Box className={classes.addContainer}>
				<Typography
					variant='body1'
					className={classes.addLabel}
					onClick={handleAdd}
				>
					<AddIcon className={classes.addIcon} /> Add {label}
				</Typography>
			</Box>
		</Box>
	);
};

export default SortableList;
