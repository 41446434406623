import { useEffect } from 'react';

const useScript = (url, callback) => {
	useEffect(() => {
		loadScript(url, callback);
	}, [url]);
};

const loadScript = (url, callback) => {
	const script = document.createElement('script');
	script.src = url;
	script.async = true;
	document.body.appendChild(script);
	if (callback) {
		script.onload = () => {
			callback(true);
		};
	}
	return () => {
		document.body.removeChild(script);
	};
};

export { useScript, loadScript };
