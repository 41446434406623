/* eslint-disable @typescript-eslint/no-magic-numbers */
import { useEffect, useState, useReducer } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import CircularProgress from '@mui/material/CircularProgress';
import TplProductRow from 'components/TplProductRow/TplProductRow';
import Loading from 'legacy/components/common/elements/v2/Loading';
import ConservDialog from 'components/ConservDialog/ConservDialog';
import NumberInput from 'components/NumberInput/NumberInput';
import getProductList from 'services/tpl/getProductList';
import reducerProductsToSend from 'components/ThirdPartyProductsModal/reducerProductsToSend';
import type ShipbobProduct from 'types/ShipbobProduct';
import ThirdPartySendProductsModalSuccess from 'components/ThirdPartyProductsModal/ThirdPartySendProductsModalSuccess';
import type OrderListItem from 'types/OrderListItem';
import ShippingAddressFrom from 'components/ShippingAddressForm/ShippingAddressFrom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import createOrder from 'services/tpl/createOrder';

interface ThirdPartyProductsModalProperties {
	orderListItem: OrderListItem;
	openDialog: boolean;
	onCloseDialog: () => void;
}
const schema = yup.object({
	name: yup.string().trim().required('The name is required'),
	address: yup.object({
		address1: yup.string().required('The address is required'),
		address2: yup.string().nullable(),
		company_name: yup.string().required('The company name is required'),
		city: yup.string().required('The city is required'),
		state: yup.string().required('The state is required'),
		country: yup.string().required('The country is required'),
		zip_code: yup.string().required('The zip code is required')
	}),
	email: yup.string().trim().email().required('The email is required'),
	phone_number: yup.string().trim().required('The phone number is required')
});

export default function ThirdPartyProductsModal({
	orderListItem,
	openDialog,
	onCloseDialog
}: ThirdPartyProductsModalProperties) {
	const [products, setProducts] = useState<ShipbobProduct[] | []>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [sending, setSending] = useState<boolean>(false);
	const [updating, setUpdating] = useState<boolean>(true);
	const [success, setSuccess] = useState<boolean>(false);
	const [tplOrder, setTplOrder] = useState<unknown>();
	const [productsToAdd, dispatch] = useReducer(reducerProductsToSend, []);
	const { register, handleSubmit, formState } = useForm({
		mode: 'onChange',
		resolver: yupResolver(schema)
	});
	const { isValid } = formState;

	useEffect(() => {
		if (!openDialog) return;
		const getTheProductList = async () => {
			const productList = (await getProductList()) as ShipbobProduct[];
			setProducts(productList);
			setLoading(false);
		};
		void getTheProductList();
	}, [openDialog]);

	const onCloseDialogHandler = () => {
		dispatch({ type: 'CLEAR_ITEMS' });
		setUpdating(true);
		setSuccess(true);
		if (success) {
			onCloseDialog();
		}
	};

	const onConfirmDialogHandler = () => {
		const anchor = document.querySelector('#back-to-top-anchor');
		if (anchor) {
			anchor.scrollIntoView({
				block: 'start'
			});
		}

		setUpdating(false);
	};
	const onSubmit = async (data: unknown) => {
		if (isValid) {
			const order = {
				recipient: data,
				products: productsToAdd
			};
			const tplOrderResponse = await createOrder({
				order,
				uuid: orderListItem.uuid
			});
			console.log(tplOrderResponse);
			setTplOrder(tplOrderResponse);
		}
	};

	const onSend = async () => {
		try {
			setSending(true);
			await handleSubmit(onSubmit)();
			setSuccess(true);
		} catch (error) {
			console.error(error);
		}
	};

	const getProductAddedById = (id: number | string) =>
		productsToAdd.find(productToAdd => productToAdd.id === id);

	const onIncreaseProductQuantity = (id: number | string, quantity: number) => {
		if (getProductAddedById(id)) {
			dispatch({ type: 'INCREASE_AMOUNT', payload: id });
		} else {
			dispatch({
				type: 'ADD_ITEM',
				payload: { id, quantity }
			});
		}
	};

	const onDecreaseProductQuantity = (id: number | string) => {
		const productAdded = getProductAddedById(id);
		if (productAdded) {
			const action =
				productAdded.quantity === 1 ? 'REMOVE_ITEM' : 'DECREASE_AMOUNT';
			dispatch({ type: action, payload: id });
		}
	};

	const onChangeProductQuantity = (id: number | string, quantity: number) => {
		if (getProductAddedById(id)) {
			dispatch({ type: 'SET_AMOUNT', payload: { id, quantity } });
		} else {
			dispatch({
				type: 'ADD_ITEM',
				payload: { id, quantity }
			});
		}
	};

	const successComponent = (
		<ThirdPartySendProductsModalSuccess
			onCloseDialog={onCloseDialogHandler}
			tplOrder={tplOrder}
		/>
	);

	const content = (
		<Box id='back-to-top-anchor'>
			{loading ? (
				<Loading text='Loading products information...' />
			) : (
				<>
					<Box mb='20px'>
						<Typography variant='body1'>Order details</Typography>
						{orderListItem.products.map(({ product, quantity }) => (
							<Typography variant='body2' key={product.id} mt='5px'>
								{product.name}: {quantity}
							</Typography>
						))}
					</Box>
					{updating ? (
						<Typography variant='h6' mb='20px'>
							Please select the products to send to 3pl
						</Typography>
					) : (
						''
					)}
					{products.map(({ id, name }) => {
						const productAdded = getProductAddedById(id);
						if (!updating && !productAdded) return '';

						return (
							<Grid key={id} container sx={{ marginBottom: '20px' }}>
								<Grid xs={10}>
									<TplProductRow name={name} />
								</Grid>
								<Grid
									xs={2}
									display='flex'
									justifyContent='center'
									alignItems='center'
								>
									{updating ? (
										<NumberInput
											triggerId={id}
											onIncrease={onIncreaseProductQuantity}
											onDecrease={onDecreaseProductQuantity}
											onChange={onChangeProductQuantity}
											value={productAdded?.quantity ?? 0}
										/>
									) : (
										<Typography variant='body2'>
											{productAdded?.quantity ?? 0}
										</Typography>
									)}
								</Grid>
							</Grid>
						);
					})}
					{updating ? (
						''
					) : (
						// eslint-disable-next-line react/jsx-handler-names
						<form onSubmit={handleSubmit(onSend)}>
							<ShippingAddressFrom register={register} />
						</form>
					)}
				</>
			)}
			<Box>
				{updating ? (
					<Button
						variant='outlined'
						color='primary'
						onClick={onConfirmDialogHandler}
						sx={{ marginTop: '27px', width: '100%' }}
						disabled={productsToAdd.length === 0}
					>
						Continue
					</Button>
				) : (
					<Button
						variant='contained'
						color='primary'
						onClick={onSend}
						sx={{ marginTop: '27px', width: '100%' }}
						disabled={sending || !isValid}
					>
						{sending ? (
							<>
								<CircularProgress size={20} color='inherit' />
								&nbsp; Sending...
							</>
						) : (
							'Confirm and send Order'
						)}
					</Button>
				)}
			</Box>
		</Box>
	);

	let title = '';
	if (!success && updating) {
		title = 'Add products to send to the 3pl';
	} else if (!success && !updating) {
		title = 'Confirm and send order';
	}
	return (
		<ConservDialog
			fullWidth
			title={title}
			isOpen={openDialog}
			onCloseHandler={onCloseDialogHandler}
			showCloseButton
		>
			{success ? successComponent : content}
		</ConservDialog>
	);
}
