import React from 'react';
import { DialogTitle, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '@mdi/react';
import icons from '../../../style/icons';
import { colors } from '../../../style/colors';

const useStyles = makeStyles(theme => ({
	titleText: {
		color: '#FFF'
	},
	simple: {
		height: theme.spacing(2),
		background: colors.elektra.primary,
		color: '#FFF',
		padding: 0
	},
	newInfo: {
		height: theme.spacing(2),
		background: colors.purpleHearth.primary,
		color: '#FFF',
		padding: 0
	},
	info: {
		height: 160,
		background: 'linear-gradient(40deg, #ADADAD 27.2%, #D9D9D9 96.42%);',
		color: '#FFF'
	},
	highlight: {
		height: 160,
		background:
			'linear-gradient(43.65deg, #2CA9DA 27.2%, rgba(44, 169, 218, 0.54) 96.42%)',
		color: '#FFF'
	},
	danger: {
		height: 160,
		background:
			'linear-gradient(40deg, #F15152 27.2%, rgba(241, 81, 82, 0.65) 96.42%)',
		color: '#FFF'
	}
}));

export default function GradientDialogHeader(props) {
	const classes = useStyles(props);

	const variant = props.variant ? props.variant : 'info';

	return (
		<DialogTitle
			disableTypography
			classes={{ root: classes[variant] }}
			id='alert-dialog-title'
		>
			<Box
				display='flex'
				flexDirection='row'
				justifyContent='flex-end'
				width='100%'
			>
				{props.onClose ? (
					<Icon
						style={{ float: 'right' }}
						path={icons.close}
						size={1}
						onClick={props.onClose}
					/>
				) : (
					<Box width='24px' height='24px' />
				)}
			</Box>
			<Box
				pb={3}
				pr={4}
				height='100%'
				display='flex'
				flexDirection='column'
				justifyContent='space-evenly'
			>
				<Box>
					<Typography variant='h1'>{props.title}</Typography>
				</Box>
				{props.subtitle ? (
					<Box>
						<Typography variant='body1'>{props.subtitle}</Typography>
					</Box>
				) : null}
			</Box>
		</DialogTitle>
	);
}
