import React, { memo } from 'react';
import DraggableListItem from './DraggableListItem';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

const DraggableList = memo(({ items, onDragEnd, onEdit, onDelete, label }) => {
	const isValid = (newName, index) => {
		const exist = items.some(
			(item, indx) =>
				item.primary?.trim().toLowerCase() === newName.trim().toLowerCase() &&
				indx !== index
		);
		return {
			valid: !exist,
			errorMessage: exist ? `This ${label} name is already used` : null
		};
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId='droppable-list'>
				{provided => (
					<div ref={provided.innerRef} {...provided.droppableProps}>
						{items.map((item, index) => (
							<DraggableListItem
								item={item}
								index={index}
								key={item.id}
								isValid={isValid}
								onEdit={onEdit}
								onDelete={onDelete}
								label={label}
							/>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
});

export default DraggableList;
