import {
	getFakeSensorReadingCollection,
	SensorReadingFilter,
	SensorReadingMutableCollection
} from '@conserv/mnmx-io-common';
import { SensorReadingProviderService } from './SensorReadingProviderService';
import { getConsoleLogger } from '@conserv/mnmx-io-common';
import { d4l } from '@conserv/mnmx-io-common/dist/log/logUtil';
const LOG = getConsoleLogger('sensorreading.SensorReadingControllerImpl');

export class SensorReadingProviderServiceFakeImpl
	implements SensorReadingProviderService
{
	public async performList(
		sensorReadingFilter: SensorReadingFilter
	): Promise<SensorReadingMutableCollection> {
		LOG.trace(
			`performList(): Entering with sensorReadingFilter = ${d4l(
				sensorReadingFilter
			)}`
		);
		return getFakeSensorReadingCollection(true);
	}
}
