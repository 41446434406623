import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	tableHead: {
		'&, &:after': {
			content: "'@'",
			lineHeight: '13px',
			width: '100%',
			color: 'transparent'
		}
	},
	tableTitle: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '24px',
		whiteSpace: 'nowrap',
		boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.12)',
		height: '56px',
		backgroundColor: '#FFF'
	},
	tableRow: {
		height: '52px',
		cursor: 'pointer',
		'&:hover': {
			backgroundColor: '#EEEEEE'
		}
	},
	tableCell: {
		// borderBottom: 'none',
		maxWidth: '13ch',
		maxHeight: '52px',
		paddingBottom: 0,
		paddingTop: 0
	},
	statusCell: {
		borderBottom: 'none',
		paddingTop: '12px',
		paddingBottom: '12px'
	},
	tableCellTextBold: {
		fontWeight: 700
	},
	eventType: {
		textTransform: 'capitalize'
	},
	activeChip: {
		color: 'black',
		background: theme.palette.warning.light,
		maxHeight: '32px',
		left: 0,
		top: 0,
		border: 0,
		borderRadius: '16px',
		padding: '4px',
		'& .MuiChip-label': {
			fontFamily: 'Roboto',
			fontSize: '13px',
			lineHeight: '18px',
			paddingBottom: '7px',
			paddingTop: '7px',
			paddingLeft: 0,
			paddingRight: 0
		}
	}
}));
