import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	content: {
		paddingTop: 0,
		paddingBottom: '59px',
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4)
	},
	icon: {
		fontSize: '48px'
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '29px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0em',
		textAlign: 'center'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '22px',
		paddingLeft: '22px',
		paddingTop: '8px',
		paddingBottom: '8px',
		background: theme.palette.primary.main
	}
}));
