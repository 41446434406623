import * as yup from 'yup';

const getNameSchema = restrictedNames => ({
	name: yup
		.string()
		.trim()
		.required('An event name is required')
		.test(
			'is-not-repeated',
			'This name is already used in other event',
			name => {
				const isValid = !restrictedNames.some(
					restrictedName => restrictedName === name
				);
				return isValid;
			}
		)
});

export default getNameSchema;
