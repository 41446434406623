import type React from 'react';
import { styled } from '@mui/material/styles';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import type TreeNode from '../../types/TreeNode';
import Highlighter from 'react-highlight-words';
import { Box, Typography } from '@material-ui/core';

export interface TreeMultiselectNodeProperties {
	node: TreeNode;
	isSelected: (node: TreeNode) => boolean;
	isIndeterminated: (node: TreeNode) => boolean;
	onChangeCheckbox: (node: TreeNode, checked: boolean) => void;
	shouldShow: (node: TreeNode) => boolean;
	highlightedText: string;
	disabled?: boolean;
}

function onCheckboxClick(event: React.MouseEvent<HTMLElement>) {
	event.stopPropagation();
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
	[`& .${treeItemClasses.content}`]: {
		paddingLeft: '14px',
		flexDirection: 'row-reverse'
	}
}));

const SecondaryLabel = styled(Typography)(() => ({
	position: 'absolute',
	top: '28px',
	fontStyle: 'italic',
	fontVariant: 'caption'
}));

export default function TreeMultiselectNode({
	node,
	isSelected,
	isIndeterminated,
	onChangeCheckbox,
	shouldShow,
	highlightedText,
	disabled
}: TreeMultiselectNodeProperties): JSX.Element | null {
	const { id, label, secondaryLabel, children, hideCheckbox } = node;

	function onChangeCheckboxHandler(event: React.ChangeEvent<HTMLInputElement>) {
		event.stopPropagation();
		const { checked } = event.currentTarget;
		onChangeCheckbox(node, checked);
	}

	if (!shouldShow(node)) {
		return null;
	}

	return (
		<StyledTreeItem
			key={id}
			nodeId={id}
			label={
				<FormControlLabel
					control={
						hideCheckbox ? (
							<span style={{ width: 24, height: 42 }} />
						) : (
							<Checkbox
								color='primary'
								indeterminate={isIndeterminated(node)}
								checked={isSelected(node)}
								onChange={event => onChangeCheckboxHandler(event)}
								onClick={onCheckboxClick}
								data-testid={`tree-multiselect-node-checkbox-${id}`}
								disabled={disabled}
							/>
						)
					}
					label={
						<Box display='flex' flexDirection='column'>
							<Typography variant='body1'>
								<Highlighter
									data-testid={`tree-multiselect-node-${id}`}
									textToHighlight={label}
									searchWords={[highlightedText]}
									highlightStyle={{ backgroundColor: '#ffc107', padding: 0 }}
								/>
							</Typography>
							<SecondaryLabel variant='caption'>
								<Highlighter
									data-testid={`tree-multiselect-node-secondary-${id}`}
									textToHighlight={secondaryLabel ?? ''}
									searchWords={[highlightedText]}
									highlightStyle={{ backgroundColor: '#ffc107', padding: 0 }}
								/>
							</SecondaryLabel>
						</Box>
					}
					key={id}
				/>
			}
		>
			{children?.map((currentNode: TreeNode) => (
				<TreeMultiselectNode
					key={currentNode.id}
					node={currentNode}
					isSelected={isSelected}
					isIndeterminated={isIndeterminated}
					onChangeCheckbox={onChangeCheckbox}
					shouldShow={shouldShow}
					highlightedText={highlightedText}
					disabled={disabled}
				/>
			))}
		</StyledTreeItem>
	);
}

TreeMultiselectNode.defaultProps = {
	disabled: false
};
