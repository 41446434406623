import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Box,
	Typography
} from '@material-ui/core';
import { ReviewForm } from '../../Forms';
import useStyles from './styles';

function EventProfileConfirmationDialog({
	eventProfile,
	open,
	onDiscard,
	onSave,
	onGoBack
}) {
	const classes = useStyles();

	return (
		<Dialog open={open} classes={{ paper: classes.paper }} onClose={onGoBack}>
			<DialogTitle
				disableTypography
				className={classes.header}
				id='alert-dialog-title'
			/>
			<DialogContent classes={{ root: classes.content }}>
				<Box mt='18px' display='flex' justifyContent='center'>
					<Typography className={classes.title} variant='h1'>
						Review & confirm changes
					</Typography>
				</Box>
				<Box mt={3} display='flex' justifyContent='center'>
					<Typography className={classes.text}>
						You are updating <b>{eventProfile.name}</b> event. Does everything
						look correct?
					</Typography>
				</Box>
				<Box mt={3} className={classes.greyBox}>
					<ReviewForm />
				</Box>
				<Box mt={3} display='flex' justifyContent='space-between'>
					<Box display='flex' justifyContent='flex-start'>
						<Button
							className={(classes.button, classes.discardButton)}
							id='complete_profile'
							onClick={e => onDiscard()}
							variant='text'
						>
							Discard changes
						</Button>
					</Box>

					<Box display='flex' justifyContent='flex-end' style={{ gap: '18px' }}>
						<Button
							className={(classes.button, classes.continueButton)}
							id='complete_profile'
							onClick={onGoBack}
							variant='outlined'
							color='primary'
						>
							Continue editing
						</Button>
						<Button
							className={(classes.button, classes.saveButton)}
							id='complete_profile'
							onClick={onSave}
							variant='contained'
							color='primary'
						>
							Save changes
						</Button>
					</Box>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default EventProfileConfirmationDialog;
