import React from 'react';
import { useStoreon } from 'storeon/react';
import { Box } from '@material-ui/core';
import { Icon } from '@mdi/react';
import icons from '../../style/icons';
import PublicIcon from '@material-ui/icons/Public';

function EventScope({ eventProfile: { eventprofilescopes, scope } }) {
	const { locations, spaces, sensors } = useStoreon(
		'locations',
		'spaces',
		'sensors'
	);

	const getScopeLabel = () => {
		if (scope?.type === 'global') {
			return 'Everywhere';
		}
		if (!Array.isArray(eventprofilescopes)) {
			return;
		}

		if (eventprofilescopes.length > 1) {
			return `Multiple ${scope.type}s`;
		}
		if (eventprofilescopes.length === 0) {
			return 'All';
		}
		const entityTypeList = {
			sensor: sensors.list,
			space: spaces.list,
			location: locations.list
		};
		const singleEntity = entityTypeList[scope.type].find(
			entity => entity.uuid === eventprofilescopes[0].scopeduuid
		);
		return singleEntity?.name || ' Unknown';
	};

	const icon = icons[scope.type] ? (
		<Icon path={icons[scope.type]} size={1} />
	) : (
		<PublicIcon size={1} />
	);

	return (
		<>
			<Box display='inline' pr={1}>
				{icon}
			</Box>
			<Box display='inline'>{getScopeLabel()}</Box>
		</>
	);
}

export default EventScope;
