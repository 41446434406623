import React from 'react';
import { DialogContent, Button, Box, Typography } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import useStyles from './styles';

function NewEvent({ onContinue }) {
	const classes = useStyles();

	return (
		<DialogContent classes={{ root: classes.content }}>
			<Box display='flex' justifyContent='center' mt='43px'>
				<AccessTimeIcon className={classes.icon} />
			</Box>
			<Box mt={2} display='flex' justifyContent='center'>
				<Typography className={classes.title} variant='h1'>
					New Event
				</Typography>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Typography className={classes.text}>
					Events are a great way to track changes in a space or location. You
					can subscribe yourself or others to be alerted when the event occurs.
				</Typography>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Typography className={classes.text}>
					Here’s an example: “Trigger the event when the relative humidity is
					above 80% for 20 minutes.”{' '}
				</Typography>
			</Box>
			<Box mt={3} display='flex' justifyContent='center'>
				<Button
					className={classes.button}
					id='complete_profile'
					onClick={onContinue}
					variant='contained'
					color='primary'
				>
					continue
				</Button>
			</Box>
		</DialogContent>
	);
}

export default NewEvent;
