import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import CircularProgressWithLabel from '../../common/elements/CircularProgressWithLabel';
import Api from '../../../api/Api';

function ImportingStep(props) {
	const [importProgress, setImportProgress] = useState({
		percent: 1,
		phaseLabel: 'Starting up ...'
	});
	const [, setIntervalId] = useState();

	useEffect(() => {
		const intervalId = setInterval(() => checkImportStatus(intervalId), 3000);
		setIntervalId(intervalId);
		Api.startV2Import(props.importConfig.groupUuid)
			.then(() => {
				setImportProgress({ percent: 5, phaseLabel: 'Starting up ...' });
			})
			.catch(err => {
				props.onError({
					fatal: false,
					message:
						'There was an error processing this import file, please contact Conserv support.'
				});
				clearInterval(intervalId);
			});
		return () => clearInterval(intervalId);
	}, []);

	const checkImportStatus = intervalId => {
		Api.getImportStatus(props.importConfig.groupUuid)
			.then(res => {
				const { phase } = res.data;
				if (phase.startsWith('error:')) {
					clearInterval(intervalId);
					props.onError({
						fatal: false,
						message:
							'There was an error processing this import, please check your data format or contact support'
					});
				} else {
					switch (phase) {
						case 'status:processing':
							setImportProgress({
								percent: 10,
								phaseLabel: 'Processing files ...'
							});
							return;
						case 'status:processed':
							setImportProgress({
								percent: 50,
								phaseLabel: 'Files processed ...'
							});
							return;
						case 'status:createdsensors':
							setImportProgress({
								percent: 55,
								phaseLabel: 'Creating sets ...'
							});
							return;
						case 'status:merging':
							setImportProgress({
								percent: 70,
								phaseLabel: 'Merging data ...'
							});
							return;
						case 'status:merg':
							setImportProgress({
								percent: 85,
								phaseLabel: 'Merge complete ...'
							});
							return;
						case 'status:complete':
							Api.getImportResult(props.importConfig.groupUuid)
								.then(res => {
									setImportProgress({
										percent: 100,
										phaseLabel: 'Import complete!'
									});
									clearInterval(intervalId);
									const newConfig = { ...props.importConfig };
									newConfig.phase = 'complete';
									newConfig.importResult = res.data.result;
									props.onUpdateImportConfig(newConfig);
								})
								.catch(() => {
									clearInterval(intervalId);
									props.onError({
										fatal: false,
										message: 'There was an error checking the import results.'
									});
								});
						default:
					}
				}
			})
			.catch(() => {
				clearInterval(intervalId);
				props.onError({
					fatal: false,
					message: 'There was an error checking the import status.'
				});
			});
	};

	return (
		<Box
			mt={4}
			width='100%'
			minHeight='120px'
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
		>
			<CircularProgressWithLabel
				size={80}
				variant='determinate'
				value={importProgress.percent}
			/>
			<Box pt={2}>
				<Typography variant='h5'>{importProgress.phaseLabel}</Typography>
			</Box>
		</Box>
	);
}

export default ImportingStep;
