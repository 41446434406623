import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import Sensor from 'types/Sensor';

export default function useCustomerSensors() {
	const [sensors, setSensors] = useState<Sensor[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		const abortController = new AbortController();
		let isCancelled = false;

		const fetchCustomerSensors = async () => {
			try {
				setLoading(true);
				const { data, status } = await axios.get<Sensor[]>(
					`/api/customer/sensors?all=true`,
					{ signal: abortController.signal }
				);
				if (status !== 200) {
					throw new Error(`Failed to fetch sensors, status code: ${status}`);
				}
				if (!isCancelled) {
					setSensors(data);
				}
			} catch (error_) {
				if (!axios.isCancel(error_) && !isCancelled) {
					Sentry.captureException(error_);
					setError('Error fetching sensors');
				}
			} finally {
				if (!isCancelled) {
					setLoading(false);
				}
			}
		};

		void fetchCustomerSensors();

		return () => {
			isCancelled = true;
			abortController.abort();
		};
	}, []);

	const findSensorByUuid = useCallback((uuid: string): Sensor | undefined => 
		sensors.find(sensor => sensor.uuid === uuid)
	, [sensors]);

	return { sensors, loading, error, findSensorByUuid };
}
