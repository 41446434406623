import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	IconButton,
	Box,
	Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import useStyles from './styles';

function ConfirmationDialogFloorHvacZone({
	open,
	onCancel,
	onAccept,
	title,
	text,
	list,
	acceptButtonLabel,
	cancelButtonLabel
}) {
	const classes = useStyles();

	return (
		<Dialog open={open} onClose={onCancel}>
			<DialogTitle
				disableTypography
				className={classes.header}
				id='alert-dialog-title'
			/>

			<DialogContent classes={{ root: classes.content }}>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onCancel}
				>
					<CloseIcon />
				</IconButton>
				<Box display='flex' justifyContent='center' paddingTop={3}>
					<WarningIcon className={classes.icon} />
				</Box>
				<Box mt={3} display='flex' justifyContent='center'>
					<Typography className={classes.title} variant='h1'>
						{title}
					</Typography>
				</Box>
				<Box mt={3} pl={4} pr={4} display='flex' justifyContent='center'>
					<Typography className={classes.text}>{text}</Typography>
				</Box>
				<Box mt={1} pl={4} pr={4} display='flex'>
					<Typography className={classes.text}>{list}</Typography>
				</Box>
				<Box mt={3} display='flex' justifyContent='center'>
					<Button
						id='complete_profile'
						onClick={e => onCancel()}
						variant='outlined'
					>
						{cancelButtonLabel}
					</Button>
					<Button
						className={classes.button}
						id='complete_profile'
						onClick={e => onAccept()}
						variant='contained'
						color='primary'
					>
						{acceptButtonLabel}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default ConfirmationDialogFloorHvacZone;
