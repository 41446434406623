import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	footer: {
		bottom: 0,
		position: 'sticky'
	},
	history: {
		height: 'calc(100% - 140px)',
		overflow: 'scroll'
	}
}));
