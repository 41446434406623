/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import type { FormInputProperties } from '../../types/FormInputProperties';

const INPUT_MARGIN = 1;

export default function FormInputText({
	name,
	...inputProperties
}: FormInputProperties) {
	const { control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<TextField
					{...inputProperties}
					{...field}
					id={field.name}
					error={Boolean(error)}
					helperText={error?.message}
					inputProps={{ height: '56px' }}
					sx={{ m: INPUT_MARGIN, width: '100%' }}
				/>
			)}
		/>
	);
}
