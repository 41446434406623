/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-console */
import axios from 'axios';

const updatePassword = async ({
	userId,
	password
}: {
	userId: number;
	password: string;
}) => {
	try {
		const { data } = await axios.post(`/api/v2/user/setPassword`, {
			id: userId,
			idType: 'numeric',
			password
		});

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return data;
	} catch (error) {
		console.error('Error saving the user password:', error);
	}
	return [];
};

export default updatePassword;
