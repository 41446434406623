import React, { Component } from 'react';
import SegmentUtil from '../../../util/SegmentUtil';
import './Actions.css';
import Icon from '@mdi/react';
import icons from '../../../style/icons';

export default class InviteWelcome extends Component {
	componentDidMount() {
		if (this.props.user) {
			SegmentUtil.track(SegmentUtil.actions.signedup, {
				category: 'app',
				email: this.props.user.email,
				full_name: `${this.props.user.first_name} ${this.props.user.last_name}`,
				createdAt: new Date()
			});
		}
	}

	render() {
		return (
			<div>
				<div className='action-title'>
					<h2>Welcome to Conserv Cloud</h2>
					<h5>
						Thanks for accepting your invitation to join your colleagues on
						Conserv! Here are a few things you might want to check out:
					</h5>
				</div>
				<div className='row'>
					<div
						className='column link-panel'
						onClick={() => (window.location = '/settings')}
					>
						<div>
							<Icon size={2} path={icons.account} />
						</div>
						<div className='step-description'>Complete your profile</div>
					</div>
					<div
						className='column link-panel'
						onClick={() => (window.location = '/import/start')}
					>
						<div>
							<Icon size={2} path={icons.upload} />
						</div>
						<div className='step-description'>Upload some existing data</div>
					</div>
					<div
						className='column link-panel'
						onClick={() => this.props.callback('complete')}
					>
						<div>
							<Icon size={2} path={icons.gauge} />
						</div>
						<div className='step-description'>Take me to the dashboard!</div>
					</div>
				</div>
			</div>
		);
	}
}
