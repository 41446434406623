/* eslint-disable react/sort-default-props */

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

interface ProductRowProperties {
	imagePath: string;
	title: string;
	description: string;
	showPrice?: boolean;
	price?: number;
}

const GRID_SPACING_VALUE = 2;

export default function ProductRow({
	imagePath,
	title,
	description,
	showPrice,
	price
}: ProductRowProperties) {
	return (
		<Grid
			container
			columnSpacing={{
				xs: GRID_SPACING_VALUE
			}}
		>
			<Grid item>
				<Box>
					<img alt={title} src={imagePath} />
				</Box>
			</Grid>
			<Grid item xs={12} sm>
				<Typography
					variant='body2'
					sx={{
						color: 'rgba(0, 0, 0, 0.87)'
					}}
				>
					{title}{' '}
					{showPrice ? (
						<Typography
							variant='body3'
							component='span'
							sx={{
								color: 'rgba(0, 0, 0, 0.87)',
								fontWeight: '600',
								marginLeft: '16px'
							}}
						>
							${price}
						</Typography>
					) : undefined}
				</Typography>
				<Typography
					variant='body3'
					component='div'
					sx={{
						color: 'rgba(0, 0, 0, 0.6)'
					}}
				>
					{description}
				</Typography>
			</Grid>
		</Grid>
	);
}

ProductRow.defaultProps = {
	price: 0,
	showPrice: false
};
