import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import {
	Box,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Typography,
	IconButton,
	Menu,
	MenuItem
} from '@material-ui/core';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Icon } from '@mdi/react';
import AdminApi from '../../../api/AdminApi';
import AddMonitoringDialog from '../../../components/admin/AddMonitoringDialog';
import icons from '../../../style/icons';
import { collapsibleTableStyles, buttonStyles } from '../../../style/styles';
import useStyles from './styles';

dayjs.extend(localizedFormat);

const styles = () => ({
	...collapsibleTableStyles,
	...buttonStyles
});

function AdminTableMenu({ customer, modules }) {
	const { dispatch, subscription } = useStoreon('subscription');
	const history = useHistory();
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);

	const joinCustomer = targetCustomer => {
		AdminApi.joinCustomer(targetCustomer.id)
			.then(() => {
				dispatch('notification/add', {
					message: `Joined customer: ${targetCustomer.name}`,
					severity: 'success',
					variant: 'banner'
				});
			})
			.catch(() => {
				dispatch('notification/add', {
					message: `Failed to join customer: ${customer.name}`,
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	const addMonitoring = () => {
		dispatch('navstate/update', {
			dialogOpen: true,
			dialogContent: <AddMonitoringDialog customer={customer} />
		});
	};

	const addModule = (customer, module) => {
		AdminApi.addModuleToCustomer(customer.uuid, module.uuid)
			.then(() => {
				dispatch('notification/add', {
					message: 'Added module to customer',
					severity: 'success',
					variant: 'banner'
				});
			})
			.catch(() => {
				dispatch('notification/add', {
					message: `Failed to add module to customer: ${customer.name}`,
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	return (
		<>
			<IconButton
				size='small'
				disableRipple
				style={{ justifyContent: 'flex-end' }}
				classes={{ root: classes.iconLabeledLinkButton }}
				onClick={event => setAnchorEl(event.currentTarget)}
			>
				<Icon
					classes={{ root: classes.iconLabeledLinkButton }}
					size={1}
					style={{ minWidth: '24px' }}
					path={icons.more}
					aria-label='more options'
				/>
			</IconButton>
			<Menu
				key={`customermenu-${customer.id}`}
				id='simple-menu'
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				elevation={1}
				transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<MenuItem
					onClick={() => {
						joinCustomer(customer);
						setAnchorEl(null);
					}}
				>
					Join Customer
				</MenuItem>
				<MenuItem
					onClick={() => {
						addMonitoring(customer);
						setAnchorEl(null);
					}}
				>
					Add Monitoring
				</MenuItem>
				{modules.map(module => (
					<MenuItem
						key={module.uuid}
						onClick={() => {
							addModule(customer, module);
						}}
					>
						Add {module.slug}
					</MenuItem>
				))}
			</Menu>
		</>
	);
}

export default AdminTableMenu;
