import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../../style/colors';

export default makeStyles(theme => ({
	header: {
		height: theme.spacing(2),
		background: colors.amaranth.primary,
		color: '#FFF',
		padding: 0
	},
	icon: {
		color: colors.amaranth.primary,
		fontSize: '46px'
	},
	content: {
		paddingTop: 0,
		paddingBottom: theme.spacing(5),
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4)
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '29px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0em',
		maxWidth: '341px'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '22px',
		paddingLeft: '22px',
		paddingTop: '8px',
		paddingBottom: '8px',
		// background: colors.amaranth.primary,
		marginLeft: theme.spacing(3)
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(2)
	}
}));
