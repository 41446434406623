import React from 'react';
import { Box, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cardStyles } from '../../../style/styles';

const useStyles = makeStyles({
	...cardStyles
});

function FileTypeStep(props) {
	const classes = useStyles();

	const setFileType = type => {
		const newConfig = { ...props.importConfig };
		newConfig.fileType = type;
		props.onUpdateImportConfig(newConfig);
	};

	return (
		<Box pt={4}>
			<Box>
				<Typography variant='h3'>Choose your sensor type</Typography>
				<Typography variant='body1'>
					We support data from many different loggers and building management
					systems
				</Typography>
			</Box>
			<Box pt={2} display='flex' flexDirection='row'>
				<Card
					onClick={() => setFileType('hobowaresingle')}
					classes={{
						root:
							props.importConfig.fileType === 'hobowaresingle'
								? classes.activeImportOptionCard
								: classes.importOptionCard
					}}
				>
					<Typography variant='h4'>OnSet</Typography>
					<Typography variant='h6'>HOBO .csv</Typography>
				</Card>
				<Card
					onClick={() => setFileType('pem2')}
					classes={{
						root:
							props.importConfig.fileType === 'pem2'
								? classes.activeImportOptionCard
								: classes.importOptionCard
					}}
				>
					<Typography variant='h4'>IPI</Typography>
					<Typography variant='h6'>PEM/PEM2 .csv .pm2</Typography>
				</Card>
				<Card
					onClick={() => setFileType('csv')}
					classes={{
						root:
							props.importConfig.fileType === 'csv'
								? classes.activeImportOptionCard
								: classes.importOptionCard
					}}
				>
					<Typography variant='h4'>Other Loggers</Typography>
					<Typography variant='h6'>.csv</Typography>
				</Card>
			</Box>
		</Box>
	);
}

export default FileTypeStep;
