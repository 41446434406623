import { colors } from './colors';
import { createTheme } from '@material-ui/core';

const theme = createTheme({
	palette: {
		primary: {
			main: colors.v2.primary,
			dark: '#4800B3'
		},
		secondary: {
			main: '#2CA9DA'
		},
		success: {
			main: '#4CAF50'
		},
		error: {
			main: '#F44336'
		},
		warning: {
			main: '#ED6C02',
			light: '#FFB547'
		}
	},
	typography: {
		h2: {
			fontFamily: 'Montserrat',
			fontWeight: 'bold',
			fontSize: '24px',
			lineHeight: '32px'
		},
		h3: {
			fontFamily: 'Montserrat',
			fontWeight: 'bold',
			fontSize: '20px',
			lineHeight: '24px'
		},
		body1: {
			fontFamily: 'Roboto',
			fontSize: '16px',
			fontWeight: 400,
			lineHeight: '24px'
		},
		body2: {
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: '20px'
		}
	},
	overrides: {
		MuiPickerDTTabs: {
			tabs: {
				backgroundColor: colors.white.primary,
				color: colors.black.mediumOpacity
			}
		},
		MuiTab: {
			root: {
				color: 'rgba(0, 0, 0, 0.6)',
				fontFamily: 'Roboto',
				fontSize: '14px',
				fontWeight: 400,
				lineHeight: '24px',
				letterSpacing: '0.4px',
				'&.Mui-selected': {
					color: 'rgba(0, 0, 0, 0.87)',
					fontWeight: 500
				}
			},
			textColorInherit: {
				'&:focus': {
					outline: 'none'
				}
			}
		},
		MuiFormControl: {
			root: {
				width: '100%'
			}
		},
		MuiInputBase: {
			root: {
				minHeight: '56px'
			}
		},
		MuiOutlinedInput: {
			root: {
				fontSize: '16px',
				focused: {
					borderColor: colors.v2.primary
				},
				'&$focused $notchedOutline': {
					borderColor: colors.v2.primary,
					borderWidth: 2
				},
				width: '100%'
			},
			input: {
				padding: 9,
				fontSize: '16px',
				focused: {
					borderColor: colors.v2.primary
				},
				'&$focused': {
					borderColor: colors.v2.primary
				}
			},
			colorSecondary: {
				backgroundColor: '#fff',
				border: 0,
				'&$focused': {
					border: '2 !important'
				}
			},
			notchedOutline: {
				'&$focused': {
					borderColor: colors.ice.primary,
					color: 'tomato',
					fontWeight: 'bold',
					Mui: {
						focused: {
							borderColor: colors.v2.primary
						}
					}
				}
			}
		},
		MuiSelect: {
			root: {
				height: '56px',
				width: '100%'
			}
		},
		MuiTooltip: {
			tooltip: {
				fontFamily: 'Roboto',
				fontSize: '14px',
				fontWeight: 400
			}
		},
		MuiButton: {
			root: {
				'&:focus': {
					outline: 'none'
				}
			}
		},
		MuiIconButton: {
			root: {
				'&:focus': {
					outline: 'none'
				}
			}
		}
	}
});

export default theme;
