import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import SegmentUtil from '../../../util/SegmentUtil';
import './Actions.css';

export default class SwitchAccount extends Component {
	componentDidMount() {
		SegmentUtil.track(SegmentUtil.actions.multiaccountinviteaccepted, {
			category: 'app',
			email: this.props.user.email,
			full_name: `${this.props.user.first_name} ${this.props.user.last_name}`,
			createdAt: new Date()
		});
	}

	render() {
		return (
			<div>
				<div className='action-title'>
					<h2>Welcome to Your New Team!</h2>
					<h5>Thanks for accepting your invitation to join a Conserv team!</h5>
				</div>
				<div className='row'>
					<p>
						You now have access to more than one Conserv account! Feels good to
						be popular, doesn't it? You can easily switch between account teams
						by selecting the one you want to use from the main menu. If you get
						invited to join more teams, you'll always find them there.
					</p>
				</div>
				<div>
					<Button
						variant='contained'
						color='primary'
						onClick={() => this.props.callback('complete')}
					>
						Got it!
					</Button>
				</div>
			</div>
		);
	}
}
