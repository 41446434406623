import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	paper: {
		width: isInitialModal => (isInitialModal ? '576px' : '654px'),
		maxWidth: isInitialModal => (isInitialModal ? '576px' : '654px')
	},
	successPaper: {
		width: '582px'
	},
	stepper: {
		height: '100%',
		padding: '20px 48px'
	}
}));
