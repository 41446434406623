// eslint-disable-next-line filenames/match-exported
import React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import { theme } from './styles';

function CustomStepper({
	activeStep,
	completedSteps,
	onStepClick,
	steps = []
}) {
	return (
		<ThemeProvider theme={theme}>
			<Stepper activeStep={activeStep} nonLinear>
				{steps.map((label, index) => (
					<Step
						completed={completedSteps[index]}
						key={label}
						onClick={() => onStepClick(index)}
					>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</Stepper>
		</ThemeProvider>
	);
}

export default CustomStepper;
