import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../style/colors';

export default makeStyles(theme => ({
	errorContainer: {
		background: colors.pomegranate.primary,
		height: theme.spacing(6),
		borderRadius: '4px',
		padding: theme.spacing(2)
	},
	errorMessage: {
		color: colors.cherrywood.primary
	},
	errorIcon: {
		color: colors.amaranth.primary,
		marginRight: theme.spacing(2)
	}
}));
