/* eslint-disable @typescript-eslint/no-type-alias */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type ProductAddedToSend3pl from 'types/ProductAddedToSend3pl';

interface AddItemAction {
	type: 'ADD_ITEM';
	payload: ProductAddedToSend3pl;
}

interface RemoveItemAction {
	type: 'REMOVE_ITEM';
	payload: number | string;
}

interface IncreaseAmountAction {
	type: 'INCREASE_AMOUNT';
	payload: number | string;
}

interface DecreaseAmountAction {
	type: 'DECREASE_AMOUNT';
	payload: number | string;
}

interface ClearItemsAction {
	type: 'CLEAR_ITEMS';
}

interface SetAmountAction {
	type: 'SET_AMOUNT';
	payload: ProductAddedToSend3pl;
}

type Action =
	| AddItemAction
	| ClearItemsAction
	| DecreaseAmountAction
	| IncreaseAmountAction
	| RemoveItemAction
	| SetAmountAction;

export default function reducerProductsToSend(
	state: ProductAddedToSend3pl[] | [],
	action: Action
) {
	switch (action.type) {
		case 'ADD_ITEM': {
			return [...state, action.payload];
		}
		case 'CLEAR_ITEMS': {
			return [];
		}
		case 'DECREASE_AMOUNT': {
			return state.map(item =>
				item.id === action.payload
					? { ...item, quantity: item.quantity - 1 }
					: item
			);
		}
		case 'INCREASE_AMOUNT': {
			return state.map(item =>
				item.id === action.payload
					? { ...item, quantity: item.quantity + 1 }
					: item
			);
		}
		case 'REMOVE_ITEM': {
			return state.filter(item => item.id !== action.payload);
		}
		case 'SET_AMOUNT': {
			return state.map(item =>
				item.id === action.payload.id
					? { ...item, quantity: action.payload.quantity }
					: item
			);
		}
		default: {
			return state;
		}
	}
}
