/* TODO:  At some point we'll need a combination of sensor type and label, so we can have per-sensor labels
 and overide existing labels for sensors with generalized outputs like "digital_out", which on a Laird temp 
 sensor happens to be the battery reading when sending Cayenne LPP packets
*/
import icons from '../style/icons';

const datalabels = {
	// must keep
	getLabelText(name, minimal = false) {
		const label = this.labels[name];
		if (label) {
			return minimal ? label.minLabel : label.label;
		}
		return name;
	},

	// replace with a call to get material icons.
	getLabelIcon(name) {
		const label = this.labels[name];
		if (label) {
			return label.icon;
		}
		return '';
	},

	// must keep
	getLabelUnits(name, minimal = false) {
		const label = this.labels[name];
		if (label) {
			return minimal ? label.minUnit : label.unit;
		}
		return '';
	},

	// only used in old graph tooltip
	getAnalysisLabel(name) {
		const label = this.analysislabels[name];
		if (label) {
			return label.label;
		}
		return name;
	},

	labels: {
		temp: {
			minLabel: 'Temp',
			label: 'Temperature',
			minUnit: '°C',
			unit: 'Celsius',
			icon: icons.temp
		},
		temp1: {
			minLabel: 'Temp',
			label: 'Temperature',
			minUnit: '°C',
			unit: 'Celsius',
			icon: icons.temp
		},
		temp2: {
			minLabel: 'Temp',
			label: 'Temperature',
			minUnit: '°C',
			unit: 'Celsius',
			icon: icons.temp
		},
		// kelvin: {
		//     minLabel: 'Temp',
		//     label: 'Temperature',
		//     minUnit: 'K',
		//     unit: 'Kelvin',
		//     icon: 'thermometer-half'
		// },
		celsius: {
			minLabel: 'Temp',
			label: 'Temperature',
			minUnit: '°C',
			unit: 'Celsius',
			icon: icons.temp
		},
		fahrenheit: {
			minLabel: 'Temp',
			label: 'Temperature',
			minUnit: '°F',
			unit: 'Fahrenheit',
			icon: icons.temp
		},
		temperature: {
			minLabel: 'Temp',
			label: 'Temperature',
			unit: 'Celsius',
			icon: icons.temp
		},
		dewpoint: {
			label: 'Dew Point',
			minLabel: 'Dew Point',
			unit: 'Celsius',
			minUnit: '°C',
			icon: icons.temp
		},
		humidity: {
			minLabel: 'RH',
			unit: '%',
			minUnit: '%',
			label: 'Relative Humidity',
			icon: icons.rh
		},
		rhpercent: {
			minLabel: 'RH',
			label: 'Relative Humidity',
			unit: '%',
			minUnit: '%',
			icon: icons.rh
		},
		rh: {
			minLabel: 'RH',
			label: 'Relative Humidity',
			unit: '%',
			minUnit: '%',
			icon: icons.rh
		},
		battery: {
			label: 'Battery',
			unit: '%',
			icon: icons.bat
		},
		bat: {
			minLabel: 'Bat',
			label: 'Battery',
			unit: '%',
			minUnit: '%',
			icon: icons.bat
		},
		amin: {
			label: 'Volume Level (min)',
			unit: 'Relative',
			icon: 'volume-down'
		},
		amax: {
			label: 'Volume Level (max)',
			unit: 'Relative',
			icon: 'volume-up'
		},
		vis: {
			minLabel: 'Lx',
			label: 'Illuminance',
			unit: 'Lx',
			minUnit: 'Lx',
			icon: icons.vis
		},
		time: {
			minLabel: 'H',
			label: 'Light Hours',
			unit: 'H',
			minUnit: 'H',
			icon: icons.lightHours
		},
		lux: {
			minLabel: 'lx',
			label: 'Illuminance',
			unit: 'Lux',
			minUnit: 'lx',
			icon: icons.vis
		},
		footcandle: {
			minLabel: 'fc',
			label: 'Foot-candle',
			unit: 'Foot-candle',
			minUnit: 'fc'
		},
		feet: {
			minLabel: 'ft',
			label: 'Feet',
			unit: 'Feet',
			minUnit: 'ft'
		},
		meter: {
			minLabel: 'm',
			label: 'Meter',
			unit: 'Meter',
			minUnit: 'm'
		},
		lightHours: {
			minLabel: 'h',
			label: 'Hours',
			unit: 'Hours',
			minUnit: 'h'
		},
		ir: {
			label: 'Infrared',
			unit: 'Relative',
			icon: icons.ir
		},
		co2: {
			label: 'CO2',
			unit: 'PPM'
		},
		minco2: {
			label: 'Minimum CO2',
			unit: 'PPM'
		},
		accuracy: {
			label: 'Accuracy',
			unit: 'Absolute',
			icon: 'balance-scale'
		},
		uv1: {
			minLabel: 'UV Index',
			label: 'UV Index',
			unit: 'IDX',
			minUnit: 'IDX',
			icon: icons.ultraviolet
		},
		mres: {
			label: 'Soil Moisture (Resistance)',
			unit: 'Relative',
			icon: 'water'
		},
		mcap: {
			label: 'Soil Moisture (Capacitive)',
			unit: 'Relative',
			icon: 'water'
		},
		pm1: {
			label: '<= 1 Micron Particles',
			unit: 'ug/m^3',
			icon: 'smog'
		},
		pm2_5: {
			label: '<= 2.5 Micron Particles',
			unit: 'ug/m^3',
			icon: 'smog'
		},
		pm2point5: {
			label: '<= 2.5 Micron Particles',
			unit: 'ug/m^3',
			icon: 'smog'
		},
		pm10: {
			label: '<= 10 Micron Particles',
			unit: 'ug/m^3',
			icon: 'smog'
		},
		rssi: {
			label: 'Signal Strength',
			unit: 'DB',
			icon: 'signal'
		},
		snr: {
			label: 'Signal/Noise',
			unit: 'Ratio',
			icon: 'broadcast-tower'
		},
		digital_out: {
			label: 'Battery',
			unit: '%',
			icon: 'battery-half'
		},
		offline: {
			label: 'A sensor has not reported data',
			icon: ''
		}
	},

	analysislabels: {
		avg_temp1: {
			label: 'Average Temp'
		},
		minmax_temp1: {
			label: 'Min / Max Temp'
		},
		avg_rh: {
			label: 'Average RH'
		},
		minmax_rh: {
			label: 'Min / Max RH'
		},
		avg_vis: {
			label: 'Average Visible'
		},
		minmax_vis: {
			label: 'Min / Max Visible'
		},
		avg_uv1: {
			label: 'Average UV Index'
		},
		minmax_uv1: {
			label: 'Min / Max UV Index'
		},
		avg_ir: {
			label: 'Average IR'
		},
		minmax_ir: {
			label: 'Min / Max IR'
		},
		avg_dewpoint: {
			label: 'Average Dewpoint'
		}
	}
};

export default datalabels;
