import { useContext } from 'react';
import * as yup from 'yup';
import { PreferencesContext } from '../../../providers/PreferencesProvider';
import {
	getNameSchema,
	getScopeSchema,
	getTypeSchema,
	getTimeTriggerSchema,
	getSubscribersSchema
} from '../Utils/schema/schemas';

const validateFirstStep = ({ values, restrictedNames }) => {
	const schema = yup.object().shape({
		...getNameSchema(restrictedNames),
		...getScopeSchema()
	});
	return schema.isValidSync(values);
};

const validateSecondStep = ({ values, context }) => {
	const schema = yup.object().shape({
		...getTypeSchema(context)
	});

	return schema.isValidSync(values);
};

const validateThirdStep = ({ values }) => {
	const schema = yup.object().shape({
		...getTimeTriggerSchema()
	});

	return schema.isValidSync(values);
};

const validateFourthStep = ({ values }) => {
	const schema = yup.object().shape({
		...getSubscribersSchema()
	});

	return schema.isValidSync(values);
};

const skipStep = () => true;

const VALIDATE_STEPS = {
	0: validateFirstStep,
	1: validateSecondStep,
	2: validateThirdStep,
	3: validateFourthStep,
	4: skipStep
};

const useValidateStep = ({ step, values, restrictedNames }) => {
	const context = useContext(PreferencesContext);
	return VALIDATE_STEPS[step]({ values, context, restrictedNames });
};

export default useValidateStep;
