/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import { useStoreon } from 'storeon/react';
import Api from '../../../../api/Api';
import useStyles from './styles';

function ReviewText() {
	const classes = useStyles();
	const [startText, setStartText] = useState('');
	const [endText, setEndText] = useState('');
	const { eventProfiles } = useStoreon('eventProfiles');
	const { editing: eventProfile } = eventProfiles;

	useEffect(() => {
		if (eventProfile) {
			Api.getNotificationText({ eventProfile }).then(({ data }) => {
				const splitedText = data.split('<BR>\n');
				const eventName = splitedText[1].split('“')[1].split('”')[0];
				const start = splitedText[2]
					.replace('\n<BR>', '')
					.replace(
						'You will be notified by email',
						`Trigger the event ${eventName}`
					);

				const end = splitedText[3].includes('You will be notified again')
					? splitedText[3]
							.replace('\n<BR>', '')
							.replace('You will be notified again', 'End the event')
					: '';
				setStartText(start);
				setEndText(end);
			});
		}
	}, [eventProfile]);

	return (
		<>
			<Typography className={classes.resumeText}>
				<div dangerouslySetInnerHTML={{ __html: startText }} />
			</Typography>
			<Typography className={(classes.resumeText, classes.endText)}>
				<div dangerouslySetInnerHTML={{ __html: endText }} />
			</Typography>
		</>
	);
}

export default ReviewText;
