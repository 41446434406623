import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	link: {
		color: 'rgba(0, 0, 0, 0.87)',
		cursor: 'pointer',
		fontFamily: 'roboto',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: '24px',
		textDecoration: 'underline'
	}
}));
