import React from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	IconButton,
	Box,
	Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import WarningIcon from '@material-ui/icons/Warning';
import useStyles from './styles';

function ConfirmationDialog({ open, onDiscard, onSave, canSave, onGoBack }) {
	const classes = useStyles();

	return (
		<Dialog open={open} classes={{ paper: classes.paper }} onClose={onGoBack}>
			<DialogTitle
				disableTypography
				className={classes.header}
				id='alert-dialog-title'
			/>

			<DialogContent classes={{ root: classes.content }}>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onGoBack}
				>
					<CloseIcon />
				</IconButton>
				<Box display='flex' justifyContent='center' paddingTop={3}>
					<WarningIcon className={classes.icon} />
				</Box>
				<Box mt={3} display='flex' justifyContent='center'>
					<Typography className={classes.title} variant='h1'>
						{canSave ? 'Unsaved Changes' : 'Required Fields Missing'}
					</Typography>
				</Box>
				<Box mt={3} pl={4} pr={4} display='flex' justifyContent='center'>
					<Typography className={classes.text}>
						{canSave
							? 'You are about to leave this page without saving. All changes will be lost. Do you want to leave without saving?'
							: 'You are about to leave this page without saving. All changes will be lost. Do you want to leave without saving?'}
					</Typography>
				</Box>
				<Box mt={3} display='flex' justifyContent='space-between'>
					<Button
						id='complete_profile'
						onClick={e => onDiscard()}
						variant='outlined'
					>
						Leave without saving
					</Button>
					<Button
						className={classes.button}
						id='complete_profile'
						onClick={e => (canSave ? onSave() : onGoBack())}
						variant='contained'
						color='primary'
					>
						{canSave ? 'Save Changes' : 'Go Back'}
					</Button>
				</Box>
			</DialogContent>
		</Dialog>
	);
}

export default ConfirmationDialog;
