/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useCallback } from 'react';
import {
	Box,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Button,
	Typography,
	SelectChangeEvent,
} from '@mui/material';
import { AxiosResponse } from 'axios';
import useCustomerSensors from '../../hooks/useCustomerSensors';
import Api from '../../legacy/api/Api';
import Loading from '../../legacy/components/common/elements/v2/Loading/Loading';
import LocationSpaceSensorFilter from '../LocationSpaceSensorFilter/LocationSpaceSensorFilter';
import TreeNodeWithType from 'types/TreeNodeWithType';
import Sensor from 'types/Sensor';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import ExportNotification from './ExportNotification';
import useSnackbar from '../../hooks/useSnackbar';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import type { Dayjs } from 'dayjs';
import StyledDatePicker from '../StyledDatePicker/StyledDatePicker';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

const timeOptions = [
	{ value: 'today', label: 'Today' },
	{ value: 'yesterday', label: 'Yesterday' },
	{ value: 'this_week', label: 'This week' },
	{ value: 'last_week', label: 'Last week' },
	{ value: 'this_month', label: 'This month' },
	{ value: 'last_month', label: 'Last month' },
	{ value: 'this_year', label: 'This year' },
	{ value: 'last_year', label: 'Last year' },
	{ value: 'custom', label: 'Custom Date Range' }
];

const emptyArray: string[] = [];
const maxAmountSelectedSensors = 1;
const maxSelectedSensors = 1;
const hideCheckboxes = {
	locations: true,
	spaces: true,
	sensors: false
};

const getDateRange = (
	timeOption: string,
	customStartDate: Dayjs | null,
	customEndDate: Dayjs | null
): { start: string; end: string } => {
	if (timeOption === 'custom') {
		return {
			start:
				customStartDate?.startOf('day').format() ??
				dayjs().startOf('day').format(),
			end: customEndDate?.endOf('day').format() ?? dayjs().endOf('day').format()
		};
	}

	const now = dayjs();

	switch (timeOption) {
		case 'today': {
			return {
				start: now.startOf('day').format(),
				end: now.endOf('day').format()
			};
		}
		case 'yesterday': {
			const yesterday = now.subtract(1, 'day');
			return {
				start: yesterday.startOf('day').format(),
				end: yesterday.endOf('day').format()
			};
		}
		case 'this_week': {
			return {
				start: now.startOf('isoWeek').format(),
				end: now.format()
			};
		}
		case 'last_week': {
			const lastWeek = now.subtract(1, 'week');
			return {
				start: lastWeek.startOf('isoWeek').format(),
				end: lastWeek.endOf('isoWeek').format()
			};
		}
		case 'this_month': {
			return {
				start: now.startOf('month').format(),
				end: now.format()
			};
		}
		case 'last_month': {
			const lastMonth = now.subtract(1, 'month');
			return {
				start: lastMonth.startOf('month').format(),
				end: lastMonth.endOf('month').format()
			};
		}
		case 'this_year': {
			return {
				start: now.startOf('year').format(),
				end: now.format()
			};
		}
		case 'last_year': {
			const lastYear = now.subtract(1, 'year');
			return {
				start: lastYear.startOf('year').format(),
				end: lastYear.endOf('year').format()
			};
		}
		default: {
			return {
				start: now.format(),
				end: now.format()
			};
		}
	}
};

interface ExportResponse {
	status: number;
	data: unknown;
}

export default function ExportTab(): JSX.Element {
	const [selectedSensor, setSelectedSensor] = useState<TreeNodeWithType[]>([]);
	const [selectedTime, setSelectedTime] = useState('');
	const [isNotificationOpen, setIsNotificationOpen] = useState(false);
	const [startDate, setStartDate] = useState<Dayjs | null>(null);
	const [endDate, setEndDate] = useState<Dayjs | null>(null);
	const {
		loading,
		error: sensorsError,
		findSensorByUuid
	} = useCustomerSensors();
	const { showError, SnackbarComponent } = useSnackbar();

	const onSensorChangeHandler = (updatedSelection: TreeNodeWithType[]) => {
		setSelectedSensor(updatedSelection);
	};

	const onTimeChangeHandler = (event: SelectChangeEvent) => {
		setSelectedTime(event.target.value);
	};

	const onStartDateChange = (value: unknown) => {
		setStartDate(value as Dayjs | null);
	};

	const onEndDateChange = (value: unknown) => {
		setEndDate(value as Dayjs | null);
	};

	const onExportHandler = async () => {
		try {
			const exportDateRange = getDateRange(selectedTime, startDate, endDate);
			const sensorIds = selectedSensor
				.map(sensor => findSensorByUuid(sensor.id))
				.filter((sensor): sensor is Sensor => sensor !== undefined)
				.map(sensor => sensor.id);

			const response = (await Api.exportSensorData({
				...exportDateRange,
				sensors: sensorIds
			})) as AxiosResponse<ExportResponse>;

			if (response.status === 202) {
				setIsNotificationOpen(true);
			} else {
				showError('Failed to start export. Please try again.');
			}
		} catch (error_) {
			showError(
				'An error occurred while starting the export. Please try again.'
			);
			console.error('Export error:', error_);
		}
	};

	const onCloseNotification = () => {
		setIsNotificationOpen(false);
	};

	const renderContent = () => {
		if (loading) {
			return <Loading text='Loading sensors...' />;
		}

		if (sensorsError) {
			return <Typography color='error'>{sensorsError}</Typography>;
		}

		return (
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-end'
				}}
			>
				<Box sx={{ display: 'flex', gap: 7 }}>
					<Box sx={{ width: 289 }}>
						<LocationSpaceSensorFilter
							onChangeLocationFilter={onSensorChangeHandler}
							maxAmountChips={maxAmountSelectedSensors}
							hideCheckboxes={hideCheckboxes}
							initialSelectedSensorIds={emptyArray}
							maxSelection={maxSelectedSensors}
						/>
					</Box>

					<FormControl sx={{ width: 289 }}>
						<InputLabel sx={{ fontFamily: 'Montserrat' }}>
							Time Period
						</InputLabel>
						<Select
							value={selectedTime}
							label='Time Period'
							onChange={onTimeChangeHandler}
							variant='standard'
							sx={{
								'& .MuiSelect-select': {
									fontFamily: 'Montserrat'
								}
							}}
						>
							{timeOptions.map(option => (
								<MenuItem
									key={option.value}
									value={option.value}
									sx={{ fontFamily: 'Montserrat' }}
								>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{selectedTime === 'custom' && (
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Box display='flex' gap={2} width='100%'>
								<FormControl sx={{ width: 289 }}>
									<StyledDatePicker
										label='Start date'
										value={startDate}
										onChange={onStartDateChange}
									/>
								</FormControl>

								<FormControl sx={{ width: 289 }}>
									<StyledDatePicker
										label='End date'
										value={endDate}
										onChange={onEndDateChange}
										minDate={startDate ?? undefined}
									/>
								</FormControl>
							</Box>
						</LocalizationProvider>
					)}
				</Box>

				<Button
					variant='contained'
					onClick={onExportHandler}
					disabled={
						selectedSensor.length === 0 ||
						!selectedTime ||
						(selectedTime === 'custom' && (!startDate || !endDate))
					}
					sx={{
						height: 36,
						fontFamily: 'Montserrat'
					}}
				>
					Export Data
				</Button>
			</Box>
		);
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
			<Typography
				variant='h2'
				sx={{
					fontSize: '24px',
					fontWeight: 500,
					fontFamily: 'Montserrat'
				}}
			>
				Export data
			</Typography>

			{renderContent()}

			<ExportNotification
				open={isNotificationOpen}
				onClose={onCloseNotification}
			/>
			<SnackbarComponent />
		</Box>
	);
}
