import React, { Component } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	TextField,
	DialogActions,
	Typography,
	Box,
	Link
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Api from '../../api/Api';
import SegmentUtil from '../../util/SegmentUtil';
import { connectStoreon } from 'storeon/react';
import GradientDialogHeader from '../common/dialog/GradientDialogHeader';

class InviteUsers extends Component {
	constructor(props) {
		super(props);
		this.state = { show: false, email: '' };
	}

	show = show => {
		this.setState({ show });
	};

	validateForm = () => {
		return this.state.formvalid;
	};

	handleChange = event => {
		this.setState({ email: event.target.value }, () => {
			if (this.state.email.indexOf('@') != -1) {
				Api.validateEmail(this.state.email).then(result => {
					if (result.data.valid) {
						this.setState({
							formvalid: true,
							emailvalid: result.data.valid,
							emailexists: result.data.emailexists
						});
					} else {
						this.setState({
							formvalid: false,
							emailvalid: result.data.valid,
							emailexists: result.data.emailexists
						});
					}
				});
			}
		});
	};

	handleCancel = () => {
		this.setState({ show: false, email: '', inviteerror: false });
	};

	handleInvite = () => {
		SegmentUtil.track(SegmentUtil.actions.sendinvite);
		Api.inviteUsers([this.state.email])
			.then(res => {
				this.setState({ show: false, email: '' });
				this.props.dispatch('notification/add', { message: 'Invitation sent' });
			})
			.catch(err => {
				this.setState({ inviteerror: true });
			});
	};

	render() {
		return (
			<Dialog open={this.state.show} onHide={() => {}}>
				<GradientDialogHeader title='Invite Users' />
				<DialogContent>
					<TextField
						autoFocus
						label='Email Address'
						type='email'
						value={this.state.email}
						onChange={this.handleChange}
					/>
					{this.state.inviteerror ? (
						<Box pt={1}>
							<Alert severity='warning'>
								<Typography variant='body1'>
									There was a problem inviting your collaborator to Conserv.
								</Typography>
								<Link
									rel='noopener noreferrer'
									target='_blank'
									href='https://support.conserv.io/knowledge/kb-tickets/new'
								>
									Please contact Conserv support
								</Link>
							</Alert>
						</Box>
					) : null}
				</DialogContent>
				<DialogActions>
					<Button
						variant='contained'
						color='primary'
						onClick={this.handleInvite}
						disabled={!this.validateForm()}
					>
						Invite to Conserv!
					</Button>
					<Button
						variant='contained'
						// color="primary"
						onClick={this.handleCancel}
					>
						Cancel
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default connectStoreon('notification', InviteUsers);
