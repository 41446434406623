import { colors } from '../../../../../style/colors';
import theme from '../../../../../style/themev2';
import { createTheme } from '@material-ui/core';
const { overrides, ...rest } = theme;
const dateTimePickerTheme = createTheme({
	...rest,
	overrides: {
		...overrides,
		MuiTab: {
			textColorInherit: {
				'&$selected': {
					color: colors.v2.primary
				}
			}
		}
	}
});

export default dateTimePickerTheme;
