import * as yup from 'yup';

const getTimeTriggerSchema = () => ({
	lagStart: yup
		.number()
		.transform((v, o) => (o === '' || o === undefined ? null : v))
		.nullable(true)
		.test({
			name: 'is-right-lagStart',
			skipAbsent: true,
			test(start, ctx) {
				if (
					this.parent.type === 'offline' &&
					this.parent.lagStartUnit === 'hour' &&
					start < 3
				) {
					return ctx.createError({
						message: 'Should be more or equal than 3 hours'
					});
				}

				const isValid = !(
					this.parent.type !== 'leak' &&
					this.parent.type !== 'shock' &&
					(typeof start !== 'number' || start < 0)
				);

				if (!isValid) {
					return ctx.createError({ message: 'Should be more or equal than 0' });
				}

				return isValid;
			}
		}),
	lagEnd: yup
		.number()
		.transform((v, o) => (o === '' || o === undefined ? null : v))
		.nullable(true)
		.min(0)
		.test(
			'is-right-lagEnd',
			'Should be more or equal than 0',
			function check(end) {
				const isValid = !(
					this.parent.type !== 'leak' &&
					this.parent.type !== 'shock' &&
					this.parent.type !== 'offline' &&
					(typeof end !== 'number' || end < 0)
				);
				return isValid;
			}
		)
});

export default getTimeTriggerSchema;
