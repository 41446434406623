import React, { Component } from 'react';
import { Icon } from '@mdi/react';
import {
	Box,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableCell,
	Avatar,
	Tooltip,
	Typography,
	Button,
	IconButton,
	Menu,
	MenuItem
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { connectStoreon } from 'storeon/react';
import ObservationDrawer from './ObservationDrawer';
import Loading from '../common/elements/Loading';
import ScopeLabel from './ScopeLabel';
import ImageList from '../common/images/ImageList';
import Api from '../../api/Api';
import { showAlert } from '../error/ConservAlertPopup';
import {
	collapsibleTableStyles,
	buttonStyles,
	avatarStyles
} from '../../style/styles';
import icons from '../../style/icons';
import InfoTooltip from '../common/help/InfoTooltip';
import Paginator from '../common/table/Paginator';
const mnmxIoCommon = require('@conserv/mnmx-io-common');
const commonConstant = mnmxIoCommon.commonConstant;

dayjs.extend(localizedFormat);

const styles = () => ({
	...collapsibleTableStyles,
	...buttonStyles,
	...avatarStyles
});

// We need to wrap the menu in its own class, if a material ui menu uses a common anchor element, the menus are all treated as one.
class ObservationMenuClass extends Component {
	constructor(props) {
		super(props);
		this.state = { anchorEl: null };
	}

	render() {
		const { classes } = this.props;
		return (
			<>
				<IconButton
					size='small'
					disableRipple
					classes={{ root: classes.iconLabeledLinkButton }}
					style={{ justifyContent: 'flex-end' }}
					onClick={event => this.setState({ anchorEl: event.currentTarget })}
				>
					<Icon path={icons.more} size={1} aria-label='more options' />
				</IconButton>
				<Menu
					key={`observationmenu-${this.props.observation.uuid}`}
					id='simple-menu'
					anchorEl={this.state.anchorEl}
					keepMounted
					open={Boolean(this.state.anchorEl)}
					onClose={() => this.setState({ anchorEl: null })}
					elevation={1}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
				>
					<MenuItem
						onClick={() => {
							this.props.onDelete(this.props.observation);
							this.setState({ anchorEl: null });
						}}
					>
						Delete Observation
					</MenuItem>
				</Menu>
			</>
		);
	}
}

class ObservationSummaryClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			page: 1
		};
	}

	handlePageChange = (ev, page) => {
		this.setState(
			{ page },
			this.props.dispatch('observations/refresh', { page, typeObs: 'general' })
		);
	};

	editObservation = obs => {
		this.props.dispatch('navstate/update', {
			drawerOpen: true,
			drawerContent: <ObservationDrawer observation={obs} />
		});
	};

	confirmDelete = obs => {
		this.setState({
			alert: {
				iserror: false,
				title: 'Confirm Delete',
				destructive: true,
				message: 'Are you sure you want to delete this observation?',
				onConfirm: () => {
					this.handleDelete(obs.uuid);
				}
			}
		});
	};

	handleDelete = uuid => {
		Api.deleteObservation(uuid)
			.then(() => {
				if (this.props.callback) this.props.callback();
				this.props.dispatch('notification/add', {
					message: 'Observation deleted'
				});
				this.props.dispatch('observations/refresh', {
					page: this.state.page,
					typeObs: 'general'
				});
			})
			.catch(() => {
				this.props.dispatch('notification/add', {
					message: 'There was a problem deleting this observation',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	findScopedEntity = obs => {
		const entities = this.props[`${obs.scope}s`];
		let scopedEntity = null;
		if (entities) {
			scopedEntity = entities.list?.find(
				entity => entity.uuid === obs.scopedId
			);
		}
		return scopedEntity;
	};

	buildObservationRows = () => {
		const { classes } = this.props;
		const rows = [];
		this.props.observations
			.filter(obs => obs.type === 'general')
			.forEach((obs, idx) => {
				const scopedEntity = this.findScopedEntity(obs);
				rows.push(
					<TableRow key={obs.uuid} classes={{ root: classes.level3Rows }}>
						<TableCell>
							<Button
								classes={{ root: classes.iconLabeledLinkButton }}
								fullWidth
								disableRipple
								onClick={() =>
									this.props.dispatch('navstate/update', {
										drawerOpen: true,
										drawerContent: (
											<ObservationDrawer
												// does key go here,...
												// key={idx}
												observation={obs}
											/>
										)
									})
								}
								// or here where I found it? Both seem to work
								key={idx}
								startIcon={
									<Icon
										path={icons.observation}
										size={1}
										aria-label='observation details'
									/>
								}
							>
								<Typography
									noWrap
									variant='body1'
									style={{ paddingRight: '16px' }}
								>
									{obs.observation?.description ??
										(obs.observation?.name ||
											obs.observation?.description ||
											'No description')}
								</Typography>
							</Button>
						</TableCell>
						<TableCell>
							<ScopeLabel
								key={scopedEntity?.uuid}
								scope={obs.scope}
								scopedEntity={scopedEntity}
							/>
						</TableCell>
						<TableCell>{dayjs(obs.observationtime).format('L LT')}</TableCell>
						<TableCell>
							{obs.observationendtime
								? dayjs(obs.observationendtime).format('L LT')
								: null}
						</TableCell>
						<TableCell>
							<ImageList observation={obs} />
						</TableCell>
						<TableCell>
							<Tooltip
								key={obs.mnmxuser?.uuid}
								arrow
								title={
									obs.mnmxuser
										? `${obs.mnmxuser?.first_name} ${obs.mnmxuser?.last_name}`
										: 'Unknown'
								}
								placement='top'
							>
								<Avatar
									className={classes.avatar}
									alt={`${obs.mnmxuser?.first_name} ${obs.mnmxuser?.last_name}`}
									src={obs.mnmxuser?.avatar}
								/>
							</Tooltip>
						</TableCell>
						<TableCell>
							<ObservationMenu
								anchorEl={this.state.anchorEl}
								observation={obs}
								onDelete={this.confirmDelete}
								handleClose={() => this.setState({ anchorEl: null })}
							/>
						</TableCell>
					</TableRow>
				);
			});

		if (rows.length === 0) {
			return (
				<TableRow>
					<TableCell colSpan={7}>
						<Typography variant='h4'>No Observations Found</Typography>
					</TableCell>
				</TableRow>
			);
		}
		return rows;
	};

	render() {
		const { classes } = this.props;

		if (!this.props.loaded.has('observations')) return <Loading />;

		return (
			<>
				{showAlert(this.state.alert, () => this.setState({ alert: null }))}
				<Table stickyHeader classes={{ root: classes.tableRoot }}>
					<TableHead>
						<TableRow>
							<TableCell style={{ width: '30%', borderBottom: 0 }}>
								Observation
								<InfoTooltip name='observation' />
							</TableCell>
							<TableCell style={{ width: '20%', borderBottom: 0 }}>
								Related to
								<InfoTooltip name='relatedTo' />
							</TableCell>
							<TableCell
								style={{ width: '15%', borderBottom: 0, whiteSpace: 'nowrap' }}
							>
								Start time
								<InfoTooltip name='startTime' />
							</TableCell>
							<TableCell
								style={{ width: '15%', borderBottom: 0, whiteSpace: 'nowrap' }}
							>
								End time
								<InfoTooltip name='endTime' />
							</TableCell>
							<TableCell style={{ borderBottom: 0 }}>Images</TableCell>
							<TableCell style={{ borderBottom: 0 }}>Creator</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>
					<TableBody>{this.buildObservationRows()}</TableBody>
				</Table>
				<Box mt={2}>
					<Paginator
						onChange={this.handlePageChange}
						count={this.props.pages}
						total={this.props.total}
						pageSize={commonConstant.PAGE_SIZE_EFFECTIVELY_ALL}
						showFirstButton
						showLastButton
					/>
				</Box>
			</>
		);
	}
}

const ObservationSummary = connectStoreon(
	'navState',
	'locations',
	'spaces',
	'sensors',
	'users',
	'loaded',
	withStyles(styles)(ObservationSummaryClass)
);
const ObservationMenu = withStyles(styles, { withTheme: true })(
	ObservationMenuClass
);

export { ObservationSummary, ObservationMenu };
