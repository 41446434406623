import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { Box, Typography } from '@material-ui/core';
import QueryString from 'query-string';
import Api from '../../../api/Api';
import SegmentUtil from '../../../util/SegmentUtil';

function VerifyEmailLanding(props) {
	const [user, setUser] = useState(false);
	const [verifyError, setVerifyError] = useState(false);
	const [redirect, setRedirect] = useState(false);

	useEffect(() => {
		// call the API to verify the email address attached to this token.  If it verifies, then
		// redirect to the login page
		const { token } = QueryString.parse(props.location.search);
		Api.verifyEmail(token)
			.then(res => {
				SegmentUtil.track(SegmentUtil.actions.emailverified);
				setUser(res.data);
				const redir = setInterval(() => setRedirect(true), 4000);
			})
			.catch(() => {
				setVerifyError(true);
			});
	}, []);

	return (
		<Box display='flex' flexDirection='column' alignItems='center' width='100%'>
			{redirect ? (
				<Redirect to={`/login?email=${encodeURIComponent(user.email)}`} />
			) : null}
			{user.emailverified ? (
				<>
					<Box width='30%' minWidth='352px' pt={10} pb={2}>
						<img
							style={{ width: '100%', height: '100%', objectFit: 'contain' }}
							src='/img/stock/thumbsup.png'
							alt='success'
						/>
					</Box>
					<Box textAlign='center'>
						<Typography variant='h1'>
							Thanks for verifying your email, now we can get on with it!
						</Typography>
						<Box pt={2} />
						<Typography variant='body1'>
							We'll send you back to the login page in a moment...
						</Typography>
					</Box>
				</>
			) : null}
			{verifyError ? 'verify error' : null}
		</Box>
	);
}

export default VerifyEmailLanding;
