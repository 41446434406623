import React from 'react';
import { Button } from '@material-ui/core';

import useStyles from './styles';

export default function SubscribedButton({ onClick, disabled = false }) {
	const classes = useStyles();

	return (
		<Button
			className={classes.subscribed}
			color='success'
			onClick={onClick}
			variant='outlined'
			disabled={disabled}
		>
			Subscribed
		</Button>
	);
}
