import React, { useEffect } from 'react';
import { Dialog, DialogActions, Button } from '@material-ui/core';
import GradientDialogHeader from '../../common/dialog/GradientDialogHeader';
import Api from '../../../api/Api';

function VerifyEmailDialog(props) {
	const resendVerification = closeOnSend => {
		Api.resendVerification(props.email)
			.then(() => {
				if (closeOnSend) props.onClose();
			})
			.catch(error => {
				props.onError(error);
				if (closeOnSend) props.onClose();
			});
	};

	useEffect(() => {
		if (props.open && props.showResend) {
			resendVerification(false);
		}
	});

	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<GradientDialogHeader
				title='Check Your Email!'
				subtitle={
					props.showResend
						? 'For your security, please click the link we sent to your email to verify your address, or sign in with Google'
						: 'Please click the link we just sent to your email to complete your registration'
				}
				onClose={props.onClose}
			/>
			<DialogActions>
				<Button onClick={props.onClose} variant='contained' color='primary'>
					OK
				</Button>
				{props.showResend ? (
					<Button
						onClick={() => resendVerification(true)}
						variant='outlined'
						color='primary'
					>
						Resend the Link
					</Button>
				) : null}
			</DialogActions>
		</Dialog>
	);
}

export default VerifyEmailDialog;
