import React, { Component } from 'react';
import { Dialog, Button, DialogActions } from '@material-ui/core';
import GradientDialogHeader from '../common/dialog/GradientDialogHeader';

function showAlert(alert, onClose) {
	if (alert) {
		return (
			<ConservAlertPopup
				show
				iserror={alert.iserror}
				message={alert.message}
				destructive={alert.destructive}
				title={alert.title}
				onClose={onClose || alert.onClose}
				onConfirm={alert.onConfirm}
			/>
		);
	}
}

class ConservAlertPopup extends Component {
	constructor(props) {
		super(props);
		this.state = { show: this.props.show };

		if (this.props.onConfirm) {
			this.confirmCallback = () => {
				this.handleClose();
				this.props.onConfirm();
			};
		}
	}

	handleClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
		this.setState({ show: false });
	};

	render() {
		return (
			<Dialog
				open={this.state.show}
				onClose={this.handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<GradientDialogHeader
					title={this.props.title}
					subtitle={this.props.message ? this.props.message : null}
					variant={this.props.destructive ? 'danger' : 'info'}
					onClose={this.handleClose}
				/>
				<DialogActions>
					{this.confirmCallback ? (
						<>
							<Button
								variant='contained'
								color={this.props.destructive ? 'secondary' : 'primary'}
								onClick={this.confirmCallback}
							>
								Confirm
							</Button>
							{this.props.destructive ? (
								<Button
									variant='text'
									color='primary'
									onClick={this.handleClose}
								>
									Cancel
								</Button>
							) : null}
						</>
					) : (
						<Button
							variant='contained'
							color='primary'
							onClick={this.handleClose}
						>
							Ok
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

export { ConservAlertPopup, showAlert };
