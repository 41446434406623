import theme from './theme';
import { colors } from './colors';

const eventStyles = {
	// this is from SensorCard styles minus its maxWidth & textTrans.
	// almost identical to iconLabeledLinkButton in buttonStyles below
	btnOverride: {
		textTransform: 'initial',
		padding: '0px',
		margin: '-2px',
		border: '0px',
		justifyContent: 'left',
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:active': {
			outline: 0,
			border: 'none'
		},
		'&:focus': {
			outline: 0,
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		}
	},
	startIconOverride: {
		margin: '0px',
		paddingRight: '4px',
		justifyContent: 'left'
	}
};

const collapsibleTableStyles = {
	tableRoot: {
		borderSpacing: '0px 5px',
		borderCollapse: 'separate',
		tableLayout: 'fixed'
	},
	level1Rows: {
		backgroundColor: '#FBFBFB',
		// sets top & bottom borders for each row via indiv <td>s
		'& td': {
			borderTop: '1px solid',
			borderColor: theme.palette.primary.main
		},
		// sets left side border & radius for each row
		'& td:first-child': {
			borderRadius: '6px 0 0 6px',
			borderLeft: '1px solid',
			borderColor: theme.palette.primary.main
		},
		// sets right side border & radius for each row
		'& td:last-child': {
			borderRadius: '0 6px 6px 0',
			borderRight: '1px solid',
			borderColor: theme.palette.primary.main
		}
	},
	level2Rows: {
		backgroundColor: '#FBFBFB',
		'& td': {
			borderBottom: `1px solid${theme.palette.primary.background}`,
			borderTop: `1px solid ${theme.palette.primary.background}`
		},
		// sets left side border & radius for each level 2 row
		'& td:first-child': {
			borderRadius: '6px 0 0 6px',
			borderLeft: `1px solid ${theme.palette.primary.background}`,
			borderColor: theme.palette.primary.background
		},
		// sets right side border & radius for each level 2 row
		'& td:last-child': {
			borderRadius: '0 6px 6px 0',
			borderRight: `1px solid ${theme.palette.primary.background}`
		}
	},
	level3Rows: {
		backgroundColor: '#FBFBFB',
		'& td': {
			fontSize: theme.typography.body1.fontSize,
			borderTop: `1px solid ${colors.laser.medium}`,
			borderBottom: `1px solid ${colors.laser.medium}`
		},
		'& td:first-child': {
			borderRadius: '6px 0 0 6px',
			borderLeft: `1px solid ${colors.laser.medium}`
		},
		'& td:last-child': {
			borderRadius: '0 6px 6px 0',
			borderRight: `1px solid ${colors.laser.medium}`
		}
	},
	inactiveRows: {
		backgroundColor: colors.ice.primaryOpacity,
		'& td': {
			borderBottom: 0,
			'& p': {
				paddingLeft: '8px',
				alignSelf: 'center'
			}
		},
		'& td:first-child': {
			borderRadius: '6px 0 0 6px'
		},
		'& td:last-child': {
			borderRadius: '0 6px 6px 0'
		}
	},
	hideBottomBorder: {
		borderBottom: 0
	},
	hiddenTableElement: {
		display: 'none'
	},
	dropActiveRow: {
		backgroundColor: '#E5E5E5',
		// sets top & bottom borders for each row via indiv <td>s
		'& td': {
			borderTop: '1px solid',
			borderBottom: '1px solid',
			borderColor: theme.palette.primary.main,
			backgroundColor: colors.laser.medium
		},
		// sets left side border & radius for each row
		'& td:first-child': {
			borderRadius: '6px 0 0 6px',
			borderLeft: '1px solid',
			borderColor: theme.palette.primary.main,
			backgroundColor: colors.laser.medium
		},
		// sets right side border & radius for each row
		'& td:last-child': {
			borderRadius: '0 6px 6px 0',
			borderRight: '1px solid',
			borderColor: theme.palette.primary.main,
			backgroundColor: colors.laser.medium
		}
	},
	draggableRow: {
		borderColor: theme.palette.secondary.main,
		cursor: 'grab',
		'&:hover': {
			'& td': {
				borderBottom: `1px solid${theme.palette.secondary.main}`,
				borderTop: `1px solid ${theme.palette.secondary.main}`,
				backgroundColor: colors.laser.lightOpacity
			},
			// sets left side border & radius for each level 2 row
			'& td:first-child': {
				borderRadius: '6px 0 0 6px',
				borderLeft: `1px solid ${theme.palette.secondary.main}`,
				borderColor: theme.palette.secondary.main,
				backgroundColor: colors.laser.lightOpacity
			},
			// sets right side border & radius for each level 2 row
			'& td:last-child': {
				borderRadius: '0 6px 6px 0',
				borderRight: `1px solid ${theme.palette.secondary.main}`,
				backgroundColor: colors.laser.lightOpacity
			}
		}
	},
	draggingRow: {
		cursor: 'grabbing'
	}
};

const reportStyles = {
	weeklyReportTableRoot: {
		borderCollapse: 'separate',
		tableLayout: 'fixed',
		borderTop: '2px solid',
		borderBottom: '2px solid',
		borderLeft: '3px solid',
		borderRight: '3px solid',
		'& td': {
			borderTop: '1px solid',
			borderBottom: '1px solid'
		},
		'& th': {
			borderTop: '1px solid',
			borderBottom: '2px solid',
			textTransform: 'none'
		}
	},
	weeklyReportLocationRows: {
		'& td': {
			backgroundColor: colors.laser.light
		}
	},
	weeklyReportSpaceRows: {
		// sets top & bottom borders for each row via indiv <td>s
		'& td': {
			borderTop: '1px solid',
			borderBottom: '1px solid'
		}
	},
	facilitiesReportTableRoot: {
		// borderCollapse: 'separate',
		tableLayout: 'fixed',
		'& th': {
			backgroundColor: colors.nitro.medium,
			border: '2px solid',
			textTransform: 'none'
		},
		'& th:first-child': {
			border: 'none',
			backgroundColor: '#FFFFFF'
		}
	},
	rangeFluxRows: {
		borderTop: '2px solid',
		borderRight: '2px solid',
		borderBottom: '2px solid',
		borderLeft: '2px solid',
		'& td': {
			border: '1px solid'
		}
	},
	dataRows: {
		borderTop: '1px solid',
		borderRight: '2px solid',
		borderLeft: '2px solid',
		borderBottom: '1px solid',
		'& td': {
			border: '1px solid'
		}
	}
};

const clickableTableStyles = {
	tableRoot: {
		borderSpacing: '0px 5px',
		borderCollapse: 'separate',
		tableLayout: 'fixed'
	},
	clickableRow: {
		backgroundColor: '#FBFBFB',
		'& td': {
			borderBottom: `1px solid${theme.palette.primary.background}`,
			borderTop: `1px solid ${theme.palette.primary.background}`
		},
		'& td:first-child': {
			borderRadius: '6px 0 0 6px',
			borderLeft: `1px solid ${theme.palette.primary.background}`,
			borderColor: theme.palette.primary.background
		},
		'& td:last-child': {
			borderRadius: '0 6px 6px 0',
			borderRight: `1px solid ${theme.palette.primary.background}`
		},
		'&:hover': {
			cursor: 'pointer',
			'& td': {
				borderBottom: `1px solid${theme.palette.secondary.main}`,
				borderTop: `1px solid ${theme.palette.secondary.main}`
			},
			'& td:first-child': {
				borderRadius: '6px 0 0 6px',
				borderLeft: `1px solid ${theme.palette.secondary.main}`,
				borderColor: theme.palette.secondary.main
			},
			'& td:last-child': {
				borderRadius: '0 6px 6px 0',
				borderRight: `1px solid ${theme.palette.secondary.main}`
			}
		}
	}
};

const cardStyles = {
	wideCard: {
		minWidth: '300px'
	},
	importOptionCard: {
		height: '120px',
		width: '150px',
		marginRight: theme.spacing(3),
		border: '1px solid',
		padding: theme.spacing(2),
		cursor: 'pointer'
	},
	activeImportOptionCard: {
		height: '120px',
		width: '150px',
		marginRight: theme.spacing(3),
		border: '2px solid',
		borderColor: colors.ice.primary,
		padding: theme.spacing(2),
		cursor: 'pointer'
	},
	sensorCard: {
		width: '310px',
		border: '2px solid',
		borderColor: colors.referee.primary,
		borderRadius: 6,
		'&:hover': {
			border: '4px solid',
			width: '314px',
			margin: '-2px',
			borderColor: colors.referee.primary
		}
	},
	sensorName: {
		color: colors.text.primary,
		textTransform: 'initial',
		maxWidth: '274px',
		padding: 0,
		justifyContent: 'left',
		textAlign: 'left',
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:active': {
			outline: 0,
			border: 'none'
		},
		'&:focus': {
			outline: 0,
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		}
	},
	slimPad: {
		minHeight: '37px',
		paddingTop: '0px',
		paddingBottom: '12px'
	},
	pestCard: {
		border: '1px solid',
		borderColor: theme.palette.primary.main,
		borderRadius: 6,
		margin: theme.spacing(2),
		cursor: 'pointer'
	}
};

const avatarStyles = {
	avatar: {
		height: 25,
		width: 25,
		cursor: 'pointer'
	},
	menuAvatar: {
		height: 40,
		width: 40,
		cursor: 'pointer'
	}
};

const iconStyles = {
	iconPointer: {
		cursor: 'pointer'
	}
};

const buttonStyles = {
	collapse: {
		color: colors.text.primary,
		padding: '0px 8px 0px 0px',
		minWidth: '24px',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:active': {
			outline: 0,
			border: 'none'
		},
		'&:focus': {
			outline: 0
		}
	},
	iconLabeledLinkButton: {
		color: colors.text.primary,
		width: '100%',
		textTransform: 'initial',
		justifyContent: 'left',
		textAlign: 'left',
		padding: 0,
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:active': {
			outline: 0,
			border: 'none'
		},
		'&:focus': {
			outline: 0,
			// // if we set color here, then when the drawer is opened, the text color changes from blue to black, and when the drawer is closed, the text color flips back to blue until something else is clicked
			// // taking out the color property here has pros & cons:
			// // pro - when SensorDrawer/SpaceDrawer/etc are clicked, the blue color goes away at the time the drawer is opened & does not change back to blue when the drawer is closed
			// // con - when tabbing through, the blue color is not ever displayed
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		}
	},
	collapseDrawerButton: {
		color: colors.laser.primary,
		backgroundColor: '#FFFFFF',
		width: '100%',
		minWidth: '24px',
		maxHeight: '24px',
		padding: 0,
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: '#FFFFFF'
		},
		'&:active': {
			outline: 0,
			border: 'none'
		},
		'&:focus': {
			outline: 0,
			color: theme.palette.secondary.main,
			backgroundColor: '#FFFFFF'
		}
	},
	iconMenu: {
		color: colors.text.primary,
		padding: '0 8px',
		display: 'flex',
		flexDirection: 'row'
		// width: '280px'
	},
	iconMenuItem: {
		// color: colors.text.primary,
		padding: '12px 8px',
		width: '40px',
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:active': {
			outline: 0,
			border: 'none',
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:focus': {
			outline: 0,
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		}
	},
	// nestedContextMenu: {
	//     color: colors.text.primary,
	//     padding: 0,
	//     width: '40px'
	// },
	nestedContextMenuItem: {
		padding: '4px 16px',
		maxWidth: '280px',
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:active': {
			outline: 0,
			border: 'none',
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		},
		'&:focus': {
			outline: 0,
			color: theme.palette.secondary.main,
			backgroundColor: 'rgba(0, 0, 0, 0)'
		}
		// '&:first-child': {
		//     paddingTop: '8px'
		// },
		// '&:last-child': {
		//     paddingBottom: '8px'
		// },
	},
	restoreSensorButton: {
		width: '160px',
		height: '26px',
		color: theme.palette.secondary.main,
		backgroundColor: 'white',
		border: '1px solid',
		borderColor: theme.palette.secondary.main,
		'&:hover': {
			color: theme.palette.secondary.main,
			backgroundColor: 'white '
		},
		//   "&:active": {
		//     outline: 0,
		//     border: "none",
		//   },
		'&:focus': {
			outline: 0,
			color: theme.palette.secondary.main,
			backgroundColor: 'white'
		}
	},
	fullWidthButton: {
		width: '100%'
	}
};

const formValidationStyles = {
	errorBox: {
		color: colors.gemini.shade,
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
		border: `2px solid ${colors.gemini.shade}`,
		borderRadius: '6px'
	},
	errorLink: {
		color: colors.gemini.shade,
		fontSize: '14px',
		fontWeight: 500,
		'&:hover': {
			fontWeight: 600,
			color: colors.gemini.shade
		}
	},
	resolvedBox: {
		color: colors.blaze.primary,
		padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
		border: `2px solid ${colors.blaze.primary}`,
		borderRadius: '6px'
	}
};

const passwordStyles = {
	goodPassword: {
		color: colors.blaze.primary
	},
	okPassword: {},
	poorPassword: {
		color: colors.gemini.primary
	}
};

const linkStyles = {
	forgotPassword: {
		fontFamily: 'Roboto',
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '20px',
		letterSpacing: '0.4000000059604645px',
		textAlign: 'left',
		textDecoration: 'none',
		color: colors.lochmara.primary
	},
	newToConservSubTitle: {
		fontFamily: 'Roboto',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '31.4px',
		letterSpacing: '0.13px'
	},
	newToConservText: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	},
	newToConservLink: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	},
	termsAndConditionText: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	}
};

const loginStyles = {
	loginPanel: {
		maxWidth: `${theme.spacing(58)}px`,
		maxHeight: `${theme.spacing(47)}px`
	},
	loginTitle: {
		fontFamily: 'Sansita',
		fontSize: '40px',
		fontWeight: 400,
		fontSyle: 'normal',
		lineHeight: '56px',
		letterSpacing: '-0.01em'
	},
	ctaPanel: {
		backgroundColor: colors.heliotrope.primary,
		height: '100%',
		width: '50%'
	},
	googleButton: {
		backgroundColor: '#FFFFFF'
	},
	googleIcon: {
		maxWidth: `${theme.spacing(2)}px`,
		maxHeight: `${theme.spacing(2)}px`,
		marginRight: `${theme.spacing(1)}px`
	},
	googleText: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontWeight: 500,
		lineHeight: '26px'
	},
	singInButtonContainer: {
		display: 'flex',
		justifyContent: 'center'
	},
	singInButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '22px',
		paddingLeft: '22px',
		paddingTop: '8px',
		paddingBottom: '8px',
		background: colors.purpleHearth.primary
	},
	input: {
		padding: '17px'
	},
	resetText: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	},
	emailLink: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 700,
		color: colors.purpleHearth.primary,
		textDecoration: 'none'
	}
};

const verticalTabsStyles = {
	verticalTabs: {
		flexDirection: 'column',
		// width: '264px',
		/* Styles applied to the span element which contains the Tab label text */
		'& span': {
			paddingLeft: '24px'
		}
	},
	indicator: {
		display: 'none'
	},
	tabRoot: {
		padding: '16px',
		fontSize: 20,
		// maxWidth: 320, // 264 per MUI
		width: '264px',
		fontWeight: '700',
		textTransform: 'initial',
		outline: 0,
		border: 'none',
		textAlign: 'left'
	},
	textColorInherit: {
		color: colors.text.primary,
		opacity: 1
	},
	tabSelected: {
		color: colors.text.primary,
		backgroundColor: colors.ice.lightOpacity,
		outline: 0,
		border: 'none',
		'&:active': {
			outline: 0,
			border: 'none'
		},
		'&:focus': {
			outline: 0,
			border: 'none'
		}
	},
	wrapper: {
		width: '100%',
		display: 'inline-flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		justifyContent: 'center'
	}
};

const drawerTabsStyles = {
	indicator: {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: colors.ice.primary,
		height: '6px'
	},
	drawerTabRoot: {
		fontSize: 17,
		minWidth: '72px',
		maxWidth: '72px',
		fontWeight: '500',
		textTransform: 'initial',
		outline: 0,
		border: 'none'
	},
	drawerTabSelected: {
		color: colors.ice.primary,
		backgroundColor: 'transparent'
	}
};

const timelineStyles = {
	timelineRoot: {
		width: '410px'
	},
	header: {
		alignItems: 'baseline',
		paddingTop: '8px',
		paddingRight: '12px',
		paddingBottom: '4px',
		paddingLeft: '12px'
	},
	title: {
		fontSize: theme.typography.h3.fontSize
	},
	subheader: {
		fontSize: theme.typography.h6.fontSize,
		color: 'inherit'
	},
	footer: {
		paddingTop: 0,
		paddingRight: '12px',
		paddingLeft: '12px',
		justifyContent: 'space-between',
		alignItems: 'flex-end'
	},
	centerDots: {
		marginTop: '24px',
		marginBottom: '-24px'
	}
};

const tooltipStyles = {
	kpiTooltip: {
		backgroundColor: '#FFF',
		color: colors.text.primary,
		boxShadow: `1px 1px 2px ${colors.laser.primary}`,
		borderRadius: '6px'
	}
};

const importStyles = {
	dropBox: {
		border: '1px dashed',
		borderColor: colors.nitro.mediumOpacity,
		backgroundColor: colors.laser.light,
		borderRadius: '6px'
	},
	mapField: {
		margin: theme.spacing(1),
		borderRadius: '6px',
		border: '1px solid',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	draggableMapField: {
		cursor: 'grab',
		margin: theme.spacing(1),
		borderRadius: '6px',
		border: '1px solid',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		'&:hover': {
			border: `1px solid${theme.palette.secondary.main}`
		}
	},
	importFieldTop: {
		padding: theme.spacing(1),
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		flexGrow: 1
	},
	importFieldBottom: {
		padding: theme.spacing(1),
		borderTop: '1px solid'
	},
	dropActiveField: {
		margin: theme.spacing(1),
		borderRadius: '6px',
		border: '1px solid',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		backgroundColor: '#E5E5E5'
	}
};

const panelStyles = {
	leftCollapsePanel: {
		borderRight: '1px solid lightgray',
		boxShadow: '-3px 0px 8px 0px #C1C1C1',
		backgroundColor: '#F0F0F0',
		alignItems: 'center',
		position: 'relative'
	}
};

const graphStyles = {
	graphTextStyle: {
		fontSize: '14px',
		fontWeight: '400',
		fontFamily: 'Roboto, sans-serif',
		color: '#1C0B1A'
	},

	legendWrapperStyle: {
		fontSize: '14px',
		fontWeight: '400',
		fontFamily: 'Roboto, sans-serif',
		color: '#1C0B1A',
		top: '-10px',
		padding: '0px 45px 10px 45px'
	}
};

const navStyles = {
	leftNavItem: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingTop: '24px'
	}
};

export {
	eventStyles,
	collapsibleTableStyles,
	clickableTableStyles,
	cardStyles,
	avatarStyles,
	iconStyles,
	buttonStyles,
	formValidationStyles,
	passwordStyles,
	linkStyles,
	loginStyles,
	verticalTabsStyles,
	drawerTabsStyles,
	timelineStyles,
	tooltipStyles,
	reportStyles,
	importStyles,
	panelStyles,
	graphStyles,
	navStyles
};
