/* eslint-disable no-console */
import axios from 'axios';
import type UserListItem from 'types/UserListItem';

const getUserList = async () => {
	try {
		const { data } = await axios.get<UserListItem[]>(`/api/v2/admin/user`);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return data;
	} catch (error) {
		console.error('Error getting the product list:', error);
	}
	return [];
};

export default getUserList;
