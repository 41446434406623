import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { Fab } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { colors } from '../../style/colors';
import { useMediaQuery } from '@material-ui/core';

const drawerOpenWidth = 240;

const useStyles = makeStyles(theme => ({
	speedDial: {
		position: 'absolute',
		bottom: theme.spacing(3),
		left: '123px',
		transition: theme.transitions.create(['margin', 'left'], {
			easing: theme.transitions.easing.easeIn,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	speedDialShift: {
		position: 'absolute',
		bottom: theme.spacing(3),
		left: `calc(${drawerOpenWidth + theme.spacing(3) + 1}px)`,
		transition: theme.transitions.create(['margin', 'left'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	fabTooltip: {
		color: '#000',
		backgroundColor: colors.nitro.primary,
		'&:hover': {
			backgroundColor: colors.nitro.primary
		}
	},
	fabTooltipArrow: {
		color: colors.nitro.primary
	}
}));

export default function FloatingActionButton(props) {
	const [fabOpen, setFabOpen] = useState(false);
	const classes = useStyles(props);
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

	if (props.actions) {
		return (
			<SpeedDial
				ariaLabel='Simple floating action button'
				className={clsx(classes.speedDial, {
					[classes.speedDial]: props.drawerOpen
				})}
				icon={
					<Fab
						className={classes.fabTooltip}
						onClick={() => {
							props.actions[0].callback();
						}}
					>
						<AddIcon />
					</Fab>
				}
				open={fabOpen}
				onClose={() => setFabOpen(false)}
				onOpen={() => setFabOpen(true)}
			>
				{props.actions.map(action => (
					<SpeedDialAction
						key={action.name}
						icon={action.icon}
						tooltipTitle={action.name}
						tooltipPlacement='right'
						TooltipClasses={{
							tooltip: classes.fabTooltip
						}}
						onClick={() => {
							action.callback();
						}}
					/>
				))}
			</SpeedDial>
		);
	}
	return null;
}
