import React from 'react';
import { DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../style/colors';

const useStyles = makeStyles(theme => ({
	titleText: {
		color: '#FFF'
	},
	simple: {
		height: theme.spacing(2),
		background: colors.elektra.primary,
		color: '#FFF',
		padding: 0
	},
	customHeader: {
		height: theme.spacing(8),
		background: colors.elektra.primary,
		color: '#FFF',
		padding: 0
	}
}));

function SolidDialogHeader({ variant = 'simple', customHeader = null }) {
	const classes = useStyles();

	return (
		<DialogTitle
			disableTypography
			classes={{ root: classes[variant] }}
			id='alert-dialog-title'
		>
			<>{customHeader}</>
		</DialogTitle>
	);
}

export default SolidDialogHeader;
