import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	nested: {
		paddingLeft: theme.spacing(4)
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px'
	},
	checkbox: {
		marginRight: '23px'
	}
}));
