import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../../../style/colors';

export default makeStyles(theme => ({
	listItem: {
		paddingTop: '12px',
		paddingBottom: '12px',
		paddingRight: '12px',
		paddingLeft: '8px'
	},
	listItemDiv: {
		'&:hover': {
			backgroundColor: props =>
				!props.editing ? colors.mercury.primary : 'white'
		}
	},
	iconButton: {
		'&:focus': {
			outline: 'none'
		}
	},
	draggingListItem: {
		background: 'rgb(235,235,235)'
	},
	close: {
		color: theme.palette.error.main
	},
	done: {
		color: theme.palette.success.main
	},
	input: {
		width: '82%'
	},
	dragIcon: {
		color: '#0000008A'
	},
	itemText: {
		marginLeft: '8px'
	}
}));
