/* eslint-disable @typescript-eslint/no-unsafe-argument */
// import { useState, useEffect } from 'react';
import { useState } from 'react';
import type OrderListItem from 'types/OrderListItem';
import type { GridValidRowModel } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import ThirdPartyProductsModal from 'components/ThirdPartyProductsModal/ThirdPartyProductsModal';

interface SendOrdersButtonParameters {
	row: GridValidRowModel;
}
function SendOrdersButton({ row }: SendOrdersButtonParameters) {
	const [open, setOpen] = useState(false);
	const onCloseDialog = () => {
		setOpen(false);
		console.log('closed dialog handler');
	};

	const onClickSendButton = () => {
		setOpen(true);
	};
	return (
		<>
			<ThirdPartyProductsModal
				orderListItem={row as OrderListItem}
				openDialog={open}
				onCloseDialog={onCloseDialog}
			/>

			<Button
				variant='contained'
				size='small'
				onClick={() => onClickSendButton()}
			>
				Send Order...
			</Button>
		</>
	);
}

export default SendOrdersButton;
