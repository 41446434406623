import React, { useCallback, useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {
	Box,
	Button,
	Chip,
	List,
	Popover,
	Typography
} from '@material-ui/core';
import { useStoreon } from 'storeon/react';
import { useFormContext } from 'react-hook-form';

import Option from './Option';
import useStyles from './styles';
import theme from '../../style/themev2';

const LEVELS = [
	{
		name: 'Type',
		options: [
			{ label: 'Temperature', value: 'temp1' },
			{ label: 'Relative Humidity', value: 'rh' },
			{ label: 'Illuminance', value: 'vis' },
			// { label: 'Ultraviolet', value: 'uv1' },
			{ label: 'Movement', value: 'shock' },
			{ label: 'Leak', value: 'leak' },
			{ label: 'Offline', value: 'offline' }
		]
	},
	{
		name: 'Location',
		options: [{ label: 'All Locations', value: 'all' }]
	},
	{ name: 'Space', options: [{ label: 'All Spaces', value: 'all' }] },
	{
		name: 'Sensor',
		options: [{ label: 'All Sensors', value: 'all' }]
	},
	{
		name: 'Status',
		options: [
			{ label: 'Active', value: 'active' },
			{ label: 'Inactive', value: 'inactive' }
		]
	},
	{
		name: 'Comments',
		options: [
			{ label: 'With Comments', value: 'with comments' },
			{ label: 'Without Comments', value: 'without comments' }
		]
	}
];

function Filter({ loadAlerts }) {
	const classes = useStyles();
	const [activeFilters, setActiveFilters] = useState([]);
	const { locations, sensors, spaces } = useStoreon(
		'locations',
		'sensors',
		'spaces'
	);

	const { handleSubmit, getValues, setValue, watch } = useFormContext();

	const [anchorEl, setAnchorEl] = useState(null);

	const onSubmit = useCallback(
		data => {
			setActiveFilters(getChipsData(data));
			loadAlerts({ ...data, page: 1 });
		},
		[loadAlerts, setActiveFilters, locations, spaces, sensors]
	);

	useEffect(() => {
		const subscription = watch(() => handleSubmit(onSubmit)());
		return () => subscription.unsubscribe();
	}, [watch, setActiveFilters, locations, spaces, sensors]);

	const handleClick = event => setAnchorEl(event.currentTarget);

	const handleClose = () => setAnchorEl(null);

	const getChipLabel = (type, value) => {
		const KEY_TYPES = {
			temp1: 'Temperature',
			rh: 'Humidity',
			vis: 'Illuminance',
			// uv1: 'Ultraviolet',
			ir: 'Infrared',
			shock: 'Movement',
			leak: 'Leak',
			offline: 'Sensor Offline'
		};

		let label = value;
		switch (type) {
			case 'Location':
				label = locations.activeList.find(
					location => location.uuid === value
				)?.name;
				break;
			case 'Type':
				label = KEY_TYPES[value];
				break;
			case 'Space':
				label = spaces.activeList.find(space => space.uuid === value)?.name;
				break;

			case 'Sensor':
				label = sensors.activeList.find(sensor => sensor.uuid === value)?.name;
				break;
			default:
				break;
		}
		return label;
	};

	const getChipsData = filter => {
		const ret = [];
		Object.keys(filter).forEach(filterType => {
			const currentFilter = filter[filterType];
			if (currentFilter.some(f => f === 'all')) {
				ret.push({
					type: filterType,
					value: 'all',
					label: `All ${filterType}s`
				});
			} else {
				currentFilter.map(selection => {
					if (selection !== 'all') {
						ret.push({
							type: filterType,
							value: selection,
							label: getChipLabel(filterType, selection)
						});
					}
				});
			}
		});
		return ret;
	};

	const handleDelete = filter => {
		const orig = getValues(filter.type);
		if (filter.value === 'all') {
			setValue(filter.type, []);
		} else {
			setValue(
				filter.type,
				orig.filter(e => e != filter.value && e != 'all') // This is to remove all selection if we are removing one element
			);
		}
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	return (
		<div>
			<Button
				id='basic-button'
				aria-haspopup='true'
				onClick={handleClick}
				size='md'
				variant='outlined'
			>
				<img
					style={{ marginRight: '11.37px' }}
					alt=''
					src='/img/filter_icon.svg'
				/>
				Filter
			</Button>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left'
				}}
			>
				<List
					component='nav'
					aria-labelledby='nested-list-subheader'
					className={classes.root}
				>
					{LEVELS.map(({ name, options }) => (
						<Option
							key={name}
							name={name}
							options={options}
							{...(name === 'Location'
								? { locations: locations.activeList }
								: {})}
							{...(name === 'Sensor' ? { sensors: sensors.activeList } : {})}
							{...(name === 'Space' ? { spaces: spaces.activeList } : {})}
						/>
					))}
				</List>
			</Popover>
			<ThemeProvider theme={theme}>
				<Box className={classes.chipsContainer}>
					{activeFilters.map(filter => (
						<Chip
							key={filter.value}
							color='primary'
							label={
								<Typography className={classes.chipText}>
									{filter.label}
								</Typography>
							}
							onDelete={e => handleDelete(filter)}
							className={classes.chip}
						/>
					))}
				</Box>
			</ThemeProvider>
		</div>
	);
}

export default Filter;
