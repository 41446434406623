/* eslint-disable @typescript-eslint/no-magic-numbers */
import axios from 'axios';
import type Product from '../../types/Product';

interface GetProductListResponse {
	data: Product[];
}

interface GetProductListParameters {
	isStarterKit?: boolean;
}

const getProductList = async (parameters: GetProductListParameters = {}) => {
	try {
		const { data } = await axios.get<GetProductListResponse>(
			`/api/v2/products`,
			{ params: parameters }
		);

		return data;
	} catch (error) {
		console.error('Error getting the product list:', error);
	}

	return [];
};

export default getProductList;
