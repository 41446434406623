import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useStoreon } from 'storeon/react';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import DrawerHeader from '../../common/drawer/v2/DrawerHeader';
import EditEventProfile from '../../editors/EditEventProfile';
import Loading from '../../common/elements/Loading';
import PreferencesProvider, {
	PreferencesContext
} from '../../../providers/PreferencesProvider';
import ConfirmationModal from '../../common/drawer/v2/ConfirmationModal';
import theme from '../../../style/themev2';
import EventProfileConfirmationDialog from './EventProfileConfirmationDialog';
import useSchema from '../Utils/schema/useSchema';
import useStyles from './styles';
import Api from '../../../api/Api';
import SegmentUtil from '../../../util/SegmentUtil';

function EventProfileDrawer({ eventProfile }) {
	const classes = useStyles();
	const context = useContext(PreferencesContext);
	const { dispatch, locations, spaces, sensors, eventProfiles, user, users } =
		useStoreon(
			'locations',
			'spaces',
			'sensors',
			'eventProfiles',
			'user',
			'users'
		);
	const [currentEventProfile, setCurrentEventProfile] = useState(eventProfile);

	const restrictedNames = eventProfiles.list
		? eventProfiles.list
				.filter(e => e.uuid !== currentEventProfile.uuid)
				.map(e => e.name)
		: [];
	const schema = useSchema({ restrictedNames });
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [openConfirmation, setOpenConfirmation] = useState(false);
	const [toSaveEventProfile, setToSaveEventProfile] = useState();
	const [editable, setEditable] = useState();

	const methods = useForm({
		defaultValues: {
			name: currentEventProfile?.name || '',
			scope: currentEventProfile?.scope?.type || '',
			eventprofilescopes:
				currentEventProfile?.eventprofilescopes?.map(e => e.scopeduuid) || [],
			type:
				currentEventProfile?.type === 'reading'
					? currentEventProfile?.params.reading
					: currentEventProfile?.type,
			threshold: currentEventProfile?.params?.threshold,
			thresholdValue:
				currentEventProfile?.type === 'reading' &&
				currentEventProfile?.params?.thresholdValue
					? parseFloat(
							context.convertUnitToDisplay(
								currentEventProfile?.params.reading,
								currentEventProfile?.params?.thresholdValue
							)
					  )
					: undefined,
			rangeStart:
				currentEventProfile?.type === 'reading' &&
				currentEventProfile?.params?.rangeStart
					? parseFloat(
							context.convertUnitToDisplay(
								currentEventProfile?.params.reading,
								currentEventProfile?.params?.rangeStart
							)
					  )
					: undefined,
			rangeEnd:
				currentEventProfile?.type === 'reading' &&
				currentEventProfile?.params?.rangeEnd
					? parseFloat(
							context.convertUnitToDisplay(
								currentEventProfile?.params.reading,
								currentEventProfile?.params?.rangeEnd
							)
					  )
					: undefined,
			lagStart: currentEventProfile?.params?.lagStart
				? currentEventProfile?.params?.lagStartUnit === 'day'
					? Number(currentEventProfile?.params?.lagStart) / 1440
					: currentEventProfile?.params?.lagStartUnit === 'hour'
					? Number(currentEventProfile?.params?.lagStart) / 60
					: Number(currentEventProfile?.params?.lagStart)
				: undefined,
			lagStartUnit: currentEventProfile?.params?.lagStartUnit || 'minute',
			lagEnd: currentEventProfile?.params?.lagEnd
				? currentEventProfile?.params?.lagEndUnit === 'day'
					? Number(currentEventProfile?.params?.lagEnd) / 1440
					: currentEventProfile?.params?.lagEndUnit === 'hour'
					? Number(currentEventProfile?.params?.lagEnd) / 60
					: Number(currentEventProfile?.params?.lagEnd)
				: undefined,
			lagEndUnit: currentEventProfile?.params?.lagEndUnit || 'minute',
			subscribers: []
		},
		shouldFocusError: true,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const onSubmit = editedEventProfile => {
		const getUserInfo = email => {
			const foundUser = users.find(currentUser => currentUser.email === email);

			if (foundUser) return foundUser.uuid;

			return null;
		};

		const buildSubscribersList = () => {
			if (!editedEventProfile.subscribers) return [];

			return editedEventProfile.subscribers.map(email => ({
				email,
				subscriberUuid: getUserInfo(email)
			}));
		};

		const shouldISubscribe =
			!currentEventProfile?.uuid && editedEventProfile.mySubscription;

		const subscribeMe = shouldISubscribe
			? {
					subscriberUuid: user.uuid,
					status: editedEventProfile.mySubscription
			  }
			: null;

		const subscribersList = subscribeMe
			? [...buildSubscribersList(), subscribeMe]
			: buildSubscribersList();

		subscribersList.map(() =>
			SegmentUtil.track(SegmentUtil.actions.addsubscriber, {
				category: 'app',
				where: 'Edit Drawer'
			})
		);

		const newEditedEventProfile = {
			uuid: currentEventProfile.uuid,
			eventsubs: currentEventProfile.eventsubs,
			name: editedEventProfile.name,
			scope: editedEventProfile.scope,
			type:
				editedEventProfile.type !== 'leak' &&
				editedEventProfile.type !== 'shock'
					? 'reading'
					: editedEventProfile.type,
			eventprofilescopes: editedEventProfile.eventprofilescopes?.map(
				scopeduuid => ({ scopeduuid })
			),
			subscribersList,
			eventtype:
				editedEventProfile.type === 'shock'
					? 'vibration-start'
					: editedEventProfile.type === 'leak'
					? 'leak-detection-start'
					: editedEventProfile.type === 'offline'
					? 'offline'
					: null
		};

		if (newEditedEventProfile.type === 'reading') {
			// lagstart and lagend are being sent in minutes, then we are converting it back when loading the drawer
			newEditedEventProfile.params = {
				reading: editedEventProfile.type,
				lagEnd:
					editedEventProfile.lagEndUnit === 'day'
						? editedEventProfile.lagEnd * 1440
						: editedEventProfile.lagEndUnit === 'hour'
						? editedEventProfile.lagEnd * 60
						: editedEventProfile.lagEnd,
				lagEndUnit: editedEventProfile.lagEndUnit,
				lagStart:
					editedEventProfile.lagStartUnit === 'day'
						? editedEventProfile.lagStart * 1440
						: editedEventProfile.lagStartUnit === 'hour'
						? editedEventProfile.lagStart * 60
						: editedEventProfile.lagStart,
				lagStartUnit: editedEventProfile.lagStartUnit,
				rangeStart: editedEventProfile?.rangeStart
					? context.convertUnitToApi2(
							editedEventProfile?.type,
							editedEventProfile?.rangeStart
					  )
					: null,
				rangeEnd: editedEventProfile?.rangeEnd
					? context.convertUnitToApi2(
							editedEventProfile?.type,
							editedEventProfile?.rangeEnd
					  )
					: null,
				threshold: editedEventProfile.threshold,
				thresholdValue: editedEventProfile?.thresholdValue
					? context.convertUnitToApi2(
							editedEventProfile?.type,
							editedEventProfile?.thresholdValue
					  )
					: null
			};
		}
		setToSaveEventProfile(newEditedEventProfile);
		dispatch('eventprofiles/edit', newEditedEventProfile);
		setOpenConfirmation(true);
	};

	const callSave = () => {
		dispatch('eventprofiles/save');
		dispatch('navstate/update', { drawerOpen: false, drawerContent: null });
	};

	const handleSaveEvent = () => {
		callSave();
		setOpenConfirmation(false);
	};

	const saveConfirmation = ev => {
		methods.handleSubmit(onSubmit)();
		setOpen(false);
	};

	const onDiscard = () => {
		dispatch('navstate/update', {
			drawerOpen: false,
			drawerContent: null,
			onDrawerClose: undefined
		});
	};

	const handleCloseDrawer = ev => {
		if (!methods.formState.isDirty) {
			onDiscard();
		} else {
			setOpen(true);
		}
	};

	useEffect(() => {
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, [methods.formState.isValid]);

	useEffect(() => {
		dispatch('locations/refresh');
		dispatch('sensors/refresh');
		dispatch('spaces/refresh');
	}, []);

	useEffect(() => {
		if (currentEventProfile?.uuid) {
			Api.getEventProfilesHasEvent(currentEventProfile?.uuid).then(({ data }) =>
				setEditable(!data)
			);
		} else {
			setEditable(true);
		}
	}, [currentEventProfile]);

	useEffect(() => {
		if (locations && sensors && spaces && editable !== undefined) {
			setIsLoading(false);
		}
	}, [locations, spaces, sensors, editable]);

	useEffect(() => {
		const updatedEventProfile = eventProfiles.list.find(
			ep => ep.uuid === currentEventProfile.uuid
		);
		if (updatedEventProfile) {
			setCurrentEventProfile(updatedEventProfile);
		}
	}, [eventProfiles]);

	if (isLoading) return <Loading />;

	return (
		<ThemeProvider theme={theme}>
			<PreferencesProvider>
				<FormProvider {...methods}>
					<Grid container direction='column' className={classes.container}>
						<Grid item className={classes.topSticky}>
							<DrawerHeader
								closeCallback={handleCloseDrawer}
								editing
								title={currentEventProfile?.uuid ? 'Edit Event' : 'New Event'}
							/>
						</Grid>
						<Grid item className={classes.content}>
							<Box>
								<EditEventProfile
									eventProfile={currentEventProfile}
									editable={editable}
								/>
							</Box>
						</Grid>
						<Grid className={classes.footer} item>
							<Button
								color='primary'
								onClick={methods.handleSubmit(onSubmit)}
								variant='contained'
								disabled={!methods.formState.isDirty}
							>
								SAVE CHANGES
							</Button>
						</Grid>
					</Grid>
				</FormProvider>
				{open ? (
					<ConfirmationModal
						canSave={methods.formState.isValid}
						open={open}
						onCancel={onDiscard}
						onClose={() => setOpen(false)}
						onAccept={saveConfirmation}
						title={
							methods.formState.isValid
								? 'Unsaved Changes'
								: 'Required Fields Missing'
						}
						text={
							methods.formState.isValid
								? 'You are about to leave this page without saving. All changes will be lost. Do you want to leave without saving?'
								: 'You are about to leave this page but there are some required fields missing in your drawer. Do you want to leave without saving?'
						}
						cancelButtonLabel='Leave without saving'
						acceptButtonLabel={
							methods.formState.isValid ? 'Save Changes' : 'Go Back'
						}
					/>
				) : null}
				{openConfirmation && (
					<EventProfileConfirmationDialog
						eventProfile={{
							...toSaveEventProfile,
							eventsubs: eventProfile?.eventsubs
						}}
						open={openConfirmation}
						onGoBack={() => setOpenConfirmation(false)}
						onSave={handleSaveEvent}
						onDiscard={onDiscard}
					/>
				)}
			</PreferencesProvider>
		</ThemeProvider>
	);
}

export default EventProfileDrawer;
