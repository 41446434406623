/* eslint-disable radix */
import { useRef } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const NumberInputContainer = styled(TextField)`
	& input::-webkit-outer-spin-button,
	& input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	& input[type='number'] {
		-moz-appearance: textfield;
		text-align: center;
		font-size: 15px;
	}
	& .MuiOutlinedInput-root {
		border-radius: 5px;
		border: 1px solid #efefef;
		width: 65px;
		height: 32px;
		padding-left: 7px;
		padding-right: 7px;
	}
`;

const INCREMENT_DECREMENT_VALUE = 1;

interface NumberInputProperties {
	triggerId: number | string;
	onIncrease: (id: number | string, increasedValue: number) => void;
	onDecrease: (id: number | string, decreased: number) => void;
	onChange: (id: number | string, amount: number) => void;
	value: number;
}

export default function NumberInput({
	triggerId,
	onIncrease,
	onDecrease,
	onChange,
	value = 0
}: NumberInputProperties) {
	const numberInput = useRef<HTMLInputElement>();

	const onIncreaseHandler = () => {
		const incresedValue =
			Number.parseInt(numberInput.current?.value ?? '') +
			INCREMENT_DECREMENT_VALUE;
		onIncrease(triggerId, incresedValue);
	};

	const onDecreaseHandler = () => {
		const decresedValue =
			Number.parseInt(numberInput.current?.value ?? '') -
			INCREMENT_DECREMENT_VALUE;
		onDecrease(triggerId, decresedValue);
	};

	const onChangeHandler = () => {
		let inputValue = Number.parseInt(numberInput.current?.value ?? '');
		if (Number.isNaN(inputValue)) {
			inputValue = 0;
		}
		onChange(triggerId, inputValue);
	};

	return (
		<NumberInputContainer
			inputRef={numberInput}
			type='number'
			value={value}
			onChange={onChangeHandler}
			InputProps={{
				startAdornment: (
					<RemoveIcon
						sx={{ fontSize: '12px', cursor: 'pointer' }}
						onClick={onDecreaseHandler}
					/>
				),
				endAdornment: (
					<AddIcon
						sx={{ fontSize: '12px', cursor: 'pointer' }}
						onClick={onIncreaseHandler}
					/>
				)
			}}
		/>
	);
}
