import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	section: {
		marginTop: '24px'
	},
	titleContainer: {
		marginTop: '12px'
	},
	title: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: '22px',
		lineHeight: '28px',
		marginBottom: '5px'
	},
	subtitle: {
		fontFamily: 'Roboto',
		fontWeight: 400,
		fontSize: '16px',
		lineHeight: '24px'
	},
	radioContainer: {
		padding: '0 !important',
		display: 'flex',
		alignItems: 'center'
	}
}));
