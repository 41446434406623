import jwt_decode, { type JwtPayload } from 'jwt-decode';
import { GoogleLogin, type CredentialResponse } from '@react-oauth/google';

interface GoogleResponse {
	given_name: string;
	family_name: string;
	email: string;
	picture: string;
}

interface UserToLogin {
	first_name: string;
	last_name: string;
	email: string;
	avatar: string;
	oauthparty: string;
}

interface GoogleButtonProperties {
	onSuccess: (user: UserToLogin, token: string) => void;
	onFailure: () => void;
}

export default function GoogleButton({
	onSuccess,
	onFailure
}: GoogleButtonProperties) {
	const onSuccessResponse = async (credentialResponse: CredentialResponse) => {
		const token: string = credentialResponse.credential ?? '';
		if (token) {
			const decoded = jwt_decode<JwtPayload>(token) as GoogleResponse;
			const user: UserToLogin = {
				first_name: decoded.given_name,
				last_name: decoded.family_name,
				email: decoded.email,
				avatar: decoded.picture,
				oauthparty: 'google'
			};
			onSuccess(user, token);
		}
	};

	return <GoogleLogin onSuccess={onSuccessResponse} onError={onFailure} />;
}
