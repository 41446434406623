import React from 'react';
import { Button } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import useStyles from './styles';

function PaginatorButton({ type, page, selected, item, onChange }) {
	const classes = useStyles();

	const { onClick, ...restItem } = item;

	const component = {
		page,
		next: <ArrowRightIcon />,
		previous: <ArrowLeftIcon />,
		first: <SkipPreviousIcon />,
		last: <SkipNextIcon />
	};

	const handleOnChange = ev => {
		onClick(ev);
		onChange(ev, page);
	};

	return (
		<>
			{type === 'start-ellipsis' || type === 'end-ellipsis' ? (
				<p className={classes.p}>…</p>
			) : (
				<Button
					data-testid={type}
					key={page}
					disableElevation
					className={`${classes.button} ${selected ? classes.selected : ''} ${
						type !== 'page' ? classes.iconButton : ''
					}`}
					onClick={handleOnChange}
					{...restItem}
				>
					{component[type]}
				</Button>
			)}
		</>
	);
}

export default PaginatorButton;
