import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ExportNotificationProperties {
  open: boolean;
  onClose: () => void;
}

export default function ExportNotification({ open, onClose }: ExportNotificationProperties): JSX.Element {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="export-notification-title"
      aria-describedby="export-notification-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 451,
          bgcolor: 'background.paper',
          borderRadius: 1,
          boxShadow: 24,
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '16px',
            bgcolor: 'primary.main',
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
          }
        }}
      >
        <Box sx={{ position: 'relative' }}>
          <IconButton
            onClick={onClose}
            size="small"
            sx={{
              position: 'absolute',
              right: '16px',
              top: '30px',
              color: 'grey.400',
              padding: '4px',
              '& .MuiSvgIcon-root': {
                fontSize: '24px'
              }
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            id="export-notification-title"
            variant="h5"
            sx={{
              pt: '40px',
              px: '32px',
              pb: '24px',
              fontWeight: 700,
            }}
          >
            We are gathering your data
          </Typography>

          <Typography
            id="export-notification-description"
            variant="body1"
            sx={{
              px: '32px',
              pb: '40px',
            }}
          >
            We are generating the CSV file. In the next few minutes you will receive an email with a link to download it.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
} 