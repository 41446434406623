import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	resumeText: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '24px',
		letterSpacing: '0em',
		'& li': {
			marginLeft: '25px'
		}
	},
	endText: {
		marginTop: '24px',
		'& li': {
			marginLeft: '25px'
		}
	}
}));
