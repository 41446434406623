import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	container: {
		color: '#FFF',
		fontFamily: 'Montserrat',
		fontSize: '20px',
		fontWeight: 700,
		lineHeight: '24px'
	}
}));
