import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import SegmentUtil from '../../../util/SegmentUtil';
import './Actions.css';

export default class ViewScore extends Component {
	render() {
		SegmentUtil.track(SegmentUtil.actions.viewsamplescore);

		return (
			<div>
				<div className='action-title'>
					<h2>View Data and Score</h2>
					<h5>
						Based on the sample data, the space this sensor is in can be scored!
					</h5>
				</div>
				<div className='row'>
					<p>
						This space has some issues. Both temp and RH fluctuations are well
						outside accepted norms. In addition, this space experiences serious
						temperature spikes. Due to these fluctuations and spikes, the space
						receives the following grade on the{' '}
						<a
							target='_new'
							href='https://support.conserv.io/knowledge/scoring-and-standards'
						>
							Conserv scoring scale
						</a>
						:
					</p>
				</div>
				<div className='row' />
				<div>
					<h1>E</h1>
				</div>
				<div variant='contained' color='primary' className='button-div'>
					<Button onClick={() => this.props.callback('complete')}>
						OK, Let Me See the Graphs!
					</Button>
				</div>
			</div>
		);
	}
}
