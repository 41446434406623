/* eslint-disable import/prefer-default-export, unicorn/no-null */
export function isEmpty(stringUnderTest: string | null | undefined): boolean {
	if ((stringUnderTest as unknown) == null) {
		return true;
	}
	if (stringUnderTest === '') {
		return true;
	}
	return false;
}

export function isBlank(stringUnderTest: string | null | undefined): boolean {
	if ((stringUnderTest as unknown) == null) {
		return true;
	}
	if ((stringUnderTest as string).trim() === '') {
		return true;
	}
	return false;
}

export function isPresent(stringUnderTest: string | null | undefined): boolean {
	return !isBlank(stringUnderTest);
}

export function tryTrim(
	stringUnderTest: string | null | undefined
): string | null | undefined {
	if (stringUnderTest != null) {
		return stringUnderTest.trim();
	}

	return stringUnderTest as undefined;
}
