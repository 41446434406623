import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Button,
	Box,
	IconButton,
	Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useStoreon } from 'storeon/react';
import { SubscribersForm } from '../../Forms';
import useStyles from './styles';
import useRoles from 'legacy/hooks/useRoles';

function EventSubscribe({ eventProfile, open, onGoBack, onSave }) {
	const classes = useStyles();
	const {
		formState: { isValid, isDirty, errors }
	} = useFormContext();

	const { isUser } = useRoles();

	const { users, user } = useStoreon('users', 'user');

	return (
		<Dialog open={open} classes={{ paper: classes.paper }} onClose={onGoBack}>
			<DialogTitle
				disableTypography
				className={classes.header}
				id='alert-dialog-title'
			>
				<Typography className={classes.headerTitle} variant='body'>
					Subscribe to "{eventProfile.name}"
				</Typography>
				<IconButton
					aria-label='close'
					className={classes.closeButton}
					onClick={onGoBack}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent classes={{ root: classes.content }}>
				<Box mt={3} display='flex' justifyContent='center'>
					<Typography className={classes.text}>
						Users subscribed to this event will receive email or SMS alerts
						about this event (according to their notification channel
						preference).
					</Typography>
				</Box>
				<Box mt={3} display='flex' className={classes.subsContainer}>
					<SubscribersForm
						eventProfileUuid={eventProfile.uuid}
						isFromModal
						me={user}
						subscribers={eventProfile.eventsubs}
						users={users}
					/>
				</Box>
				{!isUser ? (
					<Box mt={3} display='flex' justifyContent='flex-end'>
						<Button
							disabled={!(isDirty && isValid)}
							className={(classes.button, classes.saveButton)}
							id='complete_profile'
							onClick={onSave}
							variant='contained'
							color='primary'
						>
							Save
						</Button>
					</Box>
				) : null}
			</DialogContent>
		</Dialog>
	);
}

export default EventSubscribe;
