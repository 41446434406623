import React, { useCallback, useRef, useEffect } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { Box, Typography } from '@material-ui/core';

import Comment from '../Comment';
import useStyles from './styles';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

function CommentHistory({ callback, comments, type, typeid, user }) {
	const classes = useStyles();

	const messageContainerRef = useRef(null);

	useEffect(() => {
		if (messageContainerRef?.current) {
			setTimeout(
				() =>
					messageContainerRef.current.scrollIntoView({ behavior: 'smooth' }),
				0
			);
		}
	}, [messageContainerRef, comments]);

	const dates = Object.keys(comments).sort((a, b) =>
		dayjs(a).isAfter(b) ? 1 : -1
	);

	const getDate = useCallback(date => {
		if (dayjs(date).isToday()) return 'Today';
		if (dayjs(date).isYesterday()) return 'Yesterday';

		return dayjs(date).format('MMM DD, YYYY');
	}, []);

	if (!dates.length) return <div />;

	return dates.map(currentDate => (
		<Box key={currentDate}>
			<Typography className={classes.date}>{getDate(currentDate)}</Typography>
			{comments[currentDate].map(comment => (
				<Box ref={messageContainerRef}>
					<Comment
						key={comment.uuid}
						callback={callback}
						comment={comment}
						type={type}
						typeid={typeid}
						user={user}
					/>
				</Box>
			))}
		</Box>
	));
}

export default CommentHistory;
