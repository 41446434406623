/* eslint-disable unicorn/no-abusive-eslint-disable */
/* eslint-disable */

import { Observable, Observer, Subject } from 'rxjs';

/*
 * TODO: Use a service here instead of a static class
 */

export class NetworkActivityTracker {
	private static _goodIconEnabled: boolean;
	private static _warnIconEnabled: boolean;
	private static _goodTimeout: any;
	private static _warnTimeout: any;

	private static _onChangeSubject = new Subject<undefined>();

	public static getChangeObservable(): Observable<undefined> {
		return NetworkActivityTracker._onChangeSubject.asObservable();
	}

	public static isGoodIconEnabled(): boolean {
		return NetworkActivityTracker._goodIconEnabled;
	}

	public static isWarnIconEnabled(): boolean {
		return NetworkActivityTracker._warnIconEnabled;
	}

	private static reportStateChange(): void {
		NetworkActivityTracker._onChangeSubject.next(undefined);
	}

	public static pingGood(): void {
		if (NetworkActivityTracker._goodTimeout != null) {
			clearTimeout(NetworkActivityTracker._goodTimeout);
			NetworkActivityTracker._goodTimeout = undefined;
		}
		NetworkActivityTracker._goodIconEnabled = true;
		NetworkActivityTracker.reportStateChange();
		NetworkActivityTracker._goodTimeout = setTimeout(() => {
			NetworkActivityTracker._goodIconEnabled = false;
			NetworkActivityTracker.reportStateChange();
		}, 250);
	}

	public static pingWarn(): void {
		if (NetworkActivityTracker._warnTimeout != null) {
			clearTimeout(NetworkActivityTracker._warnTimeout);
			NetworkActivityTracker._warnTimeout = undefined;
		}
		NetworkActivityTracker._warnIconEnabled = true;
		NetworkActivityTracker.reportStateChange();
		NetworkActivityTracker._warnTimeout = setTimeout(() => {
			NetworkActivityTracker._warnIconEnabled = false;
			NetworkActivityTracker.reportStateChange();
		}, 250);
	}
}
