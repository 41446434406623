import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { Box, Typography, Tooltip } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory } from 'react-router';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { Icon } from '@mdi/react';
import AdminApi from '../../api/AdminApi';
import CreateAccountDialog from '../../components/admin/CreateAccountDialog';
import icons from '../../style/icons';
import AdminTableMenu from './AdminTableMenu';
import useStyles from './styles';
import AdminOrders from 'components/AdminOrders/AdminOrders';
import AdminUsers from 'components/AdminUsers/AdminUsers';

dayjs.extend(localizedFormat);

function a11yProps(index) {
	return {
		id: `admin-tab-${index}`,
		'aria-controls': `admin-tabpanel-${index}`
	};
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}
/**
 * Basic Conserv admin functions, only available to people with the right role
 */
function Admin({ setFab }) {
	const { dispatch, subscription } = useStoreon('subscription');
	const history = useHistory();
	const classes = useStyles();

	const [isAdmin, setIsAdmin] = useState(false);
	const [customers, setCustomers] = useState([]);
	const [modules, setModules] = useState([]);
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	useEffect(() => {
		setFab([
			{
				icon: <Icon size={1} path={icons.customerAccount} />,
				name: 'Create Customer Account',
				callback: () =>
					dispatch('navstate/update', {
						dialogOpen: true,
						dialogContent: <CreateAccountDialog />
					})
			}
		]);

		AdminApi.validateAdmin()
			.then(() => {
				setIsAdmin(true);
				AdminApi.listCustomers().then(res => {
					const newCustomers = res.data.map(customer => {
						let adminuser = null;
						if (customer.customerusers) {
							adminuser = customer.customerusers
								.map(usr => usr.mnmxuser.email)
								.join(', ');
						}
						return {
							...customer,
							adminuser,
							startdate: customer.subscriptions[0].start,
							enddate: customer.subscriptions[0].end,
							subscriptionType: customer.subscriptions[0].type
						};
					});
					setCustomers(newCustomers);
				});
				AdminApi.listModules().then(res => {
					setModules(res.data);
				});
			})
			.catch(() => {
				history.push('logout');
			});
	}, []);

	const columns = [
		{
			field: 'name',
			headerName: 'Account Name',
			flex: 1,
			renderCell: params => {
				if (params.value == null) {
					return '';
				} else {
					return (
						<Box display='flex' alignItems='flex-end'>
							<Icon
								size={1}
								style={{ minWidth: '24px', marginRight: '4px' }}
								path={icons.customerAccount}
							/>
							<Typography noWrap>{params.value}</Typography>
						</Box>
					);
				}
			}
		},
		{
			field: 'id',
			headerName: 'Customer ID',
			flex: 1
		},
		{
			field: 'subscriptionType',
			headerName: 'Subscription',
			flex: 1
		},
		{
			field: 'startdate',
			headerName: 'Start Date',
			type: 'date',
			flex: 1,
			valueFormatter: params =>
				params.value ? dayjs(params.value).format('L') : 'N/A'
		},
		{
			field: 'adminuser',
			headerName: 'Main POC Email',
			flex: 1,
			renderCell: ({ value }) => (
				<Tooltip title={value}>
					<Typography>{value}</Typography>
				</Tooltip>
			)
		},
		{
			field: 'menu',
			headerName: '',
			sortable: false,
			filterable: false,
			width: 50,
			renderCell: params => (
				<Box>
					<AdminTableMenu customer={params.row} modules={modules} />
				</Box>
			)
		}
	];

	return (
		<>
			{isAdmin ? (
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label='basic tabs example'
						>
							<Tab label='Join Customer' {...a11yProps(0)} />
							<Tab label='Orders' {...a11yProps(1)} />
							<Tab label='Users' {...a11yProps(2)} />
						</Tabs>
					</Box>
					<TabPanel value={value} index={0}>
						<Box>
							<Typography variant='h2'>Conserv Admin Console</Typography>
							<Box pt={2} pb={12} style={{ height: '80vh' }}>
								<DataGrid
									rows={customers}
									columns={columns}
									autoPageSize={true}
								/>
							</Box>
						</Box>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<AdminOrders customers={customers} />
					</TabPanel>
					<TabPanel value={value} index={2}>
						<AdminUsers customers={customers} />
					</TabPanel>
				</Box>
			) : null}
		</>
	);
}

export default Admin;
