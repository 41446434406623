import {
	mdiText,
	mdiOfficeBuilding,
	mdiRadar,
	mdiDoorOpen,
	mdiTune,
	mdiEye,
	mdiAlarm,
	mdiBellOutline,
	mdiCloudUploadOutline,
	mdiDownload,
	mdiFileChart,
	mdiUpdate,
	mdiPoll,
	mdiBarcodeScan,
	mdiCogs,
	mdiCalendarSync,
	mdiAirFilter,
	mdiStairs,
	mdiShape,
	mdiAsterisk,
	mdiPalette,
	mdiImagePlus,
	mdiMapClock,
	mdiThermometerLines,
	mdiWaterPercent,
	mdiCloud,
	mdiLightbulbOn,
	mdiWhiteBalanceSunny,
	mdiWater,
	mdiBug,
	mdiVirus,
	mdiAlert,
	mdiSkullCrossbones,
	mdiMapMarker,
	mdiCogOutline,
	mdiAccountSwitchOutline,
	mdiLifebuoy,
	mdiForumOutline,
	mdiSchoolOutline,
	mdiExitToApp,
	mdiHeadLightbulbOutline,
	mdiSineWave,
	mdiPlus,
	mdiPlusCircle,
	mdiClose,
	mdiCloseCircle,
	mdiChevronRight,
	mdiChevronLeft,
	mdiPlusMinus,
	mdiDotsHorizontal,
	mdiShareVariant,
	mdiEmail,
	mdiEmailSend,
	mdiLayersOffOutline,
	mdiFilePdfBox,
	mdiMagnifyMinusOutline,
	mdiMagnify,
	mdiCity,
	mdiMerge,
	mdiChevronDown,
	mdiChevronUp,
	mdiSwapHorizontal,
	mdiWeb,
	mdiCalendarClock,
	mdiFileTree,
	mdiAt,
	mdiKey,
	mdiGoogle,
	mdiEyeOff,
	mdiAccount,
	mdiAccountGroup,
	mdiBattery,
	mdiMessageReplyText,
	mdiLibrary,
	mdiAlphaICircle,
	mdiVideo,
	mdiOpenInNew,
	mdiLightningBoltCircle,
	mdiSort,
	mdiSortAscending,
	mdiSortDescending,
	mdiAccountDetails,
	mdiCellphone,
	mdiBadgeAccountHorizontal,
	mdiContentSave,
	mdiSchool,
	mdiCheckCircleOutline,
	mdiEmoticonHappyOutline,
	mdiRuler,
	mdiImageFilterHdr,
	mdiLightSwitch,
	mdiArrowUpDownBold,
	mdiChevronLeftCircle,
	mdiChevronRightCircle,
	mdiPageFirst,
	mdiPageLast,
	mdiPrinter,
	mdiFileChartOutline,
	mdiFileDelimitedOutline,
	mdiMenuDown,
	mdiSprout,
	mdiLock,
	mdiArrowRight,
	mdiRouterWireless,
	mdiWifi,
	mdiSwapVerticalCircle,
	mdiFormatVerticalAlignTop,
	mdiFormatVerticalAlignBottom,
	mdiWifiOff,
	mdiEthernet,
	mdiPause,
	mdiPlay,
	mdiSortClockDescendingOutline,
	mdiWeatherCloudyAlert,
	mdiShieldBugOutline,
	mdiFormatListBulletedType,
	mdiTapeMeasure,
	mdiSelectPlace,
	mdiCalendarRange,
	mdiCalendarStart,
	mdiCalendarEnd,
	mdiGroup,
	mdiSync,
	mdiBullhornOutline,
	mdiTournament,
	mdiPencilOutline,
	mdiCommaBoxOutline,
	mdiAccountPlusOutline,
	mdiFilter,
	mdiArrowUp,
	mdiArrowDown,
	mdiArrowExpandVertical,
	mdiGauge,
	mdiRadiator,
	mdiDotsVertical,
	mdiHelp
} from '@mdi/js';

const icons = {
	moreVertical: mdiDotsVertical,
	ashrae: mdiRadiator,
	location: mdiOfficeBuilding,
	sensor: mdiRadar,
	space: mdiDoorOpen,
	level: mdiTune,
	observation: mdiEye,
	alarm: mdiAlarm,
	alert: mdiAlarm,
	event: mdiSwapHorizontal,
	notification: mdiBellOutline,
	upload: mdiCloudUploadOutline,
	download: mdiDownload,
	viewReport: mdiFileChart,
	rerun: mdiUpdate,
	analytics: mdiPoll,
	description: mdiText,
	name: mdiText,
	serial: mdiBarcodeScan,
	manufacturer: mdiCogs,
	calibrationDate: mdiCalendarSync,
	hvacZone: mdiAirFilter,
	floor: mdiStairs,
	spaceType: mdiShape,
	sensitivity: mdiAsterisk,
	materials: mdiPalette,
	addImage: mdiImagePlus,
	timeZone: mdiMapClock,
	temp: mdiThermometerLines,
	temp1: mdiThermometerLines,
	rh: mdiWaterPercent,
	dewpoint: mdiCloud,
	illuminance: mdiLightbulbOn,
	vis: mdiLightbulbOn,
	ultraviolet: mdiWhiteBalanceSunny,
	uv1: mdiWhiteBalanceSunny,
	infrared: mdiLightbulbOn,
	ir: mdiLightbulbOn,
	water: mdiWater,
	pests: mdiBug,
	mold: mdiVirus,
	risk: mdiAlert,
	damage: mdiSkullCrossbones,
	vibration: mdiSineWave,
	address: mdiMapMarker,
	city: mdiCity,
	settings: mdiCogOutline,
	switchAccount: mdiAccountSwitchOutline,
	help: mdiLifebuoy,
	contact: mdiForumOutline,
	knowledge: mdiSchoolOutline,
	ideas: mdiHeadLightbulbOutline,
	logout: mdiExitToApp,
	add: mdiPlus,
	addCircle: mdiPlusCircle,
	close: mdiClose,
	remove: mdiCloseCircle,
	contains: mdiChevronRight,
	back: mdiChevronLeft,
	delta: mdiPlusMinus,
	more: mdiDotsHorizontal,
	share: mdiShareVariant,
	email: mdiEmail,
	sendEmail: mdiEmailSend,
	layers: mdiLayersOffOutline,
	pdf: mdiFilePdfBox,
	cancelZoom: mdiMagnifyMinusOutline,
	search: mdiMagnify,
	merge: mdiMerge,
	rowOpen: mdiChevronDown,
	rowClose: mdiChevronUp,
	website: mdiWeb,
	time: mdiCalendarClock,
	scope: mdiFileTree,
	at: mdiAt,
	password: mdiKey,
	google: mdiGoogle,
	show: mdiEye,
	hide: mdiEyeOff,
	account: mdiAccount,
	customerAccount: mdiAccountGroup,
	bat: mdiBattery,
	comment: mdiMessageReplyText,
	resources: mdiLibrary,
	info: mdiAlphaICircle,
	video: mdiVideo,
	openNew: mdiOpenInNew,
	sensorStatus: mdiLightningBoltCircle,
	sort: mdiSort,
	sortAsc: mdiSortAscending,
	sortDesc: mdiSortDescending,
	firstName: mdiAccountDetails,
	lastName: mdiAccountDetails,
	mobile: mdiCellphone,
	jobTitle: mdiBadgeAccountHorizontal,
	save: mdiContentSave,
	getStarted: mdiSchool,
	complete: mdiCheckCircleOutline,
	smiley: mdiEmoticonHappyOutline,
	distance: mdiRuler,
	elevation: mdiImageFilterHdr,
	lightHours: mdiLightSwitch,
	range: mdiArrowUpDownBold,
	collapseLeftMenu: mdiChevronLeftCircle,
	expandLeftMenu: mdiChevronRightCircle,
	collapseRightMenu: mdiChevronRightCircle,
	expandRightMenu: mdiChevronLeftCircle,
	pageFirst: mdiPageFirst,
	pageLast: mdiPageLast,
	pageNext: mdiChevronRight,
	pagePrevious: mdiChevronLeft,
	print: mdiPrinter,
	report: mdiFileChartOutline,
	csv: mdiFileDelimitedOutline,
	menuDown: mdiMenuDown,
	sustainable: mdiSprout,
	locked: mdiLock,
	action: mdiArrowRight,
	gateway: mdiRouterWireless,
	connected: mdiWifi,
	disconnected: mdiWifiOff,
	ethernet: mdiEthernet,
	gatewayMessages: mdiSwapVerticalCircle,
	received: mdiFormatVerticalAlignTop,
	transmitted: mdiFormatVerticalAlignBottom,
	pause: mdiPause,
	resume: mdiPlay,
	timeThreshold: mdiSortClockDescendingOutline,
	systemProblem: mdiWeatherCloudyAlert,
	trap: mdiShieldBugOutline,
	type: mdiFormatListBulletedType,
	size: mdiTapeMeasure,
	trapPlacement: mdiSelectPlace,
	timeRange: mdiCalendarRange,
	timeStart: mdiCalendarStart,
	timeEnd: mdiCalendarEnd,
	aggregation: mdiGroup,
	lifecycle: mdiSync,
	announcement: mdiBullhornOutline,
	scientificName: mdiTournament,
	edit: mdiPencilOutline,
	delimiter: mdiCommaBoxOutline,
	addUser: mdiAccountPlusOutline,
	expandDown: mdiChevronDown,
	collapseUp: mdiChevronUp,
	filter: mdiFilter,
	arrowUp: mdiArrowUp,
	arrowDown: mdiArrowDown,
	arrowRange: mdiArrowExpandVertical,
	gauge: mdiGauge,
	mdiHelp: mdiHelp
};

export default icons;
