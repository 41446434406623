import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	root: {
		'&.Mui-disabled > fieldset': {
			borderStyle: 'dotted'
		}
	},
	input: {
		padding: '17px'
	}
}));

function CustomTextField(props) {
	const classes = useStyles();
	return <TextField {...props} InputProps={{ classes }} />;
}

export default CustomTextField;
