import React, { useCallback } from 'react';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import { Box, Typography } from '@material-ui/core';

import Event from './Event';
import useStyles from './styles';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

function SensorHistory({ history }) {
	const classes = useStyles();

	const dates = Object.keys(history).sort((a, b) =>
		dayjs(a).isBefore(b) ? 1 : -1
	);

	const getDate = useCallback(date => {
		if (dayjs(date).isToday()) return 'Today';
		if (dayjs(date).isYesterday()) return 'Yesterday';

		return dayjs(date).format('MMMM DD, YYYY');
	}, []);

	if (!dates.length) return <div />;

	return dates.map(currentDate => (
		<Box key={currentDate}>
			<Box className={classes.dateTitle}>
				<Typography className={classes.date}>{getDate(currentDate)}</Typography>
			</Box>
			{history[currentDate].map(event => (
				<Event key={event.uuid} event={event} />
			))}
		</Box>
	));
}

export default SensorHistory;
