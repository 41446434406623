/* eslint-disable @typescript-eslint/no-unsafe-argument */
// import { useState, useEffect } from 'react';
import { SetStateAction, useState } from 'react';
import { Button, TextField } from '@mui/material';
import UserListItem from 'types/UserListItem';
import updatePassword from 'services/admin/user/admin.updatePassword';
import { useStoreon } from 'storeon/react';

interface UpdatePasswordParameters {
	user: UserListItem;
}
export default function UpdatePassword({ user }: UpdatePasswordParameters) {
	const [password, setPassword] = useState('');
	const { dispatch } = useStoreon();

	const onChangeHandler = (event: {
		target: { value: SetStateAction<string> };
	}) => {
		setPassword(event.target.value);
	};
	const onClickSavePassword = async () => {
		try {
			await updatePassword({ userId: user.id, password });
			dispatch('notification/add', {
				message: `Password updated!`,
				severity: 'success',
				variant: 'banner'
			});
		} catch (error) {
			console.log('Error updating password', error);
			dispatch('notification/add', {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				message: `There was a problem updating the user password: ${error}`,
				severity: 'error',
				variant: 'banner'
			});
		} finally {
			setPassword('');
		}
	};
	return (
		<>
			<TextField
				id='outlined-basic'
				label='new password'
				variant='standard'
				onChange={onChangeHandler}
			/>
			<Button
				variant='contained'
				size='small'
				onClick={() => onClickSavePassword()}
			>
				Update Password
			</Button>
		</>
	);
}
