import React, { Component } from 'react';
import { PreferencesConsumer } from '../../../providers/PreferencesProvider';

export default class DataValue extends Component {
	render() {
		return (
			<PreferencesConsumer>
				{preferences => {
					const val = preferences.convertUnitToDisplay(
						this.props.name,
						this.props.value,
						this.props.delta
					);
					if (this.props.round) {
						return Math.round(val);
					}
					const parsed = parseFloat(val).toFixed(1);
					if (isNaN(parsed)) {
						return 'N/A';
					}
					return parsed;
				}}
			</PreferencesConsumer>
		);
	}
}
