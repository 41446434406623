/* eslint-disable @typescript-eslint/no-magic-numbers */
import axios from 'axios';
import type Product from '../../types/Product';

interface GetProductListResponse {
	data: Product[];
}

const getProductList = async () => {
	try {
		const { data } = await axios.get<GetProductListResponse>(
			`/api/v2/3pl/products`
		);

		return data;
	} catch (error) {
		console.error('Error getting the 3pl product list:', error);
	}

	return [];
};

export default getProductList;
