import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	Card,
	CardContent,
	Divider,
	Grid,
	InputAdornment,
	MenuItem,
	Typography,
	Link
} from '@material-ui/core';
import clsx from 'clsx';

import Api from '../../../api/Api';
import DecoratedTextField from '../../common/form/v2/DecoratedTextField';
import DecoratedSelect from '../../common/form/v2/DecoratedSelect';

import labels from '../../../res/data';
import { PreferencesContext } from '../../../providers/PreferencesProvider';
import { useStoreon } from 'storeon/react';

import useStyles from './styles';
import { EMPTY_STRING } from 'legacy/config/constants';

function EditSpace({ editing, space, format, visitLocation }) {
	const context = useContext(PreferencesContext);
	const classes = useStyles();
	const { dispatch } = useStoreon();
	const [alert, setAlert] = useState(null);
	const [levelProfileOptions, setLevelProfileOptions] = useState([]);
	const [facilityOptions, setFacilityOptions] = useState([]);
	const [floorsOptions, setFloorsOptions] = useState([]);
	const [hvacOptions, setHvacOptions] = useState([]);
	const [typeOptions, setTypeOptions] = useState([]);
	const [sensitivityOptions] = useState([
		{ id: 0, value: 0, label: 'Low' },
		{ id: 1, value: 1, label: 'Medium' },
		{ id: 2, value: 2, label: 'High' }
	]);
	const {
		control,
		formState: { errors },
		setValue,
		getValues,
		watch
	} = useFormContext();

	const watchLocation = watch('facilityId');

	const getDistanceDisplay = () =>
		labels.getLabelUnits(
			context.getPreferences().preferences.distanceDisplay,
			true
		);

	const handleVisitLocation = event => {
		visitLocation(getCurrentLocation()?.loc);
	};

	const getCurrentLocation = () =>
		facilityOptions.find(e => e.value === getValues('facilityId'));

	useEffect(() => {
		const locationId = getValues('facilityId');
		const locationUuid = facilityOptions.find(
			e => e.value === locationId
		)?.uuid;

		if (locationUuid) {
			Api.getFloorsAndHvacZonesByLocation(locationUuid)
				.then(({ data: properties }) => {
					const floorsProps = properties.find(e => e.property === 'floor');
					const floors =
						floorsProps?.scope_properties.map(e => ({
							value: e.uuid,
							label: e.property_value
						})) || [];

					const hvacZoneProps = properties.find(e => e.property === 'hvacZone');
					const hvacZone =
						hvacZoneProps?.scope_properties.map(e => ({
							value: e.uuid,
							label: e.property_value
						})) || [];

					setFloorsOptions([{ value: 'none', label: 'None' }, ...floors]);
					setHvacOptions([{ value: 'none', label: 'None' }, ...hvacZone]);

					//if the current space floor or hvac zone is not part of the options we set it as none
					if (!floors.some(floor => floor.value === space.floor_uuid)) {
						setValue('floor_uuid', 'none');
					} else {
						setValue('floor_uuid', space.floor_uuid);
					}
					if (!hvacZone.some(hvac => hvac.value === space.hvac_uuid)) {
						setValue('hvac_uuid', 'none');
					} else {
						setValue('hvac_uuid', space.hvac_uuid);
					}
				})
				.catch(err => {
					setAlert({
						iserror: true,
						title: 'Error Loading Floors and Hvac Zones',
						message:
							'There has been an error loading your Floors and Hvac Zones, please contact Conserv support for assitance'
					});
				});
		}
	}, [watchLocation, facilityOptions]);

	useEffect(() => {
		Api.listLevelProfiles()
			.then(({ data }) => {
				const newLevelProfileOptions = data.map(level => ({
					value: level.seriesuuid,
					label: level.name
				}));
				setLevelProfileOptions(newLevelProfileOptions);
			})
			.catch(err => {
				setAlert({
					iserror: true,
					title: 'Error Loading Level Profiles',
					message:
						'There has been an error loading your level profiles, please contact Conserv support for assitance'
				});
			});
	}, []);

	useEffect(() => {
		Api.getFacilities()
			.then(({ data }) => {
				const facilityoptions = data
					.filter(fac => fac.active)
					.map(fac => ({
						value: fac.id,
						label: fac.name,
						uuid: fac.uuid,
						loc: fac
					}));
				setFacilityOptions(facilityoptions);
			})
			.catch(() => {
				this.props.dispatch('notification/add', {
					message: 'There was an error loading locations',
					severity: 'error',
					variant: 'banner'
				});
			});
	}, []);

	useEffect(() => {
		Api.getSpaceTypes()
			.then(typeres => {
				const typeoptions = typeres.data.map(type => ({
					value: type.uuid,
					label: type.name
				}));
				setTypeOptions(typeoptions);
			})
			.catch(() => {
				dispatch('notification/add', {
					message: 'There was an error loading space types',
					severity: 'error',
					variant: 'banner'
				});
			});
	}, []);

	useEffect(() => {
		if (space) {
			setValue('name', space.name);
			setValue('levelprofile', space.levelprofile);
			setValue('facilityId', space.facilityId);
			setValue('floor_uuid', space.floor_uuid || 'none');
			setValue('hvac_uuid', space.hvac_uuid || 'none');
			setValue('description', space.description);
			setValue('size', formatSpaceSize(space.size));
			setValue('type', space.spacetypeId);
			setValue('sensitivity', space.sensitivity);
			setValue('parentId', 0);
		}
	}, [space]);

	function formatSpaceSize(size) {
		// zone size from API is in sq. meters.  Convert to display units if needed.
		let formatedSize = 0;
		if (space) {
			formatedSize = context.convertUnitToDisplay(
				'spacesize',
				size || 0,
				false
			);
			return formatedSize?.toFixed(2);
		}
	}

	return (
		<Card elevation={0} className={classes.wideCard}>
			<CardContent>
				<Grid
					container
					direction='column'
					spacing={3}
					classes={classes.content}
				>
					<Grid item className={clsx(classes.section, classes.firstSection)}>
						<Typography className={classes.title} variant='h1'>
							General
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='name'
							control={control}
							render={({ field: { onChange, value, ref } }) => (
								<DecoratedTextField
									aria-label='name of this space'
									conservField='space'
									defaultValue={space.name}
									editing={editing}
									error={!!errors.name}
									helperText={errors.name && errors.name.message}
									id='name'
									inputProps={{ height: '56px' }}
									label='Space Name'
									onChange={onChange}
									required
									inputRef={ref}
								/>
							)}
						/>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='levelprofile'
							control={control}
							render={({ field: { onChange, value } }) => (
								<DecoratedSelect
									aria-label='location level profile'
									conservField='level'
									defaultValue={space.levelprofile}
									editing={editing}
									error={errors.levelprofile}
									helperText={
										errors.levelprofile && errors.levelprofile.message
									}
									id='levelprofile'
									label={`Elevation ${labels.getLabelUnits(
										context.getDisplayUnit('distance'),
										format === 'minimal'
									)}`}
									onChange={onChange}
									placeholder='Level Profile'
								>
									{levelProfileOptions.map(({ label, value: levelValue }) => (
										<MenuItem key={levelValue} value={levelValue}>
											{label}
										</MenuItem>
									))}
								</DecoratedSelect>
							)}
						/>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='facilityId'
							control={control}
							render={({ field: { onChange, value, ref } }) => (
								<DecoratedSelect
									required
									aria-label='location'
									defaultValue={space.facilityId}
									editing={editing}
									error={errors.facilityId}
									helperText={errors.facilityId && errors.facilityId.message}
									id='facilityId'
									onChange={onChange}
									placeholder='Location'
									inputProps={{ ref }}
								>
									{facilityOptions.map(({ label, value: levelValue }) => (
										<MenuItem key={levelValue} value={levelValue}>
											{label}
										</MenuItem>
									))}
								</DecoratedSelect>
							)}
						/>
					</Grid>
					<Divider className={classes.divider} />
					<Grid item className={(classes.noPaddingTop, classes.section)}>
						<Typography variant='h3'>Floors</Typography>
						<Typography variant='body1' className={classes.topSubTitle}>
							Assign a floor for this space. To edit a floor, visit the assigned
							location
							{getCurrentLocation() ? (
								<Link
									className={classes.link}
									onClick={handleVisitLocation}
									variant='body1'
								>
									{` ${getCurrentLocation()?.label}`}
								</Link>
							) : null}
							.
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='floor_uuid'
							control={control}
							render={({ field: { onChange, value, error } }) => (
								<DecoratedSelect
									aria-label='floor'
									value={value}
									editing={editing}
									error={error}
									helperText={error?.message}
									id='floor_uuid'
									onChange={onChange}
									placeholder='Floor'
								>
									{floorsOptions.map(({ label, value: levelValue }) => (
										<MenuItem key={levelValue} value={levelValue}>
											{label}
										</MenuItem>
									))}
								</DecoratedSelect>
							)}
						/>
					</Grid>
					<Divider className={classes.divider} />
					<Grid item className={(classes.noPaddingTop, classes.section)}>
						<Typography variant='h3'>HVAC Zones</Typography>
						<Typography variant='body1' className={classes.topSubTitle}>
							Assign a HVAC zone for this space. To edit a Hvac zone, visit the
							assigned location
							{getCurrentLocation() ? (
								<Link
									className={classes.link}
									onClick={handleVisitLocation}
									variant='body1'
								>
									{` ${getCurrentLocation()?.label}`}
								</Link>
							) : null}
							.
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='hvac_uuid'
							control={control}
							render={({ field: { onChange, value, ref, error } }) => (
								<DecoratedSelect
									aria-label='hvac'
									value={value}
									editing={editing}
									error={error}
									helperText={error?.message}
									id='floor_uuid'
									onChange={onChange}
									placeholder='HVAC Zone'
									inputProps={{ ref }}
								>
									{hvacOptions.map(({ label, value: levelValue }) => (
										<MenuItem key={levelValue} value={levelValue}>
											{label}
										</MenuItem>
									))}
								</DecoratedSelect>
							)}
						/>
					</Grid>
					<Divider className={classes.divider} />
					<Grid item className={classes.section}>
						<Typography className={classes.title} variant='h1'>
							Details
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='description'
							control={control}
							render={({ field: { onChange, value } }) => (
								<DecoratedTextField
									aria-label='description of this location'
									conservField='description'
									defaultValue={space.description}
									editing={editing}
									error={errors.description}
									helperText={errors.description && errors.description.message}
									id='description'
									label='Description'
									multiline
									onChange={onChange}
									rows={4}
								/>
							)}
						/>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='size'
							control={control}
							render={({ field: { onChange, value } }) => (
								<DecoratedTextField
									aria-label={`Size (square ${getDistanceDisplay()})`}
									defaultValue={formatSpaceSize(space.size)}
									editing={editing}
									error={errors.size}
									helperText={errors.size && errors.size.message}
									id='size'
									type='number'
									endAdornment={
										<InputAdornment
											className={classes.endAdornment}
											position='end'
										>
											{getDistanceDisplay()}
										</InputAdornment>
									}
									inputProps={{
										height: '56px'
									}}
									label={`Size (square ${getDistanceDisplay()})`}
									onChange={onChange}
								/>
							)}
						/>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='spacetypeId'
							control={control}
							render={({ field: { onChange, value } }) => (
								<DecoratedSelect
									aria-label='spacetypeId'
									defaultValue={space.spacetypeId}
									editing={editing}
									error={errors.type}
									helperText={errors.type && errors.type.message}
									id='spacetypeId'
									onChange={onChange}
									placeholder='Type'
								>
									{typeOptions.map(({ label, value: levelValue }) => (
										<MenuItem key={levelValue} value={levelValue}>
											{label}
										</MenuItem>
									))}
								</DecoratedSelect>
							)}
						/>
					</Grid>
					<Grid item className={classes.section}>
						<Controller
							name='sensitivity'
							control={control}
							render={({ field: { onChange, value } }) => (
								<DecoratedSelect
									aria-label='sensitivity'
									defaultValue={space.sensitivity}
									editing={editing}
									error={errors.sensitivity}
									helperText={errors.sensitivity && errors.sensitivity.message}
									id='sensitivity'
									onChange={onChange}
									placeholder='Sensitivity'
								>
									{sensitivityOptions.map(({ label, value: levelValue }) => (
										<MenuItem key={levelValue} value={levelValue}>
											{label}
										</MenuItem>
									))}
								</DecoratedSelect>
							)}
						/>
					</Grid>
					<Divider className={classes.divider} />
					<Grid item className={classes.section}>
						<Typography className={classes.title} variant='h1'>
							Image
						</Typography>
						<Typography className={classes.body} variant='body1'>
							Upload an image to help you keep track of your space.
						</Typography>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export default EditSpace;
