import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	Box,
	Card,
	CardContent,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	Typography
} from '@material-ui/core';

import Api from '../../../api/Api';
import DecoratedTextField from '../../common/form/v2/DecoratedTextField';
import DecoratedSelect from '../../common/form/v2/DecoratedSelect';
import EditAddress from '../EditAddress';

import labels from '../../../res/data';
import { PreferencesContext } from '../../../providers/PreferencesProvider';

import useStyles from './styles';
import SortableList from '../../common/list/SortableList';
import ConfirmationDialogFloorHvacZone from '../../common/drawer/v2/ConfirmationDialogFloorHvacZone';

function EditLocation({ ashraeOptions, editing, facility, format }) {
	const context = useContext(PreferencesContext);
	const classes = useStyles();

	const [alert, setAlert] = useState(null);
	const [levelProfileOptions, setLevelProfileOptions] = useState([]);
	const [deleteDialogParams, setDeleteDialogParams] = useState({ open: false });

	const {
		control,
		formState: { errors },
		setValue
	} = useFormContext();

	useEffect(() => {
		Api.listLevelProfiles()
			.then(response => {
				setLevelProfileOptions(
					response.data.map(level => ({
						id: level.seriesuuid,
						value: level.seriesuuid,
						label: level.name
					}))
				);
			})
			.catch(err => {
				setAlert({
					iserror: true,
					title: 'Error Loading Level Profiles',
					message:
						'There has been an error loading your level profiles, please contact Conserv support for assitance'
				});
			});
	}, []);

	useEffect(() => {
		if (facility) {
			setValue('name', facility.name);
			setValue('floors', facility.floors);
			setValue('hvacZone', facility.hvacZone);
			setValue('description', facility.description);
			setValue('country', facility.address.country);
			setValue('city', facility.address.city);
			setValue('state', facility.address.state);
			setValue('zip', facility.address.zip);
			setValue('add_1', facility.address.add_1);
			setValue('elevation', facility.address.elevation);
		}
	}, [facility]);

	const getElevation = value => {
		const elevation = context.convertUnitToDisplay('elevation', value);

		if (isNaN(elevation)) return 'Update address to get elevation';

		return elevation;
	};

	const canDeleteWithLabel = label => async element => {
		const promise = new Promise((resolve, reject) => {
			Api.getSpacesUsingFloorHvacZoneByUuid(element.uuid).then(({ data }) => {
				const spacesInvolved = data.spaces?.length;
				if (spacesInvolved > 0) {
					setDeleteDialogParams({
						open: true,
						onCancel: reject,
						onAccept: resolve,
						title: `Delete ${element.primary}?`,
						text: (
							<Typography variant='body1'>
								There {spacesInvolved > 1 ? `are` : `is`}{' '}
								<b>
									{spacesInvolved > 1 ? `${spacesInvolved} spaces` : `1 space`}
								</b>{' '}
								linked to this {label.toLowerCase()}. Upon saving,{' '}
								{spacesInvolved > 1 ? `these spaces` : `this space`} will no
								longer be linked to this {label.toLowerCase()}. This cannot be
								undone.
							</Typography>
						),
						list: (
							<Typography variant='body1'>
								<b>Affected {spacesInvolved > 1 ? `spaces` : `space`}:</b>
								<ul>
									{data.spaces
										.sort((a, b) => a.name.localeCompare(b.name))
										.map(e => (
											<li className={classes.listBullet}>
												<b>{e.name}</b>
											</li>
										))}
								</ul>
							</Typography>
						),
						cancelButtonLabel: `No, Keep ${label}`,
						acceptButtonLabel: `Continue`
					});
				} else {
					resolve();
				}
			});
		})
			.then(() => true)
			.catch(() => {
				return false;
			})
			.finally(() => setDeleteDialogParams({ open: false }));
		const result = await promise;
		return result;
	};

	const canRenameWithLabel = label => async element => {
		const promise = new Promise((resolve, reject) => {
			Api.getSpacesUsingFloorHvacZoneByUuid(element.uuid).then(({ data }) => {
				const spacesInvolved = data.spaces?.length;
				if (spacesInvolved > 0) {
					setDeleteDialogParams({
						open: true,
						onCancel: reject,
						onAccept: resolve,
						title: `Rename ${element.primary}?`,
						text: (
							<Typography variant='body1'>
								This {label} is associated with{' '}
								<b>
									{spacesInvolved > 1 ? `${spacesInvolved} spaces` : `1 space`}
								</b>
								{'. '}
								Upon saving, this {label}’s name will be updated for{' '}
								{spacesInvolved > 1 ? `those spaces` : `that space`}.
							</Typography>
						),
						list: (
							<Typography variant='body1'>
								<b>Affected {spacesInvolved > 1 ? `spaces` : `space`}:</b>
								<ul>
									{data.spaces
										.sort((a, b) => a.name.localeCompare(b.name))
										.map(e => (
											<li className={classes.listBullet}>
												<b>{e.name}</b>
											</li>
										))}
								</ul>
							</Typography>
						),
						cancelButtonLabel: 'No, Keep name',
						acceptButtonLabel: `Continue`
					});
				} else {
					resolve();
				}
			});
		})
			.then(() => true)
			.catch(() => {
				return false;
			})
			.finally(() => setDeleteDialogParams({ open: false }));
		const result = await promise;
		return result;
	};

	return (
		<Card elevation={0} className={classes.wideCard}>
			<CardContent>
				<Grid container direction='column' spacing={3}>
					<Box px={4} py={3}>
						<Grid container direction='column' spacing={3}>
							<Grid item>
								<Typography className={classes.title} variant='h1'>
									General
								</Typography>
								<Typography className={classes.body} variant='body1'>
									Edit information about your location.
								</Typography>
							</Grid>
							<Grid item>
								<Controller
									name='name'
									control={control}
									render={({ field: { onChange, value, ref } }) => (
										<DecoratedTextField
											aria-label='name of this location'
											conservField='location'
											editing={editing}
											error={errors.name}
											helperText={errors.name && errors.name.message}
											id='name'
											inputProps={{ height: '56px' }}
											label='Location Name'
											onChange={onChange}
											required
											value={value}
											inputRef={ref}
										/>
									)}
								/>
							</Grid>
							<Grid item>
								<Controller
									name='description'
									control={control}
									render={({ field: { onChange, value } }) => (
										<DecoratedTextField
											aria-label='description of this location'
											conservField='description'
											editing={editing}
											error={errors.description}
											helperText={
												errors.description && errors.description.message
											}
											id='description'
											label='Description'
											multiline
											onChange={onChange}
											rows={4}
											value={value}
										/>
									)}
								/>
							</Grid>
							<Grid item>
								<Controller
									name='levelprofile'
									control={control}
									render={({ field: { onChange, value } }) => (
										<DecoratedSelect
											aria-label='location level profile'
											conservField='level'
											defaultValue={facility.levelprofile}
											editing={editing}
											error={errors.levelprofile}
											helperText={
												errors.levelprofile && errors.levelprofile.message
											}
											id='levelprofile'
											label={`Elevation ${labels.getLabelUnits(
												context.getDisplayUnit('distance'),
												format === 'minimal'
											)}`}
											onChange={onChange}
											placeholder='Level Profile'
											value={value}
										>
											{levelProfileOptions.map(
												({ label, value: levelValue }) => (
													<MenuItem key={levelValue} value={levelValue}>
														{label}
													</MenuItem>
												)
											)}
										</DecoratedSelect>
									)}
								/>
							</Grid>
							<Grid item>
								<Controller
									name='elevation'
									control={control}
									render={({ field: { onChange, value } }) => (
										<DecoratedTextField
											aria-label='elevation above the sea'
											conservField='elevation'
											editing={false}
											error={errors.elevation}
											helperText={errors.elevation && errors.elevation.message}
											id='elevation'
											inputProps={{ height: '56px' }}
											label={`Elevation ${labels.getLabelUnits(
												context.getDisplayUnit('distance'),
												format === 'minimal'
											)}`}
											onChange={onChange}
											value={getElevation(value)}
										/>
									)}
								/>
							</Grid>
							<Grid item>
								<FormControl>
									<InputLabel id='select-ashrae' variant='outlined'>
										ASHRAE Classification
									</InputLabel>
									<Controller
										name='ashrae'
										control={control}
										render={({ field: { onChange, value } }) => (
											<Select
												defaultValue={(facility.ashrae || [])[0]?.uuid || ''}
												label='ASHRAE Classification'
												labelId='select-ashrae'
												onChange={onChange}
												value={value}
												variant='outlined'
											>
												<MenuItem value='none'>None</MenuItem>
												{ashraeOptions.map(({ property, uuid }) => (
													<MenuItem key={uuid} value={uuid}>
														{property}
													</MenuItem>
												))}
											</Select>
										)}
									/>
								</FormControl>
							</Grid>
						</Grid>
					</Box>
					<Box my={2}>
						<Divider />
					</Box>
					<Box px={4} py={1}>
						<Grid container direction='column' spacing={3}>
							<Grid item>
								<Box display='flex'>
									<Typography className={classes.title} variant='h1'>
										Floors
									</Typography>
								</Box>
								<Typography className={classes.body} variant='body1'>
									Create and name all of the floors in your location.
								</Typography>
							</Grid>
							<Grid item className={classes.noPaddingTop}>
								<Controller
									name='floors'
									control={control}
									defaultValue={facility.floors}
									render={({ field: { onChange, value } }) => (
										<SortableList
											label={'Floor'}
											list={value}
											onChange={onChange}
											canDelete={canDeleteWithLabel('Floor')}
											canRename={canRenameWithLabel('floor')}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box my={2}>
						<Divider />
					</Box>
					<Box px={4} py={1}>
						<Grid container direction='column' spacing={3}>
							<Grid item>
								<Box display='flex'>
									<Typography className={classes.title} variant='h1'>
										HVAC Zones
									</Typography>
								</Box>
								<Typography className={classes.body} variant='body1'>
									Create all of your HVAC zones to assign to your space later.
								</Typography>
							</Grid>
							<Grid item className={classes.noPaddingTop}>
								<Controller
									name='hvacZone'
									control={control}
									defaultValue={facility.hvacZone}
									render={({ field: { onChange, value } }) => (
										<SortableList
											label={'HVAC Zone'}
											list={value}
											onChange={onChange}
											canDelete={canDeleteWithLabel('HVAC Zone')}
											canRename={canRenameWithLabel('HVAC Zone')}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Box>
					<Box my={2}>
						<Divider />
					</Box>
					<Box px={4} py={3}>
						<Grid container direction='column' spacing={3}>
							<Grid item>
								<Box display='flex'>
									<Typography className={classes.title} variant='h1'>
										Address
									</Typography>
									<Typography className={classes.required} variant='body1'>
										*
									</Typography>
								</Box>
								<Typography className={classes.body} variant='body1'>
									The address of the location is used to pull accurate weather
									data
								</Typography>
							</Grid>
							<Grid item>
								<EditAddress editing={editing} />
							</Grid>
						</Grid>
					</Box>
					<Box my={2}>
						<Divider />
					</Box>
					<Box px={4} py={3}>
						<Grid container direction='column' spacing={3}>
							<Grid item>
								<Typography className={classes.title} variant='h1'>
									Image
								</Typography>
							</Grid>
						</Grid>
					</Box>
				</Grid>
				{deleteDialogParams.open ? (
					<ConfirmationDialogFloorHvacZone {...deleteDialogParams} />
				) : null}
			</CardContent>
		</Card>
	);
}

export default EditLocation;
