/* eslint-disable import/prefer-default-export, unicorn/no-null, unicorn/prevent-abbreviations, max-classes-per-file, @typescript-eslint/parameter-properties, @typescript-eslint/no-explicit-any, @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-extraneous-class, no-console */

/**
 * TODO:  Consider moving this to a common location
 */

export class Logger {
	public constructor(private readonly loggerName: string) {}

	public warn(argument0: any, ...extra: any[]): void {
		if (extra.length === 0) {
			console.warn(`[ WARN] ${this.loggerName}   ${argument0}`);
		} else if (extra.length > 0) {
			console.warn(`[ WARN] ${this.loggerName}   ${argument0}`, extra);
		}
	}
}

export class LoggerGetter {
	private static readonly loggers = new Map<string, Logger>();

	public static get(loggerName: string): Logger {
		if (!this.loggers.has(loggerName)) {
			const logger = new Logger(loggerName);
			this.loggers.set(loggerName, logger);
		}

		return this.loggers.get(loggerName) as Logger;
	}
}

export const getLogger = (loggerName: string) => LoggerGetter.get(loggerName);
