function formatTimeFromMinutes(minutes) {
	if (minutes >= 1) {
		const weeks = Math.floor(minutes / 1440 / 7);
		const days = Math.floor(minutes / 1440);
		const hours = Math.floor((minutes % 1440) / 60);
		const minutesLeft = Math.floor((minutes % 1440) % 60);

		const weekFormat = weeks > 0 ? `${weeks}w ` : '';
		const dayFormat = days > 0 ? `${days}d ` : '';
		const hoursFormat = hours > 0 ? `${hours}h ` : '';
		const minutesFormat = minutesLeft > 0 ? `${minutesLeft}m ` : '';

		return weeks > 0
			? `${weekFormat} ${days % 7 > 0 ? `${days % 7}d ` : ''}`
			: `${dayFormat} ${hoursFormat} ${minutesFormat}`;
	}

	return '0m';
}

export { formatTimeFromMinutes };
