import { Box, Typography } from '@material-ui/core/';
import { Icon } from '@mdi/react';
import WifiTetheringOutlinedIcon from '@material-ui/icons/WifiTetheringOutlined';
import BarChartOutlinedIcon from '@material-ui/icons/BarChartOutlined';
import InsertChartOutlinedOutlinedIcon from '@material-ui/icons/InsertChartOutlinedOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import SettingsIcon from '@material-ui/icons/Settings';
import ConservNavLink from '../ConservNavLink';
import icons from '../../../style/icons';
import SegmentUtil from '../../../util/SegmentUtil';
import useStyles from './styles';
import PermissionProvider from '../../../providers/PermissionProvider';
import { useHistory } from 'react-router-dom';

export default function SidebarList(props) {
	const history = useHistory();
	const classes = useStyles(props);

	const trackEventPage = () =>
		SegmentUtil.track(SegmentUtil.actions.trackeventpage);

	const onClikcStore = () => {
		SegmentUtil.track(SegmentUtil.actions.clickStore);
	};

	const routeToDeveloperPage = () => {
		history.push('/ri');
	};

	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='space-between'
			pt={1}
			className={classes.leftNavBarContainer}
		>
			<Box>
				<ConservNavLink classes={{ root: classes.text }} to='/sensor'>
					<Box classes={{ root: classes.leftNavBar }}>
						<WifiTetheringOutlinedIcon size='24px' className={classes.icon} />
						<Typography variant='caption' className={classes.labelText}>
							Sensors
						</Typography>
					</Box>
				</ConservNavLink>
				<ConservNavLink classes={{ root: classes.text }} to='/ipm'>
					<Box classes={{ root: classes.leftNavBar }}>
						<Icon path={icons.pests} size='24px' className={classes.icon} />
						<Typography variant='caption' className={classes.labelText}>
							Pests
						</Typography>
					</Box>
				</ConservNavLink>
				<ConservNavLink classes={{ root: classes.text }} to='/observations'>
					<Box classes={{ root: classes.leftNavBar }}>
						<Icon
							path={icons.observation}
							size='24px'
							className={classes.icon}
						/>
						<Typography variant='caption' className={classes.labelText}>
							Observe
						</Typography>
					</Box>
				</ConservNavLink>
				<ConservNavLink classes={{ root: classes.text }} to='/analytics'>
					<Box classes={{ root: classes.leftNavBar }}>
						<BarChartOutlinedIcon size='24px' className={classes.icon} />
						<Typography variant='caption' className={classes.labelText}>
							Analyze
						</Typography>
					</Box>
				</ConservNavLink>
				<ConservNavLink classes={{ root: classes.text }} to='/reports'>
					<Box classes={{ root: classes.leftNavBar }}>
						<InsertChartOutlinedOutlinedIcon
							size='24px'
							className={classes.icon}
						/>
						<Typography variant='caption' className={classes.labelText}>
							Reports
						</Typography>
					</Box>
				</ConservNavLink>
				<ConservNavLink classes={{ root: classes.text }} to='/event/history'>
					<Box classes={{ root: classes.leftNavBar }} onClick={trackEventPage}>
						<AccessTimeOutlinedIcon size='24px' className={classes.icon} />
						<Typography variant='caption' className={classes.labelText}>
							Events
						</Typography>
					</Box>
				</ConservNavLink>
				<PermissionProvider requiredRole='superadmin' value={{}}>
					<Box
						classes={{ root: classes.leftNavBar }}
						sx={{ cursor: 'default' }}
					>
						<Typography
							variant='caption'
							className={classes.labelText}
							// style={{ color: 'rgb(55, 0, 150)' }}
							onClick={routeToDeveloperPage}
						>
							Developer
						</Typography>
					</Box>
				</PermissionProvider>
			</Box>
			<Box>
				<ConservNavLink 
					classes={{ root: classes.text }} 
					to='/import'
					isActive={(match, location) => {
						return location.pathname === '/import' || location.pathname === '/export';
					}}
				>
					<Box classes={{ root: classes.leftNavBar }}>
						<ImportExportIcon size='24px' className={classes.icon} />
						<Typography variant='caption' className={classes.labelText}>
							Import / Export
						</Typography>
					</Box>
				</ConservNavLink>
				<ConservNavLink classes={{ root: classes.text }} to='/setup/*'>
					<Box classes={{ root: classes.leftNavBar }}>
						<SettingsIcon size='24px' className={classes.icon} />
						<Typography variant='caption' className={classes.labelText}>
							Setup
						</Typography>
					</Box>
				</ConservNavLink>
			</Box>
		</Box>
	);
}
