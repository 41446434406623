import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	Box,
	Button,
	TextField,
	Typography,
	Link
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Api from '../../api/Api';
import GradiantDialogHeader from '../common/dialog/GradientDialogHeader';
import { colors } from '../../style/colors';

const useStyles = makeStyles({
	title: {
		fontFamily: 'Sansita',
		fontSize: '40px',
		fontWeight: 400,
		fontSyle: 'normal',
		lineHeight: '56px',
		letterSpacing: '-0.01em'
	},
	subTitle: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	},
	emailLink: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 700,
		color: colors.purpleHearth.primary,
		textDecoration: 'none',
		lineHeight: '22px',
		letterSpacing: '-0.01em'
	},
	input: {
		padding: '16px'
	},
	resetPasswordButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '22px',
		paddingLeft: '22px',
		paddingTop: '8px',
		paddingBottom: '8px',
		background: colors.purpleHearth.primary
	},
	returnToLoginButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '22px',
		paddingLeft: '22px',
		paddingTop: '8px',
		paddingBottom: '8px',
		color: colors.purpleHearth.primary
	}
});

function LoginHelp(props) {
	const classes = useStyles();

	const [formValid, setFormValid] = useState(false);
	const [email, setEmail] = useState('');
	const [formValidation, setFormValidation] = useState({});
	const [sent, setSent] = useState(false);
	const [sentError, setSentError] = useState('');

	const sendReset = () => {
		Api.requestPasswordReset(email)
			.then(() => {
				setSent(true);
			})
			.catch(() => {
				// how do we display an error here?
			});
	};

	const handleEmailChange = event => {
		const newEmail = event.target.value;
		if (newEmail.indexOf('@') != -1) {
			Api.validateEmail(newEmail).then(result => {
				if (result.data.valid && result.data.emailexists) {
					setFormValidation({});
					setFormValid(true);
				} else {
					if (!result.data.valid) {
						setFormValidation({
							emailError: 'A valid email address is required'
						});
					} else if (!result.data.emailexists) {
						setFormValidation({
							emailError: 'The provided email was not found'
						});
					}
					setFormValid(false);
				}
			});
		}
		setEmail(newEmail);
	};

	const handleClose = () => {
		setEmail('');
		setSent(false);
		setSentError(false);
		props.onClose();
	};

	const handleKeypress = e => {
		if (e.keyCode === 13) {
			Api.validateEmail(e.target.value).then(result => {
				if (result.data.valid && result.data.emailexists) {
					sendReset();
				}
			});
		}
	};

	const getTitle = () =>
		sent ? (
			<Typography classes={{ root: classes.title }} variant='h1'>
				Email Sent
			</Typography>
		) : (
			<Typography classes={{ root: classes.title }} variant='h1'>
				Reset Password
			</Typography>
		);

	const getSubTitle = () =>
		sent ? (
			<Typography classes={{ root: classes.subTitle }} variant='h1'>
				Click the link in your email to reset your password.
			</Typography>
		) : (
			<Typography classes={{ root: classes.subTitle }} variant='h1'>
				It happens, no worries! We’ll send you instructions on reset your
				password. If you’re still having trouble, contact{' '}
				<Link
					classes={{ root: classes.emailLink }}
					href='mailto:support@conserv.io'
				>
					support@conserv.io
				</Link>{' '}
				for help.
			</Typography>
		);

	return (
		<Dialog open={props.open}>
			<GradiantDialogHeader variant='newInfo' />
			{sent ? (
				<DialogContent
					style={{
						paddingTop: '33px',
						paddingBottom: '24px',
						width: 'auto',
						minWidth: '680px'
					}}
				>
					<Box mb={2}>{getTitle()}</Box>
					<Box mb={4}>
						<Typography variant='body1'>{getSubTitle()}</Typography>
					</Box>
					<Box mb='17px'>
						{sentError ? (
							<Button
								variant='contained'
								color='primary'
								onClick={handleClose}
								classes={{ root: classes.resetPasswordButton }}
							>
								Return to login
							</Button>
						) : (
							<Button
								variant='contained'
								color='primary'
								onClick={handleClose}
								classes={{ root: classes.resetPasswordButton }}
							>
								Return to login
							</Button>
						)}
					</Box>
				</DialogContent>
			) : (
				<DialogContent
					style={{ paddingTop: '32px', paddingBottom: '24px', width: 'auto' }}
				>
					<Box mb={2}>{getTitle()}</Box>
					<Box mb={4}>
						<Typography variant='body1'>{getSubTitle()}</Typography>
					</Box>
					<Box flexGrow={1} pr={1} mb={4}>
						<TextField
							variant='outlined'
							onChange={handleEmailChange}
							value={email}
							error={Boolean(formValidation.emailError)}
							helperText={formValidation.emailError}
							onKeyDown={handleKeypress}
							placeholder='Email Address'
							InputProps={{
								classes: {
									input: classes.input
								}
							}}
						/>
					</Box>
					<Box display='flex' pb='25px'>
						<Box>
							<Button
								variant='contained'
								color='primary'
								onClick={sendReset}
								disabled={!formValid}
								classes={{ root: classes.resetPasswordButton }}
							>
								Reset Password
							</Button>
						</Box>
						<Box ml='11px'>
							<Button
								color='primary'
								onClick={handleClose}
								classes={{ root: classes.returnToLoginButton }}
							>
								Return to login
							</Button>
						</Box>
					</Box>
				</DialogContent>
			)}
		</Dialog>
	);
}

export default LoginHelp;
