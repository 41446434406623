import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	Grid,
	MenuItem,
	Tab,
	Tabs,
	Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useStoreon } from 'storeon/react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';

import Api from '../../../api/Api';

import DecoratedSelect from '../../common/form/v2/DecoratedSelect';
import DecoratedTextField from '../../common/form/v2/DecoratedTextField';
import DecoratedDatePicker from '../../common/form/v2/DecoratedDatePicker';
import DrawerHeader from '../../common/drawer/v2/DrawerHeader';
import TabPanel from '../../common/TabPanel';
import global from '../../../res/global';
import Comments from '../../comments/Comments';
import ImageForm from '../../common/ImageForm';
import ConfirmationDialog from '../../common/drawer/v2/ConfirmationDialog';
import theme from '../../../style/themev2';

import useStyles from './styles';
import './index.css';
import * as observationUtil from '../../../../utils/observation/observationUtil';

const schema = yup.object({
	observation: yup.string().trim().required('The observation name is required'),
	scope: yup.string().required('The scope is required'),
	scopeOption: yup.string().required('The scope is required'),
	startTime: yup
		.date('Invalid date format')
		.nullable()
		.required('The time is required')
		.typeError('Invalid Date Format'),
	endTime: yup.date().nullable().typeError('Invalid Date Format')
});

function ObservationDrawer({ observation, onClose }) {
	const classes = useStyles();

	const {
		control,
		formState: { errors, isDirty },
		handleSubmit,
		setValue,
		watch
	} = useForm({
		defaultValues: {
			observation:
				observation.observation?.name || observation.observation?.description
					? observation.observation?.name ||
					  observation.observation?.description
					: '',
			description:
				observationUtil.tryGetCompositeObservationDescriptionForDisplay(
					observation
				) ?? '',
			endTime: observation.observationendtime
				? dayjs(observation.observationendtime)
				: observation.uuid
				? null
				: dayjs(),
			scope: observation.scope
				? global.scopeOptions.find(option => option.value === observation.scope)
						.value
				: '',
			startTime: observation.observationtime
				? dayjs(observation.observationtime)
				: dayjs()
		},
		mode: 'all',
		resolver: yupResolver(schema),
		shouldFocusError: true
	});

	const { dispatch } = useStoreon();

	const [label, setLabel] = useState([]);
	const [options, setOptions] = useState([]);
	const [currentTab, setCurrentTab] = useState(0);
	const [currentObservation, setCurrentObservation] = useState(null);
	const [open, setOpen] = useState(false);
	const [isDirtyImg, setIsDirtyImg] = useState(false);

	const mapOptions = useCallback(
		rawOptions =>
			setOptions(
				rawOptions
					.filter(currentOption => currentOption.active)
					.map(currentOption => ({
						id: currentOption.uuid,
						value: currentOption.uuid,
						label: currentOption.name
					}))
			),
		[]
	);

	const loadLocations = () =>
		Api.getFacilities()
			.then(response => mapOptions(response.data))
			.catch(() =>
				dispatch('notification/add', {
					message: 'There was an error loading location options',
					severity: 'error',
					variant: 'banner'
				})
			);

	const loadSpaces = () =>
		Api.getAllZones()
			.then(response => mapOptions(response.data))
			.catch(() =>
				dispatch('notification/add', {
					message: 'There was an error loading space options',
					severity: 'error',
					variant: 'banner'
				})
			);

	const loadSensors = () =>
		Api.getCustomerSensors(true)
			.then(response => mapOptions(response.data))
			.catch(() =>
				dispatch('notification/add', {
					message: 'There was an error loading sensor options',
					severity: 'error',
					variant: 'banner'
				})
			);

	useEffect(() => {
		setLabel(observation.scope);
		if (observation.scope === 'location') loadLocations();
		if (observation.scope === 'space') loadSpaces();
		if (observation.scope === 'sensor') loadSensors();
	}, [observation]);

	useEffect(() => {
		const subscription = watch((value, { name, type }) => {
			if (type === 'change' && name === 'scope') {
				setLabel(value.scope);
				setValue('scopeOption', '');
				if (value.scope === 'location') return loadLocations();
				if (value.scope === 'space') return loadSpaces();
				if (value.scope === 'sensor') return loadSensors();
			}
		});

		return () => subscription.unsubscribe();
	}, [watch]);

	useEffect(() => {
		if (observation.uuid) {
			setLabel(observation.scope);
			if (observation.scope === 'location') loadLocations();
			if (observation.scope === 'space') loadSpaces();
			if (observation.scope === 'sensor') loadSensors();
		}
	}, [observation]);

	const handleChangeTab = (event, newValue) => setCurrentTab(newValue);

	const getImagesCallback = images => {
		const newImages = images || [];
		const oldImages = currentObservation?.images || [];
		if (newImages.length !== oldImages.length) {
			setIsDirtyImg(true);
		}

		setValue('currentImages', images);
	};

	const getImages = ({ images = [] }) => (images.length > 0 && images) || [];

	const onSubmit = data => {
		const currentImages = getImages(currentObservation ?? {});
		const imagesToAdd = data.currentImages.filter(({ uuid }) => !uuid);
		const imagesToRemove = currentImages.filter(
			a => a.uuid && !data.currentImages.find(b => a.uuid === b.uuid)
		);

		const newObservation = {
			data: {
				...(observation?.uuid ? { uuid: observation.uuid } : {}),
				created: dayjs(data.startTime).format('YYYY-MM-DD HH:mm:ssZ'),
				observationtime: dayjs(data.startTime).format('YYYY-MM-DD HH:mm:ssZ'),
				observation: { name: data.observation },
				description: data.description || '',
				scope: data.scope,
				scopedId: data.scopeOption,
				observationendtime: data.endTime
					? dayjs(data.endTime).format('YYYY-MM-DD HH:mm:ssZ')
					: null,
				type: observation?.type || 'general',
				removeimageuuids: imagesToRemove.map(({ uuid }) => uuid)
			},
			files: imagesToAdd
		};

		if (Boolean(errors)) {
			dispatch('observations/edit', newObservation);
			dispatch('observations/save', newObservation);

			if (onClose) {
				onClose();
			}

			dispatch('navstate/update', {
				drawerOpen: false,
				drawerContent: null
			});
		}
	};

	const saveConfirmation = ev => {
		handleSubmit(onSubmit)();
		setOpen(false);
	};

	const onDiscard = () => {
		dispatch('navstate/update', {
			drawerOpen: false,
			drawerContent: null,
			onDrawerClose: undefined
		});
	};

	const handleCloseDrawer = ev => {
		if (!isDirty && !isDirtyImg) {
			onDiscard();
		} else {
			setOpen(true);
		}
	};

	useEffect(() => {
		if (observation.uuid) {
			Api.getObservation(observation.uuid).then(response =>
				setCurrentObservation(response.data[0])
			);
		}
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, []);

	useEffect(() => {
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, [isDirty, isDirtyImg]);

	return (
		<ThemeProvider theme={theme}>
			<Grid container direction='column'>
				<Box className={classes.topSticky}>
					<Grid item>
						<DrawerHeader
							closeCallback={handleCloseDrawer}
							editing
							title={observation.uuid ? 'Edit Observation' : 'New Observation'}
						/>
					</Grid>
					{observation.uuid && (
						<Tabs
							onChange={handleChangeTab}
							value={currentTab}
							variant='fullWidth'
							className={classes.tabs}
						>
							<Tab label='Description' />
							<Tab label='Comments' />
						</Tabs>
					)}
				</Box>
				<TabPanel index={0} value={currentTab}>
					<Grid item>
						<Card elevation={0}>
							<CardContent>
								<Grid container direction='column' spacing={3}>
									<Box px={4} py={3}>
										<Grid container direction='column' spacing={3}>
											<Grid item>
												<Typography className={classes.title} variant='h1'>
													Observation Details
												</Typography>
											</Grid>
											<Grid item>
												<Controller
													name='observation'
													control={control}
													render={({
														field: { onBlur, onChange, value, ref }
													}) => (
														<DecoratedTextField
															aria-label='observation name'
															editing
															error={errors?.observation}
															helperText={errors?.observation?.message}
															label='Observation name'
															onBlur={onBlur}
															onChange={onChange}
															required
															value={value}
															inputRef={ref}
														/>
													)}
												/>
											</Grid>
											<Grid item>
												<Controller
													name='description'
													control={control}
													render={({
														field: { onBlur, onChange, value, ref }
													}) => (
														<DecoratedTextField
															aria-label='observation description'
															editing
															error={errors?.description}
															helperText={errors?.description?.message}
															label='Description'
															multiline
															onBlur={onBlur}
															onChange={onChange}
															rows={3}
															value={value}
															inputRef={ref}
														/>
													)}
												/>
											</Grid>
											<Grid item>
												<Controller
													name='scope'
													control={control}
													render={({
														field: { onChange, value, ref },
														fieldState: { error }
													}) => (
														<DecoratedSelect
															aria-label='observation scope'
															editing
															error={error}
															helperText={error?.message}
															label='Scope'
															onChange={onChange}
															placeholder='Scope'
															required
															value={value}
															inputProps={{ ref }}
														>
															{global.scopeOptions
																.slice(1)
																.map(
																	({
																		label: labelScope,
																		value: scopeValue
																	}) => (
																		<MenuItem
																			key={scopeValue}
																			value={scopeValue}
																		>
																			{labelScope}
																		</MenuItem>
																	)
																)}
														</DecoratedSelect>
													)}
												/>
											</Grid>
											{options.length > 0 && (
												<Grid item>
													<Controller
														name='scopeOption'
														control={control}
														defaultValue={observation.scopedId}
														render={({
															field: { onBlur, onChange, value, ref },
															fieldState: { error }
														}) => (
															<DecoratedSelect
																aria-label={`${label} scope`}
																editing
																label={`${label}`}
																onBlur={onBlur}
																onChange={onChange}
																placeholder={`${label
																	.charAt(0)
																	.toUpperCase()}${label.slice(1)}`}
																required
																value={
																	value ||
																	options.find(
																		option => option.id === observation.scopedId
																	)?.value
																}
																error={error}
																helperText={error?.message}
																inputProps={{ ref }}
															>
																{options.map(({ id, label: scopeLabel }) => (
																	<MenuItem key={id} value={id}>
																		{scopeLabel}
																	</MenuItem>
																))}
															</DecoratedSelect>
														)}
													/>
												</Grid>
											)}
											<Grid item>
												<Controller
													name='startTime'
													control={control}
													render={({
														field: { onBlur, onChange, value, ref },
														fieldState: { error }
													}) => (
														<DecoratedDatePicker
															autoOk
															conservField='time'
															editing
															format='yyyy-MM-dd, HH:mm'
															includeTime
															id='observationdatetime'
															label='Start time'
															onBlur={onBlur}
															onChange={onChange}
															required
															value={value}
															variant='inline'
															error={error}
															helperText={error?.message}
															ref={ref}
														/>
													)}
												/>
											</Grid>
											<Grid item>
												<Controller
													name='endTime'
													control={control}
													render={({ field: { onBlur, onChange, value } }) => (
														<DecoratedDatePicker
															autoOk
															editing
															format='yyyy-MM-dd, HH:mm'
															includeTime
															label='End time'
															onBlur={onBlur}
															onChange={onChange}
															value={value}
															variant='inline'
														/>
													)}
												/>
											</Grid>
										</Grid>
									</Box>
									<Box my={2}>
										<Divider />
									</Box>
									<Box px={4} py={3}>
										<Grid container direction='column' spacing={3}>
											<Grid item>
												<Typography className={classes.title} variant='h1'>
													Images
												</Typography>
											</Grid>
											<Grid item>
												{((observation.uuid && currentObservation) ||
													!observation.uuid) && (
													<ImageForm
														getImages={getImagesCallback}
														images={getImages(currentObservation ?? {})}
														multiple
													/>
												)}
											</Grid>
										</Grid>
									</Box>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid className={classes.footer} item>
						<Button
							color='primary'
							onClick={handleSubmit(onSubmit)}
							size='md'
							variant='contained'
							disabled={!isDirty && !isDirtyImg}
						>
							SAVE ALL CHANGES
						</Button>
					</Grid>
				</TabPanel>
				{observation.uuid && (
					<TabPanel index={1} value={currentTab}>
						<Grid
							container
							direction='column'
							spacing={3}
							className={classes.commentsContainer}
						>
							<Comments type='observation' typeid={observation.uuid} />
						</Grid>
					</TabPanel>
				)}
			</Grid>
			<ConfirmationDialog
				canSave={Object.keys(errors).length === 0}
				onGoBack={() => setOpen(false)}
				open={open}
				onSave={saveConfirmation}
				onDiscard={onDiscard}
			/>
		</ThemeProvider>
	);
}

export default ObservationDrawer;
