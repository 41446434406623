import React, { useState } from 'react';
import { usePagination } from '@material-ui/lab/Pagination';
import { Box, Typography } from '@material-ui/core';
import useStyles from './styles';
import PaginatorButton from './PaginatorButton';

function Paginator({
	count,
	onChange,
	total,
	pageSize,
	showFirstButton,
	showLastButton,
	inPage = 1
}) {
	const [currentPage, setCurrentPage] = useState(inPage);
	const classes = useStyles();
	const { items } = usePagination({
		count,
		page: currentPage
	});

	if (items.length > 2) {
		const previousItem = items[0];
		const nextItem = items[items.length - 1];
		if (showFirstButton && previousItem.type === 'previous') {
			const firstItem = {
				...items[1],
				type: 'first',
				selected: false,
				disabled: previousItem.disabled
			};
			items.unshift(firstItem);
		}
		if (showLastButton && nextItem.type === 'next') {
			const lastItem = {
				...items[items.length - 2],
				type: 'last',
				selected: false,
				disabled: nextItem.disabled
			};
			items.push(lastItem);
		}
	}

	const handleOnChange = (ev, page) => {
		onChange(ev, page);
		setCurrentPage(page);
	};

	return (
		<Box
			display='flex'
			justifyContent='space-between'
			alignItems='center'
			className={classes.container}
			pl={0}
			pr={0}
		>
			<ul className={classes.ul}>
				{items.map(({ page, type, selected, ...item }, index) => (
					<li key={index} className={classes.li}>
						<PaginatorButton
							type={type}
							page={page}
							onChange={handleOnChange}
							selected={selected}
							item={item}
						/>
					</li>
				))}
			</ul>
			<Box>
				<Typography className={classes.resume} data-testid='paginator-resume'>
					Showing Items {Math.min(1 + (currentPage - 1) * pageSize, total)}-
					{Math.min(currentPage * pageSize, total)} of {total}
				</Typography>
			</Box>
		</Box>
	);
}

export default Paginator;
