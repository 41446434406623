import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	actions: {
		display: 'flex',
		flexDirection: 'column',
		gap: 15
	},
	bold: {
		fontWeight: 700
	},
	button: {
		textTransform: 'uppercase'
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: 24,
		padding: '50px 0'
	},
	description: {
		fontFamily: 'Roboto',
		fontSize: '16px'
	},
	secondary: {
		backgroundColor: '#FFF',
		border: '1px solid rgba(103, 58, 183, 0.5)',
		boxShadow: 'none',
		color: '#6600FD'
	},
	text: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		gap: 15
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontWeight: 700
	}
}));
