import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	container: {
		width: '100%',
		textAlign: 'center'
	},
	text: {
		paddingTop: '20px',
		color: '#00000099',
		lineHeight: '19px',
		textAlign: 'center'
	}
}));
