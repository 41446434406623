import { Subject, Observable } from 'rxjs';
import * as mnmxIoCommon from '@conserv/mnmx-io-common';
import { DamageRiskProviderService } from './DamageRiskProviderService';

const LOG = mnmxIoCommon.getConsoleLogger(
	'damage/DamageRiskProviderServiceImpl'
);
const { d4l } = mnmxIoCommon.logUtil;

export class DamageRiskProviderServiceImpl
	implements DamageRiskProviderService
{
	private availabilityFlag: boolean = false;
	private readonly onChangeSubject = new Subject<undefined>();

	public isAvailable(): boolean {
		return this.availabilityFlag;
	}

	public getChangeObservable(): Observable<undefined> {
		return this.onChangeSubject.asObservable();
	}

	public setAvailability(value: boolean): void {
		this.availabilityFlag = value;
		LOG.debug(`setAvailability(): Entering with value = ${d4l(value)}`);
		this.onChangeSubject.next(undefined);
	}
}
