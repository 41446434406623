import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
	paper: {
		width: '710px',
		maxWidth: '710px'
	},
	header: {
		padding: '16px',
		background: theme.palette.primary.main,
		color: '#FFF'
	},
	icon: {
		color: theme.palette.primary.main,
		fontSize: '46px'
	},
	content: {
		paddingTop: 0,
		paddingBottom: '40px',
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4)
	},
	headerTitle: {
		fontFamily: 'Montserrat',
		fontSize: '20px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '26px',
		letterSpacing: '0em',
		paddingLeft: '16px'
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '40px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '19px',
		letterSpacing: '0em'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px'
	},
	saveButton: {
		paddingRight: '13px',
		paddingLeft: '13px',
		paddingTop: '8px',
		paddingBottom: '8px',
		background: theme.palette.primary.main,
		height: '42px'
	},
	closeButton: {
		position: 'absolute',
		right: '1px',
		top: '4px',
		color: '#FFF'
	},
	endText: {
		marginTop: '24px'
	},
	subsContainer: {
		width: '100%'
	}
}));
