import React, { Component } from 'react';
import { Typography, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connectStoreon } from 'storeon/react';
import { Icon } from '@mdi/react';
import icons from '../../../style/icons';

class Notification extends Component {
	buildBanners = () => {
		const banners = [];
		// only show the last x banners
		if (this.props.notifications) {
			this.props.notifications.forEach(notification => {
				if (notification.variant === 'banner') {
					banners.push(
						<Alert
							key={notification.id}
							style={{ marginBottom: '8px' }}
							// if our alert specifies its own callback, use it, otherwise, default to deletealert
							onClose={
								notification.callback
									? () => notification.callback(notification.id)
									: () =>
											this.props.dispatch(
												'notification/delete',
												notification.id
											)
							}
							severity={notification.severity}
						>
							<Typography variant='h3'>
								{notification.message}{' '}
								{notification.linkUrl && notification.linkText ? (
									<a href={notification.linkUrl} target='_blank'>
										{notification.linkText}
									</a>
								) : (
									''
								)}
							</Typography>
						</Alert>
					);
				} else if (notification.variant === 'short-banner') {
					banners.push(
						<Alert
							key={notification.id}
							style={{ marginBottom: '8px' }}
							// if our alert specifies its own callback, use it, otherwise, default to deletealert
							onClose={
								notification.callback
									? () => notification.callback(notification.id)
									: () =>
											this.props.dispatch(
												'notification/delete',
												notification.id
											)
							}
							severity={notification.severity}
						>
							<Typography variant='body1'>{notification.message}</Typography>
						</Alert>
					);
				} else if (notification.variant === 'snackbar') {
					banners.push(
						<Snackbar
							anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
							key={notification.id}
							open
							autoHideDuration={4000}
							onClose={
								notification.callback
									? () => notification.callback(notification.id)
									: () =>
											this.props.dispatch(
												'notification/delete',
												notification.id
											)
							}
							TransitionComponent={this.TransitionUp}
							message={
								<Typography variant='h5'>{notification.message}</Typography>
							}
							action={
								<Icon
									path={icons.close}
									size={1}
									onClick={
										notification.callback
											? () => notification.callback(notification.id)
											: () =>
													this.props.dispatch(
														'notification/delete',
														notification.id
													)
									}
								/>
							}
						/>
					);
				}
			});
		}
		return banners;
	};

	render() {
		return <>{this.buildBanners()}</>;
	}
}

export default connectStoreon('notifications', Notification);
