/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
	Checkbox,
	Chip,
	FormControlLabel,
	MenuItem,
	TextField,
	Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MultiSelect from 'legacy/components/Multiselect/Multiselect';
import DecoratedSelect from '../../../common/form/v2/DecoratedSelect';
import { useStoreon } from 'storeon/react';

import useStyles from './styles';

function ScopeForm({ editable }) {
	const classes = useStyles();
	const [scopeOptions] = useState([
		{ label: 'Everywhere', value: 'global' },
		{ label: 'Location', value: 'location' },
		{ label: 'Space', value: 'space' },
		{ label: 'Sensor', value: 'sensor' }
	]);
	const [selectedScopeOptions, setSelectedScopeOptions] = useState([]);
	const { locations, spaces, sensors } = useStoreon(
		'locations',
		'spaces',
		'sensors'
	);
	const { control, setValue, watch } = useFormContext();

	const watchScope = watch('scope');
	const watchEventprofilescopes = watch('eventprofilescopes');

	const getScopeLabel = option => {
		let ret = option;
		if (watchScope === 'location') {
			ret = locations.activeList.find(e => e.uuid === option)?.name;
		}
		if (watchScope === 'space') {
			ret = spaces.activeList.find(e => e.uuid === option)?.name;
		}
		if (watchScope === 'sensor') {
			ret = sensors.activeList.find(e => e.uuid === option)?.name;
		}
		return ret;
	};

	const loadLocations = () => {
		const facilityOptions = locations.activeList
			.filter(fac => fac.active)
			.sort((a, b) => -b.name.localeCompare(a.name))
			.map(fac => fac.uuid);
		setSelectedScopeOptions(facilityOptions);
	};
	const loadSpaces = () => {
		const spaceOptions = spaces.activeList
			.filter(space => space.active)
			.sort((a, b) => -b.name.localeCompare(a.name))
			.map(space => space.uuid);
		setSelectedScopeOptions(spaceOptions);
	};

	const loadSensors = () => {
		const sensorOptions = sensors.activeList
			.filter(sensor => sensor.active && sensor.live)
			.sort((a, b) => -b.name.localeCompare(a.name))
			.map(sensor => sensor.uuid);
		setSelectedScopeOptions(sensorOptions);
	};

	useEffect(() => {
		if (
			watchEventprofilescopes?.length > 0 &&
			selectedScopeOptions.length > 0 &&
			!selectedScopeOptions.some(e => e === watchEventprofilescopes[0])
		) {
			setValue('eventprofilescopes', []);
		}
	}, [selectedScopeOptions]);

	useEffect(() => {
		if (locations && watchScope === 'location') {
			loadLocations();
		}
		if (spaces && watchScope === 'space') {
			loadSpaces();
		}
		if (sensors && watchScope === 'sensor') {
			loadSensors();
		}
	}, [watchScope, spaces, locations, sensors]);

	return (
		<>
			<Controller
				name='scope'
				control={control}
				render={({
					field: { onChange, value, ref, defaultValue },
					fieldState: { error }
				}) => (
					<DecoratedSelect
						required
						aria-label='scope'
						defaultValue={defaultValue}
						editing={editable}
						error={error}
						helperText={error?.message}
						id='scope'
						value={value}
						onChange={onChange}
						placeholder='Scope'
						inputProps={{ ref }}
					>
						{scopeOptions.map(({ label, value: levelValue }) => (
							<MenuItem key={levelValue} value={levelValue}>
								{label}
							</MenuItem>
						))}
					</DecoratedSelect>
				)}
			/>
			{watchScope !== 'global' ? (
				<Controller
					name='eventprofilescopes'
					control={control}
					render={({
						field: { onChange, value, ref },
						fieldState: { error }
					}) => (
						<MultiSelect
							label={watchScope}
							className={classes.autocomplete}
							disabled={!editable}
							limitTags={5}
							key={value}
							required
							multiple
							id='eventprofilescopes'
							error={error}
							helperText={error?.message}
							value={value}
							onChange={onChange}
							options={selectedScopeOptions}
							getOptionLabel={getScopeLabel}
							inputProps={{ ref }}
							ChipProps={{ color: 'primary' }}
							disableCloseOnSelect
						/>
					)}
				/>
			) : null}
		</>
	);
}

export default ScopeForm;
