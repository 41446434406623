import React, { Fragment, useState } from 'react';
import { Menu, MenuItem, IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import useStyles from './styles';

function EventSettingsList({ eventProfile }) {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const openMenu = Boolean(anchorEl);

	const handleClickMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const menuOptions = [
		{
			label: 'Edit'
		}
	];

	return (
		<Fragment>
			<IconButton
				key={`menu-${eventProfile.uuid}`}
				aria-label='more'
				aria-controls='long-menu'
				aria-haspopup='true'
				onClick={handleClickMenu}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id='long-menu'
				anchorEl={anchorEl}
				open={openMenu}
				onClose={handleCloseMenu}
				elevation={1}
			>
				{menuOptions.map(option => (
					<MenuItem key={`${option.label}-${eventProfile.uuid}`}>
						{option.label}
					</MenuItem>
				))}
			</Menu>
		</Fragment>
	);
}

export default EventSettingsList;
