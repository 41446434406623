import React, { useState } from 'react';
import {
	FormControl,
	Select,
	InputLabel,
	Box,
	FormHelperText
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import icons from '../../../style/icons';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../style/colors';

const useStyles = makeStyles(theme => ({
	afterAction: {
		width: '100%',
		maxWidth: '410px'
	},
	noAfterAction: {
		width: '100%',
		maxWidth: '440px'
	},
	error: {
		color: colors.amaranth.primary
	}
}));

export default function DecoratedSelect(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	let iconPath = null;
	// allow override of variant icon by setting icon path directly.
	if (props.iconPath) {
		iconPath = props.iconPath;
	} else {
		iconPath = icons[props.conservField];
	}

	const passthroughProps = { ...props };
	delete passthroughProps.conservField;
	delete passthroughProps.afterAction;
	delete passthroughProps.editing;
	delete passthroughProps.helperText;

	let displayValue = props.children?.find(opt => {
		if (opt?.props?.value === props.value) {
			return opt.props.children;
		}
	});

	if (!displayValue) displayValue = 'Not Set';

	return (
		<Box
			display='flex'
			flexWrap='nowrap'
			alignItems='flex-end'
			flexDirection='row'
			style={{ width: '100%' }}
		>
			{props.hideIcon ? null : (
				<Box pb={props.editing ? 1 : 1.5} style={{ minWidth: '24px' }}>
					{iconPath ? <Icon path={iconPath} size={1} /> : null}
				</Box>
			)}
			<Box pl={props.hideIcon ? 0 : 2} flexGrow={1}>
				<FormControl
					required={passthroughProps.required}
					classes={{
						root: props.afterAction
							? classes.afterAction
							: classes.noAfterAction
					}}
				>
					<InputLabel>
						{open ? props.label : props.placeholder || props.label}
					</InputLabel>
					<Select
						{...passthroughProps}
						disabled={!props.editing}
						onOpen={() => setOpen(true)}
						onClose={() => setOpen(false)}
					>
						{props.children}
					</Select>
					<FormHelperText className={classes.error}>
						{props.helperText}
					</FormHelperText>
				</FormControl>
			</Box>
			{props.afterAction ? <Box pl={1}>{props.afterAction}</Box> : null}
		</Box>
	);
}
