const testData = {
	eventProfiles: [
		{
			type: 'reading',
			uuid: 'a7fe62c8-cca1-49e8-b782-e598c05decac',
			name: 'Temperature too high',
			status: 'pause',
			subscribers: [
				{
					user: 'Ellen',
					subscribed: true
				},
				{
					user: 'Austin',
					subscribed: true
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				reading: 'temp1',
				threshold: 'gt',
				thresholdValue: 80,
				lagStart: 60,
				lagEnd: 60
			}
		},
		{
			type: 'reading',
			uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78',
			name: 'Temperature too low',
			status: 'resume',
			subscribers: [
				{
					user: 'Cheyenne',
					subscribed: true
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				reading: 'temp1',
				threshold: 'lt',
				thresholdValue: 35,
				lagStart: 60,
				lagEnd: 60
			}
		},
		{
			type: 'reading',
			uuid: '08f98659-a2c9-42c5-8d38-b0825db7aec8',
			name: 'Storage RH out of range',
			status: 'pause',
			subscribers: [
				{
					user: 'Nathan',
					subscribed: false
				},
				{
					user: 'Austin',
					subscribed: true
				}
			],
			scope: {
				type: 'space',
				list: [1, 2, 3]
			},
			params: {
				reading: 'rh',
				threshold: 'out',
				rangeStart: 35,
				rangeEnd: 65,
				lagStart: 60,
				lagEnd: 120
			}
		},
		{
			type: 'reading',
			uuid: 'b3a095a9-ab8f-483b-a79e-a702204a8a8f',
			name: 'Gallery RH too high',
			status: 'pause',
			subscribers: [
				{
					user: 'Austin',
					subscribed: true
				}
			],
			scope: {
				type: 'space',
				list: [4]
			},
			params: {
				reading: 'rh',
				threshold: 'gt',
				thresholdValue: 45,
				lagStart: 60,
				lagEnd: 60
			}
		},
		{
			type: 'reading',
			uuid: '27a6c027-0c70-4a96-8521-0849ff0b3186',
			name: 'Lab Temperature out of range',
			status: 'pause',
			subscribers: [
				{
					user: 'Ellen',
					subscribed: true
				},
				{
					user: 'Nathan',
					subscribed: false
				}
			],
			scope: {
				type: 'space',
				list: [5, 6]
			},
			params: {
				reading: 'temp1',
				threshold: 'out',
				rangeStart: 65,
				rangeEnd: 68,
				lagStart: 120,
				lagEnd: 60
			}
		},
		{
			type: 'system',
			uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78-dummy2',
			name: 'Sensor(s) offline',
			status: 'resume',
			subscribers: [
				{
					user: 'Ellen',
					subscribed: true
				},
				{
					user: 'Austin',
					subscribed: true
				},
				{
					user: 'Nathan',
					subscribed: true
				},
				{
					user: 'Cheyenne',
					subscribed: true
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				system: 'notSeen',
				systemThreshold: 12
			}
		},
		{
			type: 'system',
			uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78-dummy1',
			name: 'Sensor battery low',
			status: 'pause',
			subscribers: [
				{
					user: 'Ellen',
					subscribed: true
				},
				{
					user: 'Nathan',
					subscribed: true
				},
				{
					user: 'Cheyenne',
					subscribed: false
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				system: 'bat'
			}
		},
		{
			type: 'system',
			uuid: 'db9d3c5a-7502-4487-a48d-0630aeec88e5',
			// name: 'Gateway offline',
			name: 'Sensor(s) offline',
			status: 'pause',
			subscribers: [
				{
					user: 'Austin',
					subscribed: false
				},
				{
					user: 'Nathan',
					subscribed: true
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				system: 'notSeen',
				systemThreshold: 8
				// condition: 'sensorOffline',
				// timeDelay: 60,
				// notifyEnd: true
			}
		},
		{
			type: 'system',
			uuid: '2c8ec5d0-a8cf-414a-9e15-b54a177b4371',
			name: 'Sensor battery low',
			status: 'pause',
			subscribers: [
				{
					user: 'Austin',
					subscribed: true
				},
				{
					user: 'Nathan',
					subscribed: true
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				system: 'bat'
			}
		},
		{
			type: 'reading',
			uuid: 'a7fe62c8-cca1-49e8-b782-e598c05decac-copy',
			name: 'Temperature too high',
			status: 'pause',
			subscribers: [
				{
					user: 'Ellen',
					subscribed: true
				},
				{
					user: 'Austin',
					subscribed: true
				}
			],
			scope: {
				type: 'sensor',
				list: ['*']
			},
			params: {
				reading: 'temp1',
				threshold: 'gt',
				thresholdValue: 80,
				lagStart: 60,
				lagEnd: 60
			}
		}
	],
	alertProfiles: [
		{
			uuid: '326f65dd-83d7-4fa5-8291-1e128bb6a38f',
			name: 'No Alert Profile',
			description:
				'These events do not have an alert profile attached, so no alerts will be sent',
			members: [],
			eventProfiles: [
				{
					type: 'reading',
					uuid: 'a7fe62c8-cca1-49e8-b782-e598c05decac',
					name: 'Temperature too high',
					owner: {
						name: 'Ellen'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					params: {
						reading: 'temp1',
						threshold: 'gt',
						thresholdValue: 80,
						lagStart: 60,
						lagEnd: 60
					}
				},
				{
					type: 'reading',
					uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78',
					name: 'Temperature too low',
					owner: {
						name: 'Cheyenne'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					params: {
						reading: 'temp1',
						threshold: 'lt',
						thresholdValue: 35,
						lagStart: 60,
						lagEnd: 60
					}
				}
			]
		},
		{
			uuid: '6874bd77-41aa-41d8-881d-c311ea8d0adf',
			name: 'Conservation Staff',
			description: 'Alerts sent to the conservation staff profile',
			members: [
				'1486024c-8d11-45ae-b5a3-7f9ea0c897e6',
				'7bb878a8-102d-11e9-9202-0a0e79c724be'
			],
			eventProfiles: [
				{
					type: 'reading',
					uuid: '08f98659-a2c9-42c5-8d38-b0825db7aec8',
					name: 'Storage RH out of range',
					owner: {
						name: 'Nathan'
					},
					scope: {
						type: 'space',
						list: [1, 2, 3]
					},
					params: {
						reading: 'rh',
						threshold: 'out',
						rangeStart: 35,
						rangeEnd: 65,
						lagStart: 60,
						lagEnd: 120
					}
				},
				{
					type: 'reading',
					uuid: 'b3a095a9-ab8f-483b-a79e-a702204a8a8f',
					name: 'Gallery RH too high',
					owner: {
						name: 'Austin'
					},
					scope: {
						type: 'space',
						list: [4]
					},
					params: {
						reading: 'rh',
						threshold: 'gt',
						thresholdValue: 45,
						lagStart: 60,
						lagEnd: 60
					}
				},
				{
					type: 'reading',
					uuid: '27a6c027-0c70-4a96-8521-0849ff0b3186',
					name: 'Lab Temperature out of range',
					owner: {
						name: 'Ellen'
					},
					scope: {
						type: 'space',
						list: [5, 6]
					},
					params: {
						reading: 'temp1',
						threshold: 'out',
						rangeStart: 65,
						rangeEnd: 68,
						lagStart: 120,
						lagEnd: 60
					}
				},
				{
					type: 'system',
					uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78-dummy2',
					name: 'Sensor(s) offline',
					owner: {
						name: 'Austin'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					params: {
						system: 'notSeen',
						systemThreshold: 12
					}
				},
				{
					type: 'system',
					uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78-dummy1',
					name: 'Sensor battery low',
					owner: {
						name: 'Cheyenne'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					params: {
						system: 'bat'
					}
				}
			]
		},
		{
			uuid: 'ed068da8-b442-4920-b897-235a35f3ca74',
			name: 'Administrators',
			description: 'Alerts sent to the administration team',
			members: [],
			eventProfiles: [
				{
					type: 'system',
					uuid: 'db9d3c5a-7502-4487-a48d-0630aeec88e5',
					// name: 'Gateway offline',
					name: 'Sensor(s) offline',
					owner: {
						name: 'Nathan'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					params: {
						system: 'notSeen',
						systemThreshold: 8
						// condition: 'sensorOffline',
						// timeDelay: 60,
						// notifyEnd: true
					}
				},
				{
					type: 'system',
					uuid: '2c8ec5d0-a8cf-414a-9e15-b54a177b4371',
					name: 'Sensor battery low',
					owner: {
						name: 'Austin'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					params: {
						system: 'bat'
					}
				}
			]
		}
	],

	reportTemplates: [
		{
			uuid: '326f65dd-83d7-4fa5-8291-1e128bb6a38f-hellotemplate1',
			name: 'Weekly Report',
			description: 'A weekly summary of the spaces you select',
			members: [],
			reports: [
				{
					uuid: 'a7fe62c8-cca1-49e8-b782-e598c05decac-helloreport1a',
					name: 'Facilities Report 1a',
					fileName: '1aFacilitiesReport2020-06-15.pdf',
					notes: 'some notes for ellens report',
					dateCreated: '2020-06-15 3:46 PM',
					owner: {
						name: 'Ellen',
						avatar:
							'https://s3.amazonaws.com/test-mnmx-customer-assets/avatar-07edd853-beef-43e0-814d-7d7a0e7b99f8'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					parameters: {
						reading: 'temp1',
						threshold: 'gt',
						range: [50, 80],
						lag: [60, 60]
					}
				},
				{
					uuid: 'ca4644cc-0f9c-4495-acef-bf6657081e78-helloreport1b',
					name: 'Facilities Report 1b',
					fileName: '1bFacilitiesReport2020-07-22.pdf',
					notes: 'some notes for cheyennes report',
					dateCreated: '2020-07-22 3:34 AM',
					owner: {
						name: 'Cheyenne',
						avatar:
							'https://s3.amazonaws.com/test-mnmx-customer-assets/avatar-66f59103-6dec-44e4-b856-69a974d1d2db'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					parameters: {
						reading: 'temp1',
						threshold: 'gt',
						range: [50, 80],
						lag: [60, 60]
					}
				}
			]
		},
		/* {
            uuid: '6874bd77-41aa-41d8-881d-c311ea8d0adf-hellotemplate2',
            name: 'Collection Report Template',
            description: 'These reports are for the collections reports template',
            members: ["1486024c-8d11-45ae-b5a3-7f9ea0c897e6", "7bb878a8-102d-11e9-9202-0a0e79c724be"],
            reports: [
                {
                    uuid: '08f98659-a2c9-42c5-8d38-b0825db7aec8-helloreport2a',
                    name: 'Collection Report 2a',
                    fileName: '2aCollectionReport2020-07-31.pdf',
                    notes: 'some notes for austins report',
                    dateCreated: '2020-07-31 8:00 AM',
                    owner: {
                        name: 'Austin',
                        avatar: "https://s3.amazonaws.com/test-mnmx-customer-assets/avatar-98a22b4c-6ac2-4f4d-a603-aea7989528ce"
                    },
                    scope: {
                        type: 'space',
                        list: [1, 2, 3]
                    },
                    parameters: {
                        reading: 'temp1',
                        threshold: 'gt',
                        range: [50, 80],
                        lag: [60, 60]
                    }

                },
                {
                    uuid: 'b3a095a9-ab8f-483b-a79e-a702204a8a8f-helloreport2b',
                    name: 'Collection Report 2b',
                    fileName: '2bCollectionReport2020-08-15.pdf',
                    notes: 'some notes for austins report',
                    dateCreated: '2020-08-15 11:30 AM',
                    owner: {
                        name: 'Austin',
                        avatar: "https://s3.amazonaws.com/test-mnmx-customer-assets/avatar-98a22b4c-6ac2-4f4d-a603-aea7989528ce"
                    },
                    scope: {
                        type: 'space',
                        list: [4]
                    },
                    parameters: {
                        reading: 'temp1',
                        threshold: 'gt',
                        range: [50, 80],
                        lag: [60, 60]
                    }
                },
                {
                    uuid: '27a6c027-0c70-4a96-8521-0849ff0b3186-helloreport2c',
                    name: 'Collection Report 2c',
                    fileName: '2cCollectionReport2020-09-01.pdf',
                    notes: 'some notes for ellens report',
                    dateCreated: '2020-09-01 4:45 PM',
                    owner: {
                        name: 'Ellen',
                        avatar: "https://s3.amazonaws.com/test-mnmx-customer-assets/avatar-07edd853-beef-43e0-814d-7d7a0e7b99f8"
                    },
                    scope: {
                        type: 'space',
                        list: [5, 6]
                    },
                    parameters: {
                        reading: 'temp1',
                        threshold: 'gt',
                        range: [50, 80],
                        lag: [60, 60]
                    }
                }
            ]
        }, */
		{
			uuid: 'ed068da8-b442-4920-b897-235a35f3ca74-hellotemplate3',
			name: 'Facilities Report',
			description: 'These reports are for the facilities template',
			members: [],
			reports: [
				{
					uuid: 'db9d3c5a-7502-4487-a48d-0630aeec88e5-helloreport3a',
					name: 'Storage Report 3a',
					fileName: '3aStorageReport2020-09-04.pdf',
					notes: 'some notes for nathans report',
					dateCreated: '2020-09-04 5:00 PM',
					createdbyuuid: '81b08370-7bd3-4b1b-8680-f185cfda2fe6',
					owner: {
						name: 'Nathan',
						avatar:
							'https://lh3.googleusercontent.com/a-/AOh14GgBG4KuErnSHvbfUxc4dWHQ5vagcDEy5vfWTmmBQg=s96-c'
					},
					scope: {
						type: 'gateway',
						list: ['*']
					},
					parameters: {
						reading: 'temp1',
						threshold: 'gt',
						range: [50, 80],
						lag: [60, 60]
					}
				},
				{
					uuid: '2c8ec5d0-a8cf-414a-9e15-b54a177b4371-helloreport3b',
					name: 'Storage Report 3b',
					fileName: '3bStorageReport2020-09-25.pdf',
					notes: 'some notes for nathans report',
					dateCreated: '2020-09-25 4:03 PM',
					owner: {
						name: 'Nathan',
						avatar:
							'https://lh3.googleusercontent.com/a-/AOh14GgBG4KuErnSHvbfUxc4dWHQ5vagcDEy5vfWTmmBQg=s96-c'
					},
					scope: {
						type: 'sensor',
						list: ['*']
					},
					parameters: {
						reading: 'temp1',
						threshold: 'gt',
						range: [50, 80],
						lag: [60, 60]
					}
				}
			]
		}
	],
	sensorTimelineEvents: [
		{
			id: 1,
			time: 'January 1, 2020 at 3:43 PM',
			user: 'b2ec227b-84a3-4300-97d0-9870762fa739', // Cheyenne Mangum
			description: 'A description of the event 1.',
			type: 'sensorMove',
			title: 'Sensor Move'
		},
		{
			id: 2,
			time: 'January 10, 2020 at 8:10 AM',
			user: '81b08370-7bd3-4b1b-8680-f185cfda2fe6', // Nathan T McMinn
			description:
				'A description of the event 2. This is the ideal card height and padding, assuming the description is capped at 2 lines. Yay! :) A description of the event 2. This is the ideal card height and padding, assuming the description is capped at 2 lines. Yay! :)',
			type: 'sensorMerge',
			title: 'Sensor Merge'
		},
		{
			id: 3,
			time: 'January 16, 2020 at 1:15 PM',
			user: '49d469f0-962b-4329-a544-fe81b07223d3', // Austin Senseman
			description: 'A description of the event 3.',
			type: 'sensorMove',
			title: 'Sensor Move'
		},
		{
			id: 4,
			time: 'January 22, 2020 at 9:31 AM',
			user: '4783ca02-cd76-4250-bade-4155ee4e7017', // Nathan Test
			description: 'A description of the event 4.',
			type: 'sensorMerge',
			title: 'Sensor Merge'
		}
	]
	// trapLocations: [
	//     {
	//         uuid: '4783ca02-cd76-4250-bade-4155ee4e7018',
	//         name: 'Trap location 1',
	//         space: 'c0cb66f7-a762-48e3-be14-e17d0976bdcb', // 02-shelf 4C @ Coastal Heritage Society
	//         placement: 'Northwest corner of room, next to delta cabinet',
	//         trapType: 'Sticky Trap',
	//         lastChecked: '2020-01-26T01:30:30',
	//         lastReplaced: '2020-01-22T01:30:30',
	//         trapImage: 'http://agrilife.org/citybugstest/files/2011/12/sticky-trap-in-cupboard.jpg'
	//     },
	//     {
	//         uuid: '4783ca02-cd76-4250-bade-4155ee4e7019',
	//         name: 'Trap location 2',
	//         space: '5095a703-28f0-4a45-b52f-94eef6670e0f', // A New Space2 @ Forge Museum
	//         placement: 'Inside entry door, left side',
	//         trapType: 'Sticky Trap',
	//         //lastChecked: '2020-01-26T01:30:30',
	//         //lastReplaced: '2020-01-22T01:30:30',
	//         trapImage: ''
	//     },
	//     {
	//         uuid: '4783ca02-cd76-4250-bade-4155ee4e7020',
	//         name: 'Trap location 3',
	//         space: '41e209c3-3bdb-4134-b9d3-22b3422d893d', // Architectual Archives @ Test Fac
	//         placement: 'Hanging near window',
	//         trapType: 'Pheromone Trap',
	//         lastChecked: '2020-01-26T01:30:30',
	//         lastReplaced: '2020-01-22T01:30:30',
	//         trapImage: ''
	//     }
	// ],
	// trapObservations: [
	//     // scopedId will either scope an obs to a trapLocation OR a space. Not both.
	//     // A) if an observation is scoped to a space, then scopedId of that obs is the uuid of thst space (from spaces.list)
	//     // B) if an observation is scoped to a trapLocation, then scopedId of that obs is the uuid of that trapLoc;
	//     // * while this trapLocation does have a space uuid, its space is still not the scope of the obs
	//     {
	//         uuid: '5783ca02-cd76-4250-bade-4155ee4e7018',
	//         scopedId: '4783ca02-cd76-4250-bade-4155ee4e7018', // Trap Location 1
	//         user: 'b2ec227b-84a3-4300-97d0-9870762fa739', // Cheyenne
	//         scope: 'traplocation',
	//         created: '2020-01-26T01:30:30',
	//         observationtime: '2020-01-26T01:30:30',
	//         imageurl: 'http://agrilife.org/citybugstest/files/2011/12/sticky-trap-in-cupboard.jpg',
	//         observation: {},
	//         description: 'test description'
	//     },
	//     {
	//         uuid: '5783ca02-cd76-4250-bade-4155ee4e7019',
	//         scopedId: '5095a703-28f0-4a45-b52f-94eef6670e0f', // A New Space2 @ Forge Museum
	//         user: 'b2ec227b-84a3-4300-97d0-9870762fa739', // Cheyenne
	//         scope: 'space',
	//         created: '2020-01-26T01:30:30',
	//         observationtime: '2020-01-26T01:30:30',
	//         imageurl: '',
	//         observation: {},
	//         description: ''
	//     },
	//     {
	//         uuid: '5783ca02-cd76-4250-bade-4155ee4e7020',
	//         scopedId: '41e209c3-3bdb-4134-b9d3-22b3422d893d', // Architectual Archives @ Test Fac
	//         user: 'b2ec227b-84a3-4300-97d0-9870762fa739', // Cheyenne
	//         scope: 'space',
	//         created: '2020-01-26T01:30:30',
	//         observationtime: '2020-01-26T01:30:30',
	//         imageurl: '',
	//         observation: {},
	//         description: ''
	//     }
	// ]
};

export default testData;
