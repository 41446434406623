import React from 'react';
import { Typography } from '@material-ui/core';

import useStyles from './styles';

function H3({ children }) {
	const classes = useStyles();

	return (
		<Typography className={classes.container} variant='h3'>
			{children}
		</Typography>
	);
}

export default H3;
