import Api from '../legacy/api/Api';
import SegmentUtil from '../legacy/util/SegmentUtil';

interface RegisterUserProperties {
	email: string;
	password: string;
	first_name: string;
	last_name: string;
	orgname: string;
}

interface RegistrationResponse {
	data: {
		uuid: string;
	};
}

interface ConvertRegistrationResponse {
	data: string;
}

const registerUser = async (user: RegisterUserProperties) => {
	try {
		const { data } = (await Api.createRegistration(
			user
		)) as RegistrationResponse;
		const { uuid } = data;

		const response = (await Api.convertRegistration(
			user,
			uuid
		)) as ConvertRegistrationResponse;

		if (response.data === 'Ok') {
			// SEGMENT - track account creation (convert registration to account)
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call
			SegmentUtil.track(SegmentUtil.actions.accountcreate);

			return true;
		}
	} catch (error) {
		console.error(error);
	}

	return false;
};

export default registerUser;
