import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import {
	Box,
	Typography,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	MenuItem
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DecoratedSelect from '../../common/form/DecoratedSelect';
import DecoratedTextField from '../../common/form/DecoratedTextField';
import { cardStyles } from '../../../style/styles';
import SegmentUtil from '../../../util/SegmentUtil';

const useStyles = makeStyles({
	...cardStyles
});

function AssignSensorsStep(props) {
	const classes = useStyles();
	const [sensorActions, setSensorActions] = useState();
	const [newSensorNames, setNewSensorNames] = useState({});
	const { sensors, spaces } = useStoreon('sensors', 'spaces');

	useEffect(() => {
		const defaultActions = {};
		props.importConfig.importResult.sensors.forEach(sensor => {
			defaultActions[sensor.uuid] = { actionName: 'nothing' };
		});
		setSensorActions(defaultActions);
		SegmentUtil.track(SegmentUtil.actions.importcomplete);
	}, []);

	useEffect(() => {
		if (sensorActions && newSensorNames) {
			props.onUpdateSensorActions({
				sensorNames: newSensorNames,
				sensorActions,
				valid: validateForm()
			});
		}
	}, [sensorActions, newSensorNames]);

	const updateSensorAction = (action, sensorUuid, actionTargetUuid) => {
		const newActions = { ...sensorActions };
		newActions[sensorUuid] = {
			actionName: action,
			actionTargetUuid
		};
		setSensorActions(newActions);
	};

	const updateSensorName = (sensorUuid, newName) => {
		const newNames = { ...newSensorNames };
		newNames[sensorUuid] = newName;
		setNewSensorNames(newNames);
	};

	const validateForm = () => {
		let valid = true;
		// do we have any blank or duplicate names?
		props.importConfig.importResult.sensors.forEach(sensor => {
			if (typeof newSensorNames[sensor.uuid] !== 'undefined') {
				if (newSensorNames[sensor.uuid] === '') {
					valid = false;
				}
			}
			const action = sensorActions[sensor.uuid];
			if (
				(action.actionName === 'space' || action.actionName === 'sensor') &&
				!action.actionTargetUuid
			) {
				valid = false;
			}
		});
		return valid;
	};

	const getActionTargetOptions = actionName => {
		const options = [];
		let list = [];
		if (actionName === 'sensor') {
			list = sensors.list;
		}
		if (actionName === 'space') {
			list = spaces.list;
		}
		list
			.filter(entry => entry.active)
			.forEach(entry => {
				options.push(
					<MenuItem key={entry.uuid} value={entry.uuid}>
						{entry.name}
					</MenuItem>
				);
			});
		return options;
	};

	if (!sensorActions) return null;

	return (
		<Box pt={4}>
			<Box>
				<Typography variant='h3'>Assign your sensors</Typography>
				<Typography variant='body1'>
					Assign the new sensors to spaces, rename them, or merge the imported
					data into an existing sensor
				</Typography>
			</Box>
			<Box pt={2}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell>Sensor Name</TableCell>
							<TableCell>Action</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.importConfig.importResult.sensors.map(sensor => (
							<TableRow key={sensor.uuid}>
								<TableCell>
									<DecoratedTextField
										conservField='name'
										label='Sensor Name'
										id={`${sensorActions[sensor.uuid]}-sensorname`}
										aria-label='sensor name'
										onChange={ev =>
											updateSensorName(sensor.uuid, ev.target.value)
										}
										value={
											typeof newSensorNames[sensor.uuid] !== 'undefined'
												? newSensorNames[sensor.uuid]
												: sensor.name
										}
										editing
									/>
								</TableCell>
								<TableCell>
									<Box display='flex' flexDirection='row'>
										<Box pr={2}>
											<DecoratedSelect
												conservField='action'
												id={`sensor-${sensor.uuid}-action`}
												label='Sensor Action'
												aria-label='sensor action'
												onChange={ev => {
													updateSensorAction(
														ev.target.value,
														sensor.uuid,
														null
													);
												}}
												value={sensorActions[sensor.uuid].actionName}
												editing
											>
												<MenuItem value='sensor'>
													Merge with existing sensor
												</MenuItem>
												<MenuItem value='space'>
													Create and move into space
												</MenuItem>
												<MenuItem value='nothing'>Create new sensor</MenuItem>
											</DecoratedSelect>
										</Box>
										{sensorActions[sensor.uuid].actionName !== 'nothing' ? (
											<DecoratedSelect
												conservField={sensorActions[sensor.uuid].actionName}
												id={`sensor-${sensor.uuid}-actiontarget`}
												label={
													sensorActions[sensor.uuid].actionName === 'sensor'
														? 'Merge with sensor'
														: 'Move to space'
												}
												aria-label={sensorActions[sensor.uuid].actionName}
												onChange={ev => {
													updateSensorAction(
														sensorActions[sensor.uuid].actionName,
														sensor.uuid,
														ev.target.value
													);
												}}
												value={sensorActions[sensor.uuid].actionTargetUuid}
												editing
											>
												{getActionTargetOptions(
													sensorActions[sensor.uuid].actionName
												)}
											</DecoratedSelect>
										) : null}
									</Box>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</Box>
		</Box>
	);
}

export default AssignSensorsStep;
