// eslint-disable-next-line import/prefer-default-export

export const EMPTY_STRING = '';

export const EVENT_TYPE = {
	temp1: 'Temperature',
	vis: 'Illuminance',
	uv1: 'Ultraviolet',
	rh: 'Relative humidity',
	leak: 'Leak',
	movement: 'Movement',
	offline: 'Offline'
};

export const UNITS = {
	celsius: {
		shortLabel: 'C'
	},
	fahrenheit: {
		shortLabel: 'F'
	},
	footcandle: {
		shortLabel: 'FC'
	},
	lux: {
		shortLabel: 'LX'
	}
};

export const DATE_TIME_FORMAT_MONTH_DATE_TIME = 'MMMM D, h:mm A'; //March 23, 1:56 PM
