import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	footer: {
		background: '#FFF',
		bottom: 0,
		borderTop: '.5px solid #BDBDBD',
		padding: theme.spacing(4),
		position: 'sticky',
		width: '100%',
		zIndex: 10
	},
	imgContainer: {
		paddingBottom: theme.spacing(10)
	},
	topSticky: {
		top: 0,
		position: 'sticky',
		zIndex: 10
	}
}));
