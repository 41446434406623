import React, { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useStoreon } from 'storeon/react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
	Box,
	Button,
	Card,
	CardContent,
	Divider,
	FormControl,
	Grid,
	InputLabel,
	Link,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import Api from '../../../api/Api';
import ImageForm from '../../common/ImageForm';
import DrawerHeader from '../../common/drawer/v2/DrawerHeader';
import TabPanel from '../../common/TabPanel';
import SpaceDrawer from '../../spaces/SpaceDrawer';
import SensorHistory from '../SensorHistory';
import ConfirmationDialog from '../../common/drawer/v2/ConfirmationDialog';
import theme from '../../../style/themev2';

import useStyles from './styles';

const schema = yup.object({
	name: yup.string().trim().required('The name is required'),
	space: yup.string().required('The space is required')
});

function SensorDrawer({ sensor }) {
	const classes = useStyles();

	const { dispatch, spaces } = useStoreon('spaces');

	const {
		control,
		formState: { errors, isDirty, isValid },
		handleSubmit,
		setValue
	} = useForm({
		defaultValues: {
			calibrationdate: sensor.calibrationdate
				? dayjs(sensor.calibrationdate)
				: null,
			description: sensor.description || '',
			name: sensor.name || '',
			serialNumber: sensor.serial || '',
			space: sensor.zoneId || (spaces.list || [])[0]?.id
		},
		shouldFocusError: true,
		mode: 'onChange',
		resolver: yupResolver(schema)
	});

	const [currentTab, setCurrentTab] = useState(0);
	const [levelProfile, setLevelProfile] = useState({ profile: { name: '' } });
	const [manufacturers, setManufacturers] = useState([]);
	const [sensorTimelineEvents, setSensorTimelineEvents] = useState([]);
	const [isDirtyImg, setIsDirtyImg] = useState(false);
	const [open, setOpen] = useState(false);

	const loadManufacturers = () =>
		Api.listManufacturers()
			.then(manRes => setManufacturers(manRes.data))
			.catch(() =>
				dispatch('notification/add', {
					message: 'There was a problem loading manufacturers',
					severity: 'error',
					variant: 'banner'
				})
			);

	const loadLevelProfile = () => {
		if (sensor?.zone?.uuid) {
			Api.getLevelProfileForScope('space', sensor.zone.uuid)
				.then(response => setLevelProfile(response.data))
				.catch(() =>
					dispatch('notification/add', {
						message:
							'There was a problem loading the level profile for this sensor',
						severity: 'error',
						variant: 'banner'
					})
				);
		}
	};

	const saveConfirmation = ev => {
		handleSubmit(onSubmit)();
		setOpen(false);
	};

	const onDiscard = () => {
		dispatch('navstate/update', {
			drawerOpen: false,
			drawerContent: null,
			onDrawerClose: undefined
		});
	};

	const handleCloseDrawer = ev => {
		if (!isDirty && !isDirtyImg) {
			onDiscard();
		} else {
			setOpen(true);
		}
	};

	useEffect(() => {
		loadManufacturers();
		loadLevelProfile();
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, []);

	useEffect(() => {
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, [isDirty, isDirtyImg, currentTab]);

	const handleVisitSpace = space => {
		dispatch('navstate/update', {
			onAfterDrawerClose: () =>
				dispatch('navstate/update', {
					drawerOpen: true,
					drawerContent: <SpaceDrawer key={space.uuid} space={space} />
				})
		});
		handleCloseDrawer();
	};

	const parseSensorTimelineEvents = events =>
		events
			.sort((a, b) => a.timestamp - b.timestamp)
			.reduce(
				(
					acc,
					{
						timestamp,
						userName,
						note,
						type,
						fromName,
						toName,
						sensorName,
						fileName,
						fromZone,
						toZone
					},
					index
				) => ({
					...acc,
					[dayjs(timestamp).format('YYYY/MM/DD')]: [
						...(acc[dayjs(timestamp).format('YYYY/MM/DD')] || []),
						{
							id: index,
							time: dayjs(timestamp).format('DD MMM YYYY, HH:mm'),
							description: note || '',
							title:
								type === 'merge' ? (
									<Typography>
										<b>{userName || 'Unknown'}</b> merged data from{' '}
										<Link className={classes.bold} variant='body1'>
											{fromName}
										</Link>
										to{' '}
										<Link className={classes.bold} variant='body1'>
											{toName}
										</Link>
									</Typography>
								) : type === 'move' ? (
									<Typography>
										<b>{userName || 'Unknown'}</b> moved{' '}
										<Link className={classes.bold} variant='body1'>
											{sensorName}
										</Link>
										{fromName ? (
											<>
												from{' '}
												<Link
													className={classes.link}
													onClick={() => handleVisitSpace(fromZone)}
													variant='body1'
												>
													{fromName}
												</Link>{' '}
											</>
										) : (
											''
										)}
										to{' '}
										<Link
											className={classes.link}
											onClick={() => handleVisitSpace(toZone)}
											variant='body1'
										>
											{toName}
										</Link>
									</Typography>
								) : (
									<Typography>
										<b>{userName || 'Unknown'}</b> created this sensor from
										imported data (file name:{' '}
										<Link className={classes.fileName} variant='body1'>
											{fileName}
										</Link>
										)
									</Typography>
								)
						}
					]
				}),
				{}
			);

	useEffect(() => {
		if (sensor?.uuid) {
			Api.getSensorHistory(sensor.uuid)
				.then(response => {
					const { sensorMoveHistory, sensorMergedHistory } = response.data;
					const moveData =
						sensorMoveHistory?.sensorspacehistories?.map(
							({ startinspace, mnmxuser, note, moved_from, zone, zoneId }) => ({
								timestamp: startinspace,
								userName: mnmxuser?.first_name || 'Unknown',
								note,
								type: 'move',
								fromName: moved_from ? moved_from[0].name : null,
								toName: zone.name,
								sensorName: response.data.sensorMoveHistory.name,
								fromZone: moved_from ? moved_from[0] : null,
								toZone: { ...zone, id: zoneId }
							})
						) || [];

					const mergeData =
						sensorMergedHistory?.map(
							({ mergetime, sensorMergedBy, mergenote, mergedFrom }) => ({
								timestamp: mergetime,
								userName: sensorMergedBy[0].first_name,
								note: mergenote,
								type: 'merge',
								fromName: mergedFrom[0].name,
								toName: sensor.name
							})
						) || [];

					if (
						sensorMoveHistory.remoteId === 'offline' &&
						sensorMoveHistory.remote_appId === 'offline'
					) {
						Api.getSensorImportInfo(sensorMoveHistory.uuid)
							.then(({ data }) => {
								const importData = data.map(importInfo => ({
									timestamp: importInfo.uploaddate,
									userName: importInfo.mnmxuser.first_name,
									fileName: importInfo.filename,
									note: 'Imported data',
									type: 'import'
								}));
								const historyEvents = parseSensorTimelineEvents([
									...moveData,
									...mergeData,
									...importData
								]);
								setSensorTimelineEvents(historyEvents);
							})
							.catch(error => {
								console.log(error);
								dispatch('notification/add', {
									message: 'There was a problem loading the sensor history',
									severity: 'error',
									variant: 'banner'
								});
							});
					} else {
						const historyEvents = parseSensorTimelineEvents([
							...moveData,
							...mergeData
						]);
						setSensorTimelineEvents(historyEvents);
					}
				})
				.catch(error => {
					console.log(error);
					dispatch('notification/add', {
						message: 'There was a problem loading the sensor history',
						severity: 'error',
						variant: 'banner'
					});
				});
		}
	}, [isDirty, isDirtyImg]);

	const handleChangeTab = (event, newValue) => setCurrentTab(newValue);

	const getImagesCallback = images => {
		const newImages = images || [];
		const oldImages = sensor.images || [];
		if (newImages.length !== oldImages.length) {
			setIsDirtyImg(true);
		}
		setValue('currentImages', images);
	};

	const onSubmit = data => {
		if (
			sensor.images &&
			(sensor.images || [])[0]?.uuid &&
			data.currentImages.length === 0
		) {
			Api.removeImage(sensor.images[0].uuid);
		}

		const currentSensor = {
			data: {
				...sensor,
				name: data.name,
				description: data.description,
				manufacturerId: data.manufacturer,
				serial: data.serialNumber,
				calibrationdate: data.calibrationdate
					? dayjs(data.calibrationdate)
					: null,
				zoneId: data.space,
				remoteId: sensor.remoteId || 'offline',
				remote_appId: sensor.remote_appId || 'offline',
				sensortypeId: sensor.sensortypeId || -1
			},
			files:
				data.currentImages?.length > 0 && data.currentImages[0].uuid
					? []
					: data.currentImages || []
		};

		if (Boolean(errors)) {
			dispatch('sensors/edit', currentSensor);
			dispatch('sensors/save');
		}

		dispatch('navstate/update', { drawerOpen: false, drawerContent: null });
	};

	const getImages = useCallback(
		({ images = [] }) => (images.length > 0 && images) || [],
		[]
	);

	const onGoBack = () => {
		setOpen(false);
		dispatch('navstate/update', {
			onAfterDrawerClose: null
		});
	};

	return (
		<ThemeProvider theme={theme}>
			<Grid container direction='column'>
				<Box className={classes.topSticky}>
					<Grid item>
						<DrawerHeader
							closeCallback={handleCloseDrawer}
							editing
							title={sensor.uuid ? 'Edit Sensor' : 'New Sensor'}
						/>
					</Grid>
					<Tabs
						onChange={handleChangeTab}
						value={currentTab}
						variant='fullWidth'
						className={classes.tabs}
					>
						<Tab label='Description' />
						<Tab label='History' />
					</Tabs>
				</Box>
				<TabPanel index={0} value={currentTab}>
					<Grid item>
						<Card elevation={0}>
							<CardContent>
								<Grid container direction='column' spacing={3}>
									<Box px={4} py={3}>
										<Grid container direction='column' spacing={3}>
											<Grid item>
												<Typography className={classes.title} variant='h1'>
													General
												</Typography>
											</Grid>
											<Grid item>
												<Controller
													name='name'
													control={control}
													render={({
														field: { onChange, value, ref },
														fieldState: { error }
													}) => (
														<TextField
															error={error}
															helperText={error?.message}
															label='Sensor Name'
															onChange={onChange}
															required
															value={value}
															variant='outlined'
															inputRef={ref}
														/>
													)}
												/>
											</Grid>
											{sensor.uuid && (
												<Grid item>
													<Typography className={classes.fieldTitle}>
														Level Profile
													</Typography>
													<Typography className={classes.fieldBody}>
														{levelProfile.profile.name}
													</Typography>
												</Grid>
											)}
											<Grid item>
												{sensor.uuid ? (
													<>
														<Typography className={classes.fieldTitle}>
															Space
														</Typography>
														<Typography className={classes.fieldBody}>
															{sensor?.zone?.name || ''}
														</Typography>
													</>
												) : (
													<FormControl required>
														<InputLabel id='select-space' variant='outlined'>
															Space
														</InputLabel>
														<Controller
															name='space'
															control={control}
															render={({
																field: { onChange, value, ref },
																fieldState: { error }
															}) => (
																<Select
																	defaultValue={
																		sensor.zoneId || (spaces.list || [])[0]?.id
																	}
																	error={error}
																	helperText={error?.message}
																	label='Space'
																	labelId='select-space'
																	onChange={onChange}
																	required
																	value={value}
																	variant='outlined'
																	inputProps={{ ref }}
																>
																	{spaces.activeList?.map(space => (
																		<MenuItem key={space.uuid} value={space.id}>
																			{space.name.length > 55
																				? `${space.name.slice(0, 55)}...`
																				: space.name}
																		</MenuItem>
																	))}
																</Select>
															)}
														/>
													</FormControl>
												)}
											</Grid>
											{sensor.uuid && (
												<Grid item>
													<Typography className={classes.fieldTitle}>
														Location
													</Typography>
													<Typography className={classes.fieldBody}>
														{sensor.zone?.facility?.name || ''}
													</Typography>
												</Grid>
											)}
										</Grid>
									</Box>
									<Box my={2}>
										<Divider />
									</Box>
									<Box px={4} py={3}>
										<Grid container direction='column' spacing={3}>
											<Grid item>
												<Typography className={classes.title} variant='h1'>
													Details
												</Typography>
											</Grid>
											<Grid item>
												<Controller
													name='description'
													control={control}
													render={({ field: { onChange, value } }) => (
														<TextField
															label='Description'
															multiline
															onChange={onChange}
															value={value}
															variant='outlined'
														/>
													)}
												/>
											</Grid>
											<Grid item>
												<Controller
													name='serialNumber'
													control={control}
													render={({
														field: { onChange, value, ref },
														fieldState: { error }
													}) => (
														<TextField
															disabled={sensor.uuid && sensor.managed}
															error={error}
															helperText={error?.message}
															label='Serial Number'
															onChange={onChange}
															value={value}
															variant='outlined'
															inputRef={ref}
														/>
													)}
												/>
											</Grid>
											<Grid item>
												<FormControl>
													<InputLabel
														id='select-manufacturer'
														variant='outlined'
													>
														Manufacturer
													</InputLabel>
													<Controller
														name='manufacturer'
														control={control}
														render={({ field: { onChange, value } }) => (
															<Select
																defaultValue={sensor.manufacturerId || null}
																label='Manufacturer'
																labelId='select-manufacturer'
																onChange={onChange}
																value={value}
																variant='outlined'
																disabled={sensor.managed}
															>
																{manufacturers
																	?.filter(manufacturer =>
																		!sensor.managed
																			? manufacturer.name !== 'Conserv'
																			: manufacturer.name === 'Conserv'
																	)
																	.map(manufacturer => (
																		<MenuItem
																			key={manufacturer.uuid}
																			value={manufacturer.uuid}
																		>
																			{manufacturer.name}
																		</MenuItem>
																	))}
															</Select>
														)}
													/>
												</FormControl>
											</Grid>
											<Grid item>
												<MuiPickersUtilsProvider utils={DateFnsUtils}>
													<Controller
														control={control}
														name='calibrationdate'
														render={({ field: { onChange, value } }) => (
															<DatePicker
																disabled={sensor.managed}
																emptyLabel='Select Date'
																inputVariant='outlined'
																label='Last Calibration Date'
																onChange={onChange}
																value={value || sensor.calibrationdate || null}
																variant='inline'
															/>
														)}
													/>
												</MuiPickersUtilsProvider>
											</Grid>
										</Grid>
									</Box>
									{sensor.uuid && (
										<Box my={2}>
											<Divider />
										</Box>
									)}
									{sensor.uuid && (
										<Box px={4} py={3}>
											<Grid container direction='column' spacing={3}>
												<Grid item>
													<Typography className={classes.title} variant='h1'>
														Images
													</Typography>
													<Typography className={classes.body} variant='body1'>
														Upload images to help you keep track of your sensor
														location
													</Typography>
												</Grid>
												<Grid item>
													<ImageForm
														getImages={getImagesCallback}
														images={getImages(sensor)}
													/>
												</Grid>
											</Grid>
										</Box>
									)}
								</Grid>
							</CardContent>
						</Card>
					</Grid>
					<Grid className={classes.footer} item>
						<Button
							color='primary'
							onClick={handleSubmit(onSubmit)}
							size='md'
							variant='contained'
							disabled={!isDirty && !isDirtyImg}
						>
							SAVE ALL CHANGES
						</Button>
					</Grid>
				</TabPanel>
				<TabPanel index={1} value={currentTab}>
					<SensorHistory history={sensorTimelineEvents} />
				</TabPanel>
			</Grid>
			{open ? (
				<ConfirmationDialog
					canSave={isValid}
					onGoBack={onGoBack}
					open={open}
					onSave={saveConfirmation}
					onDiscard={onDiscard}
				/>
			) : null}
		</ThemeProvider>
	);
}

export default SensorDrawer;
