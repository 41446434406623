import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon } from '@mdi/react';
import {
	Backdrop,
	Button,
	Grid,
	Snackbar,
	Typography
} from '@material-ui/core';
import { Carousel } from 'react-responsive-carousel';
import useUndo from 'use-undo';

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import useRoles from 'legacy/hooks/useRoles';

import icons from '../../../style/icons';
import useStyles from './styles';

const MAX_SIZE = 10000 * 1024 * 1024;

function ImageForm({ getImages, images, multiple = false }) {
	const [{ present }, { canUndo, set, undo }] = useUndo(images);
	const [isOpen, setIsOpen] = useState(false);
	const [isOpenCarousel, setIsOpenCarousel] = useState(false);
	const [startIndex, setStartIndex] = useState(0);
	const classes = useStyles({ multiple });

	const { isUser } = useRoles();

	useEffect(() => {
		getImages(present);
	}, [present]);

	const handleRemove = currentFile => {
		setIsOpen(true);
		set(
			present.filter(file => {
				if (file.uuid) {
					return file.uuid !== currentFile.uuid;
				}

				if (file.path) {
					return file.path !== currentFile.path;
				}
			})
		);
	};

	const handleClose = () => setIsOpen(false);

	const closeCarousel = () => setIsOpenCarousel(false);
	const openCarousel = index => {
		setStartIndex(index);
		setIsOpenCarousel(true);
	};

	const onDrop = useCallback(
		acceptedFiles => {
			const newFile = acceptedFiles.map(file =>
				Object.assign(file, { imageurl: URL.createObjectURL(file) })
			);
			if (newFile.length > 0) {
				set([...present, newFile[0]]);
			}
		},
		[present]
	);

	const { getRootProps, getInputProps } = useDropzone({
		maxSize: MAX_SIZE,
		multiple: false,
		onDrop
	});

	const handleUndo = () => {
		if (canUndo) {
			undo();
			handleClose();
		}
	};

	const action = (
		<>
			<Button className={classes.textButton} size='small' onClick={handleUndo}>
				UNDO
			</Button>
			<Icon
				aria-label='undo change'
				className={classes.link}
				onClick={handleClose}
				path={icons.close}
				size={1}
			/>
		</>
	);

	const parsedFiles = present.filter(file => Boolean(file.imageurl));

	useEffect(() => {
		const handleEsc = event => {
			if (event.code === 'Escape') {
				event.stopPropagation();
				closeCarousel();
			}
		};

		if (isOpenCarousel) {
			window.addEventListener('keydown', handleEsc, true);
		} else {
			window.removeEventListener('keydown', handleEsc, true);
		}

		return () => {
			window.removeEventListener('keydown', handleEsc, true);
		};
	}, [isOpenCarousel]);

	const handleCloseBackdrop = event => {
		if (event.target.type === 'button') return;
		closeCarousel();
	};

	if (multiple) {
		return (
			<>
				<Snackbar
					action={action}
					anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
					autoHideDuration={6000}
					message='Image deleted'
					onClose={handleClose}
					open={isOpen}
				/>
				{isOpenCarousel && (
					<Backdrop
						className={classes.backdrop}
						onClick={handleCloseBackdrop}
						open={isOpenCarousel}
					>
						<Carousel
							selectedItem={startIndex}
							showThumbs={false}
							showIndicators={false}
							showArrows
							showStatus={false}
						>
							{parsedFiles.map(file => (
								<img
									alt={file.uuid}
									src={file.imageurl}
									style={{ width: '50vw' }}
								/>
							))}
						</Carousel>
					</Backdrop>
				)}
				<Grid container rowSpacing={2} columnSpacing={2}>
					{parsedFiles.map((file, index) => (
						<Grid key={file.uuid} item xs={4}>
							<div className={classes.imageContainer}>
								{isUser ? null : (
									<div
										className={classes.remove}
										onClick={() => handleRemove(file)}
									>
										<Icon
											aria-label='remove image'
											color='#FFF'
											path={icons.close}
											size={1}
										/>
									</div>
								)}
								<img
									alt={file.uuid}
									className={classes.image}
									loading='lazy'
									onClick={() => openCarousel(index)}
									src={file.imageurl}
								/>
							</div>
						</Grid>
					))}
					<Grid item xs={parsedFiles.length === 0 ? 12 : 4}>
						<div {...getRootProps()} className={classes.dropzone}>
							<div className={classes.container}>
								<Icon aria-label='add image' path={icons.addImage} size={1} />
								<Typography className={classes.dropzoneText}>
									Add an Image
								</Typography>
							</div>
							<input {...getInputProps()} />
						</div>
					</Grid>
				</Grid>
			</>
		);
	}

	if (parsedFiles.length) {
		return (
			<>
				{isOpenCarousel && (
					<Backdrop
						className={classes.backdrop}
						onClick={handleCloseBackdrop}
						open={isOpenCarousel}
					>
						<Carousel
							showThumbs={false}
							showIndicators={false}
							showArrows
							showStatus={false}
						>
							{parsedFiles.map(file => (
								<img alt={file.uuid} src={file.imageurl} />
							))}
						</Carousel>
					</Backdrop>
				)}
				{parsedFiles.map((file, index) => (
					<div key={file.uuid} className={classes.imageContainer}>
						{isUser ? null : (
							<div
								className={classes.remove}
								onClick={() => handleRemove(file)}
							>
								<Icon
									aria-label='remove image'
									color='#FFF'
									path={icons.close}
									size={1}
								/>
							</div>
						)}
						<img
							alt={file.uuid}
							className={classes.image}
							loading='lazy'
							onClick={() => openCarousel(index)}
							src={file.imageurl}
						/>
					</div>
				))}
			</>
		);
	}

	return (
		<>
			<Snackbar
				action={action}
				anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
				autoHideDuration={6000}
				message='Image deleted'
				onClose={handleClose}
				open={isOpen}
			/>
			<div {...getRootProps()} className={classes.dropzone}>
				<div className={classes.container}>
					<Icon aria-label='add image' path={icons.addImage} size={1} />
					<Typography className={classes.dropzoneText}>Add an Image</Typography>
				</div>
				<input {...getInputProps()} />
			</div>
		</>
	);
}

export default ImageForm;
