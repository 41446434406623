import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { withCookies } from 'react-cookie';
import { useStoreon } from 'storeon/react';
import dayjs from 'dayjs';

import CommentForm from './CommentForm';
import CommentHistory from './CommentHistory';
import Loading from '../common/elements/Loading';
import Api from '../../api/Api';
import useStyles from './styles';

function Comments({ type, typeid }) {
	const classes = useStyles();
	const { dispatch, user } = useStoreon('user');

	const [comments, setComments] = useState(null);

	const loadComments = useCallback(
		() =>
			Api.listComments(type, typeid)
				.then(response => {
					const groupedComments = response.data.reduce(
						(acc, comment) => ({
							...acc,
							[dayjs(comment.createddate).format('YYYY/MM/DD')]: [
								...(acc[dayjs(comment.createddate).format('YYYY/MM/DD')] || []),
								comment
							]
						}),
						{}
					);
					setComments(groupedComments);
				})
				.catch(() =>
					dispatch('notification/add', {
						message: 'There was a problem loading comments',
						severity: 'error',
						variant: 'banner'
					})
				),
		[]
	);

	useEffect(() => {
		loadComments();
	}, []);

	if (!comments)
		return (
			<Box>
				<Loading />
			</Box>
		);

	return (
		<Box height={'84vh'}>
			<Box className={classes.history}>
				<CommentHistory
					callback={loadComments}
					comments={comments}
					type={type}
					typeid={typeid}
					user={user}
				/>
			</Box>
			<Box px={3} className={classes.footer}>
				<CommentForm callback={loadComments} type={type} typeid={typeid} />
			</Box>
		</Box>
	);
}

export default withCookies(Comments);
