import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	tableTitle: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '24px',
		whiteSpace: 'nowrap'
	},
	container: {
		paddingBottom: '101px'
	},
	topSticky: {
		top: 0,
		position: 'sticky',
		zIndex: 10
	},
	footer: {
		borderTop: '.5px solid #BDBDBD',
		position: 'fixed',
		zIndex: 10,
		background: '#FFF',
		bottom: 0,
		padding: theme.spacing(4),
		width: '100%'
	},
	content: {
		height: '100%'
	}
}));
