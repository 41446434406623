import { makeStyles } from '@material-ui/core';

export default makeStyles(() => ({
	comment: {
		color: '#616161',
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: '500',
		letterSpacing: '0.1px',
		marginBottom: '4px'
	},
	container: {
		borderBottom: '1px solid #BDBDBD'
	},
	description: {
		color: 'rgba(0, 0, 0, 0.87)',
		letterSpacing: '0.15px',
		fontFamily: 'Roboto',
		fontWeight: '400',
		fontSize: '14px'
	},
	fullName: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 700,
		letterSpacing: '0.15px',
		marginRight: '10px'
	},
	date: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 500,
		letterSpacing: '0.1px'
	},
	link: {
		cursor: 'pointer'
	}
}));
