/* eslint-disable import/prefer-default-export, unicorn/no-null, unicorn/prevent-abbreviations, @typescript-eslint/no-explicit-any, @typescript-eslint/restrict-template-expressions */

import * as stringUtil from '../string/stringUtil';
import { getLogger } from '../log/logUtil';

const LOG = getLogger('observation/observationUtil');
/**
 * https://linear.app/conserv1/issue/PRO-361/bug-notes-and-description-discrepancy-for-ipm-observations
 *
 * An ObservationModel object has a description(string) attribute
 * An ObservationModel also has a `observation` JSON attribute (confusing for sure)
 * And that `observation` attribute can have it's own `description`
 *
 * So which to DISPLAY?
 *
 * Assuming there context-agnostic rules that can be used, these are them...
 *
 * Assumption:
 * [CURRENT SOLN] Display both
 *
 * @param observationModel
 * @returns
 */

export function tryGetCompositeObservationDescriptionForDisplay(
	observationModel: ObservationModel
): string | undefined {
	let retval;

	// observation.description vs observation.observation.description  (https://linear.app/conserv1/issue/PRO-361)

	/*
	 * "Apply some sort of logic like "if there's a value in the
	 * description field, show it, otherwise, show anything labeled
	 * description in the observation column, otherwise show nothing".
	 * Apply this to the description fields for IPM and general
	 * observations, and the notes section for IPM identify.
	 *
	 * https://linear.app/conserv1/issue/PRO-361/bug-notes-and-description-discrepancy-for-ipm-observations
	 * */

	try {
		if (stringUtil.isPresent(observationModel.description)) {
			retval = observationModel.description;
		}

		retval = stringUtil.tryTrim(retval);

		if (stringUtil.isPresent(observationModel.observation?.description)) {
			if (retval == null) {
				retval = '';
			}

			// eslint-disable-next-line unicorn/prefer-ternary
			if (retval.length > 0) {
				retval = `${retval} (${
					stringUtil.tryTrim(
						observationModel.observation?.description
					) as string
				})`;
			} else {
				retval = observationModel.observation?.description;
			}
		}
	} catch (error: any) {
		// ignore exception
		LOG.warn(
			`tryGetCompositeObservationDescriptionForDisplay(): Swallowing exception: ${error}`
		);
	}

	retval = stringUtil.tryTrim(retval);

	if (retval == null) {
		return undefined;
	}

	return retval;
}

// This is a simpified representation of the sequelize Observation Model
interface ObservationModel {
	description?: string;
	observation?: {
		description?: string;
	};
}
