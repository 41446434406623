import React, { useContext, useState } from 'react';
import { Box, Dialog } from '@material-ui/core';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Header from './Header';
import Footer from './Footer';
import NewEvent from './NewEvent';
import Stepper from './Stepper';

import Define from './Define';
import Review from './Review';
import Setup from './Setup';
import Subscribe from './Subscribe';
import SuccessMessage from './SuccessMessage';
import Trigger from './Trigger';

import ConfirmationModal from '../../common/drawer/v2/ConfirmationModal';
import useValidateStep from './useValidateStep';
import useStyles from './styles';
import { PreferencesContext } from '../../../providers/PreferencesProvider';
import { useStoreon } from 'storeon/react';
import SegmentUtil from '../../../util/SegmentUtil';
import useSchema from '../Utils/schema/useSchema';

const STEPS = {
	0: Setup,
	1: Define,
	2: Trigger,
	3: Subscribe,
	4: Review
};

const STEPS_LABELS = ['Setup', 'Define', 'Trigger', 'Subscribe', 'Review'];

const DEFAULT_STATE_COMPLETED = {
	0: false,
	1: false,
	2: false,
	3: false,
	4: false
};

function EventStepper({ onCancel, isOpen = false, selectedScope }) {
	const { eventProfiles, dispatch, user, users } = useStoreon(
		'eventProfiles',
		'user',
		'users'
	);
	const context = useContext(PreferencesContext);
	const [showCloseModal, setShowCloseModal] = useState(false);
	const [showFlow, setShowFlow] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [completedSteps, setCompletedSteps] = useState(DEFAULT_STATE_COMPLETED);

	const isInitialModal = !showSuccess && !showFlow;

	const classes = useStyles(isInitialModal);
	const restrictedNames = eventProfiles.list
		? eventProfiles.list.map(e => e.name)
		: [];
	const schema = useSchema({ restrictedNames });
	const methods = useForm({
		defaultValues: {
			subscribers: [],
			mySubscription: true,
			...(selectedScope?.scope ? { scope: selectedScope?.scope } : {}),
			...(selectedScope?.list
				? { eventprofilescopes: selectedScope?.list }
				: {})
		},
		mode: 'onChange',
		shouldFocusError: true,
		resolver: yupResolver(schema)
	});

	const { isDirty } = methods.formState;

	const currentValues = methods.watch();

	const isValidStep = useValidateStep({
		step: currentStep,
		values: currentValues,
		restrictedNames
	});

	const handleBack = () => {
		const type = methods.getValues('type');

		if (type === 'leak' && currentStep === 3) {
			return setCurrentStep(prevStep => prevStep - 2);
		}

		setCurrentStep(prevStep => prevStep - 1);
	};

	const handleContinue = () => setShowFlow(true);

	const formatData = values => {
		const getUserInfo = email => {
			const foundUser = users.find(currentUser => currentUser.email === email);

			if (foundUser) return foundUser.uuid;

			return null;
		};

		const buildSubscribersList = () => {
			if (!values.subscribers) return [];

			return values.subscribers.map(email => ({
				email,
				subscriberUuid: getUserInfo(email)
			}));
		};

		const shouldISubscribe = values.mySubscription;

		const subscribeMe = shouldISubscribe
			? {
					subscriberUuid: user.uuid,
					status: values.mySubscription
			  }
			: null;

		const subscribersList = subscribeMe
			? [...buildSubscribersList(), subscribeMe]
			: buildSubscribersList();

		subscribersList.map(() =>
			SegmentUtil.track(SegmentUtil.actions.addsubscriber, {
				category: 'app',
				where: 'Stepper'
			})
		);

		const newEditedEventProfile = {
			name: values.name,
			scope: values.scope,
			type:
				values.type !== 'leak' && values.type !== 'shock'
					? 'reading'
					: values.type,
			eventprofilescopes: values.eventprofilescopes?.map(scopeduuid => ({
				scopeduuid
			})),
			subscribersList,
			eventtype:
				values.type === 'shock'
					? 'vibration-start'
					: values.type === 'leak'
					? 'leak-detection-start'
					: values.type === 'offline'
					? 'offline'
					: null
		};

		if (newEditedEventProfile.type === 'reading') {
			// lagstart and lagend are being sent in minutes, then we are converting it back when loading the drawer
			newEditedEventProfile.params = {
				reading: values.type,
				lagEnd:
					values.lagEndUnit === 'day'
						? values.lagEnd * 1440
						: values.lagEndUnit === 'hour'
						? values.lagEnd * 60
						: values.lagEnd,
				lagEndUnit: values.lagEndUnit,
				lagStart:
					values.lagStartUnit === 'day'
						? values.lagStart * 1440
						: values.lagStartUnit === 'hour'
						? values.lagStart * 60
						: values.lagStart,
				lagStartUnit: values.lagStartUnit,
				rangeStart: values?.rangeStart
					? context.convertUnitToApi2(values?.type, values?.rangeStart)
					: null,
				rangeEnd: values?.rangeEnd
					? context.convertUnitToApi2(values?.type, values?.rangeEnd)
					: null,
				threshold: values.threshold,
				thresholdValue: values?.thresholdValue
					? context.convertUnitToApi2(values?.type, values?.thresholdValue)
					: null
			};
		}

		dispatch('eventprofiles/edit', newEditedEventProfile);
	};

	const handleFinalStep = () => {
		dispatch('eventprofiles/save');
		setShowSuccess(true);
		setShowFlow(false);
	};

	const handleCreate = () => {
		methods.reset();
		setCurrentStep(0);
		setShowSuccess(false);
		setShowFlow(true);
	};

	const handleNext = async () => {
		setCompletedSteps(prevSteps => ({ ...prevSteps, [currentStep]: true }));

		if (currentStep === 3) {
			// On review screen we need to format the data to fetch the resume text
			await methods.handleSubmit(formatData)();
		}

		setCurrentStep(prevStep => prevStep + 1);
	};

	const handleStepClick = step => {
		if (completedSteps[step]) {
			setCurrentStep(step);
		}
	};

	const handleLeave = () => {
		if (isDirty) {
			setShowCloseModal(true);
		} else {
			onCancel();
		}
	};

	const StepComponent = STEPS[currentStep];

	const isBackVisible = currentStep > 0;

	const isFinalStep = currentStep === Object.keys(STEPS).length - 1;

	if (showSuccess) {
		return (
			<Dialog open={isOpen} classes={{ paper: classes.successPaper }}>
				<Header variant='simple' />
				<SuccessMessage onBack={onCancel} onCreate={handleCreate} />
			</Dialog>
		);
	}

	if (showFlow) {
		return (
			<FormProvider {...methods}>
				<Dialog open={isOpen} classes={{ paper: classes.paper }}>
					<Header
						CustomHeader={
							<Box className={classes.stepper}>
								<Stepper
									activeStep={currentStep}
									completedSteps={completedSteps}
									onStepClick={handleStepClick}
									steps={STEPS_LABELS}
								/>
							</Box>
						}
						variant='customHeader'
					/>
					<StepComponent />
					<Footer
						backVisible={isBackVisible}
						isFinalStep={isFinalStep}
						isNextDisable={!isValidStep}
						onBack={handleBack}
						onCancel={handleLeave}
						onFinalStep={handleFinalStep}
						onNext={handleNext}
					/>
				</Dialog>
				{showCloseModal ? (
					<ConfirmationModal
						open={showCloseModal}
						onCancel={() => {
							onCancel();
							setShowCloseModal(false);
						}}
						onClose={() => setShowCloseModal(false)}
						onAccept={() => setShowCloseModal(false)}
						title='Unsaved Event'
						text='You are about to leave this page without saving your new event. Everything you completed so far will be lost. Do you want to leave anyway?'
						cancelButtonLabel='Leave without saving'
						acceptButtonLabel='Keep creating event '
					/>
				) : null}
			</FormProvider>
		);
	}

	return (
		<Dialog open={isOpen} classes={{ paper: classes.paper }}>
			<Header variant='simple' />
			<NewEvent onContinue={handleContinue} />
		</Dialog>
	);
}

export default EventStepper;
