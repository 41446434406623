/* eslint-disable import/prefer-default-export */
/* eslint-disable unicorn/filename-case */

const DI_TYPES = {
	DeveloperModeService: 'DeveloperModeService',
	DamageRiskProviderService: 'DamageRiskProviderService',
	SensorReadingProviderService: 'SensorReadingProviderService'
};

export { DI_TYPES };
