import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

interface StyledDatePickerProperties {
    label: string;
    value: Dayjs | null | undefined;
    onChange: (value: Dayjs | null) => void;
    minDate?: Dayjs;
    maxDate?: Dayjs;
}

function StyledDatePicker({
    label,
    value,
    onChange,
    minDate,
    maxDate
}: StyledDatePickerProperties) {
    return (
        <DesktopDatePicker
            label={label}
            inputFormat='MM/DD/YYYY'
            value={value}
            onChange={onChange}
            minDate={minDate}
            maxDate={maxDate}
            disableFuture
            renderInput={(parameters) => (
                <TextField
                    {...parameters}
                    variant='standard'
                    sx={{
                        '.MuiInputBase-root': {
                            display: 'flex',
                            alignItems: 'flex-end',
                            height: '32px'
                        },
                        '.MuiInputBase-input': {
                            padding: '6px 0'
                        },
                        '.MuiSvgIcon-root': {
                            marginRight: '10px',
                            marginBottom: '2px',
                            fontSize: '20px'
                        },
                        '.MuiInputAdornment-root': {
                            marginTop: '0px',
                            height: '100%',
                            maxHeight: 'none'
                        },
                        '.MuiInput-underline': {
                            '&:before': {
                                borderBottomWidth: '1px'
                            }
                        }
                    }}
                />
            )}
        />
    );
}

StyledDatePicker.defaultProps = {
    maxDate: undefined,
    minDate: undefined
};

export default StyledDatePicker; 