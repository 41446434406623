import React from 'react';
import { Box, Avatar } from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';
import { useStoreon } from 'storeon/react';
import SidebarList from './SidebarList';
import full from '../../res/img/full.png';
import square from '../../res/img/square.png';
import { colors } from '../../style/colors';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	drawerContent: {
		bottom: 0,
		height: `calc(100% - ${56}px)`
	},
	fullAv: {
		marginLeft: theme.spacing(1),
		marginTop: theme.spacing(1.5),
		marginBottom: theme.spacing(1.5),
		width: `calc(${drawerWidth - theme.spacing(2) + 1}px)`,
		borderRadius: 0
	},
	squareAv: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		width: theme.spacing(5)
	},
	subscription: {
		marginLeft: theme.spacing(2),
		marginTop: theme.spacing(2),
		width: `calc(${drawerWidth - theme.spacing(4) + 1}px)`
	}
}));

function SidebarContainer(props) {
	const classes = useStyles();
	const { open } = props;

	const { customer } = useStoreon('customer');

	let fullLogo = full;
	let squareLogo = square;

	if (customer?.fulllogo) fullLogo = customer.fulllogo;
	if (customer?.squarelogo) squareLogo = customer.squarelogo;

	return (
		<>
			<Box display='flex' justifyContent='center'>
				<Avatar
					src={open ? fullLogo : squareLogo}
					className={open ? classes.fullAv : classes.squareAv}
				/>
			</Box>
			<Box
				bgcolor={colors.ice.shade}
				color='secondary.contrastText'
				className={classes.drawerContent}
			>
				<SidebarList />
			</Box>
		</>
	);
}

export default SidebarContainer;
