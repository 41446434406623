import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	wideCard: {
		minWidth: '300px'
	},
	firstSection: {
		marginTop: '18px'
	},
	content: {
		paddingLeft: 0,
		paddingRight: 0
	},
	section: {
		marginLeft: '20px',
		marginRight: '20px'
	},
	subsSection: {
		paddingTop: '12px'
	},
	alert: {
		marginBottom: '16px'
	},
	title: {
		fontFamily: 'Montserrat',
		fontWeight: 'bold',
		fontSize: '20px',
		lineHeight: '24px'
	},
	divider: {
		marginTop: '16px',
		marginBottom: '18px'
	},

	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		gap: 10
	},
	activeChip: {
		color: 'black',
		background: 'red',
		maxHeight: '32px',
		left: 0,
		top: 0,
		border: 0,
		borderRadius: '16px',
		padding: '4px',
		'& .MuiChip-label': {
			fontFamily: 'Roboto',
			fontSize: '13px',
			lineHeight: '18px',
			paddingBottom: '7px',
			paddingTop: '7px',
			paddingLeft: 0,
			paddingRight: 0
		}
	},
	body: {
		color: 'rgba(0, 0, 0, 0.87)',
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontWeight: 'normal',
		lineHeight: '150%',
		letterSpacing: '0.15px',
		margin: '8px 0'
	},
	endAdornment: {
		fontFamily: 'Roboto'
	},
	link: {
		color: '#2898C4 !important',
		fontWeight: 'bold',
		cursor: 'pointer'
	},
	noPaddingTop: {
		paddingTop: '0 !important'
	},
	topSubTitle: {
		marginTop: '8px'
	},
	me: {
		color: '#000',
		fontWeight: 400,
		fontSize: '16px',
		letterSpacing: '0.15px'
	},
	meStyled: {
		fontWeight: 'bold'
	},
	subscribed: {
		border: '1px solid #4CAF50',
		borderRadius: '4px',
		color: '#4CAF50'
	},
	subscribersContainer: {
		maxHeight: '260px',
		overflow: 'auto',
		marginBottom: '10px'
	},
	subscriber: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		margin: '1rem 0'
	},
	subscriberInfo: {
		alignItems: 'center',
		display: 'flex',
		gap: 16
	},
	textDescription: {
		color: 'rgba(0, 0, 0, 0.6)',
		fontSize: '12px',
		letterSpacing: '0.4px',
		marginLeft: '14px',
		marginTop: '3px'
	},
	radioContainer: {
		padding: '0 !important',
		display: 'flex',
		alignItems: 'center'
	},
	checkboxText: {
		fontFamily: 'Roboto',
		fontSize: '16px'
	},
	checkbox: {
		marginRight: '23px'
	},
	chip: {
		marginTop: '8px',
		marginBottom: '8px',
		marginRight: '8px',
		textTransform: 'capitalize'
	},
	chipText: {
		fontFamily: 'Roboto',
		fontSize: '13px',
		fontWeight: 400,
		lineHeight: '18px',
		maxWidth: '116px'
	},
	subscriberOption: {
		alignItems: 'center',
		display: 'flex',
		padding: '0 16px'
	},
	subscriberUsername: {
		padding: '0 16px'
	},
	errorChip: {
		'& .MuiChip-deleteIcon': {
			color: 'yellow'
		}
	},
	triggerSection: {
		marginTop: '24px'
	}
}));
