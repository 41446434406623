/* eslint-disable @typescript-eslint/no-unsafe-argument */
// import { useState, useEffect } from 'react';
import { useState } from 'react';
import { Button } from '@mui/material';
import CreateOrderModal from 'components/CreateOrderModal/CreateOrderModal';

interface CreateOrdersButtonParameters {
	customers: unknown[];
}

function CreateOrdersButton({ customers }: CreateOrdersButtonParameters) {
	const [open, setOpen] = useState(false);
	const onCloseDialog = () => {
		setOpen(false);
		console.log('closed dialog handler');
	};

	const onClickSendButton = () => {
		setOpen(true);
	};
	return (
		<>
			{open ? (
				<CreateOrderModal
					isOpen={open}
					onCloseDialog={onCloseDialog}
					customers={customers}
				/>
			) : // eslint-disable-next-line unicorn/no-null
			null}

			<Button
				variant='contained'
				size='small'
				onClick={() => onClickSendButton()}
			>
				Create Order...
			</Button>
		</>
	);
}

export default CreateOrdersButton;
