import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		background: 'transparent'
	},
	text: {
		color: 'white',
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '22px',
		letterSpacing: '0.10000000149011612px'
	},
	step: {
		'& $completed': {
			color: 'white',
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 700,
			lineHeight: '22px',
			letterSpacing: '0.10000000149011612px'
		},
		'& $active': {
			color: 'white',
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: '700 !important',
			lineHeight: '22px',
			letterSpacing: '0.10000000149011612px'
		},
		'& $label': {
			color: 'white',
			fontFamily: 'Roboto',
			fontSize: '14px',
			fontStyle: 'normal',
			fontWeight: 400,
			lineHeight: '20px',
			letterSpacing: '0.15000000596046448px'
		},
		'& $disabled': {
			color: 'red'
		}
	},
	alternativeLabel: {},
	active: {}, // needed so that the &$active tag works
	completed: {},
	disabled: {},
	label: {},
	iconCompleted: {
		fill: 'white'
	}
}));

function CompletedStepIcon(props) {
	return (
		<img
			alt='conserv logo'
			style={{ width: '48px%' }}
			src='/img/completed_step_icon.svg'
		/>
	);
}

function HorizontalLinearStepper({ steps, activeStep }) {
	const classes = useStyles();

	return (
		<Stepper activeStep={activeStep} classes={{ root: classes.root }}>
			{steps.map((label, index) => {
				const stepProps = {};
				const labelProps = {
					classes: {
						root: classes.step,
						completed: classes.completed,
						active: classes.active,
						label: classes.label
					},
					StepIconProps: {
						classes: {
							completed: classes.iconCompleted
						}
					}
				};
				if (index < activeStep) {
					labelProps.StepIconComponent = CompletedStepIcon;
				}
				return (
					<Step key={label} {...stepProps}>
						<StepLabel {...labelProps}>{label}</StepLabel>
					</Step>
				);
			})}
		</Stepper>
	);
}

export default HorizontalLinearStepper;
