import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../style/colors';

export default makeStyles(theme => ({
	content: {
		width: '625px',
		paddingTop: 0,
		paddingBottom: theme.spacing(4),
		paddingLeft: 0,
		paddingRight: 0
	},
	subContent: {
		paddingLeft: theme.spacing(7),
		paddingRight: theme.spacing(7)
	},
	title: {
		fontFamily: 'Montserrat',
		fontSize: '24px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: '31px',
		letterSpacing: '0em'
	},
	text: {
		fontFamily: 'Roboto',
		fontSize: '16px',
		fontStyle: 'normal',
		fontWeight: 400,
		lineHeight: '18.75px'
	},
	button: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: theme.spacing(9),
		paddingLeft: theme.spacing(9),
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		background: colors.elektra.primary
	},
	copyButton: {
		color: 'white',
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		paddingRight: '25.5px',
		paddingLeft: '25.5px',
		paddingTop: '6px',
		paddingBottom: '6px',
		background: colors.elektra.primary
	},
	backButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px'
	},
	addAnotherButton: {
		ontFamily: 'Roboto',
		fontSize: '13px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '22px',
		letterSpacing: '0.46000000834465027px',
		color: colors.elektra.primary
	},
	addAnotherIcon: {
		height: '15px',
		width: '15px',
		left: '1.5px',
		top: '1.5px',
		marginRight: '9.5px'
	},
	maybeLaterButton: {
		fontFamily: 'Roboto',
		fontSize: '15px',
		fontStyle: 'normal',
		fontWeight: 500,
		lineHeight: '26px',
		letterSpacing: '0.46000000834465027px',
		color: colors.curiousBlue.primary
	},
	input: {
		padding: '17px'
	},
	linkContainer: {
		width: '-webkit-fill-available'
	}
}));
