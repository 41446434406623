import type TreeNode from '../../types/TreeNode';

export function nodesReducer(
	accumulator: TreeNode[],
	currentValue: TreeNode
): TreeNode[] {
	accumulator.push(currentValue);
	if (currentValue.children) {
		// eslint-disable-next-line unicorn/no-array-callback-reference, unicorn/no-array-reduce
		return currentValue.children.reduce(nodesReducer, accumulator);
	}
	return accumulator;
}

export function getTreeElements(node: TreeNode) {
	return node.children?.reduce(nodesReducer, [node]) ?? [node];
}

export function findParentNodes(
	tree: TreeNode,
	nodeToFind: TreeNode
): TreeNode[] | undefined {
	if (tree === nodeToFind) return [];
	if (tree.children) {
		for (const child of tree.children) {
			const partialPath = findParentNodes(child, nodeToFind);
			if (partialPath) {
				return [tree, ...partialPath];
			}
		}
	}
	return undefined;
}

export function getParentNodes(
	tree: TreeNode[],
	nodeToFind: TreeNode
): TreeNode[] | undefined {
	for (const node of tree) {
		const path = findParentNodes(node, nodeToFind);
		if (path) return path;
	}
	return undefined;
}
