import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formValidationStyles } from '../../style/styles';

const useStyles = makeStyles(() => ({
	...formValidationStyles
}));

function FormErrorAlert(props) {
	const classes = useStyles();

	if (!props.resolved) {
		return (
			<Box
				classes={{ root: classes.errorBox }}
				display='flex'
				flexDirection='row'
				justifyContent='center'
			>
				<Typography variant='body1'>
					Please fix the fields below, or{' '}
					<Link
						classes={{ root: classes.errorLink }}
						href='#'
						onClick={props.onDiscardChanges}
					>
						leave without saving
					</Link>
				</Typography>
			</Box>
		);
	}
	return (
		<Box
			classes={{ root: classes.resolvedBox }}
			display='flex'
			flexDirection='row'
			justifyContent='center'
		>
			<Typography variant='body1'>Phew, all fixed. Looks good!</Typography>
		</Box>
	);
}

export default FormErrorAlert;
