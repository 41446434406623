import React, { useState } from 'react';
import {
	FormControl,
	Select,
	Box,
	InputLabel,
	FormHelperText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../style/colors';

const useStyles = makeStyles(theme => ({
	formContainer: {
		width: '100%'
	},
	error: {
		color: colors.amaranth.primary
	}
}));

export default function DecoratedSelect(props) {
	const classes = useStyles();
	const [open, setOpen] = useState(false);

	const passthroughProps = { ...props };
	delete passthroughProps.conservField;
	delete passthroughProps.afterAction;
	delete passthroughProps.editing;
	delete passthroughProps.helperText;

	const { children, editing, helperText, placeholder, value } = props;

	let displayValue = children?.find(opt => {
		if (opt?.props?.value === value) {
			return opt.props.children;
		}
	});

	if (!displayValue) displayValue = 'Not Set';

	return (
		<Box style={{ width: '100%' }}>
			<FormControl className={classes.formContainer} required={props.required}>
				<InputLabel id='demo-simple-select-label' variant='outlined'>
					{placeholder}
				</InputLabel>
				<Select
					{...passthroughProps}
					labelId='demo-simple-select-label'
					label={placeholder}
					disabled={!editing}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					variant='outlined'
					style={{ height: '56px' }}
				>
					{children}
				</Select>
				<FormHelperText className={classes.error}>{helperText}</FormHelperText>
			</FormControl>
		</Box>
	);
}
