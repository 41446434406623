import React, { useState, useEffect } from 'react';
import { useStoreon } from 'storeon/react';
import { Dialog, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useRoles from '../../../hooks/useRoles';
import SegmentUtil from '../../../util/SegmentUtil';
import SolidDialogHeader from '../../common/dialog/SolidDialogHeader';
import WelcomeDialog from './WelcomeDialog';
import AboutYourSelfDialog from './AboutYourselfDialog';
import TeamInviteDialog from './TeamInviteDialog';
import AccountCompletedDialog from './AccountCompletedDialog';
import Api from '../../../api/Api';
import HorizontalLinearStepper from '../../common/stepper/HorizontalLinearStepper';

const useStyles = makeStyles(theme => ({
	paper: {
		maxWidth: '625px'
	}
}));

function CompleteProfileDialog({ open: initiallyOpen }) {
	const classes = useStyles();
	const { roles } = useRoles();
	const [open, setOpen] = useState(initiallyOpen);
	const { dispatch, user, customer, welcomeFlow } = useStoreon(
		'user',
		'customer',
		'welcomeFlow'
	);
	const [updatedProfile, setUpdatedProfile] = useState({});
	const [invitations, setInvitations] = useState([]);

	useEffect(() => {
		if (!updatedProfile.first_name && customer) {
			const updatedProf = {
				first_name: user?.first_name || null,
				last_name: user?.last_name || null,
				jobtitle: (user?.job_title && user?.job_title[0]?.uuid) || null,
				customer: {
					name: customer?.name
				},
				valid: false
			};
			setUpdatedProfile(updatedProf);
		}
	}, [user, customer]);

	const handleProfileUpdate = updatedProf => {
		setUpdatedProfile(updatedProf);
	};

	const handleInvitationsUpdate = newInvitations => {
		setInvitations(newInvitations);
	};

	const updateProfile = () => {
		const toBeSentProfile = { ...updatedProfile };
		toBeSentProfile.updated_at = new Date();
		SegmentUtil.track(SegmentUtil.actions.profilecomplete);
		if (user.role === 'admin') {
			dispatch('customer/update', toBeSentProfile.customer);
			dispatch('user/updateprofile', toBeSentProfile);
		} else {
			delete toBeSentProfile.customer;
			// updating the profile will trigger a refresh of the user, which will automatically close this dialog if successful
			dispatch('user/updateprofile', toBeSentProfile);
		}
	};

	const sendInvitation = () => {
		dispatch('invites/send', invitations);
	};

	const onNext = () => {
		dispatch('welcomeFlow/update', {
			...welcomeFlow,
			step: welcomeFlow.step + 1
		});
	};

	const onBack = () => {
		dispatch('welcomeFlow/update', {
			...welcomeFlow,
			step: welcomeFlow.step - 1
		});
	};

	const onMaybeLater = () => {
		updateProfile();
		onNext();
	};

	const onNextInvitations = () => {
		sendInvitation();
	};

	const onFinish = () => {
		setOpen(false);
	};

	const validateEmails = async emails => {
		const newFormValidation = {};
		const validations = await Promise.all(
			emails.map(email => Api.validateEmail(email))
		);

		// Empty mails are valid, they are no being sent to invite new members. The line under the comment make those empty mails valid. This can be done on the reducer but the complexity make difficult to read.
		validations.forEach((res, index) => {
			res.data.valid = res.data.valid || emails[index].length === 0;
		});

		const validReducer = (prev, curr) => {
			const ret = typeof prev === 'object' ? prev.data.valid : prev;
			return ret && curr.data.valid;
		};
		const valid = validations.reduce(validReducer);
		const emailsError = validations.map((res, index) =>
			emails[index].length > 0 ? res.data.message : ''
		);
		newFormValidation.emailErrors = emailsError;
		return { valid, validationForm: newFormValidation };
	};

	return (
		<Dialog open={open || false} classes={{ paper: classes.paper }}>
			{welcomeFlow.step === 0 ? (
				<SolidDialogHeader variant='simple' />
			) : (
				<SolidDialogHeader
					variant='customHeader'
					customHeader={
						<Box
							display='flex'
							justifyContent='center'
							mt='21px'
							mb='21px'
							ml='84px'
							mr='84px'
						>
							<HorizontalLinearStepper
								steps={['Complete Profile', 'Invite Colleagues']}
								activeStep={welcomeFlow.step - 1}
							/>
						</Box>
					}
				/>
			)}
			{welcomeFlow.step === 0 && <WelcomeDialog onNext={onNext} />}
			{welcomeFlow.step === 1 && (
				<AboutYourSelfDialog
					onNext={updateProfile}
					editCallback={handleProfileUpdate}
					valid={updatedProfile.valid}
					userProfile={updatedProfile}
					user={user}
					error={welcomeFlow.errorMessage}
					roles={roles}
				/>
			)}
			{welcomeFlow.step === 2 && (
				<TeamInviteDialog
					onBack={onBack}
					onNext={onNextInvitations}
					onMaybeLater={onMaybeLater}
					editCallback={handleInvitationsUpdate}
					error={welcomeFlow.errorMessage}
					validateEmails={validateEmails}
				/>
			)}
			{welcomeFlow.step === 3 && <AccountCompletedDialog onFinish={onFinish} />}
		</Dialog>
	);
}

export default CompleteProfileDialog;
