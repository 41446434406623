const colors = {
	v2: {
		primary: '#6600FD'
	},
	text: {
		primary: '#000000'
	},
	referee: {
		primary: '#000000',
		shade: '#',
		medium: '#',
		light: '#',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	ice: {
		primary: '#370096',
		shade: '#370096',
		medium: '#B380FE',
		light: '#D9BFFF',
		primaryOpacity: 'rgba(102, 0, 253, .5)',
		shadeOpacity: 'rgba(72, 0, 179, .5)',
		mediumOpacity: 'rgba(179, 128, 254, .5)',
		lightOpacity: 'rgba(217, 191, 255, .5)',
		gradient:
			'linear-gradient(34deg, rgba(44,66,81,1) 0%, rgba(44,66,81,0.71) 100%);'
	},
	nitro: {
		primary: '#0dfccb',
		shade: '#370096',
		medium: '#86FEE5',
		light: '#C3FEF2',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: 'rgba(111, 156, 242, .5)',
		lightOpacity: 'rgba(224, 237, 255, .5)'
	},
	laser: {
		primary: '#C1C1C1',
		shade: '#ADADAD',
		medium: '#D9D9D9',
		light: '#F0F0F0',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	diamond: {
		primary: '#2CA9DA',
		shade: '#2899C5',
		medium: '#75D0F3',
		light: '#BAECFF',
		primaryOpacity:
			'linear-gradient(36deg, rgba(44,169,218,1) 0%, rgba(44,169,218,1) 35%, rgba(44,169,218,0.5368522408963585) 100%)',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	gemini: {
		primary: '#F15152',
		shade: '#E43C3D',
		medium: '#F58182',
		light: '#FCB9B9',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	diesel: {
		primary: '#6070D4',
		shade: '#',
		medium: '#',
		light: '#',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	blaze: {
		primary: '#4BAD3B',
		shade: '#',
		medium: '#',
		light: '#',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	sabre: {
		primary: '#FFF95F',
		shade: '#',
		medium: '#',
		light: '#',
		primaryOpacity: '#',
		shadeOpacity: '#',
		mediumOpacity: '#',
		lightOpacity: '#'
	},
	elektra: {
		primary: '#6600FD',
		mediumOpacity: '#6600FD80'
	},
	mercury: {
		primary: '#E5E5E5'
	},
	sunny: {
		primary: '#fcf519',
		medium: '#FEFA8C',
		light: '#FEFDC6'
	},
	heliotrope: {
		primary: '#AE89F9'
	},
	purple: {
		primary: '#4800B3'
	},
	purpleHearth: {
		primary: '#673AB7'
	},
	white: {
		primary: '#FFFFFF'
	},
	black: {
		lowOpacity: '#000000DE',
		mediumOpacity: '#0000008A'
	},
	lochmara: {
		primary: '#0B79D0'
	},
	curiousBlue: {
		primary: '#2CA9DA'
	},
	amaranth: {
		primary: '#E63946'
	},
	pomegranate: {
		primary: '#feeceb'
	},
	cherrywood: {
		primary: '#621b16'
	}
};

const stackBarColors = {
	stackColor: ['#673AB7', '#AE89F9', '#370096', '#6600FD', '#B380FE', '#D9BFFF']
};
const graphColors = {
	barColor: colors.ice.primary
};

export { colors, graphColors, stackBarColors };
