/* eslint-disable @typescript-eslint/no-type-alias */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type ProductAddedToSubscription from '../../../types/ProductAddedToSubscription';

interface AddItemAction {
	type: 'ADD_ITEM';
	payload: ProductAddedToSubscription;
}

interface RemoveItemAction {
	type: 'REMOVE_ITEM';
	payload: string;
}

interface IncreaseAmountAction {
	type: 'INCREASE_AMOUNT';
	payload: string;
}

interface DecreaseAmountAction {
	type: 'DECREASE_AMOUNT';
	payload: string;
}

interface ClearItemsAction {
	type: 'CLEAR_ITEMS';
}

interface SetAmountAction {
	type: 'SET_AMOUNT';
	payload: ProductAddedToSubscription;
}

type Action =
	| AddItemAction
	| ClearItemsAction
	| DecreaseAmountAction
	| IncreaseAmountAction
	| RemoveItemAction
	| SetAmountAction;

export default function reducerProductsToUpdate(
	state: ProductAddedToSubscription[] | [],
	action: Action
) {
	switch (action.type) {
		case 'ADD_ITEM': {
			return [...state, action.payload];
		}
		case 'CLEAR_ITEMS': {
			return [];
		}
		case 'DECREASE_AMOUNT': {
			return state.map(item =>
				item.uuid === action.payload
					? { ...item, amount: item.amount - 1 }
					: item
			);
		}
		case 'INCREASE_AMOUNT': {
			return state.map(item =>
				item.uuid === action.payload
					? { ...item, amount: item.amount + 1 }
					: item
			);
		}
		case 'REMOVE_ITEM': {
			return state.filter(item => item.uuid !== action.payload);
		}
		case 'SET_AMOUNT': {
			return state.map(item =>
				item.uuid === action.payload.uuid
					? { ...item, amount: action.payload.amount }
					: item
			);
		}
		default: {
			return state;
		}
	}
}
