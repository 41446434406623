/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable react/jsx-handler-names */
import { TextField } from '@mui/material';
import type { FieldValues, UseFormRegister } from 'react-hook-form';

interface ShippingAddressFromParameters {
	register: UseFormRegister<FieldValues>;
}
export default function ShippingAddressFrom({
	register
}: ShippingAddressFromParameters) {
	return (
		<>
			<TextField
				margin='dense'
				{...register('name')}
				label='Name'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('address.address1')}
				label='Address Line 1'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('address.address2')}
				label='Address Line 2'
				fullWidth
			/>
			<TextField
				margin='dense'
				{...register('address.company_name')}
				label='Company Name'
				fullWidth
			/>
			<TextField
				margin='dense'
				{...register('address.city')}
				label='City'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('address.state')}
				label='State'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('address.country')}
				label='Country'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('address.zip_code')}
				label='Zip Code'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('email')}
				label='Email'
				fullWidth
				required
			/>
			<TextField
				margin='dense'
				{...register('phone_number')}
				label='Phone Number'
				fullWidth
				required
			/>
		</>
	);
}
