import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Box, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useStoreon } from 'storeon/react';
import { Icon } from '@mdi/react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import ImageForm from '../../common/ImageForm';

import Api from '../../../api/Api';
import DrawerHeader from '../../common/drawer/v2/DrawerHeader';
import EditLocation from '../../editors/EditLocation/EditLocation';
import icons from '../../../style/icons';
import Loading from '../../common/elements/Loading';
import PreferencesProvider from '../../../providers/PreferencesProvider';
import ConfirmationDialog from '../../common/drawer/v2/ConfirmationDialog';
import theme from '../../../style/themev2';

import useStyles from './styles';

const schema = yup.object({
	add_1: yup.string().trim().required('Address is required'),
	city: yup.string().trim().required('City is required'),
	country: yup.string().trim().required('Country is required'),
	name: yup.string().trim().required('A location name is required'),
	state: yup.string().trim().required('State or locality is required'),
	zip: yup.string().trim().required('Postal code is required')
});

function LocationDrawer({ create, location }) {
	const classes = useStyles();
	const { dispatch } = useStoreon('locations');

	const methods = useForm({
		defaultValues: {
			ashrae: (location.ashrae || [])[0]?.uuid
		},
		shouldFocusError: true,
		mode: 'all',
		resolver: yupResolver(schema)
	});

	const [currentLocation, setCurrentLocation] = useState(null);
	const [showSaveError, setShowSaveError] = useState(false);
	const [alert, setAlert] = useState(null);
	const [ashraeOptions, setAshraeOptions] = useState([]);
	const [isDirtyImg, setIsDirtyImg] = useState(false);
	const [open, setOpen] = useState(false);

	const loadLocation = () => {
		if (location.uuid) {
			Api.getFacility(location.uuid)
				.then(({ data }) => {
					Api.getFloorsAndHvacZonesByLocation(location.uuid)
						.then(({ data: properties }) => {
							const floorsProps = properties.find(e => e.property === 'floor');
							const floors =
								floorsProps?.scope_properties.map((e, index) => ({
									uuid: e.uuid,
									primary: e.property_value,
									id: index
								})) || [];

							const hvacZoneProps = properties.find(
								e => e.property === 'hvacZone'
							);
							const hvacZone =
								hvacZoneProps?.scope_properties.map((e, index) => ({
									uuid: e.uuid,
									primary: e.property_value,
									id: index
								})) || [];

							setCurrentLocation({ ...data, floors, hvacZone });
						})
						.catch(error => {
							console.log('error', error);
							dispatch('notification/add', {
								message: 'There was an error loading floors and hvac zones',
								severity: 'error',
								variant: 'banner'
							});
						});
				})
				.catch(() => {
					dispatch('notification/add', {
						message: 'There was an error loading locations',
						severity: 'error',
						variant: 'banner'
					});
				});
		} else {
			setCurrentLocation({ ...location, floors: [], hvacZone: [] });
		}
	};

	const getPropertiesToDelete = (originalArr, newArr) =>
		originalArr.filter(old => !newArr.find(e => e.uuid === old.uuid));

	const onSubmit = data => {
		if (
			currentLocation.images &&
			(currentLocation.images || [])[0]?.uuid &&
			data.currentImages.length === 0
		) {
			Api.removeImage(currentLocation.images[0].uuid);
		}

		const floorsToDelete = getPropertiesToDelete(
			currentLocation.floors,
			data.floors
		);

		const hvacZonesToDelete = getPropertiesToDelete(
			currentLocation.hvacZone,
			data.hvacZone
		);

		[...floorsToDelete, ...hvacZonesToDelete].forEach(elem => {
			Api.deleteFacilityFloorHvacZone(location.id, elem.uuid);
		});

		const selectedAshrae = ashraeOptions.find(
			option => option.uuid === data.ashrae
		);

		if (
			data.ashrae === 'none' &&
			Array.isArray(currentLocation.ashrae) &&
			currentLocation.ashrae.length > 0
		) {
			Api.removeProperty(currentLocation.ashrae[0].scope_properties_uuid);
		}

		const ashraeProperties =
			data.ashrae === 'none'
				? null
				: selectedAshrae
				? {
						property_uuid: selectedAshrae.uuid,
						property_value: selectedAshrae.property
				  }
				: currentLocation.ashrae
				? {
						property_uuid: currentLocation.ashrae[0]?.uuid,
						property_value: currentLocation.ashrae[0]?.property
				  }
				: null;

		const editedLocation = {
			data: {
				...currentLocation,
				// uuid: currentLocation.uuid,
				address: {
					...currentLocation.address,
					add_1: data.add_1,
					city: data.city,
					country: data.country,
					elevation: data.elevation,
					state: data.state,
					zip: data.zip
				},
				ashrae: ashraeProperties,
				description: data.description,
				levelprofile: data.levelprofile,
				name: data.name,
				floorData: {
					floor: data.floors.map((e, index) => ({
						uuid: e.uuid,
						value: e.primary,
						position: index + 1
					})),
					hvacZone: data.hvacZone.map((e, index) => ({
						uuid: e.uuid,
						value: e.primary,
						position: index + 1
					}))
				}
			},
			files: (data.currentImages || [])[0]?.uuid ? [] : data.currentImages
		};

		if (Boolean(methods.formState.errors)) {
			dispatch('locations/edit', editedLocation);
			dispatch('locations/save');

			dispatch('navstate/update', {
				drawerOpen: false,
				drawerContent: null
			});
		} else {
			if (methods.formState.isDirty) {
				setShowSaveError(true);
				return false;
			}

			dispatch('navstate/update', {
				drawerOpen: false,
				drawerContent: null
			});
		}
	};

	const saveConfirmation = ev => {
		methods.handleSubmit(onSubmit)();
		setOpen(false);
	};

	const onDiscard = () => {
		dispatch('navstate/update', {
			drawerOpen: false,
			drawerContent: null,
			onDrawerClose: undefined
		});
	};

	const handleCloseDrawer = ev => {
		if (!methods.formState.isDirty && !isDirtyImg) {
			onDiscard();
		} else {
			setOpen(true);
		}
	};

	useEffect(() => {
		loadLocation();
		dispatch('navstate/update', {
			onDrawerClose: handleCloseDrawer
		});
	}, [isDirtyImg]);

	useEffect(() => {
		Api.loadASHRAEClassification()
			.then(classification => setAshraeOptions(classification.data))
			.catch(err =>
				setAlert({
					iserror: true,
					title: 'Error Loading ASHRAE Classification',
					message:
						'There has been an error loading the ASHRAE classification, please contact Conserv support for assistance'
				})
			);
	}, []);

	const getImages = useCallback(
		({ images = [] }) => (images.length > 0 && images) || [],
		[]
	);

	const getImagesCallback = images => {
		setIsDirtyImg(methods.getValues('currentImages') !== undefined);
		methods.setValue('currentImages', images);
	};

	if (!currentLocation) return <Loading />;

	return (
		<ThemeProvider theme={theme}>
			<PreferencesProvider>
				<FormProvider {...methods}>
					<Grid container direction='column'>
						<Grid item className={classes.topSticky}>
							<DrawerHeader
								closeCallback={handleCloseDrawer}
								editing
								title={currentLocation.uuid ? 'Edit Location' : 'New Location'}
							/>
						</Grid>
						{currentLocation?.dirty &&
						currentLocation?.valid &&
						!showSaveError ? (
							<Grid item>
								<Box
									pl={5}
									pr={5}
									pt={2}
									display='flex'
									flexDirection='row'
									justifyContent='center'
								>
									<Icon path={icons.save} size={0.75} />
									<Typography variant='caption'>
										Your changes will be saved when the drawer is closed
									</Typography>
								</Box>
							</Grid>
						) : null}
						<Grid item>
							<Box>
								<EditLocation
									ashraeOptions={ashraeOptions}
									autosave
									editing
									create={create}
									facility={currentLocation}
								/>
							</Box>
						</Grid>
						<Grid item>
							<Box pl={5} pr={5} pb={5} display='flex' justifyContent='center'>
								<ImageForm
									getImages={getImagesCallback}
									images={getImages(currentLocation)}
								/>
							</Box>
						</Grid>
						<Grid className={classes.footer} item>
							<Button
								color='primary'
								onClick={methods.handleSubmit(onSubmit)}
								size='md'
								variant='contained'
								disabled={!methods.formState.isDirty && !isDirtyImg}
							>
								SAVE ALL CHANGES
							</Button>
						</Grid>
					</Grid>
				</FormProvider>
			</PreferencesProvider>
			<ConfirmationDialog
				canSave={Object.keys(methods.formState.errors).length === 0}
				onGoBack={() => setOpen(false)}
				open={open}
				onSave={saveConfirmation}
				onDiscard={onDiscard}
			/>
		</ThemeProvider>
	);
}

export default LocationDrawer;
