import React from 'react';
import { Box } from '@material-ui/core';
import { Icon } from '@mdi/react';
import icons from '../../../../style/icons';
import H3 from '../../typography/H3';
import useStyles from './styles';

export default function DrawerHeader({ closeCallback, title }) {
	const classes = useStyles();
	const closeDrawer = () => {
		closeCallback && closeCallback();
	};

	return (
		<Box className={classes.headerBox} px='32px' py='24px'>
			<Box maxWidth='420px'>
				<H3>{title}</H3>
			</Box>
			<Box>
				<Icon
					className={classes.link}
					path={icons.close}
					size={1}
					onClick={closeDrawer}
				/>
			</Box>
		</Box>
	);
}
