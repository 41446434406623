import React from 'react';
import {
	Card,
	CardContent,
	Divider,
	Grid,
	Typography
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
	NameForm,
	ScopeForm,
	TypeForm,
	TriggerForm,
	SubscribersForm
} from '../../events/Forms';
import { useStoreon } from 'storeon/react';
import clsx from 'clsx';

import useStyles from './styles';

function EditEventProfile({ eventProfile, editable }) {
	const classes = useStyles();
	const { user, users } = useStoreon('user', 'users');

	return (
		<Card elevation={0} className={classes.wideCard}>
			<CardContent>
				<Grid
					container
					direction='column'
					spacing={3}
					classes={classes.content}
				>
					<Grid item className={clsx(classes.section, classes.firstSection)}>
						{!editable && (
							<Alert className={classes.alert} severity='warning'>
								This event setting cannot be edited because it has associated
								events. To edit, please create a new one.
							</Alert>
						)}
						<Typography className={classes.title} variant='h1'>
							Details
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<NameForm editable={editable} />
					</Grid>
					<Grid item className={classes.section}>
						<ScopeForm editable={editable} />
					</Grid>
					<Grid item className={classes.section}>
						<TypeForm editable={editable} />
					</Grid>
					<Divider className={classes.divider} />
					<Grid item className={classes.section}>
						<Typography className={classes.title} variant='h1'>
							Trigger
						</Typography>
						<Grid className={classes.triggerSection}>
							<TriggerForm editable={editable} />
						</Grid>
					</Grid>
					<Divider className={classes.divider} />
					<Grid item className={classes.section}>
						<Typography className={classes.title} variant='h1'>
							Subscriptions
						</Typography>
					</Grid>
					<Grid item className={classes.section}>
						<SubscribersForm
							eventProfileUuid={eventProfile.uuid}
							me={user}
							subscribers={eventProfile.eventsubs}
							users={users}
						/>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

export default EditEventProfile;
