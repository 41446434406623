import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
	tableTitle: {
		fontFamily: 'Roboto',
		fontSize: '14px',
		fontWeight: 500,
		lineHeight: '24px',
		whiteSpace: 'nowrap'
	}
}));
