import React, { Component } from 'react';
import {
	Button,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Box,
	Typography,
	Link,
	IconButton,
	Menu,
	MenuItem
} from '@material-ui/core';
import { Icon } from '@mdi/react';
import Api from '../../api/Api';
import EditUser from '../editmodals/EditUser';
import dayjs from 'dayjs';
import InviteUsers from './InviteUsers';
import { showAlert } from '../error/ConservAlertPopup';
import { connectStoreon } from 'storeon/react';
import PermissionProvider from '../../providers/PermissionProvider';
import UserAvatar from '../myaccount/UserAvatar';
import SegmentUtil from '../../util/SegmentUtil';
import icons from '../../style/icons';
import { withStyles } from '@material-ui/core/styles';
import { collapsibleTableStyles, buttonStyles } from '../../style/styles';

const useStyles = theme => ({
	...collapsibleTableStyles,
	...buttonStyles
});

class UsersMenuClass extends Component {
	constructor(props) {
		super(props);
		this.state = { anchorEl: null };
	}

	render() {
		const { classes } = this.props;

		return (
			<Box>
				<IconButton
					size='small'
					disableRipple
					classes={{ root: classes.iconLabeledLinkButton }}
					style={{ justifyContent: 'flex-end' }}
					onClick={event => this.setState({ anchorEl: event.currentTarget })}
				>
					<Icon
						style={{ minWidth: '24px' }}
						size={1}
						path={icons.more}
						aria-label='more options'
					/>
				</IconButton>
				<Menu
					key={this.props.user.uuid}
					id='simple-menu'
					anchorEl={this.state.anchorEl}
					open={Boolean(this.state.anchorEl)}
					onClose={() => this.setState({ anchorEl: null })}
					elevation={1}
				>
					<MenuItem
						onClick={() => {
							this.props.onEdit(this.props.user);
							this.setState({ anchorEl: null });
						}}
					>
						Edit Info
					</MenuItem>
					<MenuItem
						onClick={() => {
							this.props.onDelete(this.props.user);
							this.setState({ anchorEl: null });
						}}
					>
						Deactivate Account
					</MenuItem>
				</Menu>
			</Box>
		);
	}
}

class OrganizationUsersClass extends Component {
	constructor(props) {
		super(props);
		this.state = {};
		this.editUserModal = React.createRef();
		this.inviteUserModal = React.createRef();
		this.state.user = props.user;
	}

	componentDidMount() {
		this.loadUsers();
	}

	loadUsers = () => {
		Api.getCustomerInvites().then(res => {
			this.setState({ invitations: res.data });
		});
	};

	editUser = user => {
		user.confirmpass = '';
		user.new = false;
		this.editUserModal.current.setUser(user);
		this.editUserModal.current.show();
	};

	confirmDelete = user => {
		this.setState({
			alert: {
				iserror: false,
				title: 'Confirm Delete',
				destructive: true,
				message:
					'Are you sure you want to deactivate this user? They will no longer be able to log in',
				onConfirm: () => this.deleteUser(user)
			}
		});
	};

	deleteUser = user => {
		// SEGMENT - Track disabling (deleting) of account users
		SegmentUtil.track(SegmentUtil.actions.removeuser);
		Api.deactivateUser(user.id, this.props.customer.id)
			.then(result => {
				this.loadUsers();
				this.props.dispatch('notification/add', {
					message: 'User deactivated'
				});
				this.props.dispatch('users/refresh');
			})
			.catch(err => {
				this.props.dispatch('notification/add', {
					message: 'There was a problem deactivating this user',
					severity: 'error',
					variant: 'banner'
				});
			});
	};

	addUser = () => {
		this.editUserModal.current.setUser({
			new: true,
			email: '',
			first_name: 'First',
			last_name: 'Last',
			pass: '',
			confirmpass: '',
			date_of_birth: new Date(),
			role: 'user'
		});
		this.editUserModal.current.show();
	};

	inviteUser = () => {
		this.inviteUserModal.current.show(true);
	};

	resendInvite = email => {
		SegmentUtil.track(SegmentUtil.actions.resendinvitation);
		Api.inviteUsers([email])
			.then(res => {
				this.setState({ show: false, email: '' });
				this.props.dispatch('notification/add', {
					message: 'Invitation resent'
				});
			})
			.catch(err => {
				this.setState({ inviteerror: true });
				this.props.dispatch('notification/add', {
					message: 'There was an error resending the invite'
				});
			});
	};

	createInviteRows = () => {
		const { classes } = this.props;
		const rows = [];
		if (this.state.invitations) {
			this.state.invitations.forEach((invitation, index) => {
				if (
					!invitation.claimed &&
					!this.props.users.some(u => u.email === invitation.email) //some users have more than one invite
				) {
					rows.push(
						<TableRow classes={{ root: classes.level3Rows }} key={index}>
							<TableCell>
								<Link
									rel='noopener noreferrer'
									target='_blank'
									href={`mailto:${invitation.email}`}
								>
									<Typography noWrap>{invitation.email}</Typography>
								</Link>
							</TableCell>
							<TableCell>
								<Typography noWrap>
									{`${invitation.mnmxuser.first_name} ${invitation.mnmxuser.last_name}`}
								</Typography>
							</TableCell>
							<TableCell>
								<Typography noWrap>
									{dayjs(invitation.senttime).format('L LT')}
								</Typography>
							</TableCell>
							<TableCell>
								<Button
									variant='outlined'
									color='primary'
									onClick={() => this.resendInvite(invitation.email)}
								>
									Resend Invite
								</Button>
							</TableCell>
						</TableRow>
					);
				}
			});
		}
		return rows;
	};

	createUserRows = () => {
		const { classes } = this.props;
		const lines = [];
		this.props.users.forEach(user => {
			lines.push(
				<TableRow classes={{ root: classes.level3Rows }} key={lines.length}>
					<TableCell>
						<UserAvatar user={user} size='small' />
					</TableCell>
					<TableCell>
						<Typography noWrap>
							{`${user.first_name} ${user.last_name}`}
						</Typography>
					</TableCell>
					<TableCell>
						<Typography noWrap>
							{(user.customerusers &&
								user.customerusers.length > 0 &&
								user.customerusers[0].customerrole) ||
								user.role}
						</Typography>
					</TableCell>
					<TableCell>
						<Link
							rel='noopener noreferrer'
							target='_blank'
							href={`mailto:${user.email}`}
						>
							<Typography noWrap>{user.email}</Typography>
						</Link>
					</TableCell>
					<TableCell>
						<Typography noWrap>{user.timezone}</Typography>
					</TableCell>
					<TableCell className='text-right'>
						<PermissionProvider requiredRole='admin' value={{}}>
							<UsersMenu
								user={user}
								onEdit={this.editUser}
								onDelete={this.confirmDelete}
							/>
						</PermissionProvider>
					</TableCell>
				</TableRow>
			);
		});
		return lines;
	};

	render() {
		const { classes } = this.props;

		return (
			<>
				<Box display='flex' justifyContent='space-between' alignItems='center'>
					<Typography variant='h2'>Manage Members</Typography>
					<Button
						variant='contained'
						color='primary'
						className='float-right invite-members'
						onClick={this.inviteUser}
					>
						Invite Members
					</Button>
				</Box>
				<Box pt={4}>
					<Table classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell style={{ width: '60px' }} />
								<TableCell style={{ width: '20%' }}>Name</TableCell>
								<TableCell style={{ width: '20%' }}>Role</TableCell>
								<TableCell style={{ width: '25%' }}>Email</TableCell>
								<TableCell style={{ width: '20%' }}>Timezone</TableCell>
								<TableCell />
							</TableRow>
						</TableHead>
						<TableBody>{this.createUserRows(this.props.users)}</TableBody>
					</Table>
				</Box>
				<Box pt={4}>
					<Typography variant='h4'>Pending Invitations</Typography>
					<Table classes={{ root: classes.tableRoot }}>
						<TableHead>
							<TableRow>
								<TableCell>Email</TableCell>
								<TableCell>Invited By</TableCell>
								<TableCell>Invitation Sent</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.createInviteRows(this.state.invitations)}
						</TableBody>
					</Table>
				</Box>
				<EditUser
					ref={this.editUserModal}
					callback={this.loadUsers}
					user={this.state.user}
				/>
				<InviteUsers ref={this.inviteUserModal} />
				{showAlert(this.state.alert, () => this.setState({ alert: null }))}
			</>
		);
	}
}

const OrganizationUsers = connectStoreon(
	'user',
	'users',
	'customer',
	withStyles(useStyles, { withTheme: true })(OrganizationUsersClass)
);
const UsersMenu = connectStoreon(
	withStyles(useStyles, { withTheme: true })(UsersMenuClass)
);

export { OrganizationUsers, UsersMenu };
